import type {ChangeEvent} from 'react';
import {useState, useEffect} from 'react';
import {classNames} from '@/utils/helpers.ts';
import {Check} from '@/assets/icons/Check.tsx';

interface Props {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
    id?: string;
    name?: string;
    className?: string;
    ariaDescribedBy?: string;
    preventClick?: boolean;
}

export default function Checkbox({
    checked = false,
    onChange,
    disabled,
    id,
    name,
    className,
    ariaDescribedBy,
    preventClick,
}: Props) {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleCheckboxChange = () => {
        if (preventClick) {
            return;
        }
        const newChecked = !isChecked;
        setIsChecked(newChecked);
        if (onChange) {
            onChange(newChecked);
        }
    };

    const onCheckboxChange = (e: ChangeEvent) => {
        const elem = e.target as HTMLInputElement;
        setIsChecked(elem.checked);
        if (onChange) {
            onChange(elem.checked);
        }
    }

    return (
        <>
            <div className={classNames(className ?? '', disabled ? 'ui-disabled' : '', isChecked ? 'ui-checked' : '', 'ui-checkbox')} onClick={handleCheckboxChange}>
                {isChecked && (
                    <Check />
                )}
            </div>
            <input
                type="checkbox"
                className="hidden"
                name={name}
                id={id}
                checked={isChecked}
                aria-describedby={ariaDescribedBy}
                onChange={onCheckboxChange}
            />
        </>
    );
};