import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { LINK_DUPLICATES_MAX_Y, LINK_WIDTH } from '../common/constants';

export class LinkDuplicatesShape extends BaseObject{

    static interactionLayer = 1;

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [],
            {
				transparent: true,
				alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                side: THREE.DoubleSide,
				// wireframe: false
            },
            { debug: false }
        );

		return material;
    }

    updateGeometry(pointSourceLocal: THREE.Vector3, pointTargetLocal: THREE.Vector3) {
		if (!this.instancedOrBatchedMesh) return;
	
		const MAX_Y_OFFSET = LINK_DUPLICATES_MAX_Y; // Your constant max Y value
		const SEGMENTS = 30; // Number of segments to approximate the curve
	
		// Midpoint for control point in the Bezier curve
		const midPoint = new THREE.Vector3()
			.addVectors(pointSourceLocal, pointTargetLocal)
			.multiplyScalar(0.5);
		midPoint.y += MAX_Y_OFFSET; // Raise the midpoint by the max Y value
	
		const vertices = [];
		const normals = [];
		const indices = [];
		const uv = [];
	
		const tangent = new THREE.Vector3().subVectors(pointTargetLocal, pointSourceLocal).normalize();
		const up = new THREE.Vector3(0, 1, 0);
		const normal = new THREE.Vector3().crossVectors(tangent, up).normalize().multiplyScalar(LINK_WIDTH / 2);
	
		// Function to calculate quadratic Bezier point
		const getBezierPoint = (t: number) => {
			const p0 = pointSourceLocal.clone().multiplyScalar((1 - t) * (1 - t));
			const p1 = midPoint.clone().multiplyScalar(2 * (1 - t) * t);
			const p2 = pointTargetLocal.clone().multiplyScalar(t * t);
			return p0.add(p1).add(p2);
		};
	
		// Generate vertices along the bezier curve
		for (let i = 0; i <= SEGMENTS; i++) {
			const t = i / SEGMENTS;
	
			// Get the Bezier curve point at parameter t
			const bezierPoint = getBezierPoint(t);
			const leftPoint = bezierPoint.clone().sub(normal);
			const rightPoint = bezierPoint.clone().add(normal);
	
			vertices.push(leftPoint.x, leftPoint.y, leftPoint.z);
			vertices.push(rightPoint.x, rightPoint.y, rightPoint.z);
	
			const faceNormal = new THREE.Vector3().crossVectors(normal, tangent).normalize();
			normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // Left vertex normal
			normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // Right vertex normal
	
			// UV mapping (simple linear mapping)
			uv.push(i / SEGMENTS, 0);
			uv.push(i / SEGMENTS, 1);
		}
	
		// Create indices for the quads between each pair of segments
		for (let i = 0; i < SEGMENTS; i++) {
			const indexOffset = i * 2;
			indices.push(indexOffset, indexOffset + 1, indexOffset + 2);
			indices.push(indexOffset + 2, indexOffset + 1, indexOffset + 3);
		}
	
		const geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uv, 2));
		geometry.setIndex(indices);
	
		const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry(geometry);
		this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
	}
}