import {LoaderFunctionArgs, redirect,} from 'react-router-dom';
import {ErrorType, getErrorMessage} from '@/utils/error.ts';
import {useAppStore} from '@/store/Store.ts';
import {NotificationType} from '@/store/Notification.ts';

export default function LoginWithGoogleResolve() {
    return null;
}

export async function loader({request}: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');

    // check error
    if (error && error.length) {
        if (
            errorDescription &&
            (errorDescription?.includes('error saving new user'))
        ) {
            useAppStore.getState().addNotification({
                type: NotificationType.ERROR,
                title: getErrorMessage(ErrorType.USER_NOT_ALLOWED_BY_EMAIL),
                sticky: true,
            });
        } else {
            useAppStore.getState().addNotification({
                type: NotificationType.ERROR,
                title: getErrorMessage(ErrorType.GOOGLE_OAUTH_ERROR),
                sticky: true,
            });
        }
        return redirect(`/logout`);
    }

    return redirect('/');
}