import type {Symbol} from '@/three/symbols/Symbol.ts';
import type {AttributeUsed} from '@/utils/link.ts'
import {Search} from '@/assets/icons/Search.tsx';
import {useAppStore} from '@/store/Store.ts';
import {ChangeEvent, useMemo, useState} from 'react';
import {getSymbolAttributesUsed} from '@/utils/link.ts';
import AppIcon from '@/components/utils/AppIcon.tsx';
import Tooltip from '@/components/utils/Tooltip.tsx';
import {ListArm} from '@/assets/icons/ListArm.tsx';
import {Folders} from '@/assets/icons/Folders.tsx';

type Props = {
    nodeDetail: Symbol;
}

export default function SymbolAttributes({ nodeDetail }: Props) {

    const { uiInteractive } = useAppStore((state) => {
        return {
            uiInteractive: state.uiInteractive
        }
    });

    const [searchQuery, setSearchQuery] = useState<string>('');

    const attributesUsed = getSymbolAttributesUsed(nodeDetail);

    const filteredAttributes: AttributeUsed[] = useMemo(()  => {
        const query = searchQuery.trim().toLowerCase();
        if (query.length > 1) {
            return attributesUsed.filter((attributeUsed) => {
                return attributeUsed.label?.toLowerCase()?.includes(query) || attributeUsed.id?.toLowerCase()?.includes(query);
            })
        }
        return attributesUsed;
    }, [attributesUsed, searchQuery])

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || '';
        setSearchQuery(value);
    }

    return (
        <div className={'flex flex-col w-full gap-6 pt-2'}>
            <div className={'flex flex-col gap-6 w-full'}>
                <div className="relative rounded-md">
                    <div
                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <Search/>
                    </div>
                    <input
                        type="text"
                        name="searchInLinks"
                        id="searchInLinks"
                        className="ui-search-input w-full"
                        placeholder="Find an attribute..."
                        onChange={onSearch}
                        value={searchQuery}
                        disabled={!uiInteractive}
                    />
                </div>
            </div>
            <div className={'flex flex-col w-full'}>
                <div className={'text-lg font-semibold pb-4'}>Attributes in use ({attributesUsed.length})</div>
                <div>
                    {filteredAttributes.map((attributeUsed) => (
                        <div key={attributeUsed.id} className="ui-list-item-separator flex flex-col py-3">
                            <div className="flex flex-col gap-y-3">
                                <div className="flex flex-row gap-y-3 relative z-10">
                                    <div className="border-b-2 border-base-100">
                                        <AppIcon
                                            className={'rounded w-5 h-5 shrink-0 p-0.5'}
                                            iconSlug={attributeUsed.targetSymbol.iconSlug}
                                            iconWidth={12}
                                            iconHeight={12}
                                            bgColor={`#${attributeUsed.targetSymbol.color.getHexString()}`}
                                            iconSrc={attributeUsed.targetSymbol.iconURL}
                                        />
                                    </div>
                                    <div className="text-lg pl-3">
                                        <Tooltip message={attributeUsed.id} position={'top'}>
                                            {attributeUsed.label || attributeUsed.id}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="flex flex-col gap pl-6">
                                    <div className="text-base-content-400 pb-4 leading-3 cursor-default">
                                        Used in ({attributeUsed.symbols.size}) scenarios
                                    </div>
                                    {Array.from(attributeUsed.symbols.values()).map((symbol) => (
                                        <div
                                            tabIndex={-1}
                                            key={symbol.symbol.id}
                                            className="relative ui-list-item py-2 flex flex-row items-center"
                                            onClick={() => {
                                                symbol.symbol.select = true;
                                            }}
                                        >
                                            <div className="absolute text-base-content-50 -left-4 bottom-[50%] z-0">
                                                <ListArm />
                                            </div>
                                            <AppIcon
                                                className={'rounded w-5 h-5 shrink-0 p-0.5'}
                                                iconSlug={symbol.symbol.iconSlug}
                                                iconWidth={12}
                                                iconHeight={12}
                                                bgColor={`#${symbol.symbol.color.getHexString()}`}
                                                iconSrc={symbol.symbol.iconURL}
                                            />
                                            <div className="pl-3 line-clamp-2">
                                                {symbol.symbol.title}
                                            </div>
                                            {symbol.otherFolder && (
                                                <div className="ml-auto text-base-content-400">
                                                    <Folders className={'!w-4 !h-4'} />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}