import * as THREE from 'three';

// Common
export const SCALE_FACTOR = 100;

export const PREVENT_Z_FIGHTING = 0.1 / SCALE_FACTOR; // Prevents glitch when two objects are in the same position because of unavoidable rounding errors in the shaders.

// Renderer
export const RENDERER_SHADOW_MAP_WIDTH = 2048;
export const RENDERER_SHADOW_MAP_HEIGHT = 2048;

// Symbol in node panel
export const SYMBOL_CANVAS_WIDTH = 128;
export const SYMBOL_CANVAS_HEIGHT = 128;

// Ground
export const GROUND_FILL_Y_POSITION = 0 / SCALE_FACTOR + PREVENT_Z_FIGHTING;
export const GROUND_PADDING = 500 / SCALE_FACTOR;

export const GROUND_SHADOW_Y_POSITION = GROUND_FILL_Y_POSITION + PREVENT_Z_FIGHTING;
export const GROUND_SHADOW_X_OFFSET = 50 / SCALE_FACTOR;
export const GROUND_SHADOW_Z_OFFSET = -25 / SCALE_FACTOR;
export const GROUND_SHADOW_MAP_WIDTH = 512;
export const GROUND_SHADOW_MAP_HEIGHT = 512;

// Grid
export const GRID_SHORT_SUBDIV = 50 / SCALE_FACTOR;
export const GRID_LARGE_SUBDIV = 500 / SCALE_FACTOR;

// Groups
export const GROUP_ROUNDED_RECT_RADIUS = 64 / SCALE_FACTOR;
export const GROUP_HEIGHT = 20 / SCALE_FACTOR;
export const GROUP_Y_POSITION = GROUND_SHADOW_Y_POSITION + (64 / SCALE_FACTOR) * 2;
export const GROUP_SIDE_Y_POSITION = 0;
export const GROUP_TOP_Y_POSITION = GROUP_HEIGHT + PREVENT_Z_FIGHTING;

// Symbols
export const SYMBOL_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_Y_POSITION = GROUP_Y_POSITION + GROUP_TOP_Y_POSITION + PREVENT_Z_FIGHTING;

export const SYMBOL_SHAPE_Y_POSITION = PREVENT_Z_FIGHTING;

export const SYMBOL_ROUNDED_RECT_RADIUS = 12 / SCALE_FACTOR;
export const SYMBOL_CIRCLE_RADIUS = 32 / SCALE_FACTOR;
export const SYMBOL_ROUNDED_TRIANGLE_RADIUS = 12 / SCALE_FACTOR;

export const SYMBOL_PAD_SIZE = SYMBOL_SIZE * 2;
export const SYMBOL_PAD_RADIUS = SYMBOL_CIRCLE_RADIUS * 2;
export const SYMBOL_PAD_Y_POSITION = PREVENT_Z_FIGHTING;

export const SYMBOL_HEIGHT = 20 / SCALE_FACTOR;

export const GROUP_PADDING = SYMBOL_PAD_SIZE * 1.5;

export const SYMBOL_ICON_SIZE = 30 / SCALE_FACTOR;
export const SYMBOL_ICON_Y_POSITION = SYMBOL_SHAPE_Y_POSITION + SYMBOL_HEIGHT + PREVENT_Z_FIGHTING;

export const SYMBOL_DUPLICATE_INDICATOR_WIDTH = 68 / 2 / SCALE_FACTOR;
export const SYMBOL_DUPLICATE_INDICATOR_HEIGHT = 100 / 2 / SCALE_FACTOR;
export const SYMBOL_DUPLICATE_INDICATOR_Y_OFFSET = (17 / SCALE_FACTOR);
export const SYMBOL_DUPLICATE_INDICATOR_Y_POSITION = SYMBOL_ICON_Y_POSITION + SYMBOL_DUPLICATE_INDICATOR_Y_OFFSET;

export const SYMBOL_HIDE_Y_POSITION = -SYMBOL_DUPLICATE_INDICATOR_Y_POSITION - SYMBOL_DUPLICATE_INDICATOR_HEIGHT;
export const GROUP_HIDE_Y_POSITION = -SYMBOL_DUPLICATE_INDICATOR_Y_POSITION - SYMBOL_DUPLICATE_INDICATOR_HEIGHT - GROUP_HEIGHT - PREVENT_Z_FIGHTING;

export const SELECCTION_INDICATOR_Y_POSITION = SYMBOL_PAD_Y_POSITION + PREVENT_Z_FIGHTING;

// Fog
export const FOG_Y_POSITION = GROUND_FILL_Y_POSITION;
export const FOG_HEIGHT = GROUP_HEIGHT * 2;

// Shared symbols
export const SYMBOL_SHARED_SIZE = 64 / SCALE_FACTOR;
export const SYMBOL_SHARED_HEIGHT = SYMBOL_SHARED_SIZE / 2;
export const SYMBOL_SHARED_Y_OFFSET = 400 / SCALE_FACTOR;
export const SYMBOL_SHARED_Y_POSITION = GROUP_Y_POSITION + GROUP_TOP_Y_POSITION + SYMBOL_SHARED_Y_OFFSET + PREVENT_Z_FIGHTING;

// Links
export const LINK_PORT_OUTLINE_RADIUS = 10 / SCALE_FACTOR;
export const LINK_PORT_RADIUS = 7 / SCALE_FACTOR;
export const LINK_PORT_Y_POSITION = SELECCTION_INDICATOR_Y_POSITION + PREVENT_Z_FIGHTING;
export const LINK_PORT_TRIGGER_SIZE_FACTOR = 1.5 / SCALE_FACTOR;

export const LINK_WIDTH = 6 / SCALE_FACTOR;
export const LINK_Y_POSITION = LINK_PORT_Y_POSITION + PREVENT_Z_FIGHTING;

export const LINK_SHAPE_Y_POSITION = LINK_Y_POSITION + PREVENT_Z_FIGHTING;

export const LINK_INTERACTIONS_OFFSET = LINK_WIDTH * 0.65;

// Link indicator
export const LINK_INDICATOR_SIZE_FACTOR = 1.5 / SCALE_FACTOR;
export const LINK_INDICATOR_VELOCITY = 200 / SCALE_FACTOR;
export const LINK_INDICATOR_Y_POSITION = LINK_SHAPE_Y_POSITION + PREVENT_Z_FIGHTING;

// Duplicates links
export const LINK_DUPLICATES_MAX_Y = 1000 / SCALE_FACTOR;

// Selection indicator
export const SELECTION_INDICATOR_OUTER_RADIUS = SYMBOL_PAD_SIZE / 2 + ((LINK_WIDTH / 1.5) / 2);
export const SELECTION_INDICATOR_INNER_RADIUS = SYMBOL_PAD_SIZE / 2 - ((LINK_WIDTH / 1.5) / 2);

// Symbol Legends
export const LEGEND_MAX_UNTRIMMED_CHARS = 18;
export const LEGEND_POSITION_Y_OFFSET = 56 / SCALE_FACTOR;
export const LEGENDS_INITIAL_ZOOM = 0.4;
export const LEGENDS_FINAL_ZOOM = 0.5;
export const LEGEND_FONT_SIZE = 13 / SCALE_FACTOR;

export const LEGEND_OUTLINE_HORIZONTAL_PADDING = 11.5 / SCALE_FACTOR;
export const LEGEND_OUTLINE_VERTICAL_PADDING = 4.5 / SCALE_FACTOR;
export const LEGEND_OUTLINE_RADIUS = 6.5 / SCALE_FACTOR;

export const LEGEND_BACKGROUND_HORIZONTAL_PADDING = 10 / SCALE_FACTOR;
export const LEGEND_BACKGROUND_VERTICAL_PADDING = 3 / SCALE_FACTOR;
export const LEGEND_BACKGROUND_RADIUS = 5 / SCALE_FACTOR;

// Group Legends
export const LEGEND_GROUPS_MAX_UNTRIMMED_CHARS = 36;
export const LEGEND_GROUPS_FONT_SIZE = 15 / SCALE_FACTOR;

export const LEGEND_GROUPS_OUTLINE_HORIZONTAL_PADDING = 12.5 / SCALE_FACTOR;
export const LEGEND_GROUPS_OUTLINE_VERTICAL_PADDING = 6.5 / SCALE_FACTOR;
export const LEGEND_GROUPS_OUTLINE_RADIUS = 7.5 / SCALE_FACTOR;

export const LEGEND_GROUPS_BACKGROUND_HORIZONTAL_PADDING = 11 / SCALE_FACTOR;
export const LEGEND_GROUPS_BACKGROUND_VERTICAL_PADDING = 5 / SCALE_FACTOR;
export const LEGEND_GROUPS_BACKGROUND_RADIUS = 6 / SCALE_FACTOR;

export const LEGEND_GROUPS_OFFSET_X = 140 / SCALE_FACTOR;
export const LEGEND_GROUPS_OFFSET_Z = 140 / SCALE_FACTOR;
export const LEGEND_GROUPS_FOLDER_WIDTH = 28 / 2 / SCALE_FACTOR;
export const LEGEND_GROUPS_FOLDER_HEIGHT = 28 / 2 / SCALE_FACTOR;

// Interaction
export const INTERACTION_SPOTLIGHT_DELAY = 0.3; // in seconds
export const INTERACTION_SPOTLIGHT_STAGGERING = 0.15; // in seconds

export const INTERACTION_ANIMATION_SPEED_SUPER_FAST = 0.1; // in seconds
export const INTERACTION_ANIMATION_SPEED_FAST = 0.3; // in seconds
export const INTERACTION_ANIMATION_SPEED_MEDIUM = 0.6; // in seconds
export const INTERACTION_ANIMATION_SPEED_SLOW = 1.2; // in seconds

export const MAX_GROUPS_TRANSITION_STAGGER = INTERACTION_ANIMATION_SPEED_SLOW; // in seconds

export const PAUSE_TO_FREE_RESOURCES = 0.3; // in seconds

export const CAMERA_FIT_WIDTH_MARGIN = 0.1; // in percentage of the viewport from 0 to 1
export const CAMERA_FIT_HEIGHT_MARGIN = 0.1; // in percentage of the viewport from 0 to 1

export const IDLE_ROATION_SPEED = 60; // in seconds
export const MAX_USER_IDLE_TIME = 300; // in seconds - 60secs * 5mins = 300secs


// Yfiles
export const YFILES_GROUPS_MIN_DISTANCE = GROUP_PADDING * 2 + SYMBOL_PAD_SIZE * 1.5;
export const YFILES_SYMBOLS_MIN_DISTANCE = SYMBOL_PAD_SIZE * 1.5;

// Theme (Light / Dark)
export const DISABLED_COLOR = new THREE.Color("#8C8C8C");

export const GROUND_COLOR_LIGHT = new THREE.Color("#DFD7E0");
export const GROUND_COLOR_DARK = new THREE.Color("#212022");

export const GROUPS_TOP_COLOR_LIGHT = new THREE.Color("#FBF8FB");
export const GROUPS_TOP_COLOR_DARK = new THREE.Color("#262526");

export const GROUPS_SIDE_COLOR_LIGHT = new THREE.Color("#c76ef0");
export const GROUPS_SIDE_COLOR_DARK = new THREE.Color("#603B66");

export const GROUPS_SIDE_TINT_COLOR_LIGHT = new THREE.Color("#EBEAEC");
export const GROUPS_SIDE_TINT_COLOR_DARK = new THREE.Color("#444245");

export const SYMBOLS_PAD_COLOR_LIGHT = new THREE.Color("#EFEDF0");
export const SYMBOLS_PAD_COLOR_DARK = new THREE.Color("#383638");

export const SYMBOLS_MUTED_TINT_LIGHT = new THREE.Color("#EBEAEC");
export const SYMBOLS_MUTED_TINT_DARK = new THREE.Color("#444245");

export const LINK_PORT_COLOR_LIGHT = new THREE.Color("#FBF8FB");
export const LINK_PORT_COLOR_DARK = new THREE.Color("#262526");

export const LINK_DEFAULT_COLOR_LIGHT = new THREE.Color("#D3CCFF");
export const LINK_DEFAULT_COLOR_DARK = new THREE.Color("#48446A");

export const LINK_TRIGGER_COLOR_LIGHT = new THREE.Color("#E2ACF1");
export const LINK_TRIGGER_COLOR_DARK = new THREE.Color("#62446A");

export const LINK_DISABLED_COLOR_LIGHT = new THREE.Color("#d4d4d4");
export const LINK_DISABLED_COLOR_DARK = new THREE.Color("#565256");

export const LINKS_MUTED_TINT_LIGHT = new THREE.Color("#EFEDF0");
export const LINKS_MUTED_TINT_DARK = new THREE.Color("#383638");

export const LINK_INDICATOR_DEFAULT_COLOR_LIGHT = new THREE.Color("#9080FF");
export const LINK_INDICATOR_DEFAULT_COLOR_DARK = new THREE.Color("#837AB8");

export const LINK_INDICATOR_TRIGGER_COLOR_LIGHT = new THREE.Color("#B164D8");
export const LINK_INDICATOR_TRIGGER_COLOR_DARK = new THREE.Color("#9960A9");

export const LEGEND_BORDER_COLOR_LIGHT = new THREE.Color("#EFEDF0");
export const LEGEND_BORDER_COLOR_DARK = new THREE.Color("#383638");

export const LEGEND_OUTLINE_COLOR_LIGHT = new THREE.Color("#EFEDF0");
export const LEGEND_OUTLINE_COLOR_DARK = new THREE.Color("#383638");

export const LEGEND_BACKGROUND_COLOR_LIGHT = new THREE.Color("#FFFFFF");
export const LEGEND_BACKGROUND_COLOR_DARK = new THREE.Color("#2E2C2E");

export const LEGEND_TEXT_COLOR_LIGHT = new THREE.Color("#393939");
export const LEGEND_TEXT_COLOR_DARK = new THREE.Color("#D5D5D5");

export const SELECCTION_INDICATOR_COLOR_LIGHT = new THREE.Color("#BC5EE8");
export const SELECCTION_INDICATOR_COLOR_DARK = new THREE.Color("#C34AFC");

export const SCENARIO_VIEW_LINK_COLOR_LIGHT = new THREE.Color("#8C8C8C");
export const SCENARIO_VIEW_LINK_COLOR_DARK = new THREE.Color("#8C8C8C");