import type { ReactNode } from 'react'
import {classNames} from '../../utils/helpers.ts';

type Props = {
  children: ReactNode;
  message: string;
  position?: 'top' | 'bottom';
  enabled?: boolean;
}

export default function Tooltip({ children, message, position, enabled }: Props) {

  if (typeof enabled === 'undefined') {
    enabled = true;
  }

  const getPositionClass = () => {
    switch (position) {
      default:
      case 'bottom':
        return 'top-10'
      case 'top':
        return '-top-9';
    }
  }

  return (
    <div className="group/tooltip relative flex">
      {children}
      <span
          className={classNames(getPositionClass(), enabled ? 'group-hover/tooltip:opacity-100' : '' ,`absolute w-max max-w-md z-50 opacity-0 pointer-events-none transition-all rounded border border-tooltip-border bg-tooltip text-tooltip-content py-1 px-2 text-xs leading-4`)}
      >
        {message}
      </span>
    </div>
  )
}