import * as THREE from 'three';
import { BaseObject } from './BaseObject';

export class BaseInstancedMesh extends THREE.InstancedMesh{

    elems: BaseObject[] = [];

    instanceOpacity: THREE.InstancedBufferAttribute | null = null;
    instanceTintAmounts: THREE.InstancedBufferAttribute | null = null;
    instanceDrawProgress: THREE.InstancedBufferAttribute | null = null;

    matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
    opacityNeedsUpdate = false;
    tintNeedsUpdate = false;
    drawProgressNeedsUpdate = false;

    shouldUpdateBoundingSphere = false;

    protected mtx = new THREE.Matrix4();
    protected vector = new THREE.Vector3();
    protected transform = new THREE.Object3D();

    constructor (geometry : THREE.BufferGeometry, material : THREE.Material, count : number){
        super(geometry, material, count);

        // this.matrixAutoUpdate = false;
        // this.matrixWorldAutoUpdate = false;
        this.frustumCulled = false;

        this.instanceOpacity = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(1.0), 1 ).setUsage(THREE.DynamicDrawUsage);
        this.instanceTintAmounts = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(0.0), 1 ).setUsage(THREE.DynamicDrawUsage);
        this.instanceDrawProgress = new THREE.InstancedBufferAttribute( new Float32Array( count ).fill(0.0), 1 ).setUsage(THREE.DynamicDrawUsage);

        this.geometry.setAttribute('instOpacity', this.instanceOpacity);
        this.geometry.setAttribute('instTintAmounts', this.instanceTintAmounts);
        this.geometry.setAttribute('instDrawProgress', this.instanceDrawProgress);

        this.opacityNeedsUpdate = true;
        this.tintNeedsUpdate = true;
        this.drawProgressNeedsUpdate = true;
    }

    sync(){
        if((this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.tintNeedsUpdate || this.drawProgressNeedsUpdate)){
            let matricesWhereUpdated = false;
            
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(this.matrixNeedsUpdate && elem.matrixNeedsUpdate){
                    this.syncMatrix(elem);

                    matricesWhereUpdated = true;
                }
                if(this.colorNeedsUpdate && elem.colorNeedsUpdate){
                    this.syncColor(elem);
                }
                if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);
                }
                if(this.tintNeedsUpdate && elem.tintNeedsUpdate){
                    this.syncTint(elem);
                }
                if(this.drawProgressNeedsUpdate && elem.drawProgressNeedsUpdate){
                    this.syncDrawProgress(elem);
                }
            }

            if(matricesWhereUpdated && this.shouldUpdateBoundingSphere){
                // needs to be computed for the raycasting and frustrum culling to work (that's why frustrum culling is disabled by default).
                this.computeBoundingSphere();
                this.matrixNeedsUpdate = false;
            }

            this.colorNeedsUpdate = false;
            this.opacityNeedsUpdate = false;
            this.tintNeedsUpdate = false;
            this.drawProgressNeedsUpdate = false;
        }
    }

    syncMatrix(elem: BaseObject){
        elem.matrixNeedsUpdate = false;

        if(elem.three.parent){
            elem.three.updateMatrix();
            elem.three.parent.updateMatrix();
            this.setMatrixAt(elem.instanceId, this.mtx.multiplyMatrices(elem.three.parent.matrix, elem.three.matrix));
            this.instanceMatrix.needsUpdate = true;
        }
	}

    syncColor(elem: BaseObject){
        elem.colorNeedsUpdate = false;
        this.setColorAt(elem.instanceId, elem.color);
        this.instanceColor!.needsUpdate = true;
    }

    syncOpacity(elem: BaseObject){
        elem.opacityNeedsUpdate = false;
        this.setOpacityAt(elem.instanceId, elem.opacity);
        this.instanceOpacity!.needsUpdate = true;
    }

    setOpacityAt(instanceId: number, opacity: number){
        this.instanceOpacity!.array[instanceId] = opacity;
    }

    syncTint(elem: BaseObject){
        elem.tintNeedsUpdate = false;
        this.setTintAt(elem.instanceId, elem.tint);
        this.instanceTintAmounts!.needsUpdate = true;
    }

    setTintAt(instanceId: number, tint: number){
        this.instanceTintAmounts!.array[instanceId] = tint;
    }

    syncDrawProgress(elem: BaseObject){
        elem.drawProgressNeedsUpdate = false;
        this.setDrawProgressAt(elem.instanceId, elem.drawProgress);
        this.instanceDrawProgress!.needsUpdate = true;
    }

    setDrawProgressAt(instanceId: number, drawProgress: number){
        this.instanceDrawProgress!.array[instanceId] = drawProgress;
    }

    override dispose() : this{
        this.geometry.dispose();
		(this.material as any).dispose();

        this.elems = [];

        return super.dispose();
    }

}