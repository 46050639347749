type Props = {
    className?: string,
}

export const MagicWand = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.93367 10.6606C1.59735 10.9969 1.59735 11.5422 1.93367 11.8785L2.81883 12.7637C3.15515 13.1 3.70043 13.1 4.03675 12.7637L10.762 6.03845L8.65887 3.93536L1.93367 10.6606Z"
                fill="currentColor"/>
            <path
                d="M8.65887 3.93536L1.93367 10.6606C1.59735 10.9969 1.59735 11.5422 1.93367 11.8785L2.81883 12.7637C3.15515 13.1 3.70043 13.1 4.03675 12.7637L10.762 6.03845M8.65887 3.93536L11.0562 1.53806C11.3925 1.20174 11.9378 1.20174 12.2741 1.53806L13.1593 2.42322C13.4956 2.75954 13.4956 3.30483 13.1593 3.64115L10.762 6.03845M8.65887 3.93536L10.762 6.03845"
                stroke="currentColor" strokeWidth="1.7224" strokeLinejoin="round"/>
            <path
                d="M2.70538 1.21721C2.78704 1.06789 3.0015 1.06789 3.08317 1.21721L3.71052 2.36427C3.73027 2.40039 3.75998 2.4301 3.7961 2.44985L4.94316 3.0772C5.09248 3.15887 5.09248 3.37333 4.94316 3.45499L3.7961 4.08234C3.75998 4.1021 3.73027 4.1318 3.71052 4.16793L3.08317 5.31499C3.0015 5.46431 2.78704 5.46431 2.70538 5.31499L2.07803 4.16793C2.05827 4.1318 2.02857 4.1021 1.99244 4.08234L0.845381 3.45499C0.69606 3.37333 0.69606 3.15887 0.845381 3.0772L1.99244 2.44985C2.02857 2.4301 2.05827 2.40039 2.07803 2.36427L2.70538 1.21721Z"
                fill="currentColor" fillOpacity="0.6" />
            <path
                d="M6.58697 0.345381C6.66864 0.19606 6.8831 0.19606 6.96476 0.345381L7.12816 0.644143C7.14792 0.680268 7.17762 0.709971 7.21375 0.729728L7.51251 0.893127C7.66183 0.974793 7.66183 1.18925 7.51251 1.27092L7.21375 1.43432C7.17762 1.45407 7.14792 1.48377 7.12816 1.5199L6.96476 1.81866C6.8831 1.96798 6.66864 1.96798 6.58697 1.81866L6.42358 1.5199C6.40382 1.48378 6.37412 1.45407 6.33799 1.43432L6.03923 1.27092C5.88991 1.18925 5.88991 0.974793 6.03923 0.893127L6.33799 0.729728C6.37412 0.709971 6.40382 0.680268 6.42358 0.644143L6.58697 0.345381Z"
                fill="currentColor" fillOpacity="0.6" />
            <path
                d="M11.7135 9.22502C11.7952 9.0757 12.0096 9.0757 12.0913 9.22502L12.7905 10.5036C12.8103 10.5397 12.84 10.5694 12.8761 10.5892L14.1547 11.2884C14.304 11.3701 14.304 11.5846 14.1547 11.6662L12.8761 12.3655C12.84 12.3853 12.8103 12.415 12.7905 12.4511L12.0913 13.7296C12.0096 13.879 11.7952 13.879 11.7135 13.7296L11.0142 12.4511C10.9945 12.415 10.9648 12.3853 10.9286 12.3655L9.65007 11.6662C9.50075 11.5846 9.50075 11.3701 9.65007 11.2884L10.9286 10.5892C10.9648 10.5694 10.9945 10.5397 11.0142 10.5036L11.7135 9.22502Z"
                fill="currentColor" fillOpacity="0.6" />
        </svg>
)
}
