import { BaseObject } from '../common/BaseObject';
import { LEGEND_GROUPS_OFFSET_X, LEGEND_GROUPS_OFFSET_Z, LEGEND_POSITION_Y_OFFSET } from '../common/constants';
import { InstancedText } from '../common/InstancedText';
import { CosmosThree } from '../CosmosThree';
import { GroupLegend } from './GroupLegend';

export class GroupLegendsTextsIM extends InstancedText{

	textNeedsUpdate = false;

    constructor(size : number){
    
        super(size);

		this.name = "groupLegendsTextsInstancedMesh";
    }

    sync (){
        let colorsWhereUpdated = false;
        let opacitiesWhereUpdated = false;
    
        /* Logger.time('[perf] mesh: update matrices'); */
        for(let i = 0 ; i < this.elems.length; i++){
            const elem = this.elems[i];
            if(elem.instanceId !== -1){
                this.syncMatrix(elem);

                if(elem.colorNeedsUpdate){
                    this.syncColor(elem);

                    colorsWhereUpdated = true;
                }

                if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
                    this.syncOpacity(elem);

                    opacitiesWhereUpdated = true;
                }
            }
        }
    
        if(colorsWhereUpdated) this.colorNeedsUpdate = false;
        if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;
    
        /* Logger.timeEnd('[perf] mesh: update matrices'); */
    }

    override syncMatrix(elem: BaseObject){
		if(elem.three.parent){
			this.vector.setFromMatrixPosition(elem.three.parent.matrix);

            this.vector.x += (elem as GroupLegend).offsetX - LEGEND_GROUPS_OFFSET_X;
			this.vector.z += (elem as GroupLegend).offsetZ - LEGEND_GROUPS_OFFSET_Z;

			this.vector.add(CosmosThree.meshOffset);
			this.vector.project(CosmosThree.graphCamera);

			this.vector.unproject(CosmosThree.guiCamera);

			// Update the matrices of the objects in the scene
			this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

			this.mtx.makeTranslation(this.vector);

			// We apply the scale of the legend to efectively 'hide' the legend if necessary
			// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
			this.mtx.scale(elem.three.scale);

			this.setMatrixAt(elem.instanceId, this.mtx);
		}
	}
}