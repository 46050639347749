import {Fragment} from 'react';
import {Transition} from '@headlessui/react';
import {CheckCircleIcon, InformationCircleIcon, ExclamationCircleIcon, XCircleIcon} from '@heroicons/react/20/solid';
import {NotificationType} from '@/store/Notification.ts';
import {useAppStore} from '@/store/Store.ts';
import {SpinnerLogo} from '@/assets/icons/Spinner.tsx';
import {CloseSmall} from '@/assets/icons/CloseSmall.tsx';

export default function Notifications() {

  const { notifications, removeNotification } = useAppStore((state) => {
    return {
      notifications: state.notifications,
      removeNotification: state.removeNotification
    }
  });

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed bottom-24 right-6 inset-0 flex items-end"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {notifications.map((item) => (
              <Transition
                key={item.id}
                show={item.show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="ui-pointer-events ui-panel py-3 px-2 w-64 max-w-sm overflow-hidden">
                    <div className="flex flex-row items-center justify-center">
                      <div className="flex items-center justify-center flex-shrink-0 w-5 h-5">
                        {item.type === NotificationType.INFO && (
                          <InformationCircleIcon className="text-info-content" aria-hidden="true" />
                        )}
                        {item.type === NotificationType.SUCCESS && (
                          <CheckCircleIcon className="text-success-content" aria-hidden="true" />
                        )}
                        {item.type === NotificationType.WARNING && (
                          <ExclamationCircleIcon className="text-warning-content" aria-hidden="true" />
                        )}
                          {item.type === NotificationType.ERROR && (
                              <XCircleIcon className="text-error" aria-hidden="true" />
                          )}
                          {item.type === NotificationType.LOADING && (
                              <SpinnerLogo className={'w-4 h-4'} />
                          )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="font-medium">{item.title}</p>
                        {item.message && (
                          <p className="mt-1 text-base-content-500">{item.message}</p>
                        )}
                      </div>
                        {item.type !== NotificationType.LOADING && item.showClose && (
                          <div className="ml-1 flex flex-shrink-0">
                            <button
                                tabIndex={-1}
                                type="button"
                                className="ui-btn-icon-close"
                                onClick={() => {
                                    removeNotification(item.id)
                                }}
                            >
                              <CloseSmall />
                            </button>
                          </div>
                        )}
                    </div>
                </div>
              </Transition>
            ))}
        </div>
      </div>
    </>
  )
}