import type {StateCreator} from 'zustand'
import {v4 as uuidv4} from 'uuid';

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    LOADING = 'loading',
}

export enum LoadingNotifications {
    MESH_LOADING = 'meshLoading',
    MESH_LAYOUTING = 'meshLayouting',
}

export enum BlueprintNotifications {
    BLUEPRINT_LOADING = 'blueprintLoading',
}

export enum InfoNotifications {
    IDLE_MODE = 'idleMode',
}

export type Notification = {
    id: string;
    type: NotificationType;
    title: string;
    show: boolean;
    message?: string;
    sticky?: boolean;
    showClose?: boolean;
    duration?: number;
}

export interface NotificationSlice {
    notifications: Notification[];
    addNotification: (options: NotificationOptions) => Notification | undefined;
    removeNotification: (id: string) => void;
}

export interface NotificationOptions {
    id?: string;
    type: NotificationType,
    title: string;
    message?: string;
    sticky?: boolean;
    showClose?: boolean;
    duration?: number;
}

export const createNotificationSlice: StateCreator<NotificationSlice> = (set, get) => ({
    notifications: [],
    addNotification: (options: NotificationOptions) => {

        let update = false;
        // check if already exists
        if (options.id && get().notifications.find((item) => {
            return options.id === item.id
        })) {
            update = true;
        }

        const newNotification: Notification = {
            id: options.id ?? uuidv4(),
            type: options.type,
            title: options.title,
            message: options.message,
            show: false,
            sticky: !!options.sticky,
            showClose: !!options.showClose,
            duration: options.duration,
        };

        set((state) => (
            {notifications: update ? state.notifications.map((item) => {
                if (item.id === newNotification.id) {
                    item.title = newNotification.title;
                    item.message = newNotification.message;
                    item.type = newNotification.type;
                }
                return item;
                }) : [...state.notifications, newNotification]}
        ))

        // need this because transitions don't work on newly added elements
        setTimeout(() => {
            set((state) => (
                {
                    notifications: state.notifications.map((notification) => {
                        if (notification.id === newNotification.id) {
                            notification.show = true;
                        }
                        return notification;
                    })
                }
            ))
        }, 0);

        if (!newNotification.sticky && newNotification.type !== NotificationType.LOADING) {
            setTimeout(() => {
                get().removeNotification(newNotification.id);
            }, newNotification?.duration ? newNotification.duration : 2000);
        }

        return newNotification;
    },
    removeNotification: (id: string) => {

        // set show to false
        set((state) => (
            {
                notifications: state.notifications.map((notification) => {
                    if (notification.id === id) {
                        notification.show = false;
                    }
                    return notification;
                })
            }
        ))

        setTimeout(() => {
            // remove
            set((state) => (
                {notifications: state.notifications.filter((notification) => notification.id !== id)}
            ))
        }, 500)
    }
})
