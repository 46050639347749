import type {Symbol} from '@/three/symbols/Symbol.ts';
import type {SearchResult} from '@/three/utils/search/SearchManager.ts';
import type {FilterItem} from '@/store/Filter.ts';
import {classNames} from '@/utils/helpers.ts';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useLazyEffect} from '@/utils/hooks.ts';
import {useAppStore} from '@/store/Store.ts';
import {Transition} from '@headlessui/react';
import UseFiltersState from '@/components/state/FiltersState.ts';
import AppIcon from '@/components/utils/AppIcon.tsx';
import {Enter} from '@/assets/icons/Enter.tsx'
import Checkbox from '@/components/utils/Checkbox.tsx'

type View = 'objects' | 'filters';

export default function SearchResults() {

    const {
        currentMeshInstance,
        filterList,
        updateActiveFilters,
        showSearchResults,
        setShowSearchResults,
        searchQuery,
        setSearchResultsCount,
        uiInteractive,
    } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance,
            filterList: state.filterList,
            updateActiveFilters: state.updateActiveFilters,
            showSearchResults: state.showSearchResults,
            setShowSearchResults: state.setShowSearchResults,
            searchQuery: state.searchQuery,
            setSearchResultsCount: state.setSearchResultsCount,
            uiInteractive: state.uiInteractive,
        }
    });

    const { activeFilters } = UseFiltersState();

    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [filterGroupOpen, setFilterGroupOpen] = useState<string | null>(null);
    const [hoveredSymbols, setHoveredSymbols] = useState<Symbol[] | null>(null);
    const [selectedSearchSymbol, setSelectedSearchSymbol] = useState<Symbol | null>(null);
    const [selectedSearchFilterItem, setSelectedSearchFilterItem] = useState<FilterItem | null>(null);
    const [view, setView] = useState<View>('objects');
    const showSearchResultsRef = useRef<boolean>(showSearchResults);
    const selectedItemIndex = useRef<number>(0);

    const selectedSearchSymbolRef = useRef<Symbol | null>(null);
    const selectedSearchFilterItemRef = useRef<FilterItem | null>(null);

    const performSearch = (query: string) => {
        if (currentMeshInstance && query?.length > 1) {
            const searchResults = currentMeshInstance.search(query);
            if (searchResults.length) {
                setSelectedSearchSymbol(searchResults[0].symbol)
            }
            setSearchResults(searchResults);
            setSearchResultsCount(searchResults.length);
        } else {
            setSearchResultsCount(0);
        }
    }

    const handleSelectedItemIndex = (index: number) => {
        switch (view) {
            case 'objects':
                if (
                    showSearchResults &&
                    searchResults.length > 1 &&
                    typeof searchResults[index] !== 'undefined'
                ) {
                    setSelectedSearchSymbol(searchResults[index].symbol);
                    const elem = document.getElementById(`searchResultObject${searchResults[index].symbol.id}`)
                    elem?.focus();
                    setHoveredSymbols(searchResults[index].duplications || [searchResults[index].symbol]);
                    return true;
                }
                break;
            case 'filters':
                if (
                    showSearchResults &&
                    filteredFilterList.length > 1 &&
                    typeof filteredFilterList[index] !== 'undefined'
                ) {
                    setSelectedSearchFilterItem(filteredFilterList[index]);
                    currentMeshInstance?.spotlightOnFilterItem(filteredFilterList[index]);
                    return true;
                }
                break;
        }
        return false;
    }

    useEffect(() => {
        selectedSearchSymbolRef.current = selectedSearchSymbol;
    }, [selectedSearchSymbol]);

    useEffect(() => {
        selectedSearchFilterItemRef.current = selectedSearchFilterItem;
    }, [selectedSearchFilterItem]);

    useEffect(() => {
        showSearchResultsRef.current = showSearchResults;
        if (!showSearchResults) {
            setHoveredSymbols(null);
        }
    }, [showSearchResults]);

    useEffect(() => {
        selectedItemIndex.current = 0;
        switch (view) {
            case 'objects':
                if (searchResults.length) {
                    setSelectedSearchSymbol(searchResults[0].symbol);
                }
                break;
            case 'filters':
                if (filteredFilterList.length) {
                    setSelectedSearchFilterItem(filteredFilterList[0]);
                }
                break;
        }
    }, [view]);

    useLazyEffect(() => {
        setFilterGroupOpen(null); // ?
        performSearch(searchQuery);
        if (searchQuery.length > 1) {
            setShowSearchResults(true);
        } else {
            setShowSearchResults(false);
        }
    }, [searchQuery], 100);

    useLazyEffect(() => {
        if (activeFilters) {
            performSearch(searchQuery);
        }
    }, [activeFilters], 100);

    const onFilterItemHover = (index: number, filterItem: FilterItem) => {
        selectedItemIndex.current = index;
        setSelectedSearchFilterItem(filterItem);
        currentMeshInstance?.spotlightOnFilterItem(filterItem);
    }

    const onFiltersLeave = () => {
        if (currentMeshInstance?._selected) {
            currentMeshInstance?.spotlight([currentMeshInstance?._selected], false);
        } else {
            currentMeshInstance?.spotlightOnFilterItem(null);
        }
    }

    useLazyEffect(() => {
        if(currentMeshInstance) {
            if (currentMeshInstance._selected) {
                return;
                // currentMeshInstance.spotlight([currentMeshInstance._selected], false);
            } else if (hoveredSymbols) {
                currentMeshInstance.spotlight(hoveredSymbols, false);
            } else {
                currentMeshInstance.spotlight([], false);
            }
        }
    }, [hoveredSymbols, currentMeshInstance], 50);

    const onSymbolHover = (index: number, searchResult?: SearchResult) => {
        selectedItemIndex.current = index;
        setSelectedSearchSymbol(searchResult?.symbol || null);
        setHoveredSymbols(!searchResult ? null : (searchResult.duplications || [searchResult.symbol]));
    }

    const onObjectsLeave = () => {
        setHoveredSymbols(null);
    }

    const onSymbolClick = (searchResult: SearchResult) => {
        if(currentMeshInstance) {
            currentMeshInstance.preventCameraReset = true;
            if (!searchResult.duplications?.length) {
                searchResult.symbol.select = true;
            } else {
                currentMeshInstance.showUsages = true;
                searchResult.duplications[0].select = true;
            }
            setShowSearchResults(false);
        }
    }

    const onCheckboxChangeCustom = (checked: boolean, group: string, item: FilterItem) => {
        updateActiveFilters(group, item, checked);
    }

    const filteredFilterList = useMemo(() => {
        const itemsToShow: FilterItem[] = [];

        const q = searchQuery.trim().toLowerCase();

        if (!filterList) {
            return [];
        }

        filterList.map((group) => {
            if (!q.length || q.length < 2) {
                return;
            }
            /*const items = group.items.filter((item) => {
                return !item.disabled;
            });*/
            const items = group.items;

            items.map((item) => {
                if (item.label && item.label.toLowerCase().includes(q)) {
                    itemsToShow.push(item);
                }
            });
        });

        // return toDisplay;
        return itemsToShow;
    }, [filterList, searchQuery, filterGroupOpen]);

    const switchView = () => {
        if (view === 'objects') {
            setView('filters');
        } else {
            setView('objects')
        }
    }

    const submitSelection = () => {
        if (view === 'objects' && selectedSearchSymbolRef.current) {
            selectedSearchSymbolRef.current.select = true;
        }
        if (view === 'filters' && selectedSearchFilterItemRef.current) {
            onCheckboxChangeCustom(!selectedSearchFilterItemRef.current.selected, selectedSearchFilterItemRef.current.groupDefinition.id, selectedSearchFilterItemRef.current);
        }
        setShowSearchResults(false);
    }

    useEffect(() => {
        const detectKeyInputs = (event: KeyboardEvent) => {
            if (showSearchResultsRef.current) {
                switch (event.key) {
                    case 'ArrowUp': {
                        event.preventDefault();
                        if (selectedItemIndex.current > 0) {
                            const newIndex = selectedItemIndex.current - 1;
                            if (newIndex >= 0 && handleSelectedItemIndex(newIndex)) {
                                selectedItemIndex.current = newIndex;
                            }
                        }
                        break;
                    }
                    case 'ArrowDown': {
                        event.preventDefault();
                        const newIndex = selectedItemIndex.current + 1;
                        if (handleSelectedItemIndex(newIndex)) {
                            selectedItemIndex.current = newIndex;
                        }
                        break;
                    }
                    case 'Tab': {
                        event.preventDefault();
                        switchView();
                        break;
                    }
                    case 'Enter': {
                        event.preventDefault();
                        submitSelection();
                        break;
                    }
                    case 'ArrowRight': {
                        event.preventDefault();
                        if (view === 'objects') {
                            setView('filters')
                        }
                        break;
                    }
                    case 'ArrowLeft': {
                        event.preventDefault();
                        if (view === 'filters') {
                            setView('objects')
                        }
                        break;
                    }
                }
            }
        }
        document.addEventListener('keydown', detectKeyInputs);
        return () => {
            document.removeEventListener('keydown', detectKeyInputs);
        }
    }, [view, showSearchResults, searchResults, filteredFilterList]);

    const renderMatches = (matches: any) => {
        let i = 0;
        return (
            <div>
                {matches.map((match: any, index: number) => {
                    const beforeHighlight = match.value.substring(0, match.indices[0][0]);
                    const highlighted = match.value.substring(match.indices[0][0], match.indices[0][1] + 1);
                    const afterHighlight = match.value.substring(match.indices[0][1] + 1);
                    if (match.key === 'title'  || i > 0) {
                        return;
                    }
                    i += 1;
                    return (
                        <span
                            className="flex break-words items-start gap-x-1 text-base-content-400 first:pt-1"
                            key={index}
                        >
                            <span className="py-1 font-semibold">{match.key}:</span>
                            <span className="rounded-md inline-block py-1 break-all">
                                {beforeHighlight}
                                <span className="inline-block px-0.5 rounded-sm bg-primary-200">{highlighted}</span>
                                {afterHighlight}
                            </span>
                        </span>
                    )
                })}
            </div>
        )
    }

    return (
        <Transition show={showSearchResults}>
            <div className={'ui-pointer-events absolute flex flex-col w-available top-12 transition duration-300 ease-in-out data-[closed]:opacity-0'}>
                <div className="ui-panel pb-3">
                    <div className="flex flex-row p-3 px-2.5 gap-2">
                        <button
                            type="button"
                            className={classNames(view === 'objects' ? 'ui-active' : '', 'ui-btn-text font-semibold')}
                            onClick={() => setView('objects')}
                        >
                            Objects ({searchResults.length})
                        </button>
                        <button
                            type="button"
                            className={classNames(view === 'filters' ? 'ui-active' : '', 'ui-btn-text font-semibold')}
                            onClick={() => setView('filters')}
                        >
                            Filters ({filteredFilterList.length})
                        </button>
                    </div>
                    {!searchResults.length && !filteredFilterList.length && (
                        <div className="flex items-center justify-center">
                            <p className="mb-6 mt-4 font-semibold">No results found</p>
                        </div>
                    )}
                    {view === 'objects' && searchResults.length > 0 && (
                        <ul
                            className="overflow-auto px-4 max-h-[80vh]"
                            onPointerLeave={
                            () => onObjectsLeave()}
                        >
                            {searchResults.map((searchResult, index) => (
                                <li
                                    key={searchResult.symbol.id}
                                    className="ui-list-item-separator"
                                    onPointerOver={() => onSymbolHover(index, searchResult)}
                                    onClick={() => onSymbolClick(searchResult)}
                                    tabIndex={-1}
                                    id={`searchResultObject${searchResult.symbol.id}`}
                                >
                                    <div
                                        className={classNames(
                                            searchResult.outside ? 'grayscale-[50%] opacity-40' : '',
                                            selectedSearchSymbol?.id === searchResult.symbol.id && uiInteractive ? 'ui-list-item-active' : '',
                                            'ui-list-item-simple flex flex-col justify-center items-start cursor-default select-none px-0.5 py-2.5'
                                        )}
                                    >
                                        <div className="flex items-center w-full">
                                            <AppIcon
                                                className={'rounded w-[1.125rem] h-[1.125rem] shrink-0 mr-3'}
                                                iconSlug={searchResult.symbol.iconSlug}
                                                bgColor={searchResult.symbol.color.getStyle()}
                                                iconWidth={10}
                                                iconHeight={10}
                                                iconSrc={`https://eu1.make.com/static/img/packages/${searchResult.symbol.iconSlug}_32.png`}
                                            />
                                            {!searchResult.symbol.originalData?.title?.length && (
                                                <span
                                                    className={classNames('truncate text-lg font-medium text-base-content-300')}>
                                                    Name unknown
                                                </span>
                                            )}
                                            <span className={classNames('text-lg font-medium [word-break:break-word] truncate')}>
                                                {searchResult.symbol.originalData?.title}
                                            </span>
                                            {
                                                uiInteractive &&
                                                selectedSearchSymbol?.id === searchResult.symbol.id && (
                                                <span className="ui-key-bind ui-active gap-1 ml-auto shrink-0">
                                                    <Enter className={'align-baseline'} /> to submit
                                                </span>
                                            )}
                                        </div>
                                        {
                                        typeof searchResult.fuseResult.matches !== 'undefined' &&
                                            searchResult.fuseResult.matches.length > 0 && (
                                                <div className="">
                                                    {renderMatches(searchResult.fuseResult.matches)}
                                                </div>
                                            )}
                                        {searchResult.outside && (
                                            <div className="absolute right-0 top-0 text-xs">filtered out</div>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                    {view === 'filters' && filteredFilterList.length > 0 && (
                        <ul
                            className="overflow-auto px-4 max-h-[80vh]"
                            onPointerLeave={() => {
                                onFiltersLeave()
                            }}
                        >
                            {filteredFilterList.map((filterItem, index) => (
                                <li
                                    key={filterItem.id}
                                    className="ui-list-item-separator"
                                    onPointerOver={() => {
                                        onFilterItemHover(index, filterItem)
                                    }}
                                    tabIndex={-1}
                                >
                                    <label
                                        className={classNames(
                                            /*filterItem.disabled ? 'grayscale-[50%] opacity-40' : '',*/
                                            selectedSearchFilterItem?.id === filterItem.id ? 'ui-list-item-active' : '',
                                            'ui-list-item-simple flex flex-col justify-center items-start cursor-default px-0.5 py-2.5'
                                        )}
                                        htmlFor={`searchResultFilter${filterItem.id}`}
                                    >
                                        <div className="flex items-center w-full">
                                            <Checkbox
                                                preventClick={true}
                                                onChange={(checked) => onCheckboxChangeCustom(checked, filterItem.groupDefinition.id, filterItem)}
                                                id={`searchResultFilter${filterItem.id}`}
                                                name={filterItem.id}
                                                className="mr-3"
                                                checked={filterItem.selected as boolean}
                                                disabled={filterItem.disabled}
                                            />
                                            <span
                                                className={classNames('text-lg font-medium [word-break:break-word] truncate')}>
                                                {filterItem.label}
                                            </span>
                                            {
                                                uiInteractive &&
                                                selectedSearchFilterItem?.id === filterItem.id && (
                                                <span className="ui-key-bind ui-active gap-1 ml-auto shrink-0">
                                                    <Enter className={'align-baseline'}/> to activate
                                                </span>
                                            )}
                                        </div>
                                        <div className="">
                                            <span
                                                className="flex break-words items-start gap-x-1 pt-1 text-base-content-400"
                                            >
                                                <span className="">Group:</span>
                                                <span className="rounded-md inline-block break-all">
                                                    {filterItem.groupDefinition.label}
                                                </span>
                                            </span>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </Transition>
    )
}