import type { FormItem } from '../../types/form.ts'
import type { FormGeneratorOnChange, FormGeneratorData } from './FormGenerator.tsx'
import type { FormItemOption } from '../../types/form.ts'

import { useState } from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utils/helpers'
import InfoBlock from './utils/InfoBlock.tsx';
/*import {useAppStore} from '../../store/Store.ts';*/

function Select({parameters, data, onChange}: { parameters: FormItem, data?: FormGeneratorData, onChange?: FormGeneratorOnChange }) {
    const defaultOption = parameters.options?.find((option) => {
        if (data?.[parameters.name]) {
            return option.value === data?.[parameters.name]
        }
        return option.value === parameters.defaultValue
    });
    const [selectedOption, setSelectedOption] = useState(defaultOption)
    const onSelected = (option: FormItemOption) => {
        if (onChange) {
            onChange(parameters.name, option.value);
        }
    }

    /*const {
        uiInteractive
    } = useAppStore((state) => {
        return {
            uiInteractive: state.uiInteractive
        }
    });*/

    const onSelectChange = (option: FormItemOption) => {
        setSelectedOption(option);
        onSelected(option);
    }

    return (
        <div>
            <Listbox value={selectedOption} onChange={onSelectChange}>
                <>
                    <div className="block font-medium leading-6">
                        {parameters.label}
                    </div>
                    {(parameters?.info) && (
                        <InfoBlock text={parameters?.info} />
                    )}
                    <div className="relative mt-2">
                        <ListboxButton
                            /*disabled={!uiInteractive}  doesnt work */
                            className="relative w-full cursor-default rounded-md bg-base-100 py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-base-content-300 focus:outline-none sm:text-sm sm:leading-6"
                        >
                            {selectedOption && <span className="block truncate">{selectedOption.label}</span>}
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="group h-5 w-5 text-base-content-300" aria-hidden="true"/>
                         </span>
                        </ListboxButton>
                        <ListboxOptions
                            anchor="bottom"
                            transition
                            className="w-[var(--button-width)] z-10 mt-1 max-h-60 overflow-auto rounded-md bg-base-100 py-1 shadow-lg ring-1 ring-base-content ring-opacity-5 focus:outline-none transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0">
                            {parameters.options?.map((option) => (
                                <ListboxOption
                                    key={option.value}
                                    value={option}
                                    className={'group data-[focus]:bg-base-200 relative cursor-default select-none py-2 pl-3 pr-9'}
                                >
                                    <>
                                        <span
                                            className={classNames('block truncate')}
                                        >
                                          {option.label}
                                        </span>
                                        <span
                                            className={classNames(selectedOption?.value === option.value ? '' : 'invisible', 'absolute text-primary inset-y-0 right-0 flex items-center pr-4')}
                                        >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                        </span>
                                    </>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </>
            </Listbox>
        </div>
    )
}

export default Select;
