import {useAppStore} from '@/store/Store.ts';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {CloseSmall} from '@/assets/icons/CloseSmall.tsx';
import {Settings} from '@/assets/icons/Settings.tsx';
import {SignOut} from '@/assets/icons/SignOut.tsx';
import {Menu as MenuIcon} from '@/assets/icons/Menu.tsx';
import supabase from '@/utils/supabase-client';
import {useNavigate} from 'react-router-dom';
import {UI_PANEL_POSITION, UI_PANELS} from '@/store/UiPanel.ts';
import FeaturesPanel from './features/FeaturesPanel.tsx';
import {Logger} from '@/utils/Logger';
import SpaceSelect from '@/components/spaces/SpaceSelect.tsx';
import {AppEnv} from '@/utils/AppEnv.ts';
import {Cog6ToothIcon} from '@heroicons/react/24/outline';
import SpaceSettings from '@/components/spaces/SpaceSettings.tsx';

export default function UserMenu() {

    const navigate = useNavigate();

    const {
        user,
        openUiPanel,
        uiInteractive,
        openModal
    } = useAppStore((state) => {
        return {
            user: state.user,
            openUiPanel: state.openUiPanel,
            uiInteractive: state.uiInteractive,
            openModal: state.openModal,
        }
    });

    const logOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            Logger.error(`Error signing out: ${error}`);
        }
        return navigate('/');
    }

    const openSpaceSettings = () => {
        openModal(SpaceSettings);
    }

    return (
        <Menu as="div" className="ui-panel ui-pointer-events items-center">
            <div className={'p-1.5'}>
                <MenuButton
                    title={user?.email}
                    className="ui-btn-icon outline-none w-7 h-7 rounded-md"
                    disabled={!uiInteractive}
                >
                    {user && (
                        <MenuIcon />
                    )}
                </MenuButton>
            </div>
            <MenuItems
                anchor="bottom end"
                transition
                className="[--anchor-offset:0.375rem] ui-panel w-fit max-w-60 right-6 z-10 mt-4 p-4 transition duration-300 ease-in-out data-[closed]:opacity-0"
            >
                <div className="">
                    <MenuItem
                        as='div'
                        className="ui-list-item-separator-ignore"
                    >
                        {({ close }) => (
                            <div className="flex flex-col gap-1 pb-3 w-full relative">
                                <div className={'text-left w-full text-sm'}>
                                    Space:
                                </div>
                                <SpaceSelect />
                                <div className="absolute -right-2 -top-2">
                                    <button
                                        tabIndex={-1}
                                        type="button"
                                        className="ui-btn-icon-close"
                                        onClick={close}
                                    >
                                        <CloseSmall />
                                    </button>
                                </div>
                            </div>
                        )}
                    </MenuItem>
                    {AppEnv.getMode() === 'development' && (
                        <MenuItem
                            as='div'
                            className="ui-list-item-separator"
                        >
                            <div
                                tabIndex={-1}
                                className={'ui-list-item tabindex flex items-center gap-3 w-full px-0 py-3 text-lg text-left'}
                                onClick={() => openSpaceSettings()}
                            >
                                <Cog6ToothIcon className={'w-4 h-4'} />
                                Space Settings
                            </div>
                        </MenuItem>
                    )}
                    <MenuItem
                        as='div'
                        className="ui-list-item-separator"
                    >
                        <div
                            tabIndex={-1}
                            className={'ui-list-item tabindex flex items-center gap-3 w-full px-0 py-3 text-lg text-left '}
                            onClick={() => openUiPanel(UI_PANELS.FEATURES, UI_PANEL_POSITION.RIGHT, FeaturesPanel)}
                        >
                            <Settings />
                            Settings / Features
                        </div>
                    </MenuItem>
                    <MenuItem
                        as='div'
                        className="ui-list-item-separator"
                    >
                        <div
                            tabIndex={-1}
                            className={'ui-list-item flex items-center gap-3 w-full px-0 py-3 text-lg text-left'}
                            onClick={logOut}
                        >
                            <SignOut />
                            Sign out
                        </div>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    )
}