import type {StateCreator} from 'zustand'
import type {FeaturesSlice} from './Features.ts'
import {SearchSlice} from '@/store/Search.ts';
import _ from 'lodash';
import {FilterDefinition, FilterGroupDefinition} from '@/components/utils/filters/SymbolFilterDefinitions.ts';
import {updateQuery} from '@/utils/url-query-parser.ts';

export type FilterItem = {
    id: string,
    label: string,
    symbolType: string,
    forUndefined?: boolean,
    definition: FilterDefinition,
    groupDefinition: FilterGroupDefinition,
    selected?: boolean | string,
    priority?: number,
    numberOfSymbols?: number,
    disabled?: boolean
}

export type FilterListGroup = {
    id: string,
    label: string,
    definition: FilterGroupDefinition,
    items: FilterItem[],
    isOpen: boolean,
    itemsToShow?: FilterItem[];
    isOpenBySearch?: boolean
    priority?: number
}

export type ActiveFilterItem = Pick<FilterListGroup, 'id' | 'label' | 'definition' | 'items'>;
export type ActiveFilters = ActiveFilterItem[];

export type FiltersFromUrl = {
    [key: string]: string[]
}

export interface SpotlightFromUrl {
    symbolType: string,
    symbolExternalId: string,
    folderId: string | null,
}

export interface FilterSlice {
    showFilters: boolean;
    openFilters: () => void;
    closeFilters: () => void;
    toggleFilters: () => void;
    filterList: FilterListGroup[] | null;
    filtersFromUrl: FiltersFromUrl;
    filtersSearch: string;
    spotlightFromUrl: SpotlightFromUrl | null;
    setFilterList: (list: FilterListGroup[] | null) => void;
    setFiltersFromUrl: (filters: FiltersFromUrl) => void;
    setFiltersSearch: (query: string) => void;
    setSpotlightFromUrl: (spotlightedFromUrl: SpotlightFromUrl | null) => void;
    updateActiveFilters: (group: string, item: FilterItem, value: boolean | string) => void;
    toggleFilterGroupOpen: (groupId: string, open?: boolean) => void;
    resetFilters: () => void;
    getActiveFilters: () => ActiveFilters | null;
}

export const createFilterSlice: StateCreator<FilterSlice & FeaturesSlice & SearchSlice, [], [], FilterSlice> = (set, get) => ({
    showFilters: false,
    filterList: null, // null state concept, so we can determine when the filter are initialized/ready for the first time
    filtersFromUrl: {},
    filtersSearch: '',
    spotlightFromUrl: null,
    openFilters: () => set(() => ({ showFilters: true })),
    closeFilters: () => set(() => ({ showFilters: false })),
    toggleFilters: () => {
        if (!get().showFilters && get().showSearchResults) {
            get().setShowSearchResults(false);
        }
        return set((state) => ({ showFilters: !state.showFilters }))
    },
    setFilterList: (list) => set(() => (
        {filterList: list})
    ),
    setFiltersFromUrl: (filters) => {
        updateQuery(filters, get().spotlightFromUrl);
        return set(() => (
         {filtersFromUrl: filters}
        ))
    },
    setFiltersSearch: (query: string) => set(() => (
        {filtersSearch: query}
    )),
    setSpotlightFromUrl: (spotlightFromUrl: SpotlightFromUrl | null) => {
        updateQuery(get().filtersFromUrl, spotlightFromUrl);
        return set(() => (
            {spotlightFromUrl: spotlightFromUrl}
        ))
    },
    updateActiveFilters: (group: string, item: FilterItem, value: boolean | string) => {
        if (!get().filterList) {
            return;
        }
        return set((state) => {
            return ({
                filterList: state.filterList!.map((g) => {
                    if (g.id !== group) {
                        return g;
                    }
                    return {
                        ...g,
                        items: g.items.map((i) => {
                            if (i.id !== item.id) {
                                return i;
                            }
                            return {
                                ...i,
                                selected: value
                            }
                        })
                    }
                })
            })
        })
    },
    toggleFilterGroupOpen: (groupId: string, open?: boolean) => {
        if (!get().filterList) {
            return;
        }
        return set((state) => {
            return ({
                filterList: state.filterList!.map((g) => {
                    if (g.id !== groupId) {
                        return g;
                    }
                    return {
                        ...g,
                        isOpen: typeof open === 'boolean' ? open : !g.isOpen
                    }
                })
            })
        })
    },
    resetFilters: () => {
        if (!get().filterList) {
            return;
        }
        return set((state) => {
            return ({
                filterList: state.filterList!.map((g) => {
                     return {
                        ...g,
                        items: g.items.map((i) => {
                            return {
                                ...i,
                                selected: false
                            }
                        })
                    }
                })
            })
        })
    },
    getActiveFilters: () => {
        const filterList = get().filterList;
        if (!filterList) {
            return null;
        }
        return filterList.reduce((acc: ActiveFilters, group) => {
            const activeItems: FilterItem[] = group.items.filter((item) => {
                if (!item.selected) {
                    return false;
                }
                return true;
            });
            if (activeItems && activeItems.length) {
                const clonedGroup = _.cloneDeep(group);
                const activeFilterGroup: ActiveFilterItem = {
                    id: clonedGroup.id,
                    label: clonedGroup.label,
                    definition: clonedGroup.definition,
                    items: []
                };
                activeFilterGroup.items = activeItems;
                acc!.push(activeFilterGroup);
            }
            return acc;
        }, [])
    }
})
