import { Transition } from '@headlessui/react';

export default function LoadingOverlay({ isLoading }: { isLoading: boolean }) {

    return (
        <Transition show={isLoading}>
            <div className={'ui-bg-gradient w-[100svw] h-[100svh] z-[1000] fixed flex flex-col justify-center items-center transition duration-1000 ease-in-out data-[leave]:opacity-0'}>
                <div className='flex items-center'>
                    <img src='/images/make-logo.png' className='h-4' alt="Make Grid"></img>
                    <span className='text-base-content-500 flex text-lg relative top-[0.05rem]'>| Grid</span>
                </div>
                <div className={'text-primary h-10 w-20'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                        <circle fill="currentColor" stroke="currentColor" strokeWidth="15" r="15" cx="40" cy="100">
                            <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                     keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate>
                        </circle>
                        <circle fill="currentColor" stroke="currentColor" strokeWidth="15" r="15" cx="100" cy="100">
                            <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                     keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate>
                        </circle>
                        <circle fill="currentColor" stroke="currentColor" strokeWidth="15" r="15" cx="160" cy="100">
                            <animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;"
                                     keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate>
                        </circle>
                    </svg>
                </div>
            </div>
        </Transition>
    );

}