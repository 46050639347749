import type {StateCreator} from 'zustand'
import type {SupabaseResponse} from '../types/common.ts'
/*import { NotificationType } from './Notification.ts'*/
import {LocalStorageKeys, Nullable} from '../types/common.ts'
import type {CosmosSlice} from './Cosmos.ts'
import {NotificationSlice, NotificationType} from './Notification.ts'
import type {ConnectionSlice} from './Connection.ts'
import type {NodeDetailSlice} from './NodeDetail.ts';
import supabase from '../utils/supabase-client'
import {filter, unionBy} from 'lodash'
import {orderByCreated} from '../utils/helpers.ts'
import {Logger} from '../utils/Logger'

export type Space = {
    id: string;
    name: string;
    make_zone: string;
    make_organization_id: number;
    created_at: string;
    icon?: string;
}

export interface SpaceSlice {
    spaces: Space[];
    activeSpace: Nullable<Space>;
    showSpaceList: boolean;
    showSpaceSettings: boolean;
    setActiveSpace: (space: Space) => void;
    fetchSpace: (spaceId: string) => Promise<Nullable<Space>>;
    fetchSpaceByZoneAndOrganization: (zone: string, organizationId: number) => Promise<Nullable<Space>>;
    fetchSpaces: () => Promise<Nullable<Space[]>>;
    createSpace: (space: Partial<Space>) => Promise<Nullable<Space>>;
    updateSpace: (spaceId: string, payload: Partial<Space>) => Promise<Nullable<Space>>;
    updateSpaceInStore: (space: Space) => void;
    insertSpaceToStore: (space: Space) => void;
    removeSpaceFromStore: (id: string) => void;
    toggleSpaceList: () => void;
    toggleSpaceSettings: () => void;
}

export const createSpaceSlice: StateCreator<
  SpaceSlice &
  CosmosSlice &
  NotificationSlice &
  ConnectionSlice &
  NodeDetailSlice,
  [],
  [],
  SpaceSlice> = (set, get) => ({
    spaces: [],
    activeSpace: null,
    showSpaceList: false,
    showSpaceSettings: false,
    setActiveSpace: async (space: Space) => {
        if (get().activeSpace?.id === space.id) {
            return;
        }

        localStorage.setItem(LocalStorageKeys.LAST_ACTIVE_SPACE, space.id);

        set(() => ({ activeSpace: space }));
        if (get().nodeDetail) {
            get().setNodeDetail(null);
        }

        await get().fetchConnectionsTypes();
        await get().fetchConnections(space.id);
    },
    fetchSpace: async (spaceId: string) => {
        Logger.log('fetching space');
        const { data, error }: SupabaseResponse<Space> = await supabase.from('spaces')
			.select('id, name, make_zone, make_organization_id, created_at')
			.eq('id', spaceId)
			.maybeSingle();
        if (error || !data) {
            // throw error;
            get().addNotification({
                type: NotificationType.ERROR,
                title: 'Space doesn\'t exist.',
                duration: 10000,
            });
            if (error) {
                Logger.error(error);
            }
            return null;
        }
        // spaces: orderByCreated(unionBy([space], state.spaces, 'id'))
        set((state) => (
          { spaces: orderByCreated(unionBy([data], state.spaces, 'id')) })
        )
        return data;
    },
    fetchSpaceByZoneAndOrganization: async (zone: string, organizationId: number) => {
        Logger.log('fetching space by zone and organization');
        const { data, error }: SupabaseResponse<Space> = await supabase.from('spaces')
            .select('id, name, make_zone, make_organization_id, created_at')
            .eq('make_zone', zone)
            .eq('make_organization_id', organizationId)
            .maybeSingle();
        if (error || !data) {
            // throw error;
            get().addNotification({
                type: NotificationType.ERROR,
                title: 'No space found by zone and organization.',
                duration: 10000,
            });
            if (error) {
                Logger.error(error);
            }
            return null;
        }
        // spaces: orderByCreated(unionBy([space], state.spaces, 'id'))
        set((state) => (
            { spaces: orderByCreated(unionBy([data], state.spaces, 'id')) })
        )
        return data;
    },
    fetchSpaces: async () => {
        Logger.log('fetching spaces');
        const { data, error }: SupabaseResponse<Space[]> = await supabase.from('spaces')
            .select('id, name, make_zone, make_organization_id, created_at');
        if (error) {
            // throw error;
            get().addNotification({
                type: NotificationType.ERROR,
                title: 'Cannot fetch spaces.',
                duration: 10000,
            });
            Logger.error(error);
            return null;
        }
        set(() => ({ spaces: orderByCreated(data) || [] }));
        return data;
    },
    createSpace: async (/*space: Partial<Space>*/) => {
        /*Logger.log('creating space');
        const { data, error }: SupabaseResponse<Space> = await supabase.from('spaces')
          .insert(space)
          .select('id, name, make_zone, make_organization_id, created_at')
          .single();

        if (error) {
            throw error;
        }
        if (!data) {
            return null;
        }

        get().insertSpaceToStore(data);
        get().addNotification({type: NotificationType.SUCCESS, title: 'Space created'});
        return data;*/
        return null;
    },
    updateSpace: async (/*spaceId: string, payload: Partial<Space>*/) => {
        /*Logger.log('updating space');
        const { data, error }: SupabaseResponse<Space> = await supabase
          .from('spaces')
          .update(payload)
          .eq('id', spaceId)
          .select('id, name, make_zone, make_organization_id, created_at')
          .single();

        if (error) {
            throw error;
        }
        if (!data) {
            return null;
        }

        get().updateSpaceInStore(data);
        get().addNotification({type: NotificationType.SUCCESS, title: 'Space updated'});
        return data;*/
        return null;
    },
    updateSpaceInStore: (space: Space) => {
        if (get().activeSpace?.id === space.id) {
            set((state) => ({ activeSpace: { ...state.activeSpace , ...space} }));
        }
        set((state) => (
          { spaces: orderByCreated(unionBy([space], state.spaces, 'id')) })
        )
    },
    insertSpaceToStore: (space: Space) => set((state) => (
      { spaces: orderByCreated(unionBy([space], state.spaces, 'id')) }
    )),
    removeSpaceFromStore: (id: string) => set((state) => (
      { spaces: orderByCreated(filter(state.spaces, (item: Space) => { return item.id !== id})) })
    ),
    toggleSpaceList: () => set((state) => ({ showSpaceList: !state.showSpaceList })),
    toggleSpaceSettings: () => set((state) => ({ showSpaceSettings: !state.showSpaceSettings })),
})
