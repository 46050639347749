import type {ReactNode} from 'react';

import Tooltip from '../utils/Tooltip.tsx';
import {classNames} from './../../utils/helpers.ts';

type Props = {
    backgroundColor?: string;
    title?: string;
    children?: ReactNode;
    className?: string;
    showBorder?: boolean;
    tooltip?: string
    onClick?: () => void;
}

export default function Badge({
                                  children,
                                  backgroundColor,
                                  title,
                                  className,
                                  showBorder,
                                  tooltip,
                                  onClick
}: Props) {

    const renderBadge = () => (
            <div
                className={classNames(showBorder ? '' : '', 'bg-base-200 text-base-content rounded-btn w-8 h-8 p-2 content-center text-center', className || '')}
                onClick={onClick || (() => {})}
                style={{
                    backgroundColor: backgroundColor || undefined
                }}
            >
                {children}
            </div>
    )

    return (
        <div className={'flex flex-col items-center gap-2 w-16'}>
            {tooltip?.length ?
                (<Tooltip message={tooltip} position={'top'}>{renderBadge()}</Tooltip>) :
                renderBadge()}
            <div className={'text-base-content-500 text-xs text-center'}>{title ? title : '&nbsp;'}</div>
        </div>
    )
}