type Props = {
    className?: string,
}

export const UpAndDown = ({className}: Props) => {
    return (
        <svg className={'w-2.5 h-2.5 ' + (className || '')} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99973 6.89819L7.10143 8.7965L5.20312 6.89819" stroke="currentColor" strokeWidth="1.29" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.10156 8.79674V4.45776" stroke="currentColor" strokeWidth="1.29" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 3.10167L2.8983 1.20337L4.79661 3.10167" stroke="currentColor" strokeWidth="1.29" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.89844 1.20337V5.54235" stroke="currentColor" strokeWidth="1.29" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}