import { BaseInstancedMesh } from "../common/BaseInstancedMesh";

export class SymbolsShapesIM extends BaseInstancedMesh{
    constructor(symbolsGroupKlass: any, size : number){
        super(symbolsGroupKlass.getGeometry(), symbolsGroupKlass.getMaterial(), size);

        this.layers.enable( symbolsGroupKlass.interactionLayer );

        this.castShadow = true;

        // This has to be set to true so the raycasting works
        this.shouldUpdateBoundingSphere = true;

        this.name = "symbolShapesIM";
    }
}