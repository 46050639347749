import type { FormEvent } from 'react'
import type { Connection, Feed, ConnectionType } from '@/store/Connection.ts'

import { Form } from 'react-router-dom'
import { useAppStore } from '@/store/Store.ts'
import { useEffect, useState } from 'react'
import { SpinnerLogo } from '@/assets/icons/Spinner.tsx'
import FormGenerator, { parseFormData } from '../form/FormGenerator.tsx'
import { collectionToMap } from '@/utils/helpers.ts'

function ConnectionForm({ inputConnectionType, connection } : { inputConnectionType?: ConnectionType, connection?: Connection }) {

  const {
    closeModal,
    activeSpace,
    connectionsTypes,
    fetchFeedTypes,
    fetchFeeds,
    feedTypes,
    createConnection,
    updateConnection,
    uiInteractive
  } = useAppStore((state) => {
    return {
      closeModal: state.closeModal,
      activeSpace: state.activeSpace,
      connectionsTypes: state.connectionsTypes,
      fetchFeedTypes: state.fetchFeedTypes,
      fetchFeeds: state.fetchFeeds,
      feedTypes: state.feedTypes,
      createConnection: state.createConnection,
      updateConnection: state.updateConnection,
      uiInteractive: state.uiInteractive
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [connectionType, setConnectionType] = useState<ConnectionType | undefined>(inputConnectionType);
  const [feedsMap, setFeedsMap] = useState<Map<string, Feed>>(new Map());

  useEffect(() => {
    const fetchFeedTypesAndFeeds = async () => {

      let typeFound: ConnectionType | undefined = undefined;
      if (!connectionType && connection?.connection_type_id) {
        typeFound = connectionsTypes.find((type) => type.id === connection.connection_type_id);
        if (typeFound) {
          setConnectionType(typeFound);
        }
      } else if (inputConnectionType) {
        typeFound = inputConnectionType;
        setConnectionType(inputConnectionType);
      }

      if (typeFound) {
        await fetchFeedTypes(typeFound.id);
        if (connection) { // editing connection, fetch feeds
          const feeds = await fetchFeeds(connection.id);
          if (feeds) {
            setFeedsMap(collectionToMap(feeds, feedsMap, 'feed_type_id'));
          }
        }
        setShowForm(true);
      }
    }
    fetchFeedTypesAndFeeds();
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!activeSpace || !connectionType) {
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const payload: Partial<Connection> = {
      name: formData.get('name') as string,
    }
    if (!connection?.id) {
      payload.credentials = JSON.stringify(parseFormData(formData, connectionType.parameters));
    }
    const feedTypeIds = formData.getAll('feedType[]') as string[];

    if (connection?.id) {
      await updateConnection(connection.id, payload, feedTypeIds);
    } else {
      await createConnection(activeSpace, connectionType, payload, feedTypeIds);
    }

    setIsSubmitting(false);

    setTimeout(() => {
      closeModal();
    }, 300);

    setTimeout(() => {
      setIsSubmitting(false);
    }, 500);
  }

  const isFeedEnabled = (feedTypeId: string) => {
    if (!connection) {
      return true;
    }
    return feedsMap.get(feedTypeId)?.enabled;
  }

  return (
    <>
      {showForm && connectionType && (
        <Form className="ui-pointer-events p-5" onSubmit={onSubmit}>
          <div className="flex gap-4 flex-col">
            <div>
              <h2 className="text-xl font-semibold leading-7 pr-6">
                {connection?.id ? `Update connection ${connection.name} ` : `Create new ${connectionType.name} Connection for ${activeSpace?.name}`}
              </h2>
            </div>
            <div className={''}>
              <label htmlFor="name" className="block text-sm font-medium leading-6">
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  defaultValue={connection ? connection.name : ''}
                  className="ui-input"
                  placeholder=""
                />
              </div>
            </div>
            {!connection && (
              <FormGenerator items={connectionType.parameters} />
            )}
            <fieldset>
              <legend className="text-base font-semibold leading-6">Feed Types</legend>
              <div className="mt-4 divide-y divide-base-content-50 border-b border-t border-base-content-50">
                {feedTypes.map((feedType, index) => (
                  <div key={index} className="relative flex items-start py-4">
                    <label htmlFor={`feedType[${feedType.id}]`} className="min-w-0 flex-1 text-sm select-none font-medium">
                      <div className="leading-6">
                          {feedType.name}
                      </div>
                    </label>
                    <div className="ml-3 flex h-6 items-center">
                      <input
                        id={`feedType[${feedType.id}]`}
                        name={`feedType[]`}
                        value={feedType.id}
                        type="checkbox"
                        defaultChecked={isFeedEnabled(feedType.id)}
                        className=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </fieldset>
            <div className="sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                className="ui-btn sm:col-start-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? <SpinnerLogo /> : 'Save'}
              </button>
              <button
                type="button"
                className="ui-btn-outline mt-3 sm:col-start-1 sm:mt-0"
                onClick={() => closeModal()}
                disabled={!uiInteractive || isSubmitting}
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </>
  );
}

export default ConnectionForm;
