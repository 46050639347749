import { parseLoginRequestData, getZoneFromReferrer, buildSpaceRedirectQuery } from '@/utils/url-query-parser.ts';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { LocalStorageManager } from '@/utils/LocalStorageManager.ts';
import supabase from '@/utils/supabase-client';
import {useAppStore} from '@/store/Store.ts';

export default function LoginWithMake() {
    return null;
}

export async function loader({request}: LoaderFunctionArgs) {

    const url = new URL(request.url);

    const zoneFromReferrer = getZoneFromReferrer();

    const loginRequestData = parseLoginRequestData(url.searchParams);
    if (!loginRequestData.zone) {
        loginRequestData.zone = zoneFromReferrer;
    }

    let redirectTo: string | null = null;

    // check already logged in
    const { data } = await supabase.auth.getSession();


    if (data?.session?.user) {
        redirectTo = '/';
    }

    if (loginRequestData.zone && loginRequestData.organizationId) {
        if (data?.session?.user) {
            // check if org exists, if not, just redirect to auth flow to  create a new space
            const spaceData = await useAppStore.getState().fetchSpaceByZoneAndOrganization(loginRequestData.zone, loginRequestData.organizationId);
            if (spaceData?.id) {
                redirectTo = buildSpaceRedirectQuery(spaceData.id, loginRequestData);
                // redirect to space
            } else {
                redirectTo = null;
            }
        }
    }

    if (redirectTo) {
        return redirect(redirectTo);
    }

    const loginRequestId = uuidv4();

    LocalStorageManager.setValue('login', loginRequestId, loginRequestData);

    await supabase.auth.signInWithOAuth({
        provider: 'keycloak',
        options: {
            redirectTo: `${url.protocol}//${url.host}/login-with-make-resolve?login=${loginRequestId}`,
            queryParams: {
                scope: 'openid email profile teams:read organizations:read scenarios:read datastores:read connections:read hooks:read devices:read organization-variables:read team-variables:read apps:read',
            }
        },
    });

    return null;
}