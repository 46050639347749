type Props = {
    className?: string,
}

export const Sun = ({className}: Props) => {
    return (
        <svg className={'w-[1.188rem] h-[1.188rem] ' + (className || '')} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 12.5C11.1569 12.5 12.5 11.1569 12.5 9.5C12.5 7.84315 11.1569 6.5 9.5 6.5C7.84315 6.5 6.5 7.84315 6.5 9.5C6.5 11.1569 7.84315 12.5 9.5 12.5Z"
                fill="currentColor" fillOpacity="0.6" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 1.5V3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.1574 3.84314L14.0967 4.90384" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M17.5 9.5H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.1574 15.1569L14.0967 14.0962" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M9.5 17.5V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.84277 15.1569L4.90347 14.0962" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M1.5 9.5H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.84277 3.84314L4.90347 4.90384" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

