import {Fragment} from 'react'; // i hate react sorry

export interface BreadcrumbItem {
    id: string,
    label: string,
    url?: string
}

type Props = {
    items: BreadcrumbItem[];
}

export default function Breadcrumb({items} : Props) {

    if (!items.length) {
        return null;
    }

    return (
        <ul className={'list-none flex flex-wrap gap-x-1 text-base-content-500'}>
            {items.map((item, i) => (
                <Fragment key={item.id}>
                    <li>
                        {item.label}
                    </li>
                    {i < items.length - 1 && (
                        <li>/</li>
                    )}
                </Fragment>
            ))}
        </ul>
    )
}