type Props = {
    className?: string,
}

export const ThreeDCube = ({className}: Props) => {
    return (
        <svg className={'w-[0.938rem] h-[1.063rem] ' + (className || '')} width="15" height="17" viewBox="0 0 15 17" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.11194 4.36306C1.63488 4.08479 1.02257 4.24593 0.74429 4.72298C0.466014 5.20004 0.627154 5.81235 1.10421 6.09063L6.3444 9.14736V15.7554C6.3444 16.3077 6.79212 16.7554 7.3444 16.7554C7.89669 16.7554 8.3444 16.3077 8.3444 15.7554V9.14709L13.5834 6.09103C14.0605 5.81275 14.2216 5.20044 13.9434 4.72338C13.6651 4.24633 13.0528 4.08519 12.5757 4.36347L7.34417 7.41515L2.11194 4.36306Z"
                fill="currentColor" fillOpacity="0.6"/>
            <path
                d="M6.33654 2.15858L2.33654 4.49188C1.72214 4.85028 1.34424 5.50808 1.34424 6.21948V11.422C1.34424 12.1333 1.72204 12.7911 2.33654 13.1496L6.33654 15.4829C6.95924 15.8462 7.72934 15.8462 8.35204 15.4829L12.352 13.1496C12.9664 12.7912 13.3443 12.1334 13.3443 11.422V6.21948C13.3443 5.50818 12.9665 4.85038 12.352 4.49188L8.35204 2.15858C7.72934 1.79528 6.95924 1.79528 6.33654 2.15858Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
