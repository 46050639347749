import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LEGEND_POSITION_Y_OFFSET, LEGENDS_FINAL_ZOOM, LEGENDS_INITIAL_ZOOM } from '../common/constants';
import { Repository } from '../common/Repository';
import { CosmosThree } from '../CosmosThree';
import { SymbolLegendBackground } from './SymbolLegendBackground';
import { BaseObject } from '../common/BaseObject';

export class SymbolLegendsBackgroundsBM extends BaseBatchedMesh{
    constructor(size : number){
        // check geometry.attributes.position.count to figure out the needed max vertex count
        // the geometry is non-indexed so no need for max index count
        super(size, size * 156, size * 150, SymbolLegendBackground.getMaterial());
    }

    override sync (){
        // We want to make the legends disappear depending on camera zoom value
        this.updateOpacityFromZoom();

        let colorsWhereUpdated = false;
        let opacitiesWhereUpdated = false;
    
        /* Logger.time('[perf] mesh: update matrices'); */
        if(this.visible){
            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.instanceId !== -1){
                    this.syncMatrix(elem);

                    if(elem.colorNeedsUpdate){
                        this.syncColor(elem);

                        colorsWhereUpdated = true;
                    }

                    if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
                        this.syncOpacity(elem);

                        opacitiesWhereUpdated = true;
                    }
                }
            }
        }

        if(colorsWhereUpdated) this.colorNeedsUpdate = false;
        if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;

        /* Logger.timeEnd('[perf] mesh: update matrices'); */
    }

    override syncMatrix(elem: BaseObject){
		this.vector.setFromMatrixPosition(elem.three.parent!.parent!.matrix);

		this.vector.add(CosmosThree.meshOffset);
		this.vector.project(CosmosThree.graphCamera);

		this.vector.unproject(CosmosThree.guiCamera);

		// Update the matrices of the objects in the scene
		this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

		this.mtx.makeTranslation(this.vector);

		// We apply the scale of the legend to efectively 'hide' the legend if necessary
		// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
		this.mtx.scale(elem.three.scale);

		this.setMatrixAt(elem.instanceId, this.mtx);
	}

    private updateOpacityFromZoom() {
		const currentZoom = CosmosThree.graphCamera.zoom;
		if(!Repository.mesh?.intoIdleMode && !Repository.mesh?.intoScenarioMode){
			if(currentZoom < LEGENDS_FINAL_ZOOM){
				if(currentZoom < LEGENDS_INITIAL_ZOOM){
					this.visible = false;
					(this.material as any).opacity = 0;
				}else{
					this.visible = true;
					// Ensure the zoom level is clamped within the initial and final zoom values
					const clampedZoom = Math.min(Math.max(currentZoom, LEGENDS_INITIAL_ZOOM), LEGENDS_FINAL_ZOOM);
				
					// Calculate the interpolation factor based on the current zoom level
					const interpolatedOpacity = (clampedZoom - LEGENDS_INITIAL_ZOOM) / (LEGENDS_FINAL_ZOOM - LEGENDS_INITIAL_ZOOM);
				
					// Set the opacity of the mesh
					(this.material as any).opacity = interpolatedOpacity;
				}
			}else{
				this.visible = true;
				(this.material as any).opacity = 1;
			}
		}
	}
}