import * as THREE from 'three';
import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { CosmosThree } from '../CosmosThree';
import { SYMBOL_DUPLICATE_INDICATOR_HEIGHT, SYMBOL_DUPLICATE_INDICATOR_WIDTH } from '../common/constants';
import { ExtensionTint } from '../utils/materials/extensions/ExtensionTint';
import { Repository } from '../common/Repository';

export class SymbolDuplicateIndicator extends BaseObject{

    static interactionLayer = 1;

    static getGeometry(): THREE.BufferGeometry {
        const geometry = new THREE.PlaneGeometry(SYMBOL_DUPLICATE_INDICATOR_WIDTH, SYMBOL_DUPLICATE_INDICATOR_HEIGHT, 1, 1)    
            .translate(0 , SYMBOL_DUPLICATE_INDICATOR_HEIGHT / 2, 0)
            .rotateX(-Math.PI / 4)    
            .rotateY(Math.PI / 4);

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionTint, ExtensionFog],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                map: CosmosThree.duplicatesIconTexture,
                tintColor: CosmosThree.symbolsMutedTint,
                transparent: true,
                opacity: Repository.mesh!.cameraMode === "3d" ? 1 : 0,
                // wireframe: false
            },
            { debug: false }
        );

        return material;
    }
}