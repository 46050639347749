import type {FormGroup} from '../types/form.ts';

export interface Theme {
    id: string,
    label: string,
    variables: {[key: string]: string},
    /* acts as initial theme, the default css variables values from .css files are filled into initial theme,
    just not to duplicate default css values here and in css files */
    initial?: boolean,
    forDarkMode?: boolean
}

export interface ThemeVariableDefinition {
    cssName: string;
    label: string;
    unit?: string;
    info?: string;
}

export default class ThemeManager {

    static defaultThemeId: string = 'cosmosLight';
    private static localStorageKey = 'activeTheme';

    static themes: Theme[] = [
        {
            id: 'cosmosLight',
            label: 'Cosmos Light',
            initial: true,
            variables: {} // initial variables are loaded from css style
        }, {
            id: 'cosmosDark',
            label: 'Cosmos Dark',
            forDarkMode: true,
            variables: {
                uiColorBase50: '56, 54, 56',
                uiColorBase100: '46, 44, 46',
                uiColorBase200: '56, 54, 56',
                uiColorBase300: '66, 64, 66',
                uiColorBaseOpacity50: '1',
                uiColorBaseContent: '213, 213, 213',
                uiColorBaseContent700: '242, 242, 242',
                uiColorBaseContent500: '150, 150, 150',
                uiColorBaseContent400: '109, 109, 109',
                uiColorBaseContent300: '87, 87, 87',
                uiColorBaseContent50: '56, 54, 56',
                uiColorPrimary: '135, 66, 166',
                uiColorPrimary700: '151, 76, 184',
                uiColorPrimary500: '156, 88, 186',
                uiColorPrimary300: '60, 46, 66',
                uiColorPrimary200: '66, 64, 66',
                uiColorPrimaryContent: '213, 213, 213',
                uiColorPrimaryContent100: '216, 184, 231',
                uiColorTooltip: '24, 22, 24',
                uiColorTooltipContent: '242, 242, 242',
                uiColorTooltipBorder: '46, 44, 46',
                uiColorTrigger: '56, 54, 56',
                uiColorTriggerContent: '213, 159, 229',
                uiColorWrite: '56, 54, 56',
                uiColorWriteContent: '201, 192, 252',
                uiColorRead: '56, 54, 56',
                uiColorReadContent: '156, 143, 224',
                uiColorBrand100: '38, 9, 43',
            }
        }
    ];

    static variablesDefinition = new Map([

        // system ui variables
        ['uiPointerEvents', {
            cssName: '--ui-pointer-events',
            label: 'UI interactive'
        }],

        // color variables
        ['uiColorBase300', {
            cssName: '--ui-color-base-300',
            label: 'Base Color 300'
        }],
        ['uiColorBase200', {
            cssName: '--ui-color-base-200',
            label: 'Base Color 200'
        }],
        ['uiColorBase100', {
            cssName: '--ui-color-base-100',
            label: 'Base Color',
            info: 'Used generally for backgrounds and most of the content'
        }],
        ['uiColorBase50', {
            cssName: '--ui-color-base-50',
            label: 'Base Color 50'
        }],
        ['uiColorBaseOpacity50', {
            cssName: '--ui-color-base-opacity-50',
            label: 'Opacity for Base Color 50'
        }],
        ['uiColorBaseContent', {
            cssName: '--ui-color-base-content',
            label: 'Base Content Color',
            info: 'Foreground content color to use on base color'
        }],
        ['uiColorBaseContent700', {
            cssName: '--ui-color-base-content-700',
            label: 'Base Content Color 700'
        }],
        ['uiColorBaseContent500', {
            cssName: '--ui-color-base-content-500',
            label: 'Base Content Color 500'
        }],
        ['uiColorBaseContent400', {
            cssName: '--ui-color-base-content-400',
            label: 'Base Content Color 400'
        }],
        ['uiColorBaseContent300', {
            cssName: '--ui-color-base-content-300',
            label: 'Base Content Color 300'
        }],
        ['uiColorBaseContent50', {
            cssName: '--ui-color-base-content-50',
            label: 'Base Content Color 50'
        }],
        ['uiColorPrimary', {
            cssName: '--ui-color-primary',
            label: 'Primary Color',
            info: 'Used as active state for buttons and dropdown items',
        }],
        ['uiColorPrimary700', {
            cssName: '--ui-color-primary-700',
            label: 'Primary Color 700'
        }],
        ['uiColorPrimary500', {
            cssName: '--ui-color-primary-500',
            label: 'Primary Color 500'
        }],
        ['uiColorPrimary300', {
            cssName: '--ui-color-primary-300',
            label: 'Primary Color 300'
        }],
        ['uiColorPrimary200', {
            cssName: '--ui-color-primary-200',
            label: 'Primary Color 200'
        }],
        ['uiColorPrimaryContent', {
            cssName: '--ui-color-primary-content',
            label: 'Primary Foreground Color',
            info: 'Used as foreground color for active state for buttons and dropdown items',
        }],
        ['uiColorPrimaryContent100', {
            cssName: '--ui-color-primary-content-100',
            label: 'Primary Foreground Color 100',
        }],
        ['uiColorTooltip', {
            cssName: '--ui-color-tooltip',
            label: 'Tooltip background Color'
        }],
        ['uiColorTooltipContent', {
            cssName: '--ui-color-tooltip-content',
            label: 'Tooltip foreground Color'
        }],
        ['uiColorTooltipBorder', {
            cssName: '--ui-color-tooltip-border',
            label: 'Tooltip border Color'
        }],
        ['uiColorTrigger', {
            cssName: '--ui-color-trigger',
            label: 'Trigger Color'
        }],
        ['uiColorTriggerContent', {
            cssName: '--ui-color-trigger-content',
            label: 'Trigger Foreground Color'
        }],
        ['uiColorWrite', {
            cssName: '--ui-color-write',
            label: 'Write Color'
        }],
        ['uiColorWriteContent', {
            cssName: '--ui-color-write-content',
            label: 'Write Foreground Color'
        }],
        ['uiColorRead', {
            cssName: '--ui-color-read',
            label: 'Read Color'
        }],
        ['uiColorReadContent', {
            cssName: '--ui-color-read-content',
            label: 'Read Foreground Color'
        }],
        ['uiColorBrand100', {
            cssName: '--ui-color-brand-100',
            label: 'Brand Color 100'
        }],
        ['uiColorInfo', {
            cssName: '--ui-color-info',
            label: 'Info Color'
        }],
        ['uiColorInfoContent', {
            cssName: '--ui-color-info-content',
            label: 'Info Foreground Color'
        }],
        ['uiColorSuccess', {
            cssName: '--ui-color-success',
            label: 'Success Color'
        }],
        ['uiColorSuccessContent', {
            cssName: '--ui-color-success-content',
            label: 'Success Foreground Color'
        }],
        ['uiColorWarning', {
            cssName: '--ui-color-warning',
            label: 'Warning Color'
        }],
        ['uiColorWarningContent', {
            cssName: '--ui-color-warning-content',
            label: 'Warning Foreground Color'
        }],
        ['uiColorError', {
            cssName: '--ui-color-error',
            label: 'Error Color'
        }],
        ['uiColorErrorContent', {
            cssName: '--ui-color-error-content',
            label: 'Error Foreground Color'
        }],

        // utility variables sizes etc.
        ['uiRoundedBox', {
            cssName: '--ui-rounded-box',
            label: 'Rounded Box'
        }],
        ['uiRoundedBtn', {
            cssName: '--ui-rounded-btn',
            label: 'Rounded Btn'
        }],
        ['uiRoundedBadge', {
            cssName: '--ui-rounded-badge',
            label: 'Rounded Badge'
        }],
        ['uiPAdjust', {
            cssName: '--ui-p-adjust',
            label: 'Global padding adjust',
            unit: 'rem'
        }],
        ['uiRootFontSize', {
            cssName: '--ui-root-font-size',
            label: 'Main font size',
            unit: 'rem'
        }],
    ]);

    static loadVariablesFromCss(): {[key: string]: string} {

        const output: {[key: string]: string} = {};
        const style = getComputedStyle(document.body);
        this.variablesDefinition.forEach((variable, variableId) => {
            let value = style.getPropertyValue(variable.cssName);
            if (value) {
                if (variable.unit) {
                    value = value.substring(0, value.indexOf(variable.unit));
                }
                output[variableId] = value;
            }
        })
        return output;
    }

    static updateCssVariable(key: string, value: string): void {
        const variable = this.getThemeVariableDefinition(key);
        if (!variable) {
            return;
        }
        document.documentElement.style.setProperty(variable.cssName, `${value}${variable.unit || ''}`);
    }

    static setTheme(theme: Theme): void {
        for (const [variableId, value] of Object.entries(theme.variables)) {
            this.updateCssVariable(variableId, value);
        }
    }

    static getInitialTheme(): Theme {

        const initialTheme = this.themes.find((theme) => theme.initial === true)!;
        initialTheme.variables = this.loadVariablesFromCss();

        // @TODO check for query params and of there is theme query param

        let loadedTheme: Theme | undefined = undefined;
        // try to load from local storage
        try {
            const themeJson = localStorage.getItem(this.localStorageKey);
            if (themeJson) {
                loadedTheme = JSON.parse(themeJson);
                if (loadedTheme) {
                    // just ignore possibly customized css variables in the persisted theme for now
                    loadedTheme = this.getThemeById(loadedTheme.id);
                }
            }
        } catch (e) {/* ignore */}

        if (loadedTheme) {
            // apply css vars
            this.setTheme(loadedTheme);
        }

        return loadedTheme || initialTheme;
    }

    static getFirstLightTheme(): Theme | undefined {
        return this.themes.find((theme) => !theme.forDarkMode)!;
    }

    static getDarkModeTheme(): Theme | undefined {
        return this.themes.find((theme) => theme.forDarkMode === true)!;
    }

    static getThemes(): Theme[] {
        return this.themes;
    }

    static getThemeById(themeId: string): Theme | undefined {
        return this.themes.find((theme) => {
            return themeId === theme.id;
        });
    }

    static getThemeVariableDefinition(index: string): ThemeVariableDefinition | undefined {
        return this.variablesDefinition.get(index);
    }

    static getThemeSettingsForm(): FormGroup[] {
        return [
            {
                id: 'theme',
                label: 'Theme',
                items: [
                    {
                        name: 'theme',
                        label: 'Theme',
                        type: 'select',
                        options: this.getThemes().map((theme) => {
                            return {
                                label: theme.label,
                                value: theme.id,
                            }
                        }),
                        defaultValue: this.defaultThemeId,
                    },
                    /*{
                        name: 'uiRootFontSize',
                        label: 'Adjust Global Font Size',
                        type: 'number',
                        defaultValue: 1,
                        attributes: {
                            min: 0.1,
                            max: 2,
                            step: 0.1
                        },
                        info: ''
                    },
                    {
                        name: 'uiPAdjust',
                        label: 'Adjust Padding',
                        type: 'slider',
                        defaultValue: 0,
                        attributes: {
                            min: -5,
                            max: 5,
                            step: 0.1
                        },
                        info: ''
                    },*/
                ]
            }
        ]
    }

    static persistTheme(theme: Theme) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(theme));
    }

}