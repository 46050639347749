export const ExtensionInstancedAtlas = {
    name: "extension-instanced-atlas",
    uniforms: {
      atlasSize: {x: 1, y: 1}
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform vec2 atlasSize;

        attribute vec2 texOffset;
        attribute vec2 texSize;  // Size of the individual texture for this instance
        ${
          shader.replace(
            '#include <uv_vertex>',
            `
            #include <uv_vertex>
    
            // Calculate the UV offset based on the texture position and size
            float uOffset = texOffset.x / atlasSize.x;
            float vOffset = 1.0 - ((texOffset.y + texSize.y) / atlasSize.y);

            // Adjust the UV coordinates based on the individual texture size
            vMapUv = (uv * (texSize / atlasSize)) + vec2(uOffset, vOffset);
            `
          )
        }
        `;
      return shader;
    }
};