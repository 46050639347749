import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionBatchedOpacity } from '../utils/materials/extensions/ExtensionBatchedOpacity';
import { LINK_INTERACTIONS_OFFSET, LINK_PORT_RADIUS, LINK_WIDTH } from '../common/constants';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { CosmosThree } from '../CosmosThree';
import { ExtensionBatchedTint } from '../utils/materials/extensions/ExtensionBatchedTint';

export class LinkInteractionsShape extends BaseObject{

	static interactionLayer = 1;

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionBatchedTint, ExtensionFog, ExtensionBatchedOpacity],
            {
				fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
				fActive: true,
				tintColor: CosmosThree.linksMutedTint,
				transparent: true,
				alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                // wireframe: false
            },
            { debug: false }
        );

		return material;
    }

    updateGeometry(pointSourceLocal: THREE.Vector3, pointTargetLocal: THREE.Vector3) {
		if (!this.instancedOrBatchedMesh) return;
	
		// Base geometry data for a single line segment
		const vertices = [];
		const normals = [];
		const indices = [0, 1, 2, 2, 1, 3];
		const uv = [];
	
		// Calculate the tangent and normal
		const tangent = new THREE.Vector3().subVectors(pointTargetLocal, pointSourceLocal).normalize();
		const up = new THREE.Vector3(0, 1, 0);
		const normal = new THREE.Vector3().crossVectors(tangent, up).normalize();
	
		// Calculate vertices for the single line
		const leftSource = new THREE.Vector3().copy(pointSourceLocal).sub(normal.clone().multiplyScalar(LINK_WIDTH / 2.5));
		const rightSource = new THREE.Vector3().copy(pointSourceLocal).add(normal.clone().multiplyScalar(LINK_WIDTH / 2.5));
		const leftTarget = new THREE.Vector3().copy(pointTargetLocal).sub(normal.clone().multiplyScalar(LINK_WIDTH / 2.5));
		const rightTarget = new THREE.Vector3().copy(pointTargetLocal).add(normal.clone().multiplyScalar(LINK_WIDTH / 2.5));
	
		vertices.push(
			leftSource.x, leftSource.y, leftSource.z,
			rightSource.x, rightSource.y, rightSource.z,
			leftTarget.x, leftTarget.y, leftTarget.z,
			rightTarget.x, rightTarget.y, rightTarget.z
		);
	
		// Normals for lighting
		const faceNormal = new THREE.Vector3().crossVectors(normal, tangent).normalize();
		for (let i = 0; i < 4; i++) {
			normals.push(faceNormal.x, faceNormal.y, faceNormal.z);
		}
	
		// UV coordinates
		uv.push(0, 0, 1, 0, 0, 1, 1, 1);
	
		// Create single line geometry
		const singleLineGeometry = new THREE.BufferGeometry()
			.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
			.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3))
			.setAttribute('uv', new THREE.Float32BufferAttribute(uv, 2))
			.setIndex(indices);
	
		// Duplicate and translate each line based on direction
		const offsetDistance = LINK_INTERACTIONS_OFFSET; // Increased offset for better separation
		const line1Geometry = singleLineGeometry.clone();
		line1Geometry.translate(normal.x * offsetDistance, normal.y * offsetDistance, normal.z * offsetDistance); // Offset line along the normal
	
		const line2Geometry = singleLineGeometry.clone();
		line2Geometry.translate(-normal.x * offsetDistance, -normal.y * offsetDistance, -normal.z * offsetDistance); // Offset line in the opposite direction
	
		// Merge both lines into a single geometry
		let geometry = BufferGeometryUtils.mergeGeometries([line1Geometry, line2Geometry]);
	
		// Add circular ports at each end
		const sourcePointGeo = new THREE.CircleGeometry(LINK_PORT_RADIUS, 12)
			.rotateX(-Math.PI / 2)
			.translate(pointSourceLocal.x, 0, pointSourceLocal.z);
	
		const targetPointGeo = new THREE.CircleGeometry(LINK_PORT_RADIUS, 12)
			.rotateX(-Math.PI / 2)
			.translate(pointTargetLocal.x, 0, pointTargetLocal.z);
	
		geometry = BufferGeometryUtils.mergeGeometries([sourcePointGeo, targetPointGeo, geometry]);

		// Batched mesh update
		if (this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh) {
			if (this.instanceId === -1) {
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry(geometry);
				this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			} else {
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt(this.instanceId, geometry);
			}
		}
	}
	
	
}