import * as THREE from 'three';
import { ScenarioAppShape } from './ScenarioAppShape';
import { ScenarioAppIcon } from './ScenarioAppIcon';
import { LEGEND_TEXT_COLOR_LIGHT, PREVENT_Z_FIGHTING, SCALE_FACTOR, SYMBOL_ICON_Y_POSITION } from '@/three/common/constants';
import {Text} from 'troika-three-text';

export class ScenarioApp extends THREE.Group{

    private _shape: ScenarioAppShape;
    private _icon: ScenarioAppIcon;
    private _title: any;

    constructor(color: string, title: string, slug: string){
        super();

        this._shape = new ScenarioAppShape(color);
        this._shape.renderOrder = 16;
        this.add(this._shape);

        this._icon = new ScenarioAppIcon(slug);
        this._icon.position.y = SYMBOL_ICON_Y_POSITION;
        this._icon.renderOrder = 17;
        this.add(this._icon);

        this._title = new Text();
        this._title.text = title;
        this._title.font = '/webfonts/Inter-Medium.woff';
        this._title.anchorX = 'center';
        this._title.fontSize = 0.12;
        this._title.color = LEGEND_TEXT_COLOR_LIGHT;
        this._title.rotation.x = -Math.PI / 2;
        this._title.position.y = PREVENT_Z_FIGHTING * 2;
        this._title.position.z = 40 / SCALE_FACTOR;
        this._title.renderOrder = 19;
        this._title.sync();
        this.add(this._title);
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        super.clear();

        this._shape.clear();
        this._icon.clear();
        this._title.dispose();

        return this;
    }

}