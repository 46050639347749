import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { GROUP_ROUNDED_RECT_RADIUS } from '../common/constants';
import { CosmosThree } from '../CosmosThree';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';

export class GroupShapeTop extends BaseObject{

    width = 0.001;
    height = 0.001;

    constructor(){
        super();
    }

    updateGeometry() {
        if (!this.instancedOrBatchedMesh) return;

        const shape = new THREE.Shape();
        const angleStep = Math.PI * 0.5;
        const width = this.width;
        const height = this.height;
        const radius = GROUP_ROUNDED_RECT_RADIUS;

        shape.absarc(width / 2 - radius, height / 2 - radius, radius, angleStep * 0, angleStep * 1);
        shape.absarc(-width / 2 + radius, height / 2 - radius, radius, angleStep * 1, angleStep * 2);
        shape.absarc(-width / 2 + radius, -height / 2 + radius, radius, angleStep * 2, angleStep * 3);
        shape.absarc(width / 2 - radius, -height / 2 + radius, radius, angleStep * 3, angleStep * 4);

        const geometry = new THREE.ShapeGeometry(shape, 6).rotateX( -Math.PI / 2 );

        if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.instanceId === -1){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry, 52, 150 );
                this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}
    }
    
    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [/* ExtensionDottedGrid, */ ExtensionFog],
            {
                /* dotSize: 0.05,
                repeat: 10.0,
                dotColor: new THREE.Color("#000000"), */
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                color: CosmosThree.groupsTopColor,
                // wireframe: true
            },
            { debug: false }
        );

		return material;
    }
}