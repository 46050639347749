import * as THREE from 'three';

import { gsap } from "gsap";

import { BaseObject } from '../common/BaseObject';
import { GroupShapeTop } from './GroupShapeTop';
import { GroupShapeSide } from './GroupShapeSide';
import { getBoundsRectBySymbols } from '../utils/utils';
import { GROUP_HIDE_Y_POSITION, GROUP_PADDING, GROUP_TOP_Y_POSITION, GROUP_Y_POSITION, INTERACTION_ANIMATION_SPEED_FAST, INTERACTION_ANIMATION_SPEED_SLOW, LEGEND_POSITION_Y_OFFSET, SYMBOL_PAD_SIZE } from '../common/constants';
import { Repository } from '../common/Repository';
import { CosmosThree } from '../CosmosThree';
import { Symbol } from "../symbols/Symbol";
import { GroupLegend } from './GroupLegend';

export class Group extends BaseObject{

	private _symbols: Symbol[] = [];

	readonly isGroup = true;

	private _visible = false;
	private _filtered = false;
	private _muted = false;

	private _title = "";

	private _shapeSide: GroupShapeSide;
	private _shapeTop: GroupShapeTop;

	legend: GroupLegend;

	width = 0;
	height = 0;

	private _tintSideTo: gsap.QuickToFunc;
	private opacityTo: gsap.QuickToFunc;
	private yTo: gsap.QuickToFunc;

    constructor () {
		super();

		this._shapeSide = new GroupShapeSide();
		this.three.add(this._shapeSide.three);

		this._shapeTop = new GroupShapeTop();
		this._shapeTop.three.position.y = GROUP_TOP_Y_POSITION;
		this.three.add(this._shapeTop.three);

		this.legend = new GroupLegend();
		this.legend.three.position.y = -LEGEND_POSITION_Y_OFFSET;

		this.three.add(this.legend.three);

		this._shapeSide.instancedOrBatchedMesh = Repository.groupsShapesSideMesh!;
		this._shapeTop.instancedOrBatchedMesh = Repository.groupsShapesTopMesh!;
		this.legend.instancedOrBatchedMesh = Repository.groupsLegendsTextsMesh!;

        // Quick tween functions to boost performance even more
		this._tintSideTo = gsap.quickTo(this._shapeSide, "tint", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		this.opacityTo = gsap.quickTo(this, "opacity", { duration: INTERACTION_ANIMATION_SPEED_SLOW, ease: "none" });
		this.yTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED_SLOW, ease: "power2.inOut", onUpdate: () => {
			this.matrixNeedsUpdate = true;
			CosmosThree.contactShadowsNeedUpdate = true;
		} });
	}

    override get matrixNeedsUpdate() {
		return super.matrixNeedsUpdate;
	}

	override set matrixNeedsUpdate(value) {
		super.matrixNeedsUpdate = value;

		this._shapeSide.matrixNeedsUpdate = value;
		this._shapeTop.matrixNeedsUpdate = value;
		this.legend.matrixNeedsUpdate = value;
	}

	override get color() {
		return super.color;
	}

	override set color(value: THREE.Color) {
		if (this.color.equals(value)) return;

		super.color = value;

		this._shapeSide.color = this.color;
		this._shapeTop.color = this.color;
	}

	override get opacity() {
		return super.opacity;
	}

	override set opacity(value) {
		if(this.opacity === value) return;

		super.opacity = value;

		this.legend.opacity = value;
	}

	override get tint() {
		return super.tint;
	}

	override set tint(value) {
		if(this.tint === value) return;

		super.tint = value;

		this._shapeSide.tint = value;
		this._shapeTop.tint = value;
	}

	get visible() {
		return this._visible;
	}

	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			if(this._visible){
				this.hideOut();
			}else{
				this.hideIn();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	public get title() {
		return this._title;
	}

	public set title(value) {
		if(this._title === value) return;

		this._title = value;

		this.legend.title = this._title;

		// 32 characters, the double of the max allowed for the symbols legends
		// It takes 780px of width
		// this.legend.title = "MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM";

		// This MF takes more than 6000px in width..., we have to trim it
		// 255 seems to be the limit for the folder titles
		// this.legend.title = "MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM";

	}

	public get symbols(): Symbol[] {
		return this._symbols;
	}

	public set symbols(value: Symbol[]) {
		this._symbols = value;
	}

	muteIn(){
		this._tintSideTo(1);
	}

	muteOut(){
		this._tintSideTo(0);
	}

	hide(){
		this.opacity = 0;
		this.three.position.y = GROUP_HIDE_Y_POSITION;
		this.matrixNeedsUpdate = true;
		CosmosThree.contactShadowsNeedUpdate = true;
	}

	hideIn(){
		this.opacityTo(0);
		this.yTo(GROUP_HIDE_Y_POSITION);
	}

	hideOut(){
		this.opacityTo(1);
		this.yTo(GROUP_Y_POSITION);
	}

    update(){
		const symbols = this._symbols.filter(symbol => !symbol.filtered);

        const bounds = getBoundsRectBySymbols(symbols, SYMBOL_PAD_SIZE / 2 + GROUP_PADDING);

		this.width = bounds.z;
		this.height = bounds.w;

		this.three.position.x = bounds.x + this.width / 2;
		this.three.position.z = bounds.y + this.height / 2;
		this.matrixNeedsUpdate = true;

		this._shapeSide.width = this.width;
		this._shapeSide.height = this.height;
		this._shapeSide.updateGeometry();

		this._shapeTop.width = this.width;
		this._shapeTop.height = this.height;
		this._shapeTop.updateGeometry();

		this.legend.offsetX = this.width / 2;
		this.legend.offsetZ = this.height / 2;
    }

	override dispose(){
		this.symbols = [];

		this._shapeSide?.dispose();
		this._shapeTop?.dispose();
		this.legend?.dispose();

		super.dispose();
	}

}