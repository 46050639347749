type Props = {
    className?: string,
}

export const SymbolType = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.64289 6.17422C6.49042 6.15732 6.33553 6.14868 6.17861 6.14868C3.87083 6.14868 2.00003 8.01947 2.00003 10.3273C2.00003 12.635 3.87083 14.5058 6.17861 14.5058C8.48638 14.5058 10.3572 12.635 10.3572 10.3273C10.3572 10.1703 10.3485 10.0153 10.3316 9.86297"
                stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M8.5 1.50586H13.1429C14.1685 1.50586 15 2.33733 15 3.363V8.00586C15 9.03153 14.1685 9.863 13.1429 9.863H8.5C7.47433 9.863 6.64286 9.03153 6.64286 8.00586V3.363C6.64286 2.33733 7.47433 1.50586 8.5 1.50586Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
