import {ReactNode, useEffect, useState} from 'react'
import {useAppStore} from '@/store/Store.ts';
import {classNames} from '@/utils/helpers.ts'
import {useMemo, useRef, ChangeEvent} from 'react';
import UseFiltersState from '@/components/state/FiltersState.ts';
import Filters from '@/components/filters/Filters.tsx';
import {CloseSmall} from '@/assets/icons/CloseSmall.tsx';
import {MagicWand} from '@/assets/icons/MagicWand.tsx';
import {MagnifyingGlass} from '@/assets/icons/MagnifyingGlass.tsx';
import Tooltip from '@/components/utils/Tooltip.tsx';
import {Transition} from '@headlessui/react'

type Props = {
    children?: ReactNode;
}

export default function FiltersPanel({children}: Props) {

    const [searchQuery, setSearchQuery] = useState('');
    const settingsElem = useRef<HTMLDivElement>(null);

    const {activeFiltersCount} = UseFiltersState();

    const {
        currentMeshInstance,
        showFilters,
        closeFilters,
        setFiltersSearch,
        resetFilters,
        uiInteractive
    } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance,
            showFilters: state.showFilters,
            closeFilters: state.closeFilters,
            setFiltersSearch: state.setFiltersSearch,
            resetFilters: state.resetFilters,
            uiInteractive: state.uiInteractive
        }
    });

    const showFiltersRef = useRef<boolean>(showFilters);

    const showPanel = useMemo(() => {
        return showFilters;
    }, [showFilters])

    useEffect(() => {
        showFiltersRef.current = showPanel;
    }, [showPanel]);

    useEffect(() => {
        const query = searchQuery.trim().toLowerCase()
        if (query.length > 1) {
            setFiltersSearch(query)
        } else {
            setFiltersSearch('');
        }
    }, [searchQuery]);

    const onFilterSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(event.target.value);
    }

    const reLayout = () => {
        currentMeshInstance?.filterNodes();
    }

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (showFiltersRef.current) {
                    e.preventDefault();
                    closeFilters();
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <Transition show={showPanel}>
            <div className={'ui-panel ui-pointer-events w-available top-12 right-0 absolute transition duration-300 ease-in-out data-[closed]:opacity-0'}>
                <div
                    className={'w-full h-auto max-h-[80vh] group flex flex-col gap-3 transition-[width,height] duration-300 ease-in-out'}
                    ref={settingsElem}
                >
                    <div className={'flex flex-row w-full items-center gap-1 px-4 pt-3'}>
                        <div className="text-lg font-semibold mr-auto">
                            Filters
                        </div>
                        <div className="">
                            <a
                                className={classNames(activeFiltersCount > 0 ? 'text-primary' : 'ui-disabled', 'ui-btn-text')}
                                onClick={() => {
                                    if (!activeFiltersCount) {
                                        return;
                                    }
                                    setFiltersSearch('');
                                    resetFilters()
                                }}
                            >
                                Clear All
                            </a>
                        </div>
                        <button
                            tabIndex={-1}
                            type="button"
                            className="ui-btn-icon-close relative -right-1.5"
                            onClick={closeFilters}
                        >
                            <CloseSmall />
                        </button>
                    </div>
                    <div className="flex flex-row w-full justify-between gap-2 px-4 mb-1">
                        <div className="grow relative rounded-md">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlass className="h-4 w-4 text-base-content relative"/>
                            </div>
                            <input
                                type="text"
                                name="searchInFilters"
                                id="searchInFilters"
                                className="ui-search-input w-full"
                                placeholder="Search filters..."
                                onChange={onFilterSearch}
                                value={searchQuery}
                                disabled={!uiInteractive}
                            />
                        </div>
                        <Tooltip message={'Align'} position={'top'}>
                            <button
                                className="ui-btn-icon w-7 relative -right-1.5"
                                type="button"
                                onClick={() => reLayout()}
                            >
                                <MagicWand />
                            </button>
                        </Tooltip>
                    </div>
                    <div
                        className={'overflow-y-auto h-auto'}
                    >
                        <div className="min-h-max px-4 overflow-auto">
                            <Filters />
                        </div>
                        {children}
                    </div>
                    {/* bottom spacer for scrollbar */}
                    <div className={'w-full h-3'}>
                    </div>
                </div>
            </div>
        </Transition>
    )
}