import { CosmosThree } from '@/three/CosmosThree';

class SpriteSheetIcons {

    getStyle(name: string, width: number, height: number): Record<string, string> | null {
        const dpr = (window.devicePixelRatio > 1) ? 2 : 1;
        let suffix = "";

        if(width <= 16){
            suffix = dpr === 1 ? "32" : "32";
        }else if(width <= 32){
            suffix = dpr === 1 ? "32" : "64";
        }else{
            suffix = dpr === 1 ? "64" : "64";
        }

        const data = CosmosThree.iconsAtlasMaps.get(suffix)!.get(name); 
        if (!data) {
            return null;
        }

        const scaleFactorX = data.frame.w / width;
        const scaleFactorY = data.frame.h / height;

        const sheetWidth = CosmosThree.iconsAtlasJsons.get(suffix)!.meta.size.w;
        const sheetHeight = CosmosThree.iconsAtlasJsons.get(suffix)!.meta.size.h;

        return {
            width: `${width}px`,
            height: `${height}px`,
            backgroundImage: 'url("/textures/atlases/iconpack'+ suffix +'.png")',
            backgroundPosition: `-${data.frame.x / scaleFactorX}px -${data.frame.y / scaleFactorY}px`,
            backgroundSize: `${sheetWidth / scaleFactorX}px ${sheetHeight / scaleFactorY}px`,
            backgroundRepeat: 'no-repeat',
        }
    }

}

export const spriteSheetIcons = new SpriteSheetIcons();