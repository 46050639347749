type Props = {
    className?: string,
}

export const ListArm = ({className}: Props) => {
    return (
        <svg className={'w-[0.875rem] h-[4.375] ' + (className || '')} width="14" height="70" viewBox="0 0 14 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9915 69H5.20898C2.99984 69 1.20898 67.2092 1.20898 65V0.503906" stroke="currentColor"
                  strokeWidth="1.8"/>
        </svg>
    )
}
