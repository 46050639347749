import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkInteractionsShape } from './LinkInteractionsShape';

export class LinksInteractionsShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        super(size, size * 36, size * 84, LinkInteractionsShape.getMaterial());

        this.layers.enable( LinkInteractionsShape.interactionLayer );

        // This has to be set to true so the raycasting works
        this.shouldUpdateBoundingSphere = true;

        this.castShadow = false;
        this.receiveShadow = true;

        this.name = "linksInteractionsShapesBM";
    }
}