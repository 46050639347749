import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LEGEND_GROUPS_OFFSET_X, LEGEND_GROUPS_OFFSET_Z, LEGEND_POSITION_Y_OFFSET } from '../common/constants';
import { CosmosThree } from '../CosmosThree';
import { BaseObject } from '../common/BaseObject';
import { GroupLegendOutline } from './GroupLegendOutline';

export class GroupLegendsOutlinesBM extends BaseBatchedMesh{
    constructor(size : number){
        // check geometry.attributes.position.count to figure out the needed max vertex count
        // the geometry is non-indexed so no need for max index count
        super(size, size * 156, size * 150, GroupLegendOutline.getMaterial());
    }

    override sync (){
        let colorsWhereUpdated = false;
        let opacitiesWhereUpdated = false;
    
        /* Logger.time('[perf] mesh: update matrices'); */
		for(let i = 0 ; i < this.elems.length; i++){
			const elem = this.elems[i];
			if(elem.instanceId !== -1){
				this.syncMatrix(elem);

				if(elem.colorNeedsUpdate){
					this.syncColor(elem);

					colorsWhereUpdated = true;
				}

				if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
					this.syncOpacity(elem);

					opacitiesWhereUpdated = true;
				}
			}
		}

        if(colorsWhereUpdated) this.colorNeedsUpdate = false;
        if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;

        /* Logger.timeEnd('[perf] mesh: update matrices'); */
    }

    override syncMatrix(elem: BaseObject){
		this.vector.setFromMatrixPosition(elem.three.parent!.parent!.matrix);

		this.vector.x += (elem as GroupLegendOutline).offsetX - LEGEND_GROUPS_OFFSET_X;
		this.vector.z += (elem as GroupLegendOutline).offsetZ - LEGEND_GROUPS_OFFSET_Z;

		this.vector.add(CosmosThree.meshOffset);
		this.vector.project(CosmosThree.graphCamera);

		this.vector.unproject(CosmosThree.guiCamera);

		// Update the matrices of the objects in the scene
		this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

		this.mtx.makeTranslation(this.vector);

		// We apply the scale of the legend to efectively 'hide' the legend if necessary
		// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
		this.mtx.scale(elem.three.scale);

		this.setMatrixAt(elem.instanceId, this.mtx);
	}
}