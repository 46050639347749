type Props = {
    className?: string,
}

export const Moon = ({className}: Props) => {
    return (
        <svg className={'w-[1.063rem] h-[1.063rem] ' + (className || '')} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.2694 10.9908C14.4703 10.4939 13.8785 10.0972 13.377 10.2864C12.81 10.5003 12.1955 10.6173 11.5537 10.6173C8.70006 10.6173 6.38672 8.30395 6.38672 5.4503C6.38672 4.92754 6.46435 4.42292 6.60871 3.94733C6.76442 3.43434 6.32962 2.8696 5.8469 3.10282C3.77729 4.10272 2.34961 6.22181 2.34961 8.67436C2.34961 12.0898 5.11834 14.8585 8.53375 14.8585C11.13 14.8585 13.3527 13.2586 14.2694 10.9908Z"
                fill="currentColor"/>
            <path
                d="M15.8474 3.05958L14.444 2.52958L13.9172 1.11663C13.7345 0.624046 12.9272 0.624046 12.7445 1.11663L12.2177 2.52958L10.8143 3.05958C10.5704 3.15193 10.4082 3.38716 10.4082 3.64986C10.4082 3.91256 10.5704 4.14778 10.8143 4.24014L12.2177 4.77013L12.7445 6.18308C12.8359 6.42942 13.0699 6.59233 13.3308 6.59233C13.5918 6.59233 13.8258 6.42933 13.9171 6.18308L14.4439 4.77013L15.8473 4.24014C16.0912 4.14778 16.2534 3.91256 16.2534 3.64986C16.2534 3.38716 16.0912 3.15193 15.8474 3.05958Z"
                fill="currentColor" fillOpacity="0.6"/>
        </svg>
    )
}

