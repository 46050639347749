type Props = {
    className?: string,
}

export const Attributes = ({className}: Props) => {
    return (
        <svg className={'w-7 h-7 ' + (className || '')} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 7V10H12" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 10V17C8 18.105 8.895 19 10 19H12" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M19 7H16C15.4477 7 15 7.44772 15 8V11C15 11.5523 15.4477 12 16 12H19C19.5523 12 20 11.5523 20 11V8C20 7.44772 19.5523 7 19 7Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M19 16H16C15.4477 16 15 16.4477 15 17V20C15 20.5523 15.4477 21 16 21H19C19.5523 21 20 20.5523 20 20V17C20 16.4477 19.5523 16 19 16Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
