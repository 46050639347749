import {useAppStore} from '../store/Store.ts'
import {classNames} from '../utils/helpers.ts';
import Tooltip from './utils/Tooltip.tsx';
import {ThreeDCube} from '@/assets/icons/ThreeDCube.tsx';
import {TwoDGrid} from '@/assets/icons/TwoDGrid.tsx';
import {MagicWand} from '@/assets/icons/MagicWand.tsx';

function CosmosToolbar() {

    const {
		cameraMode,
        setCameraMode,
        uiInteractive,
        currentMeshInstance,
	} = useAppStore((state) => {
        return {
			cameraMode: state.userSettings.cameraMode,
            setCameraMode: state.setCameraMode,
            uiInteractive: state.uiInteractive,
            currentMeshInstance: state.currentMeshInstance,
		}
    });

    const layout = async () => {
        currentMeshInstance?.filterNodes();
    }

    return (
        <div
            className='ui-panel ui-pointer-events p-1.5 fixed bottom-7 inset-x-0 max-w-max mx-auto gap-1.5 items-center z-30 flex flex-wrap'>
            <ul className='flex items-center gap-1'>
                <li className="relative">
                    <Tooltip message={"Align"} position={'top'}>
                        <button
                            type="button"
                            className="ui-btn-icon"
                            onClick={layout}
                            disabled={!uiInteractive}
                        >
                            <MagicWand className={''} />
                        </button>
                    </Tooltip>
                </li>
            </ul>
            <div className="ui-toolbar-separator"></div>
            <ul className='flex items-center gap-1'>
                <li className="relative">
                    <Tooltip message={"3D View"} position={'top'}>
                        <button
                            type="button"
                            className={classNames(cameraMode === "3d" ? 'active' : '', 'ui-btn-icon')}
                            onClick={() => {
                                setCameraMode("3d")
                            }}
                            disabled={!uiInteractive}
                        >
                            <ThreeDCube className={'relative -top-[0.2px]'} />
                            </button>
                        </Tooltip>
                </li>
                <li className="relative">
                    <Tooltip message={"2D View"} position={'top'}>
                        <button
                            type="button"
                            className={classNames(cameraMode === "2d" ? 'active' : '', 'ui-btn-icon')}
                            onClick={() => {
                                setCameraMode("2d")
                            }}
                            disabled={!uiInteractive}
                        >
                            <TwoDGrid className={''} />
                        </button>
                    </Tooltip>
                </li>
            </ul>
        </div>
);
}

export default CosmosToolbar;