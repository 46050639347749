import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionBatchedOpacity } from '../utils/materials/extensions/ExtensionBatchedOpacity';
import { LEGEND_OUTLINE_RADIUS } from '../common/constants';
import { CosmosThree } from '../CosmosThree';

export class SymbolLegendOutline extends BaseObject{

    width = 0.001;
    height = 0.001;

    constructor(){
        super();
    }

    updateGeometry(): THREE.BufferGeometry {
        const shape = new THREE.Shape();
        const angleStep = Math.PI * 0.5;
        const width = this.width;
        const height = this.height;
        const radius = LEGEND_OUTLINE_RADIUS;

        shape.absarc(width / 2 - radius, height / 2 - radius, radius, angleStep * 0, angleStep * 1);
        shape.absarc(-width / 2 + radius, height / 2 - radius, radius, angleStep * 1, angleStep * 2);
        shape.absarc(-width / 2 + radius, -height / 2 + radius, radius, angleStep * 2, angleStep * 3);
        shape.absarc(width / 2 - radius, -height / 2 + radius, radius, angleStep * 3, angleStep * 4);

        const geometry = new THREE.ShapeGeometry(shape, 6);

        if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.instanceId === -1){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry, 156, 150 );
                this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionBatchedOpacity],
            {
                color: CosmosThree.symbolLegendsOutlineColor,
                transparent: true,
                // wireframe: true
            },
            { debug: false }
        );

		return material;
    }
}