export const symbolPropsMap: Map<string, {key: string, slug: string, filterLabel: string}> = new Map([
    ["default", {key: "default", slug: "default", filterLabel: "Default"}],
    ["airtable:base", {key: "storage", slug: "airtable", filterLabel: "Airtable Base"}],
    ["airtable:table", {key: "storage", slug: "airtable", filterLabel: "Airtable Table"}],
    ["clickup:list", {key: "default", slug: "clickup", filterLabel: "ClickUp List"}],
    ["email:mailbox", {key: "default", slug: "email", filterLabel: "Email Mailbox"}],
    ["freshdesk:agents", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Agents"}],
    ["freshdesk:canned_responses", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Canned Responses"}],
    ["freshdesk:companies", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Companies"}],
    ["freshdesk:contacts", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Contacts"}],
    ["freshdesk:groups", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Groups"}],
    ["freshdesk:tickets", {key: "default", slug: "freshdesk", filterLabel: "Freshdesk Tickets"}],
    ["google:calendar", {key: "default", slug: "google-calendar", filterLabel: "Google Calendar"}],
    ["google:file", {key: "storage", slug: "google-drive", filterLabel: "Google File"}],
    ["google:folder", {key: "storage", slug: "google-drive", filterLabel: "Google Folder"}],
    ["google:spreadsheet", {key: "storage", slug: "google-sheets", filterLabel: "Google Spreadsheet"}],
    ["hubspot:calls", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Calls"}],
    ["hubspot:companies", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Companies"}],
    ["hubspot:contacts", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Contacts"}],
    ["hubspot:custom_objects", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Custom Objects"}],
    ["hubspot:deals", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Deals"}],
    ["hubspot:products", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Products"}],
    ["hubspot:tickets", {key: "default", slug: "hubspotcrm", filterLabel: "HubSpot Tickets"}],
    ["jira:project", {key: "default", slug: "jira", filterLabel: "Jira Project"}],
    ["jotform:form", {key: "default", slug: "jotform", filterLabel: "Jotform Form"}],
    ["make:datastore", {key: "storage", slug: "datastore", filterLabel: "Make Datastore"}],
    ["make:module", {key: "default", slug: "make", filterLabel: "Make Module"}],
    ["make:scenario", {key: "make_scenario", slug: "make", filterLabel: "Make Scenario"}],
    ["make:webhook", {key: "make_webhook", slug: "gateway", filterLabel: "Make Webhook"}],
    ["monday:board", {key: "default", slug: "monday", filterLabel: "Monday Board"}],
    ["mysql:table", {key: "storage", slug: "mysql", filterLabel: "MySQL Table"}],
	["openai:assistant", {key: "default", slug: "openai-gpt-3", filterLabel: "OpenAI Assistant"}],
    ["salesforce:records", {key: "default", slug: "salesforce", filterLabel: "Salesforce Records"}],
    ["slack:channel", {key: "default", slug: "slack", filterLabel: "Slack Channel"}],
    ["tally:form", {key: "default", slug: "tally", filterLabel: "Tally Form"}],
]);