import {Logger} from '@/utils/Logger.ts';

export type EdgeFunctionError = {
    context: {
        body: ReadableStream
    }
}

export interface EdgeFunctionErrorBody {
    message: string;
    name: string;
    stack: string | null;
}

export enum ErrorType {
    USER_NOT_ALLOWED_BY_EMAIL = 'user_not_allowed_by_email',
    USER_NOT_ALLOWED_BY_ORGANIZATION = 'user_not_allowed_by_organization',
    USER_NO_PERMISSION_FOR_OAUTH = 'user_no_permission_for_oauth',
    GENERAL_LOGIN_ERROR = 'general_login_error',
    GENERAL_ERROR = 'general_error',
    SUPABASE_OAUTH_ERROR = 'supabase_oauth_error',
    GOOGLE_OAUTH_ERROR = 'google_oauth_error',
    MISSING_ZONE_AND_ORGANIZATION_INFO = 'missing_zone_and_organization_info',
    MAKE_OAUTH_TOKEN_EXPIRED = 'make_oauth_token_expired',
    FAILED_TO_RESOLVE_MAKE_ORGANIZATIONS = 'failed_to_resolve_make_organizations',
    ERROR_WHILE_RESOLVING_GRID_CONNECTION = 'error_while_resolving_grid_connection',
}

const errorMessages: Map<ErrorType, string> = new Map();
errorMessages.set(ErrorType.USER_NOT_ALLOWED_BY_EMAIL, 'The user\'s email is not permitted to access the Grid.');
errorMessages.set(ErrorType.USER_NOT_ALLOWED_BY_ORGANIZATION, 'The user\'s organization is not permitted to access the Grid.');
errorMessages.set(ErrorType.USER_NO_PERMISSION_FOR_OAUTH, 'The user is missing the required OAuth permissions.');
errorMessages.set(ErrorType.GENERAL_LOGIN_ERROR, 'An error occurred during login.');
errorMessages.set(ErrorType.GENERAL_ERROR, 'Something went wrong.');
errorMessages.set(ErrorType.SUPABASE_OAUTH_ERROR, 'Initial OAuth Error.');
errorMessages.set(ErrorType.GOOGLE_OAUTH_ERROR, 'Google OAuth Error.');
errorMessages.set(ErrorType.MISSING_ZONE_AND_ORGANIZATION_INFO, 'Missing zone and organization info.');
errorMessages.set(ErrorType.MAKE_OAUTH_TOKEN_EXPIRED, 'Make OAuth token expired.');
errorMessages.set(ErrorType.FAILED_TO_RESOLVE_MAKE_ORGANIZATIONS, 'Failed to resolve Make organizations.');
errorMessages.set(ErrorType.ERROR_WHILE_RESOLVING_GRID_CONNECTION, 'Error while resolving Grid connection.');

export const getErrorMessage = (error: ErrorType) => {
    let message = errorMessages.get(error);
    if (!message) {
        message = 'Something went wrong.';
    }
    return message;
}

export async function handleEdgeFunctionError(error: EdgeFunctionError): Promise<EdgeFunctionErrorBody | null> {
    if (error?.context?.body instanceof ReadableStream) {
        const reader = error.context.body.getReader();
        const chunks = [];
        let done = false;

        while (!done) {
            const { value, done: isDone } = await reader.read();
            if (value) {
                chunks.push(value);
            }
            done = isDone;
        }
        const concatenatedChunks = new Uint8Array(
            chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), [])
        );
        const jsonString = new TextDecoder("utf-8").decode(concatenatedChunks);
        if (!jsonString) {
            return null;
        }
        try {
            return JSON.parse(jsonString);
        } catch (parseError) {
            Logger.error("Failed to parse edge function error:", parseError);
            return null;
        }
    } else {
        Logger.error("Edge function error body is not a ReadableStream:", error);
        return null;
    }
}