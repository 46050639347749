import type { StateCreator } from 'zustand'
import type { SupabaseResponse } from '../types/common.ts'
import type { NotificationSlice } from './Notification.ts'
import type { Space, SpaceSlice } from './Space.ts'
import type { Json } from '../types/common.ts'
import type { FormItem } from '../types/form.ts'

import { NotificationType } from './Notification.ts'
import { Nullable } from '../types/common.ts'
import supabase from '../utils/supabase-client'
import { unionBy } from 'lodash';
import { orderByCreated, orderByName } from '../utils/helpers.ts'
import { Logger } from '../utils/Logger.ts'


export type Connection = {
    id: string;
    name: string;
	owner_id: string;
    space_id: string;
    credentials?: Json;
    connection_type_id: string;
    enabled: boolean;
    created_at: string;
}

export type ConnectionType = {
    id: string;
    slug: string;
    name: string;
    provider_id: string;
    parameters: FormItem[];
}

export type FeedType = {
    id: string;
    slug: string;
    name: string;
    connection_type_id: string;
}

export type Feed = {
    id: string;
    connection_id: string;
    feed_type_id: string;
    interval_seconds: number;
    last_check: Nullable<string>;
    next_check: Nullable<string>;
    enabled: boolean;
}

export interface ConnectionSlice {
    connections: Connection[];
    connectionsTypes: ConnectionType[];
    feedTypes: FeedType[];
    showConnectionList: boolean;
    fetchConnections: (spaceId: string) => Promise<Nullable<Connection[]>>;
    fetchConnectionsTypes: () => Promise<Nullable<ConnectionType[]>>;
    fetchFeedTypes: (connectionTypeId: string) => Promise<Nullable<FeedType[]>>;
    fetchFeeds: (connectionId: string) => Promise<Nullable<Feed[]>>;
    createConnection: (space: Space, connectionType: ConnectionType, payload: Partial<Connection>, feedTypeIds: string[]) => Promise<Nullable<Connection>>;
    updateConnection: (connectionId: string, payload: Partial<Connection>, feedTypeIds: string[]) => Promise<Nullable<Connection>>;
    toggleConnectionList: (show?: boolean) => void;
}

export const createConnectionSlice: StateCreator<ConnectionSlice & NotificationSlice & SpaceSlice, [], [], ConnectionSlice> = (set, get) => ({
    connections: [],
    connectionsTypes: [],
    feedTypes: [],
    showConnectionList: false,
    fetchConnections: async (spaceId: string) => {
        Logger.log('fetching connections by space id');
        const { data, error }: SupabaseResponse<Connection[]> = await supabase.from('connections')
          .select('id, name, owner_id, space_id, connection_type_id, enabled, created_at')
          .eq('space_id', spaceId)
          .returns<Connection[]>();

        if (error) {
            throw error;
        }
        set(() => (
          { connections: orderByCreated(data) })
        )
        return data;
    },
    fetchConnectionsTypes: async () => {
        Logger.log('fetching connection types');
        const { data, error }: SupabaseResponse<ConnectionType[]> = await supabase.from('connections_types')
          .select('id, slug, name, provider_id, parameters')
          .eq('deleted', false)
          .returns<ConnectionType[]>();

        if (error) {
            throw error;
        }
        set(() => (
          { connectionsTypes: orderByName(data) || [] })
        )
        return data;
    },
    fetchFeedTypes: async (connectionTypeId: string) => {
        Logger.log('fetching feed types');
        const { data, error }: SupabaseResponse<FeedType[]> = await supabase.from('feeds_types')
          .select('id, slug, name, connection_type_id')
          .eq('deleted', false)
          .eq('connection_type_id', connectionTypeId)
          .returns<FeedType[]>();

        if (error) {
            throw error;
        }
        set(() => (
          { feedTypes: orderByName(data) || [] })
        )
        return data;
    },
    fetchFeeds: async (connectionId: string) => {
        Logger.log('fetching feeds');
        const { data, error }: SupabaseResponse<Feed[]> = await supabase.from('feeds')
          .select('id, connection_id, feed_type_id, interval_seconds, last_check, next_check, enabled')
          .eq('connection_id', connectionId)
          .order('id', { ascending: false })
          .returns<Feed[]>();

        if (error) {
            throw error;
        }
        return data;
    },
    createConnection: async (space: Space, connectionType: ConnectionType, payload: Partial<Connection>, feedTypeIds: string[]) => {
        Logger.log('creating connection');
        payload = {
            ...payload,
            ...{
                space_id: space.id,
                connection_type_id: connectionType.id,
                enabled: true
            }}

        const { data, error }: SupabaseResponse<Connection> = await supabase
          .from('connections')
          .insert(payload)
          .select('id, name, owner_id, space_id, connection_type_id, enabled, created_at')
          .single();

        if (error) {
            throw error;
        }

        for (const feedTypeId of feedTypeIds) {
            const feedPayload = {
                connection_id: data?.id,
                feed_type_id: feedTypeId,
                interval_seconds: 600,
                last_check: null,
                next_check: null,
                enabled: true
            }
            await supabase.from('feeds')
				.insert(feedPayload)
				.select('id, connection_id, feed_type_id, interval_seconds, last_check, next_check, enabled')
				.single();
        }

        if (data) {
            // add new item to store
            set((state) => (
              { connections: orderByCreated(unionBy([data], state.connections, 'id')) })
            )
        }

        get().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Connection created'
        });
        return data;
    },
    updateConnection: async (connectionId: string, payload: Partial<Connection>, feedTypeIds: string[]) => {
        Logger.log('updating connection');

        const { data, error }: SupabaseResponse<Connection> = await supabase.from('connections')
          .update(payload)
          .eq('id', connectionId)
          .select('id, name, owner_id, space_id, connection_type_id, enabled, created_at')
          .maybeSingle();

        if (error) {
            throw error;
        }

        if (data) {
            set((state) => (
              { connections: orderByCreated(unionBy([data], state.connections, 'id')) })
            )
        }

        // @TODO refactor uodate logic after unique constraint is added to feed_type_id and connection_id
        // update feeds
        const feedsUpdatesIds = [];
        for (const feedTypeId of feedTypeIds) {
            const feedPayload = {
                connection_id: connectionId,
                feed_type_id: feedTypeId,
                interval_seconds: 600,
                enabled: true,
            }

            const { data }: SupabaseResponse<Feed> = await supabase
              .from('feeds')
              .upsert(feedPayload, { ignoreDuplicates: false, onConflict: 'feed_type_id, connection_id' })
              .select('id, connection_id, feed_type_id, interval_seconds, last_check, next_check, enabled')
              .maybeSingle();
			if (data?.id) {
              feedsUpdatesIds.push(data.id);
			}
        }

		Logger.log('feedsUpdatesIds:');
        Logger.log(`${JSON.stringify(feedsUpdatesIds)}`);

        // set all other feeds to enabled false
        const query = supabase
          .from('feeds')
          .update({ enabled: false })
          .eq('connection_id', connectionId);
        if (feedsUpdatesIds.length > 0) {
            query.not('id', 'in', `(${feedsUpdatesIds.join(',')})`);
        }
        await query;

        get().addNotification({
            type: NotificationType.SUCCESS,
            title: 'Connection updated'
        });
        return data;
    },
    toggleConnectionList: (show) => {
        set((state) => (
          { showConnectionList: show ?? !state.showConnectionList })
        )
    }
})
