type Props = {
    className?: string,
}

export const MakeLogo = ({className}: Props) => (
    <svg className={(className || '')} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36"
         viewBox="0 0 36 36">
        <defs>
            <linearGradient id="linear-gradient" x1="-0.952" y1="0.672" x2="1.165" y2="0.421"
                            gradientUnits="objectBoundingBox">
                <stop offset="0.02" stopColor="#b02de9"/>
                <stop offset="0.8" stopColor="#6d00cc"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.313" y1="0.557" x2="1.339" y2="0.245"
                            gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#f0f"/>
                <stop offset="0.18" stopColor="#e80df9"/>
                <stop offset="0.54" stopColor="#c024ed"/>
                <stop offset="0.73" stopColor="#b02de9"/>
            </linearGradient>
            <linearGradient id="linear-gradient-3" x1="-1.093" y1="0.783" x2="1.935" y2="0.245"
                            gradientUnits="objectBoundingBox">
                <stop offset="0.02" stopColor="#f0f"/>
                <stop offset="0.09" stopColor="#e80df9"/>
                <stop offset="0.23" stopColor="#c024ed"/>
                <stop offset="0.3" stopColor="#b02de9"/>
                <stop offset="0.42" stopColor="#a42be3"/>
                <stop offset="0.63" stopColor="#8527d5"/>
                <stop offset="0.85" stopColor="#6021c3"/>
            </linearGradient>
        </defs>
        <g id="M_color" transform="translate(-3675 3904)">
            <rect id="Rectangle_9375" data-name="Rectangle 9375" width="36" height="36" transform="translate(3675 -3904)"
                  fill="none"/>
            <g id="FAV-Make" transform="translate(3677.311 -3897.092)">
                <path id="Path_131270" data-name="Path 131270"
                      d="M159.018,23.4h-5.464a.756.756,0,0,1-.755-.755V2.475a.756.756,0,0,1,.755-.755h5.464a.755.755,0,0,1,.748.755V22.645a.755.755,0,0,1-.748.755Z"
                      transform="translate(-128.388 -1.442)" fill="url(#linear-gradient)"/>
                <path id="Path_131271" data-name="Path 131271"
                      d="M6.722,22.7,1.837,20.243A.756.756,0,0,1,1.5,19.229l9.06-18.021A.755.755,0,0,1,11.574.872l4.881,2.454a.755.755,0,0,1,.335,1.013L7.735,22.36a.755.755,0,0,1-1.013.335Z"
                      transform="translate(-1.421 -0.664)" fill="url(#linear-gradient-2)"/>
                <path id="Path_131272" data-name="Path 131272"
                      d="M88.1,21.625l3.87-19.643a.755.755,0,0,0-.59-.885L86.026.014a.756.756,0,0,0-.89.6l-3.87,19.643a.755.755,0,0,0,.59.885l5.356,1.082A.756.756,0,0,0,88.1,21.625Z" transform="translate(-68.377 0.001)" fill="url(#linear-gradient-3)"/>
            </g>
        </g>
    </svg>
)