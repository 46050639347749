type Props = {
    className?: string,
}

export const Apps = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.14286 6.28571C5.32632 6.28571 6.28571 5.32632 6.28571 4.14286C6.28571 2.95939 5.32632 2 4.14286 2C2.95939 2 2 2.95939 2 4.14286C2 5.32632 2.95939 6.28571 4.14286 6.28571Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4.14286 14.0001C5.32632 14.0001 6.28571 13.0407 6.28571 11.8572C6.28571 10.6737 5.32632 9.71436 4.14286 9.71436C2.95939 9.71436 2 10.6737 2 11.8572C2 13.0407 2.95939 14.0001 4.14286 14.0001Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.8572 6.28571C13.0407 6.28571 14.0001 5.32632 14.0001 4.14286C14.0001 2.95939 13.0407 2 11.8572 2C10.6737 2 9.71436 2.95939 9.71436 4.14286C9.71436 5.32632 10.6737 6.28571 11.8572 6.28571Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.8569 9.71436V14.0001" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M14.0001 11.8572H9.71436" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}
