import type {FilterItem} from '@/store/Filter.ts';
import UseFiltersState from '@/components/state/FiltersState.ts';
import {useAppStore} from '@/store/Store.ts'
import {XMarkIcon} from '@heroicons/react/24/outline';

export default function FilterBadges() {

  const {flattenActiveFilters} = UseFiltersState();

  const {
    updateActiveFilters
  } = useAppStore((state) => {
    return {
      updateActiveFilters: state.updateActiveFilters,
    }
  });

  const removeFilter = (item: FilterItem) => {
    updateActiveFilters(item.definition.groupId, item, false);
  }

  return (
    <div className="flex flex-row flex-wrap items-center gap-2 max-w-max">
      {flattenActiveFilters.map((item) => {
        return (
          <div
            key={`${item.definition.groupId}:${item.id}`}
            className="ui-pointer-events h-5 inline-flex items-center gap-x-0.5 rounded-md text-primary-content bg-primary px-2 py-1 text-xs font-medium ring-1 ring-inset ring-primary/50">
            {item.label}
            <button type="button" onClick={() => removeFilter(item)}
                    className="ui-icon-close text-primary-content/60 -mr-2">
              <XMarkIcon className="h-3.5 w-3.5" aria-hidden="true"/>
            </button>
        </div>
        )
      })}
    </div>
  );

}