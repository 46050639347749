import * as THREE from 'three';

import {Text} from 'troika-three-text';
import { DynamicDrawUsage } from "three";
import { glyphBoundsAttrName, glyphIndexAttrName, GlyphsGeometry } from "../utils/troika/GlyphsGeometry.js";
import { createDerivedMaterial } from "troika-three-utils";
import { createTextDerivedMaterial } from "troika-three-text";
import { BaseObject } from './BaseObject.js';
import { CosmosThree } from '../CosmosThree.js';
import { LEGEND_POSITION_Y_OFFSET, LEGENDS_FINAL_ZOOM, LEGENDS_INITIAL_ZOOM } from './constants.js';
import { Repository } from './Repository.js';

const syncStartEvent = { type: "syncstart" };
const syncCompleteEvent = { type: "synccomplete" };
const memberIndexAttrName = "aTroikaTextBatchMemberIndex";

const floatsPerMember = 16;

export class InstancedText extends THREE.Mesh {

  elems: BaseObject[] = [];

  private _members = new Map();

  private maxInstanceCount = -1;

  private matricesTexture: THREE.DataTexture;
  private colorsTexture: THREE.DataTexture;
  private opacitiesTexture: THREE.DataTexture;

  matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
  opacityNeedsUpdate = false;
  tintNeedsUpdate = false;
  drawProgressNeedsUpdate = false;

  protected mtx = new THREE.Matrix4();
  protected vector = new THREE.Vector3();

  constructor (count : number) {
    super(new GlyphsGeometry(), new THREE.MeshBasicMaterial());

    this.frustumCulled = false;

    this.material = this.createBatchedTextMaterial(this.material as any);

    this._members = new Map();

    this.maxInstanceCount = count;

    this.matricesTexture = this.initMatricesTexture();
    (this.material as any).setMatrixTexture(this.matricesTexture);

    this.colorsTexture = this.initColorsTexture();
    (this.material as any).setColorsTexture(this.colorsTexture);

    this.opacitiesTexture = this.initOpacitiesTexture();
    (this.material as any).setOpacitiesTexture(this.opacitiesTexture);
  }

  onMemberSynced (e: THREE.Event ) {
    this._members.get(e.target).dirty = true;
  }

  onMemberRemoved (e: THREE.Event ) {
    this.removeText(e.target as Text);
  }

  private initMatricesTexture(){
    let size = Math.sqrt( this.maxInstanceCount * floatsPerMember ); // 4 pixels needed for 1 matrix
		size = Math.ceil( size / floatsPerMember ) * floatsPerMember;
		size = Math.max( size, floatsPerMember );

    const matricesArray = new Float32Array( size * size * floatsPerMember ); // 4 floats per RGBA pixel
    const matricesTexture = new THREE.DataTexture( matricesArray, size, size, THREE.RGBAFormat, THREE.FloatType );

    matricesTexture.needsUpdate = true;

    return matricesTexture;
  }

  private initColorsTexture(){
    let size = Math.sqrt( this.maxInstanceCount );
		size = Math.ceil( size );

    // 4 floats per RGBA pixel initialized to white
		const colorsArray = new Float32Array( size * size * 4 ).fill( 1 );
    const colorsTexture = new THREE.DataTexture( colorsArray, size, size, THREE.RGBAFormat, THREE.FloatType );
		colorsTexture.colorSpace = THREE.ColorManagement.workingColorSpace;

    colorsTexture.needsUpdate = true;

    return colorsTexture;
  }

  private initOpacitiesTexture(){
    let size = Math.sqrt( this.maxInstanceCount );
		size = Math.ceil( size );

    // 4 floats per RGBA pixel initialized to white
		const opacitiesArray = new Float32Array( size * size ).fill( 1 );
    const opacitiesTexture = new THREE.DataTexture( opacitiesArray, size, size, THREE.RedFormat, THREE.FloatType );
		opacitiesTexture.colorSpace = THREE.ColorManagement.workingColorSpace;

    opacitiesTexture.needsUpdate = true;

    return opacitiesTexture;
  }

  syncMatrix(elem: BaseObject){
		if(elem.three.parent){
			this.vector.setFromMatrixPosition(elem.three.parent.matrix);

			this.vector.add(CosmosThree.meshOffset);
			this.vector.project(CosmosThree.graphCamera);

			this.vector.unproject(CosmosThree.guiCamera);

			// Update the matrices of the objects in the scene
			this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

			this.mtx.makeTranslation(this.vector);

			// We apply the scale of the legend to efectively 'hide' the legend if necessary
			// The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
			this.mtx.scale(elem.three.scale);

			this.setMatrixAt(elem.instanceId, this.mtx);
		}
	}

  setMatrixAt( instanceId: number, matrix: THREE.Matrix4 ) {
		const matricesArray = this.matricesTexture.image.data;
		matrix.toArray( matricesArray, instanceId * 16 );
		this.matricesTexture.needsUpdate = true;
	}

  syncColor(elem: BaseObject){
    elem.colorNeedsUpdate = false;
    this.setColorAt(elem.instanceId, elem.color);
}

  setColorAt( instanceId: number, color: THREE.Color ) {
		const colorsArray = this.colorsTexture.image.data;
		color.toArray( colorsArray, instanceId * 4 );
		this.colorsTexture.needsUpdate = true;
	}

  syncOpacity(elem: BaseObject){
    elem.opacityNeedsUpdate = false;
    this.setOpacityAt(elem.instanceId, elem.opacity);
}

  setOpacityAt( instanceId: number, opacity: number ) {
    this.opacitiesTexture.image.data[ instanceId ] = opacity;
		this.opacitiesTexture.needsUpdate = true;
	}

  protected updateOpacityFromZoom() {
		const currentZoom = CosmosThree.graphCamera.zoom;
		if(!Repository.mesh?.intoIdleMode && !Repository.mesh?.intoScenarioMode){
			if(currentZoom < LEGENDS_FINAL_ZOOM){
				if(currentZoom < LEGENDS_INITIAL_ZOOM){
					this.visible = false;
					(this.material as any).opacity = 0;
				}else{
					this.visible = true;
					// Ensure the zoom level is clamped within the initial and final zoom values
					const clampedZoom = Math.min(Math.max(currentZoom, LEGENDS_INITIAL_ZOOM), LEGENDS_FINAL_ZOOM);
				
					// Calculate the interpolation factor based on the current zoom level
					const interpolatedOpacity = (clampedZoom - LEGENDS_INITIAL_ZOOM) / (LEGENDS_FINAL_ZOOM - LEGENDS_INITIAL_ZOOM);
				
					// Set the opacity of the mesh
					(this.material as any).opacity = interpolatedOpacity;
				}
			}else{
				this.visible = true;
				(this.material as any).opacity = 1;
			}
		}
	}

  /**
   * Batch any Text objects added as children
   */
  addTexts (...objects: Text[]) {
    for (let i = 0; i < objects.length; i++) {
      if (objects[i] instanceof Text) {
        this.addText(objects[i]);
      }
    }
    return this;
  }

  addText (text: Text) {
    if (!this._members.has(text)) {
      this._members.set(text, {
        index: -1,
        glyphCount: -1,
        dirty: true
      });
      text.addEventListener("removed", this.onMemberRemoved.bind(this));
      text.addEventListener("synccomplete" as any, this.onMemberSynced.bind(this));
    }
  }

  removeText (text: Text) {
    text.removeEventListener("removed", this.onMemberRemoved.bind(this));
    text.removeEventListener("synccomplete" as any, this.onMemberSynced.bind(this));
    this._members.delete(text);
  }

  syncTexts (callback?: any) {
    // TODO: skip members updating their geometries, just use textRenderInfo directly

    // Trigger sync on all members that need it
    let syncPromises: any;
    this._members.forEach((packingInfo, text) => {
      if (packingInfo.dirty || text._needsSync) {
        packingInfo.dirty = false;
        (syncPromises || (syncPromises = [])).push(new Promise(resolve => {
          if (text._needsSync) {
            text.sync(resolve);
          } else {
            resolve(undefined);
          }
        }));
      }
    });

    // If any needed syncing, wait for them and then repack the batched geometry
    if (syncPromises) {
      this.dispatchEvent(syncStartEvent as any);

      Promise.all(syncPromises).then(() => {
        const geometry = this.geometry;
        const batchedAttributes = geometry.attributes;
        let memberIndexes = batchedAttributes[memberIndexAttrName] && batchedAttributes[memberIndexAttrName].array || new Uint16Array(0);
        let batchedGlyphIndexes = batchedAttributes[glyphIndexAttrName] && batchedAttributes[glyphIndexAttrName].array || new Float32Array(0);
        let batchedGlyphBounds = batchedAttributes[glyphBoundsAttrName] && batchedAttributes[glyphBoundsAttrName].array || new Float32Array(0);

        // Initial pass to collect total glyph count and resize the arrays if needed
        let totalGlyphCount = 0;
        this._members.forEach((_packingInfo, { textRenderInfo }) => {
          if (textRenderInfo) {
            totalGlyphCount += textRenderInfo.glyphAtlasIndices.length;
          }
        });

        if (totalGlyphCount !== memberIndexes.length) {
          memberIndexes = this.cloneAndResize(memberIndexes, totalGlyphCount);
          batchedGlyphIndexes = this.cloneAndResize(batchedGlyphIndexes, totalGlyphCount);
          batchedGlyphBounds = this.cloneAndResize(batchedGlyphBounds, totalGlyphCount * 4);
        }

        // Populate batch arrays
        let memberIndex = 0;
        let glyphIndex = 0;

        this._members.forEach((packingInfo, { textRenderInfo }) => {
          if (textRenderInfo) {
            const glyphCount = textRenderInfo.glyphAtlasIndices.length;
            memberIndexes.fill(memberIndex, glyphIndex, glyphIndex + glyphCount);

            // TODO can skip these for members that are not dirty or shifting overall position:
            batchedGlyphIndexes.set(textRenderInfo.glyphAtlasIndices, glyphIndex);
            batchedGlyphBounds.set(textRenderInfo.glyphBounds, glyphIndex * 4);

            glyphIndex += glyphCount;
            packingInfo.index = memberIndex++;
          }
        });

        // Update the geometry attributes
        (geometry as any).updateAttributeData(memberIndexAttrName, memberIndexes, 1);
        (geometry.getAttribute(memberIndexAttrName) as any).setUsage(DynamicDrawUsage);
        (geometry as any).updateAttributeData(glyphIndexAttrName, batchedGlyphIndexes, 1);
        (geometry as any).updateAttributeData(glyphBoundsAttrName, batchedGlyphBounds, 4);

        this.dispatchEvent((syncCompleteEvent as any));
        
        const text = new Text();

        text.sync(()=>{

          text._prepareForRender(this.material);

          if (callback) {
            callback();
          }
        });

      });
    }
  }

  cloneAndResize (source: THREE.TypedArray, newLength: number) {
    const copy = new (source.constructor as any)(newLength);
    copy.set(source.subarray(0, newLength));
    return copy;
  }

  private createBatchedTextMaterial (baseMaterial: any) {
    const texMatricesUniformName = "uTroikaMatricesTexture";
    const texMatricesSizeUniformName = "uTroikaMatricesTextureSize";
    const texColorsUniformName = "uTroikaColorsTexture";
    const texColorsSizeUniformName = "uTroikaColorsTextureSize";
    const texOpacitiesUniformName = "uTroikaOpacitiesTexture";
    const texOpacitiesSizeUniformName = "uTroikaOpacitiesTextureSize";
    const colorVaryingName = "vTroikaTextColor";
    const opacityVaryingName = "vTroikaTextOpacity";
    const batchMaterial = createDerivedMaterial(baseMaterial, {
      chained: true,
      uniforms: {
        [texMatricesSizeUniformName]: { value: new THREE.Vector2() },
        [texMatricesUniformName]: { value: null },
        [texColorsSizeUniformName]: { value: new THREE.Vector2() },
        [texColorsUniformName]: { value: null },
        [texOpacitiesSizeUniformName]: { value: new THREE.Vector2() },
        [texOpacitiesUniformName]: { value: null }
      },
      // language=GLSL
      vertexDefs: `
        uniform highp sampler2D ${texMatricesUniformName};
        uniform vec2 ${texMatricesSizeUniformName};
        uniform highp sampler2D ${texColorsUniformName};
        uniform vec2 ${texColorsSizeUniformName};
        uniform highp sampler2D ${texOpacitiesUniformName};
        uniform vec2 ${texOpacitiesSizeUniformName};
        attribute float ${memberIndexAttrName};
        varying vec3 ${colorVaryingName};
        varying float ${opacityVaryingName};
  
        mat4 troikaGetBatchingMatrix( const in float i ) {
          int size = textureSize( ${texMatricesUniformName}, 0 ).x;
          int j = int( i ) * 4;
          int x = j % size;
          int y = j / size;
          vec4 v1 = texelFetch( ${texMatricesUniformName}, ivec2( x, y ), 0 );
          vec4 v2 = texelFetch( ${texMatricesUniformName}, ivec2( x + 1, y ), 0 );
          vec4 v3 = texelFetch( ${texMatricesUniformName}, ivec2( x + 2, y ), 0 );
          vec4 v4 = texelFetch( ${texMatricesUniformName}, ivec2( x + 3, y ), 0 );
          return mat4( v1, v2, v3, v4 );
        }

        vec3 troikaGetBatchingColor( const in float i ) {
          int size = textureSize( ${texColorsUniformName}, 0 ).x;
          int j = int( i );
          int x = j % size;
          int y = j / size;
          return texelFetch( ${texColorsUniformName}, ivec2( x, y ), 0 ).rgb;
        }

        float troikaGetBatchingOpacity( const in float i ) {
          int size = textureSize( ${texOpacitiesUniformName}, 0 ).x;
          int j = int( i );
          int x = j % size;
          int y = j / size;
          return texelFetch( ${texOpacitiesUniformName}, ivec2( x, y ), 0 ).r;
        }
      `,
      // language=GLSL prefix="void main() {" suffix="}"
      vertexTransform: `
        mat4 matrix = troikaGetBatchingMatrix(${memberIndexAttrName});
        position.xyz = (matrix * vec4(position, 1.0)).xyz;

        ${colorVaryingName} = troikaGetBatchingColor(${memberIndexAttrName});

        ${opacityVaryingName} = troikaGetBatchingOpacity(${memberIndexAttrName});
      `,
      // language=GLSL
      fragmentDefs: `
        varying vec3 ${colorVaryingName};
        varying float ${opacityVaryingName};
      `,
      // language=GLSL prefix="void main() {" suffix="}"
      fragmentColorTransform: `
        gl_FragColor.rgb = ${colorVaryingName};
        gl_FragColor.a = ${opacityVaryingName} * opacity;

      `
      // TODO: If the base shader has a diffuse color modify that rather than gl_FragColor
      // customRewriter({vertexShader, fragmentShader}) {
      //   return {vertexShader, fragmentShader}
      // },
    });
    batchMaterial.setMatrixTexture = (texture: any) => {
      batchMaterial.uniforms[texMatricesUniformName].value = texture;
      batchMaterial.uniforms[texMatricesSizeUniformName].value.set(texture.image.width, texture.image.height);
    };
    batchMaterial.setColorsTexture = (texture: any) => {
      batchMaterial.uniforms[texColorsUniformName].value = texture;
      batchMaterial.uniforms[texColorsSizeUniformName].value.set(texture.image.width, texture.image.height);
    };
    batchMaterial.setOpacitiesTexture = (texture: any) => {
      batchMaterial.uniforms[texOpacitiesUniformName].value = texture;
      batchMaterial.uniforms[texOpacitiesSizeUniformName].value.set(texture.image.width, texture.image.height);
    };
    return createTextDerivedMaterial(batchMaterial);
  }

  dispose() : this{
    this.geometry.dispose();
    (this.material as any).dispose();

    this.matricesTexture.dispose();
    this.colorsTexture.dispose();
    this.opacitiesTexture.dispose();

    this.elems = [];

    return this;
  }
}

