type Props = {
    className?: string,
}

export const SymbolBrand = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9288 5.64795H7.6141C6.63708 5.64795 5.84253 6.4425 5.84253 7.41952V14.5058H14.7004V7.41952C14.7004 6.4425 13.9058 5.64795 12.9288 5.64795ZM9.38567 11.8484C9.38567 12.3374 8.98972 12.7342 8.49988 12.7342C8.01005 12.7342 7.6141 12.3374 7.6141 11.8484V11.4056C7.6141 10.9166 8.01005 10.5198 8.49988 10.5198C8.98972 10.5198 9.38567 10.9166 9.38567 11.4056V11.8484ZM9.38567 8.7482C9.38567 9.23715 8.98972 9.63398 8.49988 9.63398C8.01005 9.63398 7.6141 9.23715 7.6141 8.7482V8.30531C7.6141 7.81635 8.01005 7.41952 8.49988 7.41952C8.98972 7.41952 9.38567 7.81635 9.38567 8.30531V8.7482ZM12.9288 11.8484C12.9288 12.3374 12.5329 12.7342 12.043 12.7342C11.5532 12.7342 11.1572 12.3374 11.1572 11.8484V11.4056C11.1572 10.9166 11.5532 10.5198 12.043 10.5198C12.5329 10.5198 12.9288 10.9166 12.9288 11.4056V11.8484ZM12.9288 8.7482C12.9288 9.23715 12.5329 9.63398 12.043 9.63398C11.5532 9.63398 11.1572 9.23715 11.1572 8.7482V8.30531C11.1572 7.81635 11.5532 7.41952 12.043 7.41952C12.5329 7.41952 12.9288 7.81635 12.9288 8.30531V8.7482Z"
                fill="currentColor"/>
            <path
                d="M3.1853 14.5059V3.65853C3.1853 3.26347 3.4475 2.91536 3.8275 2.80641L8.25642 1.54062C8.82244 1.37852 9.3858 1.8037 9.3858 2.39275V3.87555"
                stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.29956 14.5059H14.7006" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}
