import {useAppStore} from '../store/Store.ts'
import Tooltip from '@/components/utils/Tooltip.tsx';
import {classNames} from '@/utils/helpers.ts';
import {Moon} from '@/assets/icons/Moon.tsx'
import {Plus} from '@/assets/icons/Plus.tsx'
import {Minus} from '@/assets/icons/Minus.tsx'
import {Sun} from '@/assets/icons/Sun.tsx'
import {CloudSuccess} from '@/assets/icons/CloudSuccess.tsx';
import {CosmosThree} from '@/three/CosmosThree.ts';

function CosmosControls() {

  const {
    toggleDarkMode,
    darkModeActive,
    uiInteractive,
  } = useAppStore((state) => {
    return {
        toggleDarkMode: state.toggleDarkMode,
        darkModeActive: state.darkModeActive,
        uiInteractive: state.uiInteractive,
    }
  });

  const zoomOut = () => {
      const zoomScale = Math.pow( 0.95, 3.5 * CosmosThree.camControls.dollySpeed );
      const zoom = CosmosThree.graphCamera.zoom * zoomScale;

      CosmosThree.camControls.zoomTo(zoom, true);
  }

  const zoomIn = () => {
      const zoomScale = Math.pow( 0.95, -3.5 * CosmosThree.camControls.dollySpeed );
      const zoom = CosmosThree.graphCamera.zoom * zoomScale;

      CosmosThree.camControls.zoomTo(zoom, true);
  }

  return (
      <div
          className='ui-panel ui-pointer-events p-1.5 fixed flex flex-wrap items-center max-w-max bottom-7 right-6 gap-1.5'>
        <ul className='flex items-center gap-1'>
          <li className="relative">
            <Tooltip message={darkModeActive ? "Light Mode" : "Dark Mode"} position={'top'}>
              <button
                  type="button"
                  className={classNames('ui-btn-icon')}
                  onClick={() => {
                    toggleDarkMode()
                  }}
                  disabled={!uiInteractive}
              >
                {darkModeActive ? (
                    <Sun/>
                ) : (
                    <Moon />
                )}
              </button>
            </Tooltip>
          </li>
        </ul>
        <div className="ui-toolbar-separator"></div>
        <ul className='flex flex-row items-center gap-1'>
          <li className='relative'>
            <button
                type="button"
                className={classNames('ui-btn-icon')}
                onClick={() => {
                  zoomOut()
                }}
                disabled={!uiInteractive}
            >
              <Minus/>
            </button>
          </li>
          <li className="relative min-w-9 text-center">
              <span id="zoomPercentSpan">100</span>%
          </li>
          <li>
            <button
                type="button"
                className={classNames('ui-btn-icon')}
                onClick={() => {
                  zoomIn()
                }}
                disabled={!uiInteractive}
            >
              <Plus />
            </button>
          </li>
        </ul>
        <div className="ui-toolbar-separator"></div>
        <ul className='flex flex-row items-center gap-1'>
          <li className="relative">
            <button
                type="button"
                className={classNames('ui-btn-icon')}
                onClick={() => {

                }}
                disabled={!uiInteractive}
            >
              <CloudSuccess />
            </button>
          </li>
        </ul>
      </div>
  )
}

export default CosmosControls;
