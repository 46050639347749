import type {Link} from '@/three/links/Link.ts';
import type {Symbol} from '@/three/symbols/Symbol.ts';
import {classNames} from '@/utils/helpers.ts';
import Panel from '@/components/ui/panels/Panel.tsx';
import {Close} from '@/assets/icons/Close.tsx';
import {VerticalLine} from '@/assets/icons/VerticalLine.tsx';
import {useEffect, useState} from 'react';
import {useAppStore} from '@/store/Store.ts';
import NodeTitle from './NodeTitle.tsx';
import {AppEnv} from '@/utils/AppEnv.ts';
import Badge from '@/components/ui/Badge.tsx';
import {CodeBracketSquareIcon} from '@heroicons/react/24/outline';
import {getLinkDetailData} from '@/utils/link.ts';
import {ArrowBoth} from '@/assets/icons/ArrowBoth.tsx';
import {ArrowLeft} from '@/assets/icons/ArrowLeft.tsx';
import {ArrowRight} from '@/assets/icons/ArrowRight.tsx';
import {Trigger} from '@/assets/icons/Trigger.tsx';
// import {Copy} from '@/assets/icons/Copy.tsx';
import {Delete} from '@/assets/icons/Delete.tsx';
import {Read} from '@/assets/icons/Read.tsx';
import {Write} from '@/assets/icons/Write.tsx';
import AppIcon from '@/components/utils/AppIcon.tsx';

type Props = {
    nodeDetail: Link;
    onReady: () => void;
}

export default function LinkDetail({nodeDetail, onReady}: Props) {

    const { currentMeshInstance } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance
        }
    });

    const [linkDetailData, setLinkDetailData] = useState(getLinkDetailData(nodeDetail));
    const [showRaw, setShowRaw] = useState(false);

    const closeDetail = () => {
        if (currentMeshInstance) {
            // close spotlight
            currentMeshInstance.unselectAll();
        }
    }

    const getTopSymbol = (nodeDetail: Link): Symbol => {
        return nodeDetail.source as Symbol;
    }

    const getBottomSymbol = (nodeDetail: Link): Symbol => {
        return nodeDetail.target as Symbol;
    }

    useEffect(() => {
        setLinkDetailData(getLinkDetailData(nodeDetail));
        setTimeout(() => {
            onReady();
        })
    }, [nodeDetail]);

    const getLinkIconByType = (linkType: string) => {
        switch (linkType) {
            case 'system:listen':
            case'system:invoke':
                return (<Trigger />);
            case 'system:read':
                return (<Read />);
            case 'system:update':
                return (<Write />);
            case 'system:base':
                return (<Read />);
            case 'system:arrow':
                return (<Trigger />);
            case 'system:create':
                return (<Write />);
            case 'system:delete':
                return (<Delete />);
            case 'system:stream':
                return (<Write />);
            default:
                return (<Read />);
        }
    }

    return (
        <>
            <Panel className={'max-h-[80vh]'}>
                <div className={'flex flex-col w-full gap-8 overflow-y-auto'}>
                    <div className={'flex w-full gap-3 justify-between flex-row items-start p-0 px-sm'}>
                        <div className={'flex flex-col gap-y-2'}>
                            <NodeTitle symbol={getTopSymbol(nodeDetail)}/>
                            <div className={'flex flex-row'}>
                                <div className={'w-10 text-base-content-400 flex justify-center items-center'}>
                                    <VerticalLine/>
                                </div>
                                <div className={'flex flex-row gap-2'}>
                                    {linkDetailData.items.map((linkItem) => (
                                        <div
                                            key={linkItem.linkType.id}
                                            className={classNames(linkItem.linkType.bgColor, linkItem.linkType.contentColor, 'flex content-center justify-center items-center min-w-[3.75rem] h-max text-center border-base-100 font-medium text-sm leading-3 py-1 px-2 rounded-badge')}
                                        >
                                            {linkItem.linkType.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <NodeTitle symbol={getBottomSymbol(nodeDetail)}/>
                        </div>
                        <button
                            tabIndex={-1}
                            type="button"
                            className="ui-btn-icon-close"
                            onClick={closeDetail}
                        >
                            <Close/>
                        </button>
                    </div>

                    {AppEnv.getMode() === 'development' && (
                        <div className={'flex gap-3 flex-col justify-center items-start px-sm'}>
                            <div className={'flex flex-row w-full gap-2 items-center justify-center'}>
                                <Badge
                                    title={'Data'}
                                    className={'cursor-pointer'}
                                    onClick={() => {
                                        setShowRaw(!showRaw)
                                    }}
                                >
                                    <CodeBracketSquareIcon/>
                                </Badge>
                            </div>
                            <pre
                                className={classNames(showRaw ? 'p-2 max-h-60' : 'opacity-0 max-h-0 hidden', 'rounded-box bg-base-100 max-w-full overflow-auto text-xs transition-all duration-200 ease-in-out')}
                            >
                                {JSON.stringify(nodeDetail.originalData, null, 4)}
                            </pre>
                        </div>
                    )}
                    {/*<div className={'flex flex-col w-full gap-4 px-sm'}>
                        <div className={'text-lg font-semibold'}>Description</div>
                        <div className={'text-base-content-500'}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse porttitor neque et risus posuere pharetra.
                            Mauris congue diam vel lorem ornare tincidunt.
                            Nulla nec bibendum augue.
                        </div>
                    </div>*/}
                    <div className={'flex flex-col w-full gap-4 px-sm overflow-y-auto'}>
                        <div className={'text-lg font-semibold'}>Interaction groups</div>
                        <div className={'flex flex-col gap-6'}>
                            {linkDetailData.items.map((linkItem) => (
                                <div
                                    key={linkItem.linkType.id}
                                    className={'flex flex-col gap-4'}
                                >
                                    <div className={'flex flex-row gap-2'}>
                                        <div className={'flex items-center'}>
                                            <AppIcon
                                                className={`rounded-badge w-6 h-6 shrink-0 p-1`}
                                                iconSlug={linkItem.leftSymbol.iconSlug}
                                                bgColor={`#${linkItem.leftSymbol.color.getHexString()}`}
                                                iconWidth={16}
                                                iconHeight={16}
                                                iconSrc={linkItem.leftSymbol.iconURL}
                                                title={linkItem.leftSymbol.originalData?.title}
                                            />
                                        </div>
                                        <div className={'flex flex-col gap-0.5 items-center justify-center'}>
                                            <div key={linkItem.linkType.id}
                                                 className={classNames(linkItem.linkType.contentColor, 'flex items-center relative h-5')}>
                                                {linkItem.linkType.direction === 'both' ? (
                                                    <ArrowBoth/>
                                                ) : linkItem.linkType.direction === 'toNodeDetail' ? (
                                                    <ArrowLeft/>
                                                ) : (
                                                    <ArrowRight/>
                                                )}
                                                <div
                                                    className={classNames(linkItem.linkType.bgColor, linkItem.linkType.contentColor, 'absolute min-w-[3.75rem] text-center border-l-2 border-r-2 border-base-100 h-full left-0 right-0 mx-auto w-max text-sm leading-3 py-1 px-2 rounded-badge')}>
                                                    {linkItem.linkType.label}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex items-center'}>
                                            <AppIcon
                                                className={`rounded-badge w-6 h-6 shrink-0 p-1`}
                                                iconSlug={linkItem.rightSymbol.iconSlug}
                                                bgColor={`#${linkItem.rightSymbol.color.getHexString()}`}
                                                iconWidth={16}
                                                iconHeight={16}
                                                iconSrc={linkItem.rightSymbol.iconURL}
                                                title={linkItem.rightSymbol.originalData?.title}
                                            />
                                        </div>
                                    </div>
                                    {linkItem.modules.length > 0 && (
                                        <div className={'flex flex-col'}>
                                            {linkItem.modules.map((module) => (
                                                <div className="ui-list-item-separator" key={module.id}>
                                                    <div className={'flex flex-row items-center gap-3 py-2'}>
                                                        <div>
                                                            {module.moduleSymbol ? (
                                                                <AppIcon
                                                                    className={`rounded-badge w-6 h-6 shrink-0 p-1`}
                                                                    containerStyle={{
                                                                        backgroundColor: `#${module.moduleSymbol.color.getHexString()}`
                                                                    }}
                                                                    iconSlug={module.moduleSymbol.iconSlug}
                                                                    iconWidth={16}
                                                                    iconHeight={16}
                                                                    iconSrc={module.moduleSymbol.iconURL}
                                                                    title={module.moduleSymbol.originalData?.title}
                                                                />
                                                                ) : (
                                                                <AppIcon
                                                                    className={`rounded-badge w-6 h-6 shrink-0 p-1`}
                                                                    containerStyle={{
                                                                        backgroundColor: `#${linkItem.rightSymbol.color.getHexString()}`
                                                                    }}
                                                                    iconSlug={linkItem.rightSymbol.iconSlug}
                                                                    iconWidth={16}
                                                                    iconHeight={16}
                                                                    iconSrc={linkItem.rightSymbol.iconURL}
                                                                    title={linkItem.rightSymbol.originalData?.title}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className={'truncate [word-break:break-word]'}>
                                                            {module.customLabel || module.name}
                                                        </div>
                                                        <div className={'flex items-center justify-center border border-base-content-300 text-base-content-400 text-sm min-w-4 px-0.5'}>
                                                            {module.id}
                                                        </div>
                                                        <div className={'text-base-content-400 ml-auto'}>
                                                            {getLinkIconByType(module.originalType)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Panel>
        </>
    );

}