import type {ReactNode} from 'react';
import {classNames} from '@/utils/helpers.ts';

type Props = {
  className?: string;
  children?: ReactNode;
}

export default function Panel({className, children}: Props) {

  return (
    <div className={classNames(className || '', 'ui-panel ui-pointer-events ui-transition-a py-sm right-0 gap-6 w-[24rem] flex flex-col justify-center items-start')}>
      {children}
    </div>
  );
}