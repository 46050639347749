import type {ReactNode} from 'react';

import {classNames} from '../../utils/helpers.ts';

type Props = {
    children: ReactNode;
    color?: string,
    className?: string,
}

export default function Pill({children, color, className}: Props) {

    return (
        <div className={classNames('bg-base-200 flex flex-row justify-center items-center text-xs rounded-badge py-1 px-1.5', className || '')}>
            {color && (
                <span
                    className={`rounded-full w-2.5 h-2.5 mr-1`}
                    style={{
                        backgroundColor: color,
                    }}
                ></span>
            )}{children}
        </div>
    )
}