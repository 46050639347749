import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { GROUP_HEIGHT, GROUP_ROUNDED_RECT_RADIUS } from '../common/constants';
import { CosmosThree } from '../CosmosThree';
import { ExtensionFog } from '../utils/materials/extensions/ExtensionFog';
import { ExtensionBatchedTint } from '../utils/materials/extensions/ExtensionBatchedTint';

export class GroupShapeSide extends BaseObject{

    width = 0.001;
    height = 0.001;

    constructor(){
        super();
    }

    updateGeometry() {
        if (!this.instancedOrBatchedMesh) return;

        const shape = new THREE.Shape();
        const angleStep = Math.PI * 0.5;
        const width = this.width;
        const height = this.height;
        const radius = GROUP_ROUNDED_RECT_RADIUS;

        shape.absarc(width / 2 - radius, height / 2 - radius, radius, angleStep * 0, angleStep * 1);
        shape.absarc(-width / 2 + radius, height / 2 - radius, radius, angleStep * 1, angleStep * 2);
        shape.absarc(-width / 2 + radius, -height / 2 + radius, radius, angleStep * 2, angleStep * 3);
        shape.absarc(width / 2 - radius, -height / 2 + radius, radius, angleStep * 3, angleStep * 4);
    
        const geometry = new THREE.ExtrudeGeometry(shape, {
            depth: GROUP_HEIGHT,
            bevelEnabled: false,
            bevelThickness: 0,
            bevelSize: 0,
            bevelSegments: 0,
            curveSegments: 6
        }).rotateX(-Math.PI / 2).translate(0, 0, 0);

        if(this.instancedOrBatchedMesh && (this.instancedOrBatchedMesh as THREE.BatchedMesh).isBatchedMesh){
			if(this.instanceId === -1){
				const geometryId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addGeometry( geometry, 612, 0 );
                this.instanceId = (this.instancedOrBatchedMesh as THREE.BatchedMesh).addInstance(geometryId);
			}else{
				(this.instancedOrBatchedMesh as THREE.BatchedMesh).setGeometryAt( this.instanceId, geometry );
			}
		}

        geometry.clearGroups();
    }
    
    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionBatchedTint, ExtensionFog],
            {
                fDepth: CosmosThree.fogDepth,
                fColor: CosmosThree.fogColor,
                fPlane: CosmosThree.fogPlane,
                fActive: true,
                tintColor: CosmosThree.groupsSideMutedTint,
                color: CosmosThree.groupsSideColor,
                // gradientMap: CosmosThree.gradientMapThree,
                // wireframe: true
            },
            { debug: false }
        );

		return material;
    }
}