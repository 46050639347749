import {redirect} from 'react-router-dom';
import supabase from '@/utils/supabase-client';

export default function Logout() {
    return (
        <></>
    )
}

export async function loader() {
    await supabase.auth.signOut();
    return redirect(`/`);
}