import {ChangeEvent, useEffect, useRef} from 'react';
import {classNames, isMac} from '@/utils/helpers.ts';
import {useAppStore} from '@/store/Store.ts';
import {CommandMacKey} from '@/assets/icons/CommandMacKey.tsx';
import {CtrlKey} from '@/assets/icons/CtrlKey.tsx';
import {MagnifyingGlass} from '@/assets/icons/MagnifyingGlass.tsx';
import {Close} from '@/assets/icons/Close.tsx';
import {UpAndDown} from '@/assets/icons/UpAndDown.tsx';

export default function ExtendedSearch() {

    const {
        currentMeshInstance,
        showSearchResults,
        setShowSearchResults,
        searchQuery,
        setSearchQuery,
        searchResultsCount,
        uiInteractive
    } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance,
            showSearchResults: state.showSearchResults,
            setShowSearchResults: state.setShowSearchResults,
            searchQuery: state.searchQuery,
            setSearchQuery: state.setSearchQuery,
            searchResultsCount: state.searchResultsCount,
            uiInteractive: state.uiInteractive
        }
    });

    const showSearchResultsRef = useRef<boolean>(showSearchResults);
    const searchElementRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        showSearchResultsRef.current = showSearchResults;
    }, [showSearchResults]);

    useEffect(() => {
        const detectKeyInputs = (event: KeyboardEvent) => {
            if (isMac() ? event.metaKey : event.ctrlKey) {
                if (event.key === 'f' || event.key === 'F') {
                    event.preventDefault();
                    if (searchInputRef?.current) {
                        searchInputRef.current.focus();
                    }
                }
            }
            if (event.key === 'Escape') {
                if (showSearchResultsRef.current) {
                    event.preventDefault();
                    setShowSearchResults(false);
                    if (searchInputRef.current) {
                        searchInputRef.current.blur();
                    }
                    return;
                }
            }
        }

        document.addEventListener('keydown', detectKeyInputs);

        return () => {
            document.removeEventListener('keydown', detectKeyInputs);
        }
    }, [currentMeshInstance]);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const q = e.target.value;
        setSearchQuery(q);
    }

    const onFocus = () => {
        if (searchQuery.length > 0) {
            setShowSearchResults(true);
        }
    }

    return (
            <div
                tabIndex={-1}
                className="relative"
                ref={searchElementRef}
            >
                <div className="rounded-md relative group">
                    <div className="absolute select-none inset-y-0 left-0 flex items-center pl-3">
                        {searchQuery.length > 0 ? (
                            <button
                                type="button"
                                className="relative"
                                onClick={() => {
                                    setSearchQuery('');
                                }}
                            >
                                <Close className="!h-3 !w-3" />
                            </button>
                        ) : (
                            <MagnifyingGlass className="h-4 w-4 text-base-content relative" />
                        )}
                    </div>
                    <div>
                        <input
                            type="text"
                            ref={searchInputRef}
                            className={classNames(
                                showSearchResults ? 'w-72' : 'w-56',
                                'ui-search-input ui-extended-search pr-7')}
                            onChange={onSearch}
                            onFocus={onFocus}
                            value={searchQuery}
                            autoComplete="off"
                            disabled={!uiInteractive}
                        />
                    </div>
                    {!searchQuery.length && (
                        <div
                            className={'absolute flex align-middle items-center justify-between w-full h-full pl-10 pr-1 top-0 left-0 text-base-content-400 select-none pointer-events-none'}
                        >
                            <span className={'leading-4 text-lg'}>Find anything...</span>
                            <span className={'ui-key-bind'}>{isMac() ? (<CommandMacKey className={'!h-3'} />) : (<CtrlKey className={'!h-3'} />)}+F</span>
                        </div>
                    )}
                    {showSearchResults && searchResultsCount > 1 && (
                        <div className="absolute right-0 top-0 hidden pr-1 pt-1 sm:block">
                            <div className="ui-key-bind ui-active gap-1">
                                <UpAndDown /> to navigate
                            </div>
                        </div>
                    )}
                </div>
            </div>
    )
}