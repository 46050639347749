import type {LinkItem} from '@/utils/link.ts';
import {useState, useEffect, ChangeEvent} from 'react';
import NodeLink from './NodeLink.tsx';
import {ArrowDown} from '@/assets/icons/ArrowDown.tsx';
import {Search} from '@/assets/icons/Search.tsx';
import {useAppStore} from '@/store/Store.ts';
import {linkTypeMap, sortLinkItems, getAllSymbolLinks} from '@/utils/link.ts';
import {Symbol} from '@/three/symbols/Symbol';
import {Link} from '@/three/links/Link.ts';
import UseFiltersState from '@/components/state/FiltersState.ts';

type Props = {
    nodeDetail: Symbol;
    showSearch?:boolean;
    limit: number;
    showFiltered?: boolean;
    limitFiltered?: number
    showCount?: boolean;
    onShowAll?: () => void;
}

const filterLinksByQuery = (query: string) => {
    return (link: LinkItem): boolean => {
        const linkTypes = [];
        const mainType = linkTypeMap[link.link.type];
        if (mainType) {
            linkTypes.push(mainType.id);
        }
        if (link.link.originalData?.allLinks?.length) {
            for (let i = 0; i < link.link.originalData?.allLinks.length; i++) {
                const anotherLink = link.link.originalData?.allLinks[i];
                const type = linkTypeMap[anotherLink.type];
                if (type && !linkTypes.includes(type.id)) {
                    linkTypes.push(type.id);
                }
            }
        }
        const found = linkTypes.find((typeId) => {
            return typeId.includes(query);
        });
        if (found) {
            return true;
        }
        if (link.link.target!.id === link.node.id) {
            // check source title
            return (link.link.source! as Symbol).title.toLowerCase().includes(query)
        } else {
            // check target title
            return (link.link.target! as Symbol).title.toLowerCase().includes(query)
        }
    };
}

export default function NodeLinks({nodeDetail, showSearch, limit, showFiltered, limitFiltered, showCount, onShowAll}: Props) {

    const { uiInteractive } = useAppStore((state) => {
        return {
            uiInteractive: state.uiInteractive
        }
    });

    const {activeFilters} = UseFiltersState();

    const [visibleLinksCount, setVisibleLinksCount] = useState(0);
    const [filteredLinksCount, setFilteredLinksCount] = useState(0);
    const [visibleLinks, setVisibleLinks] = useState<LinkItem[]>([]);
    const [filteredLinks, setFilteredLinks] = useState<LinkItem[]>([]);
    const [showAllFilteredLinks, setShowAllFilteredLinks] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const query = searchQuery && searchQuery.length > 1 ? searchQuery : '';

        const links = getAllSymbolLinks(nodeDetail);

        let vLinks: LinkItem[] = [];
        let fLinks: LinkItem[] = [];

        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if (link.link.filtered && showFiltered) {
                fLinks.push(link);
            } else if (showFiltered || (!showFiltered && !link.link.filtered)) {
                vLinks.push(link);
            }
        }

        if (showFiltered) {
            setVisibleLinksCount(vLinks.length);
            setFilteredLinksCount(fLinks.length);
        } else {
            setVisibleLinksCount(links.length);
        }

        vLinks.sort(sortLinkItems);
        fLinks.sort(sortLinkItems);

        if (!query.length) {
            if (limit && limit > 0 && vLinks.length > limit) {
                vLinks = vLinks.slice(0, limit);
            }
            if (!showAllFilteredLinks && showFiltered && limitFiltered && limitFiltered > 0 && fLinks.length > limitFiltered) {
                fLinks = fLinks.slice(0, limitFiltered);
            }
        } else {
            vLinks = vLinks.filter(filterLinksByQuery(query));
            fLinks = fLinks.filter(filterLinksByQuery(query));
        }

        setVisibleLinks(vLinks);
        setFilteredLinks(fLinks);

    }, [showAllFilteredLinks, searchQuery, nodeDetail, activeFilters]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.trim().toLowerCase())
    }

    return (
        <div className={'flex flex-col gap-6 w-full'}>
            {showSearch && (
                <div className="relative rounded-md">
                    <div
                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <Search />
                    </div>
                    <input
                        type="text"
                        name="searchInLinks"
                        id="searchInLinks"
                        className="ui-search-input w-full"
                        placeholder="Find a link..."
                        onChange={onSearch}
                        value={searchQuery}
                        disabled={!uiInteractive}
                    />
                </div>
            )}
            {visibleLinks.length > 0 && (
                <div className={'flex flex-col w-full gap-2'}>
                    <div className={'text-lg font-semibold'}>Links {showCount && `(${visibleLinksCount})`}</div>
                    <div className="flex flex-col">
                        {visibleLinks.map((link) => (
                            <div className="ui-list-item-separator" key={link.link.id}>
                                <NodeLink nodeDetail={link.node} link={link.link as Link} otherFolder={link.otherFolder} />
                            </div>
                        ))}
                    </div>
                    {limit > 0 && visibleLinksCount > 3 && (
                        <div
                            className={'w-full text-center font-medium cursor-pointer'}
                            onClick={() => onShowAll ? onShowAll() : undefined}
                        >
                            View all ({visibleLinksCount}) -&gt;
                        </div>
                    )}
                </div>
            )}
            {showFiltered && filteredLinks.length > 0 && (
                <div className={'flex flex-col w-full gap-2'}>
                    <div className={'text-lg'}>Filtered out Links {showCount && `(${filteredLinksCount})`}</div>
                    <div className="flex flex-col">
                        {filteredLinks.map((link) => (
                            <div className="ui-list-item-separator" key={link.link.id}>
                                <NodeLink nodeDetail={link.node} link={link.link as Link} otherFolder={link.otherFolder} />
                            </div>
                        ))}
                    </div>
                    {limitFiltered && limitFiltered > 0 && filteredLinks.length > 3 && (
                        <div
                            className={'w-full flex items-center justify-center text-center font-medium cursor-pointer'}
                            onClick={() => setShowAllFilteredLinks(!showAllFilteredLinks)}
                        >
                            {showAllFilteredLinks ? (
                                <>View less</>
                            ) : (
                                <>View all ({filteredLinksCount})&nbsp;<ArrowDown className={'inline'}/></>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}