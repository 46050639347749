import * as THREE from 'three';

import { gsap } from "gsap";

import { PAUSE_TO_FREE_RESOURCES, SCALE_FACTOR, SYMBOL_CIRCLE_RADIUS, SYMBOL_HEIGHT, SYMBOL_SIZE, SYMBOL_Y_POSITION } from '../../common/constants';
import { SymbolsShapesIM } from '../SymbolsShapesIM';
import { SerializedSymbol } from '../BaseSymbol';
import { Symbol } from '@/three/symbols/Symbol';
import supabase from '@/utils/supabase-client';
import { Repository } from '@/three/common/Repository';
import { ScenarioApp } from '../scenario/ScenarioApp';
import { CosmosThree } from '@/three/CosmosThree';
import { useAppStore } from '@/store/Store';
import { boxFromElemsPositions } from '@/three/utils/graph/GraphUtils';
import { ScenarioLink } from '../scenario/ScenarioLink';

export interface SerializedApp {
	slug: string;
	theme: string;
}

export interface ScenarioFolder {
	id: string,
	name: string
}

export interface SerializedScenario extends SerializedSymbol {
	attributes: {
		instant: boolean;
		active: boolean;
		apps: SerializedApp[];
		folder?: ScenarioFolder,
		organization? : {
			id: string,
			name: string,
		},
		team?: {
			id: string,
			name: string,
		},
	}
}

export interface BlueprintRoute {
	flow: BlueprintModule[];
}

export interface BlueprintModule {
	id: number;
	module: string;
	metadata?: {
		designer?: {
			x: number;
			y: number;
		}
	}
	routes?: BlueprintRoute[];
}

export class MakeScenarioSymbol extends Symbol {

	private _instant = false;

	readyScenario = false;

	private scenarioBBox: THREE.Box3;
	private scenarioApps: ScenarioApp[] = [];
	private scenarioLinks: ScenarioLink[] = [];

	constructor(json: SerializedSymbol, shapeMesh: SymbolsShapesIM){
		json.theme = '#9933CC';

		super(json, shapeMesh);

		this.active = (json.attributes as any).active;

		this.scenarioBBox = new THREE.Box3().makeEmpty();
	}

	get instant() {
		return this._instant;
	}

	override get select() {
		return super.select;
	}

	override set select(value) {
		if(this.readyScenario && !value){
			this.hideScenario();
		}

		super.select = value;
	}

	static override getGeometry() {
		const shape = new THREE.Shape();
		const angleStep = Math.PI * 0.5;
		const size = SYMBOL_SIZE;
		const radius = SYMBOL_CIRCLE_RADIUS;

		shape.absarc(size / 2 - radius, size / 2 - radius, radius, angleStep * 0, angleStep * 1);
		shape.absarc(-size / 2 + radius, size / 2 - radius, radius, angleStep * 1, angleStep * 2);
		shape.absarc(-size / 2 + radius, -size / 2 + radius, radius, angleStep * 2, angleStep * 3);
		shape.absarc(size / 2 - radius, -size / 2 + radius, radius, angleStep * 3, angleStep * 4);

		const geometry = new THREE.ExtrudeGeometry(shape, {
			depth: SYMBOL_HEIGHT,
			bevelEnabled: false,
			bevelThickness: 0,
			bevelSize: 0,
			bevelSegments: 0,
			curveSegments: 6
		}).rotateX(-Math.PI/2);

        geometry.clearGroups();

        return geometry;
    }

	async getBlueprint(){
		if (!this.originalData.external_id) throw new Error('Invalid Symbol.');

		Repository.mesh!.blueprintLoadStarted.dispatch();

		Repository.mesh!.scenarioCont.children.forEach(child => child.clear() );
		Repository.mesh!.scenarioCont.clear();

		this.scenarioApps = [];
		this.scenarioLinks = [];

		const { data, error } = await supabase.functions.invoke('providers-rpc', {
			body: JSON.stringify({
				symbol: this.originalData.id,
				'function': 'make/getBlueprint',
				parameters: { scenarioId: parseInt(this.originalData.external_id) }
			})
		});

		if (error) {
			Repository.mesh!.intoScenarioMode = false;

			Repository.mesh!.enableInteraction();
			useAppStore.getState().enableUIInteractions();

			Repository.mesh!.blueprintLoadError.dispatch();

			throw error;
		}
		const blueprint = data;

		// console.log('blueprint loaded', blueprint);

		Repository.mesh!.blueprintLoadEnded.dispatch();

		// console.log(this.originalData.attributes);

		this.iterateFlow(blueprint.flow);

		// We calculate a bbox from the apps positions
		let box = boxFromElemsPositions(this.scenarioApps, SYMBOL_SIZE);

		const center = new THREE.Vector3();
		box.getCenter(center);

		this.scenarioApps.forEach(scenarioApp => {
			scenarioApp.position.sub(center);
		});

		this.scenarioLinks.forEach(scenarioLink => {
			scenarioLink.position.sub(center);
		});

		// The position of the apps has changed so we calculate again the bbox
		box = boxFromElemsPositions(this.scenarioApps, SYMBOL_SIZE);

		const position = new THREE.Vector3(this.three.position.x , SYMBOL_Y_POSITION * 2, this.three.position.z);

		box.min.add(position);
		box.max.add(position);

		box.min.add(CosmosThree.meshOffset);
		box.max.add(CosmosThree.meshOffset);

		this.scenarioBBox = box;

		Repository.mesh!.scenarioCont.position.set(position.x , position.y, position.z);

		if(CosmosThree.debug){
			Repository.mesh!.cosmos.scenarioBBoxHelper.box = box;
		}
	}

	private iterateFlow(flow: BlueprintModule[], prevApp?: ScenarioApp){
		for(let j = 0 ; j < flow.length; j++){
			const blueprintModuleData = flow[j];

			const app = (this.originalData.attributes! as any).apps.find((app : any) => app.slug === blueprintModuleData.module.split(':')[0]);

			const scenarioApp = new ScenarioApp(app?.theme || '#a1d36e', app?.title || 'Flow Control', app?.slug || 'builtin');

			scenarioApp.position.x = (blueprintModuleData.metadata?.designer?.x || 0) / SCALE_FACTOR / 2;
			scenarioApp.position.y = 0;
			scenarioApp.position.z = (blueprintModuleData.metadata?.designer?.y || 0) / SCALE_FACTOR / 2;

			this.scenarioApps.push(scenarioApp);

			Repository.mesh!.scenarioCont.add(scenarioApp);

			if (prevApp) {
				const scenarioLink = new ScenarioLink(prevApp, scenarioApp);

				this.scenarioLinks.push(scenarioLink);

				Repository.mesh!.scenarioCont.add(scenarioLink);
			}

			if (blueprintModuleData.routes) {
				for (let i = 0 ; i < blueprintModuleData.routes.length; i++){
					this.iterateFlow(blueprintModuleData.routes[i].flow, scenarioApp);
				}
			}

			prevApp = scenarioApp;
		}
	}

	async showScenario(){
		if(Repository.mesh!.intoScenarioMode) return;
		Repository.mesh!.intoScenarioMode = true;

		Repository.mesh!.disableInteraction();
		useAppStore.getState().disableUIInteractions();

		await this.getBlueprint();

		// Now the elements are created, so we move the camera and change the perspective
		gsap.delayedCall(PAUSE_TO_FREE_RESOURCES, () => { 
			Repository.mesh!.showScenarioCont(this.scenarioBBox);
			this.readyScenario = true;
		});
	}

	hideScenario(){
		this.readyScenario = false;

		Repository.mesh!.hideScenarioCont();
	}
}