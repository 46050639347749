type Props = {
    className?: string,
}

export const Folder = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9996 4.4952H7.93297L6.44086 2.25659C6.27877 2.01302 6.00453 1.8667 5.71189 1.8667H3.11406C2.38859 1.8667 1.7998 2.45548 1.7998 3.18095V11.5045C1.7998 12.1888 2.06178 12.8109 2.4911 13.2788C2.26855 12.9108 2.1713 12.4631 2.27381 12.0004L3.24723 7.61961C3.42509 6.81792 4.13654 6.24753 4.95751 6.24753H12.3138V5.80945C12.3138 5.08398 11.725 4.4952 10.9996 4.4952Z"
                fill="currentColor" fillOpacity="0.6" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.4737 14.1331H3.98427C2.86365 14.1331 2.03041 13.0948 2.27399 12.0005L3.24741 7.61964C3.42527 6.81794 4.13672 6.24756 4.95769 6.24756H12.4472C13.5678 6.24756 14.401 7.28582 14.1574 8.38015L13.184 12.761C13.0062 13.5627 12.2947 14.1331 11.4737 14.1331Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
