import {useNavigate, useRouteError} from 'react-router-dom';
import supabase from '@/utils/supabase-client';
import {useEffect} from 'react';
import {Logger} from "@/utils/Logger";
import {Link} from 'react-router-dom';

export default function ErrorPage() {

    const error = useRouteError() as { status: number, statusText: string, data: { message: string } };
    Logger.error(error);

    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            const {data} = await supabase.auth.getSession();
            if (!data.session) {
                await supabase.auth.signOut();
                navigate('/');
            }
        }
        checkSession();
    }, []);

    let title = 'Something went wrong';

    if (error.status === 404) {
        title = 'Not found';
    }

	const reload = () => {
		window.location.reload();
	}

    return (
		<div className="h-screen flex flex-col gap-3 items-center justify-center bg-base-100 text-base-content">
			<div className="text-lg font-bold">{title}</div>
			<div>Try <span className="underline cursor-pointer" onClick={() => reload()}>reload</span> the page or go to <Link to={'/'} reloadDocument={true}><span className="underline cursor-pointer">homepage</span></Link></div>
		</div>
	)
}