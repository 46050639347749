type Props = {
    className?: string,
}

export const Close = ({className}: Props) => {
  return (
      <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none"
           xmlns="http://www.w3.org/2000/svg">
          <path d="M3 3L13 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M3 13L13 3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  );
}