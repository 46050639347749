import type {Space} from '@/store/Space.ts'
import type {User} from '@/store/User.ts';
import type {ActionFunctionArgs} from 'react-router-dom';
import {Outlet, redirect, useNavigate} from 'react-router-dom';

import {LocalStorageKeys} from '@/types/common.ts'
import {useEffect, useState} from 'react';
import supabase from '@/utils/supabase-client';
import MainHeader from '@/components/MainHeader';
import Cosmos from '@/components/Cosmos.tsx';
import Modal from '@/components/utils/Modal.tsx';
import Notifications from '@/components/utils/Notifications.tsx';
import {Logger} from '@/utils/Logger';
import CosmosControls from '@/components/CosmosControls.tsx';
import RightPanels from '@/components/ui/panels/RightPanels.tsx';
import CosmosToolbar from '@/components/CosmosToolbar.tsx';
import {useAppStore} from '@/store/Store.ts'
import {MakeLogo} from '@/assets/icons/MakeLogo.tsx';
import LoadingOverlay from '@/components/utils/LoadingOverlay.tsx';
import {decodeFiltersFromQuery, decodeSpotlightFromQuery} from '@/utils/url-query-parser.ts';

function RootLayout() {

	const {
		setUser,
	} = useAppStore((state) => {
		return {
			setUser: state.setUser,
		}
	});

    const navigate = useNavigate();

    const [session, setSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const setUserData = (userData: Record<string, string>) => {
        setUser(userData ? {
            email: userData.email,
            name: userData.name,
            avatar: userData.avatar_url
        } as User : undefined);
    }

    useEffect(() => {

        const preventWheel = (event: WheelEvent) => {
            if (event.ctrlKey) {
                // prevent zooming the page
                event.preventDefault();
            }
        };

        supabase.auth.getSession().then(({data}: { data: any }) => {
            setSession(data.session);
            if (data.session) {
                setUserData(data.session.user.user_metadata);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });

        const {
            data: {subscription},
        } = supabase.auth.onAuthStateChange((_event, session: any) => {
            setSession(session);
            if (session) {
                setUserData(session.user.user_metadata);
            }
        });

        document.addEventListener('wheel', preventWheel, {passive: false});

        return () => {
            subscription.unsubscribe();
            document.removeEventListener('wheel', preventWheel);
        };
    }, []);

    const loginWithMake = async () => {
        navigate('/login-with-make');
    }

    const loginWithGoogle = async () => {
        const url = new URL(window.location.href);
        await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: `${url.protocol}//${url.host}/login-with-google-resolve`,
                queryParams: {
                    prompt: 'select_account',
                },
            },
        });
    }

    return (
        <>
            <LoadingOverlay isLoading={isLoading}/>
            {!isLoading && (
                <>
                    {!session ? (
                        <div className='ui-bg-gradient flex h-screen justify-center items-center flex-col gap-y-3'>
                            <div className='flex flex-row items-center pb-2'>
                                <img src='/images/make-logo.png' className='h-4' alt="Make Grid"></img>
                                <span
                                    className='text-base-content-500 flex text-lg relative top-[0.05rem]'>| Grid</span>
                            </div>
                            <div>
                                <button
                                    type={'button'}
                                    className={'ui-btn-outline w-56 text-lg flex items-center fle-row gap-3'}
                                    onClick={() => loginWithGoogle()}
                                >
                                    <div className={'w-8 h-8 self-start'}>
                                        <img src="/images/google-logo.png"/>
                                    </div>
                                    <div className="grow font-normal">Sign in with Google</div>
                                </button>
                            </div>
                            <div>
                                <button
                                    type={'button'}
                                    className={'ui-btn-outline w-56 text-lg flex items-center fle-row gap-3'}
                                    onClick={() => loginWithMake()}
                                >
                                    <div className={'w-7 h-7 relative'}>
                                        <MakeLogo className={'h-full w-full'}/>
                                    </div>
                                    <div className="grow font-normal">Sign in with Make</div>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div id="view" className="w-[100svw] h-[100svh] fixed overflow-hidden">
                                <Cosmos/>
                                <div id="ui"
                                     className="w-[100svw] h-[100svh] fixed overflow-hidden pointer-events-none">
                                    <MainHeader/>
                                    <div className="flex flex-wrap">
                                        {/*<SpaceList />*/}
                                        {/*<ConnectionList />*/}
                                    </div>
                                    <RightPanels/>
                                    <CosmosToolbar/>
                                    <CosmosControls/>
                                    <Outlet/>
                                    <Modal/>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            <Notifications/>
        </>
    )
}

export default RootLayout;

export async function loader({request, params}: ActionFunctionArgs) {
    const spaces = await useAppStore.getState().fetchSpaces();
    const activeSpace = useAppStore.getState().activeSpace;

    const requestUrl = new URL(request.url);

    // parse query params
    const filtersFromQuery = decodeFiltersFromQuery(requestUrl.searchParams);
    if (Object.keys(filtersFromQuery).length) {
        useAppStore.getState().setFiltersFromUrl(filtersFromQuery);
    }

    useAppStore.getState().setSpotlightFromUrl(decodeSpotlightFromQuery(requestUrl.searchParams));

    // resolve active space
    if (spaces?.length && !activeSpace) {

        const spaceIds = spaces.map((space) => {
            return space.id;
        });

        Logger.log('resolving active space in root loader');
        let lastActiveFound: string | null = null;
        let spaceExists = false;
        const activeIdFromLocalStorage = localStorage.getItem(LocalStorageKeys.LAST_ACTIVE_SPACE);
        if (activeIdFromLocalStorage) {
            spaces.forEach((space: Space) => {
                if (space.id === activeIdFromLocalStorage) {
                    lastActiveFound = space.id;
                }
                if (space.id === params?.spaceId) {
                    spaceExists = true;
                    return;
                }
            });
        }

        if (!spaceExists) {
            if (lastActiveFound && spaceIds.includes(lastActiveFound)) {
                return redirect(`/spaces/${lastActiveFound}`);
            } else {
                return redirect(`/spaces/${spaces[0].id}`);
            }
        }
    }
    return {
        spaces: spaces
    };
}
