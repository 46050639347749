import * as THREE from 'three';
import { ScenarioApp } from './ScenarioApp';
import { LINK_PORT_TRIGGER_SIZE_FACTOR, LINK_WIDTH, PREVENT_Z_FIGHTING, SCENARIO_VIEW_LINK_COLOR_LIGHT, SYMBOL_SIZE } from '@/three/common/constants';
import { CosmosThree } from '@/three/CosmosThree';
import { BufferGeometryUtils } from 'three/examples/jsm/Addons.js';

export class ScenarioLink extends THREE.Mesh{

    private source: ScenarioApp;
	private target: ScenarioApp;

    constructor(source: ScenarioApp, target: ScenarioApp){
        super();

        this.source = source;
        this.target = target;

        this.renderOrder = 18;

        this.material = ScenarioLink.getMaterial();

        this.update();
    }

    static getMaterial(){
        const material = new THREE.MeshLambertMaterial({ color: SCENARIO_VIEW_LINK_COLOR_LIGHT });

        return material;
    }

    update(){
        if (!this.source || !this.target) return;

        // Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);

        // Calculate the direction vector from source to target
		const directionVectorSource = this.target.position.clone().sub(this.source.position);
		directionVectorSource.normalize();
	
		// Offset the start point slightly in the direction of the target by the radius of the symbol pad
		pointSource.add(directionVectorSource.clone().multiplyScalar(SYMBOL_SIZE / 2));

        const pointTarget = this.target.position.clone().sub(this.source.position);

        // Calculate the direction vector from target to source
		const directionVectorTarget = this.target.position.clone().sub(this.source.position);
		directionVectorTarget.normalize();
	
		// Offset the end point slightly in the direction of the target by the radius of the symbol pad
		pointTarget.sub(directionVectorTarget.clone().multiplyScalar(SYMBOL_SIZE / 1.5));

        // Position and matrix updates
		this.position.x = this.source.position.x;
        this.position.y = PREVENT_Z_FIGHTING;
		this.position.z = this.source.position.z;
		
        // Generate vertices, normals, and UVs for a single segment
		const vertices = [];
		const normals = [];
		const indices = [0, 1, 2, 2, 1, 3];
		const uv = [];

		// Calculate the tangent and normal
		const tangent = new THREE.Vector3().subVectors(pointTarget, pointSource).normalize();
		const up = new THREE.Vector3(0, 1, 0);
		const normal = new THREE.Vector3().crossVectors(tangent, up).normalize().multiplyScalar(LINK_WIDTH / 2);

		const leftSource = new THREE.Vector3().copy(pointSource).sub(normal);
		const rightSource = new THREE.Vector3().copy(pointSource).add(normal);
		const leftTarget = new THREE.Vector3().copy(pointTarget).sub(normal);
		const rightTarget = new THREE.Vector3().copy(pointTarget).add(normal);

		vertices.push(leftSource.x, leftSource.y, leftSource.z);
		vertices.push(rightSource.x, rightSource.y, rightSource.z);
		vertices.push(leftTarget.x, leftTarget.y, leftTarget.z);
		vertices.push(rightTarget.x, rightTarget.y, rightTarget.z);

		// Normals for lighting
		const faceNormal = new THREE.Vector3().crossVectors(normal, tangent).normalize();
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right source vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For left target vertex
		normals.push(faceNormal.x, faceNormal.y, faceNormal.z); // For right target vertex

		uv.push(0, 0);
		uv.push(1, 0);
		uv.push(0, 1);
		uv.push(1, 1);

        // Calculate the angle of rotation around the Y-axis
        const angle = Math.atan2(tangent.x, tangent.z) + Math.PI / 2;

        const targetPointGeo = new THREE.ShapeGeometry(CosmosThree.linkTriggerArrowSVGShape, 6)
            .center()
            .scale(LINK_PORT_TRIGGER_SIZE_FACTOR, LINK_PORT_TRIGGER_SIZE_FACTOR, LINK_PORT_TRIGGER_SIZE_FACTOR)
            .rotateX(-Math.PI / 2)
            .rotateY(angle)
            .translate(pointTarget.x, 0, pointTarget.z);

        // Create the geometry
		let geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uv, 2));
		geometry.setIndex(indices);

        geometry = BufferGeometryUtils.mergeGeometries([targetPointGeo, geometry]);

        this.geometry.dispose();
        this.geometry = geometry;
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        this.geometry.dispose();
        (this.material as any).dispose();

        super.clear();

        return this;
    }

}