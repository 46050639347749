import { AppEnv } from './utils/AppEnv';
AppEnv.resolve();

import './css/index.css';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout, { loader as rootLoader } from './routes/RootLayout';
import LoginWithMake, { loader as loginWithMakeLoader } from '@/routes/LoginWithMake.tsx';
import LoginWithMakeResolve, { loader as loginWithMakeResolveLoader } from '@/routes/LoginWithMakeResolve.tsx';
import LoginWithGoogleResolve, { loader as loginWithGoogleResolveLoader } from '@/routes/LoginWithGoogleResolve.tsx';
import Logout, { loader as logoutLoader } from '@/routes/Logout.tsx';
import ErrorPage from './routes/Error';

import SpaceDetail, { loader as spaceDetailLoader } from './routes/spaces/SpaceDetail';

const router = createBrowserRouter([
	{ 
		path: '/',
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		id: 'root',
		loader: rootLoader,
		children: [
			{
				path: 'spaces/:spaceId',
				id: 'space-detail',
				loader: spaceDetailLoader,
				element: <SpaceDetail />,
			},
			{
				path: 'spaces',
				id: 'spaces',
				loader: spaceDetailLoader,
				element: <SpaceDetail />,
			}
		]
	}, {
		path: '/login-with-make',
		element: <LoginWithMake />,
		errorElement: <ErrorPage />,
		id: 'loginWithMake',
		loader: loginWithMakeLoader,
	}, {
		path: '/login-with-make-resolve',
		element: <LoginWithMakeResolve />,
		errorElement: <ErrorPage />,
		id: 'loginWithMakeResolve',
		loader: loginWithMakeResolveLoader,
	}, {
		path: '/login-with-google-resolve',
		element: <LoginWithGoogleResolve />,
		errorElement: <ErrorPage />,
		id: 'loginWithGoogleResolve',
		loader: loginWithGoogleResolveLoader,
	}, {
		path: '/logout',
		element: <Logout />,
		errorElement: <ErrorPage />,
		id: 'logout',
		loader: logoutLoader,
	}
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<RouterProvider router={router} />
)
