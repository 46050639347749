import type { FormEvent } from 'react'
import type { Space } from '@/store/Space.ts'
import { Form } from 'react-router-dom'
import { useAppStore } from '@/store/Store.ts'
import { useEffect, useState } from 'react'
import ConnectionList from '@/components/connections/ConnectionList.tsx'

type FormValues = {
  name: string;
}

function SpaceSettings() {

  const {
    activeSpace,
    updateSpace,
    toggleSpaceSettings,
    /*uiInteractive*/
  } = useAppStore((state) => {
    return {
      activeSpace: state.activeSpace,
      updateSpace: state.updateSpace,
      toggleSpaceSettings: state.toggleSpaceSettings,
      /*uiInteractive: state.uiInteractive*/
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: activeSpace?.name || '',
  });

  useEffect(() => {
    setFormValues({
      name: activeSpace?.name || '',
    });
  }, [activeSpace?.id])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.currentTarget);
    const payloadData: Partial<Space> = {
      name: formData.get('name') as string,
    }

    if (activeSpace?.id) {
      await updateSpace(activeSpace.id, payloadData);
    }

    toggleSpaceSettings();
    setIsSubmitting(false);
  }

  return (
      <>
      {activeSpace && (
        <div className="p-5 text-left">
          <Form className="ui-pointer-events" onSubmit={onSubmit}>
            <div className="flex gap-4 flex-col">
              <div>
                <h2 className="text-xl font-semibold leading-7 pr-6">
                  {`Settings for ${activeSpace.name} `}
                </h2>
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium leading-6">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    readOnly={true}
                    required
                    className="ui-input"
                    placeholder="Some Space name"
                    value={formValues.name}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        name: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div>
                <h2 className="text-base font-semibold leading-7">
                  Connections
                </h2>
              </div>
              <div>
                <ConnectionList display={true} />
              </div>
              <div className="sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                {/*<button
                  className="ui-btn inline-flex w-full sm:col-start-2"
                  disabled={!uiInteractive || isSubmitting}
                >
                  {isSubmitting ? <SpinnerLogo /> : 'Save'}
                </button>*/}
                <button
                  type="button"
                  className="ui-btn-outline mt-3 inline-flex w-full sm:col-start-1 sm:mt-0"
                  onClick={() => toggleSpaceSettings()}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

export default SpaceSettings;
