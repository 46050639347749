import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject.js';
import { Text } from 'troika-three-text';
import { Repository } from '../common/Repository.js';
import { LEGEND_GROUPS_BACKGROUND_HORIZONTAL_PADDING, LEGEND_GROUPS_BACKGROUND_VERTICAL_PADDING, LEGEND_GROUPS_FOLDER_WIDTH, LEGEND_GROUPS_FONT_SIZE, LEGEND_GROUPS_MAX_UNTRIMMED_CHARS, LEGEND_GROUPS_OUTLINE_HORIZONTAL_PADDING, LEGEND_GROUPS_OUTLINE_VERTICAL_PADDING } from '../common/constants.js';
import { CosmosThree } from '../CosmosThree.js';
import { BaseBatchedMesh } from '../common/BaseBatchedMesh.js';
import { GroupLegendOutline } from './GroupLegendOutline.js';
import { GroupLegendBackground } from './GroupLegendBackground.js';
import { GroupLegendFolder } from './GroupLegendFolder.js';

export class GroupLegend extends BaseObject{

    private outline: GroupLegendOutline;
    private background: GroupLegendBackground;
    text: Text;
    private folder: GroupLegendFolder;

    private _title = "";

    private _offsetX = 0;
    private _offsetZ = 0;

    constructor(){
        super();

        this.outline = new GroupLegendOutline();
        this.outline.instancedOrBatchedMesh = Repository.groupsLegendsOutlinesMesh! as BaseBatchedMesh;
        this.three.add(this.outline.three);

        this.background = new GroupLegendBackground();
        this.background.instancedOrBatchedMesh = Repository.groupsLegendsBackgroundsMesh! as BaseBatchedMesh;
        this.three.add(this.background.three);

        this.text = new Text();

        (this.text as any).font = '/webfonts/Inter-Medium.woff';
        (this.text as any).anchorX = 'center' as any;
        (this.text as any).anchorY = 'middle' as any;
        (this.text as any).fontSize = LEGEND_GROUPS_FONT_SIZE;
        this.color = CosmosThree.groupLegendsTextColor;

        Repository.groupsLegendsTextsMesh!.addText(this.text);

        this.folder = new GroupLegendFolder();
        this.folder.instancedOrBatchedMesh = Repository.groupsLegendsFoldersMesh!;
        this.three.add(this.folder.three);
    }

    public get offsetX() {
        return this._offsetX;
    }
    public set offsetX(value) {
        if(this.offsetX === value) return;

        this._offsetX = value;

        this.outline.offsetX = this._offsetX;
        this.background.offsetX = this._offsetX;
        this.folder.offsetX = this._offsetX;
    }

    public get offsetZ() {
        return this._offsetZ;
    }
    public set offsetZ(value) {
        if(this._offsetZ === value) return;

        this._offsetZ = value;

        this.outline.offsetZ = this._offsetZ;
        this.background.offsetZ = this._offsetZ;
        this.folder.offsetZ = this._offsetZ;
    }

    override get opacity() {
		return super.opacity;
	}
	override set opacity(value) {
		if(this.opacity === value) return;

		super.opacity = value;

        this.outline.opacity = value;
		this.background.opacity = value;
        this.folder.opacity = value;
	}

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";

        let ellipsis = "";
        if (this._title.length > LEGEND_GROUPS_MAX_UNTRIMMED_CHARS){
            ellipsis = "…";
        }else{
            ellipsis = "";
        }
        this._title = "     " + this._title.substring(0, LEGEND_GROUPS_MAX_UNTRIMMED_CHARS) + ellipsis;

        (this.text as any).text = this._title;


        Repository.groupsLegendsTextsMesh!.addEventListener("synccomplete" as any, ()=>{
            const size = new THREE.Vector3();

            const box = (this.text as any).geometry.boundingBox;
            box?.getSize(size);

            this.outline.width = size.x + LEGEND_GROUPS_OUTLINE_HORIZONTAL_PADDING * 2;
            this.outline.height = size.y + LEGEND_GROUPS_OUTLINE_VERTICAL_PADDING * 2;
            this.outline.updateGeometry();

            this.background.width = size.x + LEGEND_GROUPS_BACKGROUND_HORIZONTAL_PADDING * 2;
            this.background.height = size.y + LEGEND_GROUPS_BACKGROUND_VERTICAL_PADDING * 2;
            this.background.updateGeometry();

            this.folder.positionX = -size.x / 2 + LEGEND_GROUPS_FOLDER_WIDTH / 2;
        })
    }

    override dispose(){
        this.outline.dispose();
        this.background.dispose();
        (this.text as any).dispose();
        this.folder.dispose();

		super.dispose();
	}
}