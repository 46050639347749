import type { Space } from '../../store/Space.ts'
import type { Nullable } from '../../types/common.ts'
import { useEffect, useState } from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useNavigate } from "react-router-dom"
import { useAppStore } from '../../store/Store.ts'
import { SpinnerLogo } from '../../assets/icons/Spinner.tsx'
import { classNames } from '../../utils/helpers.ts'

export default function SpaceSelect() {

  const {spaces, activeSpace } = useAppStore((state) => {
    return {
      spaces: state.spaces,
      activeSpace: state.activeSpace
    }
  })
  const navigate = useNavigate()
  const [selected, setSelected] = useState<Nullable<Space>>(null);

  useEffect(() => {
    if (activeSpace) {
      setSelected(activeSpace);
    }
  }, [activeSpace]);

  const onSelected = (space: Space) => {
    setSelected(space);
    navigate(`/spaces/${space.id}`)
  }

  return (
    <Listbox value={selected} onChange={onSelected}>
      {spaces.length > 0 ? (
        <>
             {/*{
              activeSpace ? (
                <Link
                  to={`/spaces/${activeSpace?.id}`}
                >
                  <span className="block truncate font-bold">{activeSpace.name}</span>
                </Link>
              ): (
                <SpinnerLogo />
              )
            }*/}
            <ListboxButton className="relative w-full cursor-pointer rounded-md bg-transparent pr-10 text-left sm:leading-6">
              {
                activeSpace ? (
                    <span className="block truncate font-semibold text-xl">{activeSpace.name}</span>
                ): (
                    <SpinnerLogo />
                )
              }
              <span className="pointer-events-none absolute inset-y-0 flex items-center -right-[0.25rem]">
                <ChevronUpDownIcon className="group h-5 w-5 text-base-content-300" aria-hidden="true" />
              </span>
            </ListboxButton>
            <ListboxOptions
              anchor="bottom start"
              transition
              className="ui-listbox-options origin-top-left w-fit max-w-xl transition duration-100 ease-in-out data-[leave]:data-[closed]:opacity-0"
            >
              {spaces.map((item) => (
                <ListboxOption
                  key={item.id}
                  value={item}
                  className={'group data-[focus]:bg-base-200 relative cursor-pointer select-none py-2 pl-3 pr-9'}
                >
                  <span className={classNames('block truncate')}>
                    {item.name}
                  </span>
                  <span
                    className={classNames('invisible group-data-[selected]:visible absolute text-primary inset-y-0 right-0 flex items-center pr-4')}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
        </>
      ) : (
          <span className="text-base-content-400">- no space found -</span>
      )}
    </Listbox>
  );
}