import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { LEGEND_GROUPS_FOLDER_HEIGHT, LEGEND_GROUPS_FOLDER_WIDTH, SCALE_FACTOR } from '../common/constants';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';
import { CosmosThree } from '../CosmosThree';

export class GroupLegendFolder extends BaseObject{

    offsetX = 0;
    offsetZ = 0;

    positionX = 0;
    positionY = 1 / SCALE_FACTOR;

    static getGeometry(): THREE.BufferGeometry {
        const geometry = new THREE.PlaneGeometry(LEGEND_GROUPS_FOLDER_WIDTH, LEGEND_GROUPS_FOLDER_HEIGHT, 1, 1);

        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshBasicMaterial,
            [ExtensionOpacity],
            {
                color: CosmosThree.groupLegendsTextColor,
                map: CosmosThree.folderIconTexture,
                transparent: true,
                // wireframe: true
            },
            { debug: false }
        );

		return material;
    }
}