import * as THREE from 'three';
import { SYMBOL_ICON_SIZE } from '@/three/common/constants';
import { CosmosThree } from '@/three/CosmosThree';

export class ScenarioAppIcon extends THREE.Mesh{

    constructor(slug: string){
        super();

        this.geometry = this.getGeometry();
        this.material = ScenarioAppIcon.getMaterial();

        const iconImageData = CosmosThree.iconsAtlasMap64?.get(slug);

        if(iconImageData){
           // Calculate normalized UV offsets and sizes
        const atlasSize = new THREE.Vector2(
            CosmosThree.iconsAtlasJson64!.meta.size.w,
            CosmosThree.iconsAtlasJson64!.meta.size.h
        );

        // Icon image data
        const texOffset = new THREE.Vector2(iconImageData.frame.x, iconImageData.frame.y);
        const texSize = new THREE.Vector2(iconImageData.frame.w, iconImageData.frame.h);

        // Normalize the offset and size to create UVs in the [0, 1] range
        const uvTopLeft = new THREE.Vector2(
            texOffset.x / atlasSize.x,
            (atlasSize.y - texOffset.y - texSize.y) / atlasSize.y // Flipping the y-coordinate
        );
        const uvBottomRight = new THREE.Vector2(
            (texOffset.x + texSize.x) / atlasSize.x,
            (atlasSize.y - texOffset.y) / atlasSize.y // Flipping the y-coordinate
        );

        // Create the UV array with flipped x-coordinates
        const uvArray = new Float32Array([
            uvTopLeft.x, uvBottomRight.y,    // top right (flipped x)
            uvBottomRight.x, uvBottomRight.y, // top left (flipped x)
            uvTopLeft.x, uvTopLeft.y,        // bottom right (flipped x)
            uvBottomRight.x, uvTopLeft.y     // bottom left (flipped x)
        ]);

        // Set the UV attribute to the geometry
        this.geometry.setAttribute('uv', new THREE.BufferAttribute(uvArray, 2));
        this.geometry.attributes.uv.needsUpdate = true;

        } else {
            // If no icon data, ensure the object is not visible or has no geometry/material
            this.visible = false;
        }
    }

    getGeometry(): THREE.BufferGeometry {
        return new THREE.PlaneGeometry(SYMBOL_ICON_SIZE, SYMBOL_ICON_SIZE, 1, 1).rotateX(-Math.PI / 2);
    }

    static getMaterial(){
        const material = new THREE.MeshLambertMaterial({
            map: CosmosThree.iconsAtlasTexture,
            transparent: true,
        });

        return material;
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        this.geometry.dispose();
        (this.material as any).dispose();

        super.clear();

        return this;
    }

}