type Props = {
    className?: string,
}

export const Squares = ({className}: Props) => {
    return (
        <svg className={'w-7 h-7 ' + (className || '')} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0586 17.0059H9.05859C7.95359 17.0059 7.05859 16.1109 7.05859 15.0059V9.00586C7.05859 7.90086 7.95359 7.00586 9.05859 7.00586H15.0586C16.1636 7.00586 17.0586 7.90086 17.0586 9.00586V11.0059"
                stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M13.0586 21.0059H19.0586C20.1632 21.0059 21.0586 20.1104 21.0586 19.0059V13.0059C21.0586 11.9013 20.1632 11.0059 19.0586 11.0059L13.0586 11.0059C11.954 11.0059 11.0586 11.9013 11.0586 13.0059L11.0586 19.0059C11.0586 20.1104 11.954 21.0059 13.0586 21.0059Z"
                fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

