import * as THREE from 'three';

import { Repository } from "../../common/Repository";
import { Symbol } from "../../symbols/Symbol";
import { Link } from "@/three/links/Link";

export const getElementsToSpotlight = (startingSymbols: Symbol[], level: number) => {
    const elementsToHighlight: {level: number, symbols: Symbol[], links: Link[]}[] = [];
    const visitedSymbols = new Set();
    const visitedLinks = new Set();
    const queue = startingSymbols.map(symbol => ({ symbol, level: 0 }));

    while (queue.length > 0) {
        const item = queue.shift();

        if (!item || !item.symbol || visitedSymbols.has(item.symbol) || item.symbol.filtered || !item.symbol.visible) {
            continue; // Skip if item, symbol is undefined, or symbol has been visited
        }

        visitedSymbols.add(item.symbol);

        const { symbol, level: currentLevel } = item;

        if (currentLevel > level) {
            break; // Stop BFS if we exceed the desired level
        }

        if (!elementsToHighlight[currentLevel]) {
            elementsToHighlight[currentLevel] = { level: currentLevel, symbols: [], links: [] };
        }

        elementsToHighlight[currentLevel].symbols.push(symbol);

        if (currentLevel < level) {
            symbol.neighbors.forEach((neighborSymbol) => {
                if (!visitedSymbols.has(neighborSymbol)) {
                    queue.push({ symbol: neighborSymbol as Symbol, level: currentLevel + 1 });
                }
            });
            symbol.links.forEach((link) => {
                if (!visitedLinks.has(link)) {
                    visitedLinks.add(link);
                    elementsToHighlight[currentLevel].links.push(link as Link);
                }
            });
        }
    }

    return elementsToHighlight;
}

export const getUnmatchedSymbolsAndLinks = ( symbols: Symbol[], links: Link[]) => {
    const matchedSymbols = new Set();
    const matchedLinks = new Set();

    // Collect symbols and links
    for (let i = 0; i < symbols.length; i++){
        symbols.forEach(symbol => matchedSymbols.add(symbol));
    }

    for (let i = 0; i < links.length; i++){
        links.forEach(links => matchedLinks.add(links));
    }

    // Filter out symbols and links not present in the object
    const unmatchedSymbols: Symbol[] = Repository.mesh!.symbols.filter(symbol => !matchedSymbols.has(symbol));
    const unmatchedLinks: Link[] = Repository.mesh!.links.filter(link => !matchedLinks.has(link));

    return { unmatchedSymbols, unmatchedLinks };
}

export function boxFromElemsPositions ( elems: THREE.Object3D[], size: number): THREE.Box3 {
    const box = new THREE.Box3().makeEmpty();

    if(elems.length === 1){
        // Single object case

        // Create de box from the position of the symbol and the symbol size
        box.setFromCenterAndSize(elems[0].position, new THREE.Vector3(size, 0, size));
    }else{
        // Multiple object case

        // Iterate over all objects to expand the bounding box based on their positions
        for (let i = 0; i < elems.length; i++) {
            box.expandByPoint( elems[i].position );
        }

        // We expand the resulting box to take into account the symbol size (we take the make scenarios symbols size as they are the biggest)
        box.expandByVector(new THREE.Vector3(size / 2, 0 , size / 2 ));
    }

    return box;
}