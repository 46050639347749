import {spriteSheetIcons} from '@/utils/SpriteSheetIcons.ts';
import {changeHue} from '@/utils/color.ts';

type Props = {
    iconSlug?: string;
    iconWidth?: number;
    iconHeight?: number;
    iconSrc?: string;
    bgColor?: string;
    className?: string;
    containerStyle?: Record<string, string>;
    title?: string;
    bgGradient?: boolean;
}

export default function AppIcon(
    {
        iconSlug,
        iconWidth,
        iconHeight,
        iconSrc,
        bgColor,
        className,
        containerStyle,
        title,
        bgGradient
    }: Props) {

    let spriteStyle = null;
    if (iconSlug && iconWidth && iconHeight) {
        spriteStyle = spriteSheetIcons.getStyle(iconSlug, iconWidth, iconHeight);
    }

    const cStyle: Record<string, string> = containerStyle ?? {};
    if (bgColor) {
        if (bgGradient) {
            cStyle.background = `linear-gradient(${changeHue(bgColor, 15)}, ${bgColor})`;
        } else {
            cStyle.backgroundColor = bgColor;
        }
    }

    return (
        <div
            className={'flex items-center justify-center ' + (className || '')}
            style={cStyle}
        >
            {spriteStyle ? (
                <div
                    style={spriteStyle}
                >
                </div>
            ) : (
                <img
                    alt={title || 'Name unknown'}
                    src={iconSrc}
                />
            )}
        </div>
    );
}