import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { SymbolDuplicateIndicator } from './SymbolDuplicateIndicator';

export class SymbolsDuplicateIndicatorsIM extends BaseInstancedMesh{

    constructor(size : number){
        super(SymbolDuplicateIndicator.getGeometry(), SymbolDuplicateIndicator.getMaterial(), size);

        this.layers.enable( SymbolDuplicateIndicator.interactionLayer );

        // This has to be set to true so the raycasting works
        this.shouldUpdateBoundingSphere = true;

        this.name = "duplicateIndicatorIM";
    }
}