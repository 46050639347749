import Tooltip from '@/components/utils/Tooltip.tsx';
import UserMenu from '@/components/utils/UserMenu.tsx';
import ExtendedSearch from '@/components/search/ExtendedSearch.tsx';
import {Funnel} from '@/assets/icons/Funnel.tsx';
import {useAppStore} from '@/store/Store.ts';
import {classNames} from '@/utils/helpers.ts';
import UseFiltersState from '@/components/state/FiltersState.ts';
import {MakeLogo} from '@/assets/icons/MakeLogo.tsx';
import FiltersPanel from '@/components/filters/FiltersPanel.tsx';
import FilterBadges from '@/components/filters/FilterBadges.tsx';
import SearchResults from '@/components/search/SearchResults.tsx';

function MainHeader() {

	const {
		/*reloadCosmos,*/
		showFilters,
		toggleFilters,
		uiInteractive,
		activeSpace,
	} = useAppStore((state) => {
		return {
			/*reloadCosmos: state.reloadCosmos,*/
			showFilters: state.showFilters,
			toggleFilters: state.toggleFilters,
			uiInteractive: state.uiInteractive,
			activeSpace: state.activeSpace,
		}
	});
	const {activeFiltersCount} = UseFiltersState();

	/*const reloadMesh = () => {
		reloadCosmos();
	}*/

	// TODO
	const isAuthed = true; // (useRouteLoaderData('root') as {isAuthed: boolean}).isAuthed;

	const goToMake = () => {
		if (!activeSpace) {
			return;
		}
		window.location.href = `https://${activeSpace.make_zone}`;
	}

	return (
		<header className='relative flex items-center justify-between gap-x-1.5 p-6 pb-3 z-20 w-screen select-none'>
			<div className="flex flex-row self-start shrink-0 gap-1.5 relative">
				<div className="ui-panel ui-pointer-events flex flex-wrap gap-3 items-center p-1.5">
					<div
						className='flex items-center gap-2 h-7 w-7 pl-2 pr-0.5 -m-1 box-content cursor-pointer'
						onClick={() => goToMake()}
					>
						<MakeLogo className={'w-full h-full'} />
					</div>
					<ul className='flex items-center gap-2'>
						<li className='relative'>
							<ExtendedSearch />
						</li>
					</ul>
				</div>
				<div className="ui-panel ui-pointer-events flex flex-wrap gap-3 items-center p-1.5">
					<div className="relative">
						<Tooltip message={"Filters"}>
							<button
								type="button"
								className={classNames(showFilters ? 'active' : '', 'ui-btn-icon')}
								onClick={toggleFilters}
								disabled={!uiInteractive}
							>
								<Funnel className={''} />
							</button>
						</Tooltip>
						{activeFiltersCount > 0 &&
							<div
								className={classNames('ui-active-count-indicator-square')}
							>{activeFiltersCount}</div>
						}
					</div>
				</div>
				{/* filters container */}
				<FiltersPanel />
				<SearchResults />
			</div>
			<div className="flex">
				<FilterBadges />
			</div>

			{!isAuthed && (
				<div
					className="flex gap-4 px-4 py-2 bg-white/95 items-center rounded-md p-4 shadow-lg shadow-black/5 ring-1 ring-slate-700/10 ui-pointer-events">
					<a href="/login">Log In</a>
				</div>
			)}
			{isAuthed && (
				<div className="flex self-start shrink-0 ml-auto">
					<UserMenu/>
				</div>
			)}
		</header>
	);
}

export default MainHeader;
