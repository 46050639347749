type Props = {
    className?: string,
}

export const TwoDGrid = ({className}: Props) => {
    return (
        <svg className={'w-[0.938rem] h-[0.938rem] ' + (className || '')} width="15" height="15" viewBox="0 0 15 15" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.34375 1.19971H2.62946C1.91938 1.19971 1.34375 1.77534 1.34375 2.48542V4.19971C1.34375 4.90979 1.91938 5.48542 2.62946 5.48542H4.34375C5.05383 5.48542 5.62946 4.90979 5.62946 4.19971V2.48542C5.62946 1.77534 5.05383 1.19971 4.34375 1.19971Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12.0576 1.19971H10.3433C9.63325 1.19971 9.05762 1.77534 9.05762 2.48542V4.19971C9.05762 4.90979 9.63325 5.48542 10.3433 5.48542H12.0576C12.7677 5.48542 13.3433 4.90979 13.3433 4.19971V2.48542C13.3433 1.77534 12.7677 1.19971 12.0576 1.19971Z"
                fill="currentColor" fillOpacity="0.6" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2"
                strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M4.34375 8.91406H2.62946C1.91938 8.91406 1.34375 9.4897 1.34375 10.1998V11.9141C1.34375 12.6241 1.91938 13.1998 2.62946 13.1998H4.34375C5.05383 13.1998 5.62946 12.6241 5.62946 11.9141V10.1998C5.62946 9.4897 5.05383 8.91406 4.34375 8.91406Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12.0576 8.91406H10.3433C9.63325 8.91406 9.05762 9.4897 9.05762 10.1998V11.9141C9.05762 12.6241 9.63325 13.1998 10.3433 13.1998H12.0576C12.7677 13.1998 13.3433 12.6241 13.3433 11.9141V10.1998C13.3433 9.4897 12.7677 8.91406 12.0576 8.91406Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
