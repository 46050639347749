import type { FilterItem, FilterListGroup } from '@/store/Filter.ts';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {classNames} from '@/utils/helpers.ts';
import {useAppStore} from '@/store/Store.ts'
import {useMemo, useState} from 'react';
import UseFiltersState from '@/components/state/FiltersState.ts';
import {useLazyEffect} from '@/utils/hooks.ts';
import {Folder} from '@/assets/icons/Folder.tsx';
import {Apps} from '@/assets/icons/Apps.tsx';
import {ScenarioStatus} from '@/assets/icons/ScenarioStatus.tsx';
import {Webhooks} from '@/assets/icons/Webhooks.tsx';
import {ObjectType} from '@/assets/icons/ObjectType.tsx';
import Checkbox from '@/components/utils/Checkbox.tsx';

export default function Filters() {

    const [hoveredFilterItem, setHoveredFilterItem] = useState<FilterItem | null>(null);

    const {
        filterList,
        updateActiveFilters,
        filtersSearch,
        toggleFilterGroupOpen,
        currentMeshInstance,
        uiInteractive
    } = useAppStore((state) => {
        return {
            filterList: state.filterList,
            updateActiveFilters: state.updateActiveFilters,
            filtersSearch: state.filtersSearch,
            toggleFilterGroupOpen: state.toggleFilterGroupOpen,
            currentMeshInstance: state.currentMeshInstance,
            uiInteractive: state.uiInteractive
        }
    });

    useLazyEffect(() => {
        currentMeshInstance?.spotlightOnFilterItem(hoveredFilterItem);
    }, [hoveredFilterItem]);

    /*const onCheckboxChange = (e: FormEvent<HTMLInputElement>, group: string, item: FilterItem) => {
        const elem = e.target as HTMLInputElement;
        updateActiveFilters(group, item, elem.checked);
    }*/

    const onCheckboxChangeCustom = (checked: boolean, group: string, item: FilterItem) => {
        updateActiveFilters(group, item, checked);
    }

    const { activeFiltersCountByGroup } = UseFiltersState();

    const filteredFilterList = useMemo(() => {
        const toDisplay: FilterListGroup[] = [];

        if (!filterList) {
            return [];
        }

        filterList.map((group) => {
            if (!filtersSearch.length || filtersSearch.length < 2) {
                group.itemsToShow = group.items;
                group.isOpenBySearch = false;
                toDisplay.push(group);
                return;
            }
            const itemsToShow: FilterItem[] = [];
            group.items.map((item) => {
                if (item.label.toLowerCase().includes(filtersSearch)) {
                    itemsToShow.push(item);
                }
            });
            if (itemsToShow.length) {
                group.isOpenBySearch = true;
                group.itemsToShow = itemsToShow;
                toDisplay.push(group);
            }
        });

        return toDisplay;
    }, [filterList, filtersSearch]);

    const onFilterItemHover = (filterItem: FilterItem) =>{
        setHoveredFilterItem(filterItem);
    }

    const onFiltersLeave = () => {
        setHoveredFilterItem(null);
    }

    const getGroupIcon = (group: FilterListGroup) => {
        switch (group.id) {
            case 'scenarios':
                return (
                    <ScenarioStatus />
                )
            case 'scenarioFolder':
                return (
                    <Folder />
                )
            case 'scenarioApps':
                return (
                    <Apps />
                )
            case 'webhooks':
                return (
                    <Webhooks />
                )
            case 'symbolType':
                return (
                    <ObjectType />
                )
        }
        return null;
    }

    return (
        <>
            {!!filtersSearch && filtersSearch.length > 1 && !filteredFilterList.length && (
                <div className="flex items-center justify-center p-3 font-semibold">
                    No results found
                </div>
            )}
            {filterList && (
                <div className="">
                    <ul
                        role="list"
                        className="flex flex-col"
                        onPointerLeave={() => {onFiltersLeave()}}
                    >
                        {filteredFilterList.map((group) => (
                            <li key={group.id} className="ui-list-item-separator">
                                <div
                                    tabIndex={-1}
                                    onClick={() => {
                                        toggleFilterGroupOpen(group.id)
                                    }}
                                    onPointerOver={() => {
                                        onFiltersLeave()
                                    }}
                                    className={classNames(
                                        group.isOpen ? '' : '',
                                        'ui-list-item flex items-center justify-between w-full text-left py-2.5 gap-x-3 text-lg'
                                    )}
                                >
                                    <div className="pl-0.5">
                                        {getGroupIcon(group)}
                                    </div>
                                    <div className="">
                                        {group.label}
                                    </div>
                                    {/*!group.isOpen && */activeFiltersCountByGroup(group.id) > 0 && (
                                        <div
                                            className={classNames('ui-active-count-indicator-square static w-3 h-3')}
                                        >
                                            {activeFiltersCountByGroup(group.id)}
                                        </div>
                                    )}
                                    <div className="ml-auto">
                                        <ChevronDownIcon
                                            className={classNames(
                                                group.isOpen ? 'rotate-180' : '',
                                                'text-base-content-400 h-5 w-5 shrink-0 relative -right-0.5'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                                {(group.isOpen || group.isOpenBySearch) && (
                                    <div
                                        className={'ui-theme-transition py-1 px-0.5 rounded-b-md'}>
                                        <ul>
                                            {group.itemsToShow && group.itemsToShow.map((item) => (
                                                <li
                                                    key={item.id}
                                                    className={classNames(item.selected ? '' : '', 'ui-list-item !my-0 relative flex items-start')}
                                                    onPointerOver={() => {
                                                        onFilterItemHover(item)
                                                    }}
                                                >
                                                    <div className="flex h-6 items-center">
                                                        <Checkbox
                                                            disabled={!uiInteractive || ((item.disabled ?? false) && !item.selected)}
                                                            onChange={(checked) => onCheckboxChangeCustom(checked, group.id, item)}
                                                            id={item.id}
                                                            name={item.id}
                                                            checked={item.selected as boolean}
                                                        />
                                                        {/*<input
                                                        disabled={!uiInteractive || ((item.disabled ?? false) && !item.selected)}
                                                        onChange={(e) => onCheckboxChange(e, group.id, item)}
                                                        id={item.id}
                                                        name={item.id}
                                                        type="checkbox"

                                                        checked={item.selected as boolean}
                                                    />*/}
                                                    </div>
                                                    <div className="ml-3 leading-6 w-full">
                                                        <label htmlFor={item.id}
                                                               className="font-regular flex justify-between">
                                                        <span
                                                            className={classNames(item.disabled ? 'text-base-content-300' : '', '[word-break:break-word]')}>{item.label}</span>
                                                            {!item.disabled && (
                                                                <span
                                                                    className="inline-block pl-1 text-right font-medium break-normal">{item.numberOfSymbols ?? 0}</span>
                                                            )}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}