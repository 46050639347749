import * as THREE from 'three';
import { CosmosThree } from '../CosmosThree';
import { LEGEND_BACKGROUND_HORIZONTAL_PADDING, LEGEND_BACKGROUND_VERTICAL_PADDING, LEGEND_FONT_SIZE, LEGEND_OUTLINE_HORIZONTAL_PADDING, LEGEND_OUTLINE_VERTICAL_PADDING, LEGEND_POSITION_Y_OFFSET} from '../common/constants';
import { Symbol } from './Symbol';
import { SymbolLegendOutline } from './SymbolLegendOutline';
import { SymbolLegendBackground } from './SymbolLegendBackground';
import {Text} from 'troika-three-text';

export class SymbolFullLegend extends THREE.Group{

    outline: SymbolLegendOutline;
    background: SymbolLegendBackground;
    text: Text;

    private _title = "";

    private _vector = new THREE.Vector3();
    private _mtx = new THREE.Matrix4();
    private _symbol: Symbol |null = null;

    constructor(){
        super();

        this.outline = new SymbolLegendOutline();
        this.outline.three.material = new THREE.MeshBasicMaterial({
            color: CosmosThree.symbolLegendsOutlineColor,
            transparent: true,
        });
        this.outline.three.renderOrder = 24; // not working for some reason, it still renders behind the instanced legends
        this.add(this.outline.three);

        this.background = new SymbolLegendBackground();
        this.background.three.material = new THREE.MeshBasicMaterial({
            color: CosmosThree.symbolLegendsBackgroundColor,
            transparent: true,
        });
        this.background.three.renderOrder = 25; // not working for some reason, it still renders behind the instanced legends
        this.add(this.background.three);

        this.text = new Text();

        (this.text as any).font = '/webfonts/Inter-Medium.woff';
        (this.text as any).anchorX = 'center' as any;
        (this.text as any).anchorY = 'middle' as any;
        (this.text as any).fontSize = LEGEND_FONT_SIZE;
        (this.text as any).color = CosmosThree.symbolLegendsTextColor;

        this.visible = false;

        (this.text as any).renderOrder = 26; // not working for some reason, it still renders behind the instanced legends
        this.add((this.text as any));
    }

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";

        (this.text as any).text = this._title;

        this.visible = false;

        (this.text as any).sync(()=>{
            const size = new THREE.Vector3();

            const box = (this.text as any).geometry.boundingBox;
            box?.getSize(size);

            this.outline.width = size.x + LEGEND_OUTLINE_HORIZONTAL_PADDING * 2;
            this.outline.height = size.y + LEGEND_OUTLINE_VERTICAL_PADDING * 2;
            this.outline.three.geometry.dispose();
            this.outline.three.geometry = this.outline.updateGeometry();

            this.background.width = size.x + LEGEND_BACKGROUND_HORIZONTAL_PADDING * 2;
            this.background.height = size.y + LEGEND_BACKGROUND_VERTICAL_PADDING * 2;
            this.background.three.geometry.dispose();
            this.background.three.geometry = this.background.updateGeometry();

            this.visible = true;
        });
    }

    setFrom(symbol: Symbol | null){
        if(this._symbol === symbol) return;

        if(this._symbol){
            // We restore the scale of the previous symbol legend
            this._symbol.legend.three.scale.set(1 ,1 ,1);
            this._symbol.legend.matrixNeedsUpdate = true;
        }

        this._symbol = symbol;

        if(this._symbol){
            this.visible = true;

            this.title = this._symbol.title;

            // We restore set the scale of the current symbol legend to 0 to 'hide' it while we show the full legend
            this._symbol.legend.three.scale.set(0 ,0 ,0);
            this._symbol.legend.matrixNeedsUpdate = true;
        }else{
            this.visible = false;
        }
    }

    sync(){
        if(this._symbol && this.visible){
			this._vector.setFromMatrixPosition(this._symbol.three.matrix);

			this._vector.add(CosmosThree.meshOffset);
			this._vector.project(CosmosThree.graphCamera); // `camera` is a THREE.PerspectiveCamera

			this._vector.unproject(CosmosThree.guiCamera);

			// Update the matrices of the objects in the scene
			this._vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;
			this._mtx.makeTranslation(this._vector);

            this.position.set(this._vector.x, this._vector.y, this._vector.z);
		}
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        this.outline.dispose();
        this.background.dispose();
        (this.text as any).dispose();

        return this;
    }
}