import * as THREE from 'three';
import { BaseObject } from './BaseObject';

export class BaseBatchedMesh extends THREE.BatchedMesh{

    elems: BaseObject[] = [];

    protected _tintAmountsTexture: THREE.DataTexture | null = null;
    protected _opacitiesTexture: THREE.DataTexture | null = null;
    protected _drawProgressTexture: THREE.DataTexture | null = null;

    matrixNeedsUpdate = false;
	colorNeedsUpdate = false;
    opacityNeedsUpdate = false;
    tintNeedsUpdate = false;
    drawProgressNeedsUpdate = false;

    shouldUpdateBoundingSphere = false;

    protected mtx = new THREE.Matrix4();
    protected vector = new THREE.Vector3();
    protected transform = new THREE.Object3D();

    constructor (maxInstanceCount: number, maxVertexCount: number, maxIndexCount: number, material: THREE.Material){
        super(maxInstanceCount, maxVertexCount, maxIndexCount, material);

        // this.matrixAutoUpdate = false;
        // this.matrixWorldAutoUpdate = false;

        this.frustumCulled = false;

        if((this.material as any).uniforms.opacitiesTexture){this.initOpacityTexture();}
        if((this.material as any).uniforms.tintAmountsTexture){this.initTintAmountsTexture();}
        if((this.material as any).uniforms.drawProgressTexture){this.initDrawProgressTexture();}
    }

    initOpacityTexture(){
        let size = Math.sqrt( this.maxInstanceCount );
		size = Math.ceil( size );

        const opacitiesArray = new Float32Array( size * size ).fill( 1.0 );
        const opacitiesTexture = new THREE.DataTexture( opacitiesArray, size, size, THREE.RedFormat, THREE.FloatType );
		this._opacitiesTexture = opacitiesTexture;
        (this.material as any).uniforms.opacitiesTexture.value = this._opacitiesTexture;
    
        this.opacityNeedsUpdate = true;
        this._opacitiesTexture.needsUpdate = true;
    }

    initTintAmountsTexture(){
        let size = Math.sqrt( this.maxInstanceCount );
		size = Math.ceil( size );

        const tintAmountsArray = new Float32Array( size * size ).fill( 0.0 );
        const tintAmountsTexture = new THREE.DataTexture( tintAmountsArray, size, size, THREE.RedFormat, THREE.FloatType );
        this._tintAmountsTexture = tintAmountsTexture;
		(this.material as any).uniforms.tintAmountsTexture.value = this._tintAmountsTexture;
    
        this.tintNeedsUpdate = true;
        this._tintAmountsTexture.needsUpdate = true;
    }

    initDrawProgressTexture(){
        let size = Math.sqrt( this.maxInstanceCount );
		size = Math.ceil( size );

        const drawProgressArray = new Float32Array( size * size ).fill( 0.0 );
        const drawProgressTexture = new THREE.DataTexture( drawProgressArray, size, size, THREE.RedFormat, THREE.FloatType );
		this._drawProgressTexture = drawProgressTexture;
        (this.material as any).uniforms.drawProgressTexture.value = this._drawProgressTexture;
        
        this.drawProgressNeedsUpdate = true;
        this._drawProgressTexture.needsUpdate = true;
    }

    sync(){
        if((this.matrixNeedsUpdate || this.colorNeedsUpdate || this.opacityNeedsUpdate || this.tintNeedsUpdate || this.drawProgressNeedsUpdate)){
            let matricesWhereUpdated = false;
            let colorsWhereUpdated = false;
            let opacitiesWhereUpdated = false;
            let tintsWhereUpdated = false;
            let drawProgressesWhereUpdated = false;

            for(let i = 0 ; i < this.elems.length; i++){
                const elem = this.elems[i];
                if(elem.instanceId !== -1){
                    if(this.matrixNeedsUpdate && elem.matrixNeedsUpdate){
                        this.syncMatrix(elem);

                        matricesWhereUpdated = true;
                    }
                    if(this.colorNeedsUpdate && elem.colorNeedsUpdate){
                        this.syncColor(elem);

                        colorsWhereUpdated = true;
                    }
                    if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
                        this.syncOpacity(elem);

                        opacitiesWhereUpdated = true;
                    }
                    if(this.tintNeedsUpdate && elem.tintNeedsUpdate){
                        this.syncTint(elem);

                        tintsWhereUpdated = true;
                    }
                    if(this.drawProgressNeedsUpdate && elem.drawProgressNeedsUpdate){
                        this.syncDrawProgress(elem);

                        drawProgressesWhereUpdated = true;
                    }
                }
            }
            
            if(matricesWhereUpdated && this.shouldUpdateBoundingSphere){
                // needs to be computed for the raycasting and frustrum culling to work (that's why frustrum culling is disabled by default).
                this.computeBoundingSphere();
                this.matrixNeedsUpdate = false;
            }

            if(colorsWhereUpdated) this.colorNeedsUpdate = false;
            if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;
            if(tintsWhereUpdated) this.tintNeedsUpdate = false;
            if(drawProgressesWhereUpdated) this.drawProgressNeedsUpdate = false;
        }
    }

    syncMatrix(elem: BaseObject){
        elem.matrixNeedsUpdate = false;

        if(elem.three.parent){
            elem.three.updateMatrix();
            elem.three.parent.updateMatrix();
            this.setMatrixAt(elem.instanceId, this.mtx.multiplyMatrices(elem.three.parent.matrix, elem.three.matrix));
        }
	}

    syncColor(elem: BaseObject){
        elem.colorNeedsUpdate = false;
        this.setColorAt(elem.instanceId, elem.color);
    }

    syncOpacity(elem: BaseObject){
        elem.opacityNeedsUpdate = false;
        this.setOpacityAt(elem.instanceId, elem.opacity);
    }

    setOpacityAt( instanceId: number, opacity: number ) {
        this.validateInstanceId( instanceId );

		this._opacitiesTexture!.image.data[ instanceId ] = opacity;
		this._opacitiesTexture!.needsUpdate = true;

		return this;
	}

    syncTint(elem: BaseObject){
        elem.tintNeedsUpdate = false;
        this.setTintAt(elem.instanceId, elem.tint);
    }

    setTintAt( instanceId: number, tint: number ) {
        this.validateInstanceId( instanceId );

		this._tintAmountsTexture!.image.data[ instanceId ] = tint;
		this._tintAmountsTexture!.needsUpdate = true;

		return this;
	}

    syncDrawProgress(elem: BaseObject){
        elem.drawProgressNeedsUpdate = false;
        this.setDrawProgressAt(elem.instanceId, elem.drawProgress);
    }

    setDrawProgressAt( instanceId: number, drawProgress: number ) {
        this.validateInstanceId( instanceId );

		this._drawProgressTexture!.image.data[ instanceId ] = drawProgress;
		this._drawProgressTexture!.needsUpdate = true;

		return this;
	}

    validateInstanceId( instanceId: number ) {
		const instanceInfo = (this as any)._instanceInfo;
		if ( instanceId < 0 || instanceId >= instanceInfo.length || instanceInfo[ instanceId ].active === false ) {
			throw new Error( `THREE.BatchedMesh: Invalid instanceId ${instanceId}. Instance is either out of range or has been deleted.` );
		}
	}

    override dispose() : this{
        this.geometry.dispose();
		(this.material as any).dispose();

        if(this._tintAmountsTexture){
            this._tintAmountsTexture!.dispose();
            this._tintAmountsTexture = null;
        }

        if(this._opacitiesTexture){
            this._opacitiesTexture!.dispose();
            this._opacitiesTexture = null;
        }

        if(this._drawProgressTexture){
            this._drawProgressTexture!.dispose();
            this._drawProgressTexture = null;
        }

        this.elems = [];

        return super.dispose();
    }

}