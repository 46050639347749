import type {SpotlightFromUrl, FiltersFromUrl} from '@/store/Filter.ts';
import {FEATURES} from '../store/Features.ts';

export type FeaturesFromUrl = {
  [key in FEATURES]?: string
}

export interface LoginRequestData {
  zone: string | null;
  organizationId: number | null;
  teamId: number | null;
  spotlightType: string | null;
  spotlightExternalId: string | null;
  folderId: string | null;
}

export const prepareQuery = (filtersFromUrl: FiltersFromUrl, spotlightFromUrl: SpotlightFromUrl | null): string => {
  const query = [];
  const filterQuery: string[] = [];

  for (const [groupId, itemIds] of Object.entries(filtersFromUrl)) {
    filterQuery.push(`${groupId}:(${itemIds.join(' OR ')})`);
  }

  if (filterQuery.length) {
    query.push(`filters=${encodeURIComponent(filterQuery.join(' '))}`);
  }
  if (spotlightFromUrl) {
    query.push(`spotlightType=${encodeURIComponent(spotlightFromUrl.symbolType)}`);
    query.push(`spotlightExternalId=${encodeURIComponent(spotlightFromUrl.symbolExternalId)}`);
  }

  return query.join('&');
}

export const decodeFiltersFromQuery = (searchParams: URLSearchParams): Record<string, string[]> => {
  const decodedFilters: Record<string, string[]> = {};
  for (const [key, value] of searchParams.entries()) {
    switch (key) {
      case 'filters': {
        const filterGroups = value.split(')');
        filterGroups.forEach((group) => {
          if (!group.length) {
            return;
          }
          const [id, items] = group.split(':(');
          const groupId = id.trim();
          if (!decodedFilters[groupId]) {
            decodedFilters[groupId] = [];
          }
          const filterItems = items?.length ? items.split(' OR ') : [];
          filterItems.forEach((item) => {
            decodedFilters[groupId].push(item);
          })
        })
        break;
      }
    }
  }
  return decodedFilters;
}

export const decodeSpotlightFromQuery = (searchParams: URLSearchParams): SpotlightFromUrl | null => {
  const spotlightType = searchParams.get('spotlightType');
  const spotlightExternalId = searchParams.get('spotlightExternalId');
  const folderId = searchParams.get('folderId');

  return spotlightType && spotlightExternalId ? {
    symbolType: spotlightType,
    symbolExternalId: spotlightExternalId,
    folderId: folderId
  } : null;
}

export const updateQuery = (filtersFromUrl: FiltersFromUrl, spotlightFromUrl: SpotlightFromUrl | null) => {
  const query = prepareQuery(filtersFromUrl, spotlightFromUrl);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  window.history.replaceState({}, '', query.length ? `${url.pathname}?${query}` : url.pathname);
}

export const parseLoginRequestData = (searchParams: URLSearchParams) : LoginRequestData => {
  const zone = searchParams.get('zone');
  const organizationId =  searchParams.get('organizationId');
  const teamId =  searchParams.get('teamId');
  const spotlightType =  searchParams.get('spotlightType');
  const spotlightExternalId =  searchParams.get('spotlightExternalId');
  const folderId =  searchParams.get('folderId');

  return {
    zone,
    organizationId: organizationId ? parseInt(organizationId) : null,
    teamId: teamId ? parseInt(teamId) : null,
    spotlightType,
    spotlightExternalId,
    folderId
  }
}

export const getZoneFromReferrer = (): string | null => {

  let zoneFromReferrer = null;

  const allowedReferrers = [
    /^([a-zA-Z0-9-]+\.)?integromat\.local/,
    /^([a-zA-Z0-9-]+\.)?integromat\.dev/,
    /^([a-zA-Z0-9-]+\.)?make\.com/,
  ]

  try {
    const referrerUrl = new URL(document.referrer);
    const referrerHost = referrerUrl.host;
    for (const pattern of allowedReferrers) {
      if (pattern.test(referrerHost)) {
        zoneFromReferrer = referrerHost;
        break;
      }
    }
  } catch (e) {
    /* ignore */
  }

  return zoneFromReferrer;

}

export const buildSpaceRedirectQuery = (spaceId: string, loginRequestData: LoginRequestData | null) => {

  const query: string[] = [];
  if (loginRequestData) {
    if (loginRequestData.spotlightType) {
      query.push(`spotlightType=${loginRequestData.spotlightType}`);
    }
    if (loginRequestData.spotlightExternalId) {
      query.push(`spotlightExternalId=${loginRequestData.spotlightExternalId}`);
    }
    if (loginRequestData.folderId) {
      query.push(`spotlightExternalId=${loginRequestData.folderId}`);
    }
    if (loginRequestData.teamId) {
      query.push(`teamId=${loginRequestData.teamId}`);
    }
  }

  return `/spaces/${spaceId}${query.length ? '?' + query.join('&') : ''}`
}