type Props = {
    className?: string,
}

export const MagnifyingGlass = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2 12.2L8.62842 8.62842" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M5.7998 9.80005C8.00894 9.80005 9.7998 8.00919 9.7998 5.80005C9.7998 3.59091 8.00894 1.80005 5.7998 1.80005C3.59067 1.80005 1.7998 3.59091 1.7998 5.80005C1.7998 8.00919 3.59067 9.80005 5.7998 9.80005Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
