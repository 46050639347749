import { InstancedText } from '../common/InstancedText';

export class SymbolLegendsTextIM extends InstancedText{

	textNeedsUpdate = false;

    constructor(size : number){
    
        super(size);

		this.name = "symbolLegendsTextsInstancedMesh";
    }

    sync (){
        // We want to make the legends disappear depending on camera zoom value
        super.updateOpacityFromZoom();
    
        let colorsWhereUpdated = false;
        let opacitiesWhereUpdated = false;
    
            /* Logger.time('[perf] mesh: update matrices'); */
        if(this.visible){
          for(let i = 0 ; i < this.elems.length; i++){
              const elem = this.elems[i];
              if(elem.instanceId !== -1){
                this.syncMatrix(elem);
    
                if(elem.colorNeedsUpdate){
                  this.syncColor(elem);
    
                  colorsWhereUpdated = true;
                }
    
                if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
                  this.syncOpacity(elem);
    
                  opacitiesWhereUpdated = true;
                }
              }
          }
        }
    
        if(colorsWhereUpdated) this.colorNeedsUpdate = false;
        if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;
    
            /* Logger.timeEnd('[perf] mesh: update matrices'); */
    }
}