type Props = {
    className?: string,
}

export const ScenarioStatus = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8.00001H11.6428L9.71408 13.1433L6.28592 2.85669L4.35716 8.00001H2" stroke="currentColor"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
