type Props = {
    className?: string,
}

export const Minus = ({className}: Props) => {
    return (
        <svg className={'w-2.5 h-4 ' + (className || '')} viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.32068H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

