import type { ConnectionType } from '../../store/Connection.ts'

import { Form } from 'react-router-dom'
import { useAppStore } from '../../store/Store.ts'
import ConnectionForm from './ConnectionForm.tsx'
import { useEffect } from 'react'

function ConnectionTypeForm() {

  const {
    openModal,
    closeModal,
    connectionsTypes,
    fetchConnectionsTypes,
    uiInteractive
  } = useAppStore((state) => {
    return {
      openModal: state.openModal,
      closeModal: state.closeModal,
      connectionsTypes: state.connectionsTypes,
      fetchConnectionsTypes: state.fetchConnectionsTypes,
      uiInteractive: state.uiInteractive
    }
  });

  useEffect(() => {
    fetchConnectionsTypes();
  }, [])

  const getConnectionTypeImg = (connectionType: ConnectionType) => {
    const brandName = connectionType.slug.split(':')?.[0];
    if (brandName) {
      return `/images/${brandName}-logo.png`;
    }
    return '';
  }

  const onClickType= (connectionType: ConnectionType) => {
    openModal(ConnectionForm, { inputConnectionType: connectionType })
  }

  return (
    <Form className="ui-pointer-events ui-bg-gradient p-5">
      <div className="flex gap-4 flex-col">
        <div>
          <h2 className="text-xl font-semibold leading-7">
            Select Connection Type
          </h2>
        </div>
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {connectionsTypes.map((connectionType) => (
            <li
              key={connectionType.id}
              className="col-span-1 divide-y divide-base-200 rounded-box bg-base-100 shadow hover:bg-base-100 cursor-pointer"
              onClick={() => onClickType(connectionType)}
            >
                <div className="flex justify-center w-full h-14 items-center space-x-6 p-3">
                  <img className="object-contain object-center max-h-12" src={getConnectionTypeImg(connectionType)} alt="" />
                </div>
                <div>
                  <div className="flex">
                    <div className="flex w-full text-center font-semibold justify-center p-3">
                      {connectionType.name}
                    </div>
                  </div>
                </div>
            </li>
          ))}
        </ul>
        <div className="sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            disabled={!uiInteractive}
            className="ui-btn-outline mt-3 inline-flex w-full sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  );
}

export default ConnectionTypeForm;

