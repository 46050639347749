import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject.js';
import { Text } from 'troika-three-text';
import { Repository } from '../common/Repository.js';
import { LEGEND_BACKGROUND_HORIZONTAL_PADDING, LEGEND_BACKGROUND_VERTICAL_PADDING, LEGEND_FONT_SIZE, LEGEND_MAX_UNTRIMMED_CHARS, LEGEND_OUTLINE_HORIZONTAL_PADDING, LEGEND_OUTLINE_VERTICAL_PADDING } from '../common/constants.js';
import { SymbolLegendBackground } from './SymbolLegendBackground.js';
import { CosmosThree } from '../CosmosThree.js';
import { SymbolLegendOutline } from './SymbolLegendOutline.js';
import { BaseBatchedMesh } from '../common/BaseBatchedMesh.js';

export class SymbolLegend extends BaseObject{

    private outline: SymbolLegendOutline;
    private background: SymbolLegendBackground;
    text: Text;

    private _title = "";

    constructor(){
        super();

        this.outline = new SymbolLegendOutline();
        this.outline.instancedOrBatchedMesh = Repository.symbolsLegendsOutlinesMesh! as BaseBatchedMesh;
        this.three.add(this.outline.three);

        this.background = new SymbolLegendBackground();
        this.background.instancedOrBatchedMesh = Repository.symbolsLegendsBackgroundsMesh! as BaseBatchedMesh;
        this.three.add(this.background.three);

        this.text = new Text();

        (this.text as any).font = '/webfonts/Inter-Medium.woff';
        (this.text as any).anchorX = 'center' as any;
        (this.text as any).anchorY = 'middle' as any;
        (this.text as any).fontSize = LEGEND_FONT_SIZE;
        this.color = CosmosThree.symbolLegendsTextColor;

        Repository.symbolsLegendsTextsMesh!.addText(this.text);
    }

    override get opacity() {
		return super.opacity;
	}
	override set opacity(value) {
		if(this.opacity === value) return;

		super.opacity = value;

        this.outline.opacity = value;
		this.background.opacity = value;
	}

    get title (){
        return this._title || "";
    }

    set title (value: string){
        if(this._title === value) return;
        this._title = value || "";

        let ellipsis = "";
        if (this._title.length > LEGEND_MAX_UNTRIMMED_CHARS){
            ellipsis = "…";
        }else{
            ellipsis = "";
        }
        this._title = this._title.substring(0, LEGEND_MAX_UNTRIMMED_CHARS) + ellipsis;

        (this.text as any).text = this._title;


        Repository.symbolsLegendsTextsMesh!.addEventListener("synccomplete" as any, ()=>{
            const size = new THREE.Vector3();

            const box = (this.text as any).geometry.boundingBox;
            box?.getSize(size);

            this.outline.width = size.x + LEGEND_OUTLINE_HORIZONTAL_PADDING * 2;
            this.outline.height = size.y + LEGEND_OUTLINE_VERTICAL_PADDING * 2;
            this.outline.updateGeometry();

            this.background.width = size.x + LEGEND_BACKGROUND_HORIZONTAL_PADDING * 2;
            this.background.height = size.y + LEGEND_BACKGROUND_VERTICAL_PADDING * 2;
            this.background.updateGeometry();
        })
    }

    override dispose(){
        this.outline.dispose();
        this.background.dispose();
        (this.text as any).dispose();

		super.dispose();
	}
}