type Props = {
    className?: string,
}

export const SignOut = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7385 10.8238L10.7375 10.8229C10.6716 10.7573 10.6338 10.6667 10.6338 10.5714V5.42851C10.6338 5.33394 10.6711 5.24341 10.7385 5.17606L13.0328 2.88175C13.57 3.25648 13.9195 3.8723 13.9195 4.57137V11.4285C13.9195 12.1276 13.57 12.7434 13.0328 13.1181L10.7385 10.8238Z"
                fill="currentColor" stroke="currentColor" fillOpacity="0.6" strokeOpacity="0.6" />
            <path d="M2.41895 8H7.5618" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M6.22461 12.752C6.67547 13.4969 7.48461 14 8.4189 14H11.8475C13.2678 14 14.4189 12.8489 14.4189 11.4286V4.57143C14.4189 3.15114 13.2678 2 11.8475 2H8.4189C7.48547 2 6.67632 2.50143 6.22632 3.24543"
                stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.77609 5.85718L2.41895 8.00003L4.77609 10.1429" stroke="currentColor" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
