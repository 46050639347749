type Props = {
    className?: string,
}

export const UnCheckbox = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4014_8320)">
                <path
                    d="M2 4.29157L2 11.7201C2 13.2587 3.24721 14.5059 4.78571 14.5059H12.2143C13.7528 14.5059 15 13.2587 15 11.7201V4.29157C15 2.75307 13.7528 1.50586 12.2143 1.50586H4.78571C3.24721 1.50586 2 2.75307 2 4.29157Z"
                    stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.40015 11.106L11.6001 4.90601M5.40015 4.90601L11.6001 11.106" stroke="currentColor"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_4014_8320">
                    <rect width="16" height="16" fill="transparent" transform="translate(0.5 0.00585938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}