import type {StateCreator} from 'zustand';
import type {FilterSlice} from '@/store/Filter.ts';

export interface SearchSlice {
    searchQuery: string;
    showSearchResults: boolean;
    searchResultsCount: number;
    setSearchQuery: (query: string) => void;
    setShowSearchResults: (showSearchResults: boolean) => void;
    setSearchResultsCount: (count: number) => void;
}

export const createSearchSlice: StateCreator<SearchSlice & FilterSlice, [], [], SearchSlice> = (set, get) => ({
    searchQuery: '',
    showSearchResults: false,
    searchResultsCount: 0,
    setSearchQuery: (query: string) => {
        return set(() => (
         {searchQuery: query}
        ))
    },
    setShowSearchResults: (showSearchResults: boolean) => {
        if (showSearchResults && get().showFilters) {
            get().closeFilters();
        }
        return set(() => (
            {showSearchResults: showSearchResults}
        ))
    },
    setSearchResultsCount: (count: number) => set(() => (
        {searchResultsCount: count}
    ))
})
