import { BaseBatchedMesh } from '../common/BaseBatchedMesh';
import { LinkShape } from './LinkShape';

export class LinksShapesBM extends BaseBatchedMesh{
    constructor(size : number){
        super(size, size * 39, size * 99, LinkShape.getMaterial());

        this.layers.enable( LinkShape.interactionLayer );

        // This has to be set to true so the raycasting works
        this.shouldUpdateBoundingSphere = true;

        this.castShadow = false;
        this.receiveShadow = true;

        this.name = "linksShapesBM";
    }
}