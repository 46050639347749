type Props = {
    className?: string,
}

export const CtrlKey = ({className}: Props) => {
    return (
        <svg className={'h-4 inline ' + (className || '')} height="16" viewBox="0 0 31 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="29" height="12" rx="2" stroke="currentColor" strokeLinejoin="round"/>
            <path
                d="M9.55966 6H8.76705C8.57528 5.09233 7.79545 4.60653 6.92614 4.60653C5.73722 4.60653 4.80398 5.52699 4.80398 7.22727C4.80398 8.92756 5.73722 9.84801 6.92614 9.84801C7.79545 9.84801 8.57528 9.36222 8.76705 8.45455H9.55966C9.31676 9.79688 8.2429 10.5895 6.92614 10.5895C5.25142 10.5895 4.03693 9.2983 4.03693 7.22727C4.03693 5.15625 5.25142 3.86506 6.92614 3.86506C8.2429 3.86506 9.31676 4.65767 9.55966 6ZM10.4825 4.65767V3.95455H15.3916V4.65767H13.3334V10.5H12.5407V4.65767H10.4825ZM16.6149 10.5V3.95455H18.8265C20.3606 3.95455 21.051 4.80469 21.051 5.96165C21.051 6.83097 20.661 7.51172 19.8269 7.79616L21.3066 10.5H20.3862L19.0055 7.93999C18.9512 7.94318 18.8968 7.94318 18.8393 7.94318H17.4075V10.5H16.6149ZM17.4075 7.22727H18.8137C19.862 7.22727 20.2711 6.74787 20.2711 5.96165C20.2711 5.17543 19.862 4.65767 18.801 4.65767H17.4075V7.22727ZM22.3717 10.5V3.95455H23.1643V9.79688H26.2069V10.5H22.3717Z"
                fill="currentColor"/>
        </svg>

    )
}
