type Props = {
    className?: string,
}

export const ObjectType = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1426 11.8572C13.1426 13.0406 10.8401 14 7.99979 14C5.15947 14 2.85693 13.0406 2.85693 11.8572"
                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.85693 4.14282V11.8571" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M13.1426 4.14282V11.8571" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M7.99979 6.28571C10.8401 6.28571 13.1426 5.32632 13.1426 4.14286C13.1426 2.95939 10.8401 2 7.99979 2C5.15947 2 2.85693 2.95939 2.85693 4.14286C2.85693 5.32632 5.15947 6.28571 7.99979 6.28571Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
