import * as THREE from 'three';

export const ExtensionBatchedOpacity = {
    name: "extension-batched-opacity",
    uniforms: {
      opacitiesTexture: new THREE.Texture()
    },
    vertexShader: (shader: any) => {
      shader = `
        uniform sampler2D opacitiesTexture; // Define opacity texture
        varying float vInstOpacity; // Define varying variable to pass to fragment shader

        float getBatchingOpacity( const in float i ) {
          int size = textureSize( opacitiesTexture, 0 ).x;
          int j = int( i );
          int x = j % size;
          int y = j / size;

          return texelFetch( opacitiesTexture, ivec2( x, y ), 0 ).r;
        }
        ${
            shader.replace(
              `#include <batching_vertex>`,
              `
              #include <batching_vertex>

              #ifdef USE_BATCHING

                vInstOpacity = getBatchingOpacity( getIndirectIndex( gl_DrawID ) );

              #endif
				      `
            )
        }
      `;
      return shader;
    },
    fragmentShader: (shader: any) => {
      shader = `
        varying float vInstOpacity; // Receive opacity value from vertex shader
        ${
            shader.replace(
              '#include <color_fragment>',
              `
              #include <color_fragment>

              diffuseColor.a *= vInstOpacity * opacity;
              `
            )
        }
      `;
      return shader;
    },
};