type Props = {
    className?: string,
}

export const Webhooks = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.93359 10.3479L6.73441 5.4585M9.31132 5.53818L12.0664 10.3479M10.7952 12.5803H5.19492"
                  stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="7.99994" cy="3.987" r="1.6679" stroke="currentColor" strokeWidth="2"/>
            <circle cx="3.1679" cy="12.0129" r="1.6679" stroke="currentColor" strokeWidth="2"/>
            <circle cx="12.832" cy="12.0129" r="1.6679" stroke="currentColor" strokeWidth="2"/>
        </svg>
    )
}
