import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { FOG_HEIGHT, GROUND_COLOR_DARK, GROUND_COLOR_LIGHT, GROUND_FILL_Y_POSITION, GROUND_PADDING, GROUND_SHADOW_MAP_HEIGHT, GROUND_SHADOW_MAP_WIDTH, GROUND_SHADOW_X_OFFSET, GROUND_SHADOW_Y_POSITION, GROUND_SHADOW_Z_OFFSET, GROUP_HEIGHT, GROUP_Y_POSITION, SYMBOL_ICON_Y_POSITION, INTERACTION_ANIMATION_SPEED_FAST, MAX_USER_IDLE_TIME, RENDERER_SHADOW_MAP_HEIGHT, RENDERER_SHADOW_MAP_WIDTH, SCALE_FACTOR, SYMBOL_CANVAS_HEIGHT, SYMBOL_CANVAS_WIDTH } from './common/constants.js';
import { Repository } from './common/Repository';
import { Symbol } from '@/three/symbols/Symbol';

// Addons
import CameraControls from 'camera-controls';
CameraControls.install( { THREE: THREE } );

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
// import { CopyShaderCustom } from './utils/materials/shaders/CopyShaderCustom.js';
import { SSAOPass } from 'three/addons/postprocessing/SSAOPass.js';
// import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';

import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';

// Stats and GUI
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { RendererStats } from './utils/three/threex.rendererstats.js';
import {Pane} from 'tweakpane';

import { InputManager } from './utils/interaction/InputManager';
import { IconImageData, TexturePackerJsonData } from './utils/three/TextureAtlasTypes';
import { AppEnv } from '../utils/AppEnv';
import { Logger } from '../utils/Logger';

import { SysInfo } from './utils/misc/SysInfo';
import { useAppStore } from '@/store/Store';

import {preloadFont} from 'troika-three-text';

export class CosmosThree {

    container: HTMLDivElement;

    static systemInfo: SysInfo;

    //Debug params
    static debug = true;

    //  lil GUI, stats & Cam controls
    stats: Stats | null = null;
    rendererStats: any | null = null;
    gui: Pane | null = null;
    showGUI = false;
    showStats = true;
    createCamControls = true;
    enableCameraControls = true;
    showLightHelpers = false;
    showMeshBBoxHelper = true;
    showScenarioBBoxHelper = false;
    showMeshSphereHelper = false;
    showMeshTransformedBBoxHelper = false;
    showCameraBoundsBBoxHelper = false;
    showDynamicBoundsHelper = false;
    showHTMLColorsDebug = false;

    // Antialias & RGBEncoding
    private useAntialias = true;
    private useStencil = false;
    private useDepth = true; // Creates problems if disabled
    private useLogarithmicDepthBuffer = true;

    // Postprocessing
    usePostProcessing = false;

    // ThreeJs
    static canvasWidth = 1;
    static canvasHeight = 1;

    static rendererPixelRatio = 1;

    loadManager: THREE.LoadingManager | null = null;

    // We make the icons texture assets static for easy access across classes
    static iconsAtlasTexture: THREE.Texture = new THREE.Texture();
    static iconsAtlasJson64: TexturePackerJsonData | null = null;
    static iconsAtlasMap64: Map<string, IconImageData> | null = null;
    static iconsAtlasJson32: TexturePackerJsonData | null = null;
    static iconsAtlasMap32: Map<string, IconImageData> | null = null;
    
    static iconsAtlasJsons: Map<string, TexturePackerJsonData | null> = new Map();
    static iconsAtlasMaps: Map<string, Map<string, IconImageData> | null> = new Map();

    // static gradientMapThree: THREE.Texture = new THREE.Texture();
    static folderSVGLight: HTMLImageElement = new Image();
    static folderSVGDark: HTMLImageElement = new Image();
    static duplicatesIconTexture: THREE.Texture = new THREE.Texture();
    static folderIconTexture: THREE.Texture = new THREE.Texture();
    static symbolDefaultSVGShape: THREE.Shape = new THREE.Shape();
    static linkIndicatorArrowSVGShape: THREE.Shape = new THREE.Shape();
    static linkTriggerArrowSVGShape: THREE.Shape = new THREE.Shape();

    static graphScene: THREE.Scene;
    static guiScene: THREE.Scene;
    static clock: THREE.Clock;

    cube: THREE.Mesh = new THREE.Mesh();


    // Contact shadows
    shadowRenderTarget!: THREE.WebGLRenderTarget;
    shadowRenderTargetBlur!: THREE.WebGLRenderTarget;
    shadowCamera!: THREE.OrthographicCamera;

    groundGeo!: THREE.PlaneGeometry;
    groundFill!: THREE.Mesh;
    groundShadow!: THREE.Mesh;
    groundBlur!: THREE.Mesh;

    shadowDepthMaterial!: THREE.MeshDepthMaterial;
    shadowHorizontalBlurMaterial!: THREE.ShaderMaterial;
    shadowVerticalBlurMaterial!: THREE.ShaderMaterial;

    static contactShadowsNeedUpdate: boolean = true;

    paramsContactShadow = {
        blur: 1.0,
        darkness: 0.3,
        opacity: 1,
    };

    paramsPlane = {
        color: '#f00',
        opacity: 1
    };

    // END Contact shadows

    static graphCamera: THREE.OrthographicCamera;
    graphCameraTargetHelper!: THREE.Mesh;
    static guiCamera: THREE.OrthographicCamera;

    camMode: "3d" | "2d" = "3d";
    activeTheme: "cosmosLight" | "cosmosDark" = "cosmosLight";

    static meshOffset = new THREE.Vector3();

    ambientLight!: THREE.AmbientLight;
    static directionalLight1: THREE.DirectionalLight;
    light1Helper!: THREE.DirectionalLightHelper;
    light1CameraHelper!: THREE.CameraHelper;
    directionalLight2!: THREE.DirectionalLight;
    light2Helper!: THREE.DirectionalLightHelper;
    light2CameraHelper!: THREE.CameraHelper;

    customShadowMaterial!: THREE.ShaderMaterial;

    meshBBoxHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0xff0000);
    scenarioBBoxHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0xff00ff);
    meshSphereHelper: THREE.Mesh = new THREE.Mesh( 
        new THREE.SphereGeometry( 1, 16, 16 ), 
        new THREE.MeshBasicMaterial( { color: new THREE.Color("#ff0000"), wireframe: true } )
    );

    meshTransformedBBoxHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x0000ff);
    cameraBoundsHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x00ff00);
    dynamicBoundsHelper: THREE.Box3Helper = new THREE.Box3Helper(new THREE.Box3(), 0x00ff00);

    inputManager!: InputManager;

    static camControls: CameraControls;

    static renderer: THREE.WebGLRenderer;
    static anisotropy: number = -1;
    isRendererPaused = false;
    static gpuBusy = false;
    static rendererReady: signals.Signal;
    static cosmosReady: signals.Signal;

    composer?: EffectComposer;
    renderPass?: RenderPass;
    ssaoPass?: SSAOPass;
    outputPass?: OutputPass;

    //
    private symbolRenderTarget!: THREE.WebGLRenderTarget;
    private symbolRenderTargetCam!: THREE.OrthographicCamera;

    requestedAnimationFrameId = 0;

    //
    static lastUserInteraction = 0;
    static userControlledCamera = true;

    // Presets
    minGroundSize = 30000 / SCALE_FACTOR; // 30000 found as a good max testing several spaces, needed so shadows look similar in all cases
    groundSize = this.minGroundSize;

    graphCameraPosition = 1000;
    graphCameraNear = 0.1;
    graphCameraFar = 10000;
    showCameraTargetHelper = true;

    // Theme
    static groundColor = new THREE.Color();
    static groupsTopColor = new THREE.Color();
    static groupsSideColor = new THREE.Color();
    static groupsSideMutedTint = new THREE.Color();

    static groupLegendsOutlineColor = new THREE.Color();
    static groupLegendsBackgroundColor = new THREE.Color();
    static groupLegendsTextColor = new THREE.Color();

    static symbolsMutedTint = new THREE.Color();
    static symbolsPadColor = new THREE.Color();

    static symbolLegendsOutlineColor = new THREE.Color();
    static symbolLegendsBackgroundColor = new THREE.Color();
    static symbolLegendsTextColor = new THREE.Color();

    static linksPortColor = new THREE.Color();
    static linksDefaultColor = new THREE.Color();
    static linksTriggerColor = new THREE.Color();
    static linksDisabledColor = new THREE.Color();
    static linksMutedTint = new THREE.Color();
    static linksIndicatorDefaultColor = new THREE.Color();
    static linksIndicatorTriggerColor = new THREE.Color();

    static selectionIndicatorColor = new THREE.Color();
    static veilColor = new THREE.Color();
    static legendBlendFactor = 0;

    // Fog Plane

    static fogPlane = new THREE.Vector4();
    static fogDepth = FOG_HEIGHT;
    static fogColor = new THREE.Color(); // Beats me why only this one has to be converted to sRGB, but it works

    static fogUtilPlane = new THREE.Plane();
    static fogViewNormalMatrix = new THREE.Matrix3();
    static fogGlobalPlane = new THREE.Plane( new THREE.Vector3( 0, -1, 0 ), FOG_HEIGHT );

    // END Fog Plane

    static forceIdle = false;

    ambientLightIntensity = 1.48; // 1.2
    ambientLightColor = 0xffffff;

    directionalLight1Intensity = 1.48; // 3
    directionalLight2Intensity = 0.62; // 1.2

    // UI zoom percentage
    private zoomPercentageSpan: HTMLElement | null = null;
    private prevZoom: number = 0;

    constructor (container: HTMLDivElement, systemInfo: SysInfo){
        THREE.ColorManagement.enabled = true;

        CosmosThree.systemInfo = systemInfo;

        Logger.log('Client System Info:', CosmosThree.systemInfo);

        CosmosThree.debug = AppEnv.getMode() !== 'production';

        this.container = container;

        CosmosThree.rendererPixelRatio = (window.devicePixelRatio > 1) ? 2 : 1;

        // Stats
        if(CosmosThree.debug && this.showStats){ this.addStats(); }

        CosmosThree.cosmosReady = new signals.Signal();

        this.addScenes();

        this.addCameras();

        this.addLights();

        this.addGroundAndGrid();

        if(CosmosThree.debug) { this.addHelpers(); }

        this.addRenderer();

        this.addInputManager(); // It's very important for the input manager to be instantiated before the camera controls so the wheel event of the input controls get more precedence in the execution stack. Otherwise there is no way to override the default behaviour of camera controls.

        this.addCamControls();

        this.addEffectComposer();

        this.addSymbolRenderTarget();

        // Gui
        if(CosmosThree.debug && this.showGUI){ this.addGUIControls(); }

        // Post-creation global resize
        window.addEventListener('resize', this.updateSize.bind(this), false);
        this.updateSize();

        this.zoomPercentageSpan = document.getElementById("zoomPercentSpan");

        // Animation and rendering loop
        this.animate();

        this.loadAssets(()=>{
            CosmosThree.cosmosReady.dispatch(); 
        });
    }

    private addStats(){
        this.stats = new Stats();
        this.stats.dom.id = "cosmos-performance-stats";
        this.stats.showPanel(0);
        this.container.appendChild(this.stats.dom);

        if(CosmosThree.debug){
            this.rendererStats = RendererStats();
            this.rendererStats.domElement.style.position = 'absolute';
            this.rendererStats.domElement.style.left = '0px';
            this.rendererStats.domElement.style.bottom = '48px';
            this.container.appendChild(this.rendererStats.domElement);
        }
    }

    private loadAssets(callback: any){
        this.loadManager = new THREE.LoadingManager();

        this.loadManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            Logger.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        
        
        this.loadManager.onLoad = function ( ) {
            Logger.log( 'Loading Complete!');

            preloadFont(
                {
                  font: '/webfonts/Inter-Medium.woff', 
                  characters: " azertyuiopqsdfghjklmwxcvbnAZERTYUIOPQSDFGHJKLMWXCVBNéÉàÀèÈùÙëËüÜïÏâêîôûÂÊÎÔÛíÍáÁóÓúÚñÑłŁçÇýÝčČšŠæÆœŒ/*-–+7894561230,;:!?¡¿.%$£€={}()[]&~\"'‘’`#_°@АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯяüÜöÖäÄñÑςερτυθιοπασδφγηξκλζχψωβνμΕΡΤΥΘΙΟΠΑΣΔΦΓΗΞΚΛΖΧΨΩΒΝΜåÅæÆøØ…",
                  sdfGlyphSize: undefined
                },
                () => {
                    callback();
                }
              )
        };

        this.loadManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            Logger.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        
        this.loadManager.onError = function ( url ) {
            Logger.log( 'There was an error loading ' + url );
        };

        // Assets loaders
        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            '/textures/atlases/iconpack64.png',
        
            // onLoad callback
            (texture) => {
                // we create the texture when the image is loaded
                CosmosThree.iconsAtlasTexture = texture;

                // Crispier icons when scaled down but more artifacts and less antialias
                // CosmosThree.iconsAtlasTexture.generateMipmaps = false;

                CosmosThree.iconsAtlasTexture.premultiplyAlpha = false;
                CosmosThree.iconsAtlasTexture.colorSpace = THREE.SRGBColorSpace;
                CosmosThree.iconsAtlasTexture.anisotropy = CosmosThree.anisotropy;
            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new THREE.FileLoader(this.loadManager).load(
            // resource URL
            '/textures/atlases/iconpack64.json',
        
            // onLoad callback
            ( data ) => {
                // output the text to the console
                // Logger.log( "Text", data );
                CosmosThree.iconsAtlasJson64 = JSON.parse(data as string);
                // Logger.log( "JSON", this.iconsAtlasJson64);

                // We create a map from the Json for fast lookup
                if(CosmosThree.iconsAtlasJson64){
                    // Create a Map
                    CosmosThree.iconsAtlasMap64 = new Map<string, IconImageData>();
                    CosmosThree.iconsAtlasJson64.frames.forEach(icon => {
                        const { filename, ...imageData } = icon;
                        CosmosThree.iconsAtlasMap64!.set(filename, imageData);
                    });
                }

                CosmosThree.iconsAtlasJsons.set("64", CosmosThree.iconsAtlasJson64);
                CosmosThree.iconsAtlasMaps.set("64", CosmosThree.iconsAtlasMap64);

                // Logger.log(this.iconsAtlasMap64);
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened', err );
            }
        );

        // Assets loaders
        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            '/textures/atlases/iconpack32.png',
        
            // onLoad callback
            () => {

            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new THREE.FileLoader(this.loadManager).load(
            // resource URL
            '/textures/atlases/iconpack32.json',
        
            // onLoad callback
            ( data ) => {
                // output the text to the console
                // Logger.log( "Text", data );
                CosmosThree.iconsAtlasJson32 = JSON.parse(data as string);
                // Logger.log( "JSON", this.iconsAtlasJson32);

                // We create a map from the Json for fast lookup
                if(CosmosThree.iconsAtlasJson32){
                    // Create a Map
                    CosmosThree.iconsAtlasMap32 = new Map<string, IconImageData>();
                    CosmosThree.iconsAtlasJson32.frames.forEach(icon => {
                        const { filename, ...imageData } = icon;
                        CosmosThree.iconsAtlasMap32!.set(filename, imageData);
                    });
                }

                CosmosThree.iconsAtlasJsons.set("32", CosmosThree.iconsAtlasJson32);
                CosmosThree.iconsAtlasMaps.set("32", CosmosThree.iconsAtlasMap32);
                // Logger.log(this.iconsAtlasMap32);
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened', err );
            }
        );

        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            '/images/duplicates-icon.png',
        
            // onLoad callback
            (texture) => {
                // we create the texture when the image is loaded
                CosmosThree.duplicatesIconTexture = texture;

                // Crispier icons when scaled down but more artifacts and less antialias
                // CosmosThree.duplicatesIconTexture.generateMipmaps = false;

                CosmosThree.duplicatesIconTexture.premultiplyAlpha = false;
                CosmosThree.duplicatesIconTexture.colorSpace = THREE.SRGBColorSpace;
                CosmosThree.duplicatesIconTexture.anisotropy = CosmosThree.anisotropy;
            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new THREE.TextureLoader(this.loadManager).load(
            // resource URL
            '/images/folder-icon.png',
        
            // onLoad callback
            (texture) => {
                // we create the texture when the image is loaded
                CosmosThree.folderIconTexture = texture;

                // Crispier icons when scaled down but more artifacts and less antialias
                CosmosThree.folderIconTexture.generateMipmaps = false;

                CosmosThree.folderIconTexture.premultiplyAlpha = false;
                CosmosThree.folderIconTexture.colorSpace = THREE.SRGBColorSpace;
                CosmosThree.folderIconTexture.anisotropy = CosmosThree.anisotropy;
            },
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new SVGLoader(this.loadManager).load(
            // resource URL
            '/images/symbol-default-shape.svg',
        
            // onLoad callback
            (data) => {
                CosmosThree.symbolDefaultSVGShape = SVGLoader.createShapes( data.paths[0] )[0];
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new SVGLoader(this.loadManager).load(
            // resource URL
            '/images/link-indicator-arrow.svg',
        
            // onLoad callback
            (data) => {
                CosmosThree.linkIndicatorArrowSVGShape = SVGLoader.createShapes( data.paths[0] )[0];
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );

        new SVGLoader(this.loadManager).load(
            // resource URL
            '/images/link-trigger-arrow.svg',
        
            // onLoad callback
            (data) => {
                CosmosThree.linkTriggerArrowSVGShape = SVGLoader.createShapes( data.paths[0] )[0];
            },
        
            // onProgress callback
            function ( xhr ) {
                Logger.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },
        
            // onError callback
            function ( err ) {
                Logger.error( 'An error happened.', err );
            }
        );
    }

    private addScenes(){
        // Graph Scene
        CosmosThree.graphScene = new THREE.Scene();

        // Graph Scene
        CosmosThree.guiScene = new THREE.Scene();
    }

    private addCameras(){
        CosmosThree.graphCamera = new THREE.OrthographicCamera( - this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientHeight / (2 * SCALE_FACTOR), -this.container.clientHeight / (2 * SCALE_FACTOR), this.graphCameraNear, this.graphCameraFar );
        CosmosThree.graphCamera.position.set(this.graphCameraPosition, this.graphCameraPosition, this.graphCameraPosition);

        CosmosThree.graphCamera.layers.enable(0); // Default layer
        CosmosThree.graphCamera.layers.enable(1); // Interaction layer
        // this.graphCamera.layers.disable(2);
        CosmosThree.graphCamera.layers.enable(2); // Custom shadows layer

        if(CosmosThree.debug){
            this.graphCameraTargetHelper = new THREE.Mesh(
                new THREE.SphereGeometry( 40 / SCALE_FACTOR ),
                new THREE.MeshBasicMaterial( { color: new THREE.Color("#ffff00") } )
            )
            CosmosThree.graphScene.add( this.graphCameraTargetHelper );
            this.graphCameraTargetHelper.visible = false;
        }

        // GUI camera
        CosmosThree.guiCamera = new THREE.OrthographicCamera( - this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientWidth / (2 * SCALE_FACTOR), this.container.clientHeight / (2 * SCALE_FACTOR), - this.container.clientHeight / (2 * SCALE_FACTOR) );
        CosmosThree.guiCamera.position.z = 1;
    }

    private addLights(){
        // Lights
        this.ambientLight = new THREE.AmbientLight(this.ambientLightColor, this.ambientLightIntensity);
        CosmosThree.graphScene.add( this.ambientLight );

        CosmosThree.directionalLight1 = new THREE.DirectionalLight(0xffffff, this.directionalLight1Intensity);
        CosmosThree.directionalLight1.castShadow = true;
        CosmosThree.directionalLight1.position.set(0, this.groundSize / 2, this.groundSize / 2);

        CosmosThree.directionalLight1.shadow.mapSize.width = RENDERER_SHADOW_MAP_WIDTH;
        CosmosThree.directionalLight1.shadow.mapSize.height = RENDERER_SHADOW_MAP_HEIGHT;

        // CosmosThree.directionalLight1.shadow.

        // CosmosThree.directionalLight1.shadow.bias = -0.0001;
        CosmosThree.directionalLight1.shadow.camera.far = new THREE.Vector3(0,0,0).distanceTo(CosmosThree.directionalLight1.position) * 2;
        CosmosThree.directionalLight1.shadow.camera.near = 0.1;

        CosmosThree.directionalLight1.shadow.camera.left = -this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.right = this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.top = this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.bottom = -this.groundSize / 2;

        CosmosThree.directionalLight1.shadow.camera.zoom = 1;
        
        // this.directionalLight1.shadow.normalBias = -0.0001;
        // this.directionalLight1.shadow.radius = 2;

        CosmosThree.graphScene.add(CosmosThree.directionalLight1);

        if(CosmosThree.debug){
            this.light1Helper = new THREE.DirectionalLightHelper( CosmosThree.directionalLight1, 5 );
            this.light1Helper.visible = this.showLightHelpers;
            CosmosThree.graphScene.add( this.light1Helper );

            this.light1CameraHelper = new THREE.CameraHelper(CosmosThree.directionalLight1.shadow.camera);
            this.light1CameraHelper.visible = this.showLightHelpers;
            CosmosThree.graphScene.add(this.light1CameraHelper);
        }

        this.directionalLight2 = new THREE.DirectionalLight(0xffffff, this.directionalLight2Intensity);
        this.directionalLight2.position.set(0, this.groundSize / 2, 0);
        CosmosThree.graphScene.add(this.directionalLight2);

        if(CosmosThree.debug){
            this.light2Helper = new THREE.DirectionalLightHelper( this.directionalLight2, 5 );
            this.light2Helper.visible = this.showLightHelpers;
            CosmosThree.graphScene.add( this.light2Helper );

            this.light2CameraHelper = new THREE.CameraHelper(this.directionalLight2.shadow.camera);
            this.light2CameraHelper.visible = this.showLightHelpers;
            CosmosThree.graphScene.add(this.light2CameraHelper);
        }
    }

    private addGroundAndGrid(){
        // the render target that will show the shadows in the plane texture
        this.shadowRenderTarget = new THREE.WebGLRenderTarget( GROUND_SHADOW_MAP_WIDTH, GROUND_SHADOW_MAP_HEIGHT );
        this.shadowRenderTarget.texture.generateMipmaps = false;

        // the render target that we will use to blur the first render target
        this.shadowRenderTargetBlur = new THREE.WebGLRenderTarget( GROUND_SHADOW_MAP_WIDTH, GROUND_SHADOW_MAP_HEIGHT );
        this.shadowRenderTargetBlur.texture.generateMipmaps = false;

        // Ground
        const groundGeoSize = isFinite(this.groundSize)? this.groundSize : 1;
        this.groundGeo = new THREE.PlaneGeometry( groundGeoSize, groundGeoSize ).rotateX( Math.PI / 2 ); // Try to make the ground with many segments to prevent supposed screen tearing because of precision problems

        // make a plane and make it face up

        const groundShadowMat = new THREE.MeshBasicMaterial( {
            map: this.shadowRenderTarget.texture,
            opacity: this.paramsContactShadow.opacity,
            transparent: true,
            depthWrite: false,
        } );
        this.groundShadow = new THREE.Mesh( this.groundGeo, groundShadowMat );
        // make sure it's rendered after the fillPlane
        this.groundShadow.renderOrder = 1;
        this.groundShadow.position.x = GROUND_SHADOW_X_OFFSET;
        this.groundShadow.position.y = GROUND_SHADOW_Y_POSITION;
        this.groundShadow.position.z = GROUND_SHADOW_Z_OFFSET;
        CosmosThree.graphScene.add( this.groundShadow );

        // the y and z from the texture are flipped!
        this.groundShadow.scale.y = - 1;
        this.groundShadow.scale.z = - 1;

        // the plane onto which to blur the texture
        this.groundBlur = new THREE.Mesh( this.groundGeo );
        this.groundBlur.position.x = GROUND_SHADOW_X_OFFSET;
        this.groundBlur.position.y = GROUND_SHADOW_Y_POSITION;
        this.groundBlur.position.z = GROUND_SHADOW_Z_OFFSET;
        this.groundBlur.visible = false;
        this.groundBlur.layers.set(2);
        CosmosThree.graphScene.add( this.groundBlur );

        // the y and z from the texture are flipped!
        this.groundBlur.scale.y = - 1;
        this.groundBlur.scale.z = - 1;

        // the plane with the color of the ground
        const fillPlaneMaterial = new THREE.MeshBasicMaterial( {
            color: new THREE.Color(),
            opacity: this.paramsPlane.opacity
        } );
        this.groundFill = new THREE.Mesh( this.groundGeo, fillPlaneMaterial );
        this.groundFill.rotateX( Math.PI );
        this.groundFill.position.y = GROUND_FILL_Y_POSITION;
        CosmosThree.graphScene.add( this.groundFill );

        // the camera to render the depth material from
        this.shadowCamera = new THREE.OrthographicCamera( -groundGeoSize / 2, groundGeoSize / 2, groundGeoSize / 2, -groundGeoSize / 2, 0, GROUP_Y_POSITION + GROUP_HEIGHT );
        
        this.shadowCamera.position.y = GROUP_Y_POSITION + GROUP_HEIGHT;
        this.shadowCamera.rotation.x = -Math.PI / 2; // get the camera to look down
        this.shadowCamera.layers.set(2);

        // like MeshDepthMaterial, but goes from black to transparent
        this.shadowDepthMaterial = new THREE.MeshDepthMaterial();
        this.shadowDepthMaterial.userData.darkness = { value: this.paramsContactShadow.darkness };
        this.shadowDepthMaterial.onBeforeCompile = function ( shader ) {
            shader.uniforms.darkness = this.userData.darkness;
            shader.fragmentShader = 
            /* glsl */
            `
                uniform float darkness;
                ${shader.fragmentShader.replace(
                    'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
                    'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
                )}
            `;
        };

        this.shadowDepthMaterial.depthTest = false;
        this.shadowDepthMaterial.depthWrite = false;

        this.shadowHorizontalBlurMaterial = new THREE.ShaderMaterial( HorizontalBlurShader );
        this.shadowHorizontalBlurMaterial.depthTest = false;

        this.shadowVerticalBlurMaterial = new THREE.ShaderMaterial( VerticalBlurShader );
        this.shadowVerticalBlurMaterial.depthTest = false;
    }

    // renderTarget --> blurPlane (horizontalBlur) --> renderTargetBlur --> blurPlane (verticalBlur) --> renderTarget
    private blurShadow( amount:number ) {

        this.groundBlur.visible = true;

        // blur horizontally and draw in the renderTargetBlur
        this.groundBlur.material = this.shadowHorizontalBlurMaterial;
        (this.groundBlur.material as any).uniforms.tDiffuse.value = this.shadowRenderTarget.texture;
        this.shadowHorizontalBlurMaterial.uniforms.h.value = amount * 1 / GROUND_SHADOW_MAP_WIDTH;

        CosmosThree.renderer.setRenderTarget( this.shadowRenderTargetBlur );
        CosmosThree.renderer.render( this.groundBlur, this.shadowCamera );

        // blur vertically and draw in the main renderTarget
        this.groundBlur.material = this.shadowVerticalBlurMaterial;
        (this.groundBlur.material as any).uniforms.tDiffuse.value = this.shadowRenderTargetBlur.texture;
        this.shadowVerticalBlurMaterial.uniforms.v.value = amount * 1 / GROUND_SHADOW_MAP_HEIGHT;

        CosmosThree.renderer.setRenderTarget( this.shadowRenderTarget );
        CosmosThree.renderer.render( this.groundBlur, this.shadowCamera );

        this.groundBlur.visible = false;

    }

    private addHelpers(){
        // 
        /* const cubeMat = new MeshTransmissionMaterial({
            _transmission: 1,
            thickness: 0,
            roughness: 0,
            chromaticAberration: 0.03,
            anisotropicBlur: 0.1,
            distortion: 0,
            distortionScale: 0.5,
            temporalDistortion: 0.0,
        }) */

        /* const cubeMat = new THREE.MeshBasicMaterial({
            color: 0xff3300,
            side: THREE.DoubleSide
        });
                
        this.cube = new THREE.Mesh(new THREE.BoxGeometry( 200 / SCALE_FACTOR, 200 / SCALE_FACTOR, 200 / SCALE_FACTOR ), cubeMat);
        this.cube.name = "cube";
        this.cube.receiveShadow = true;
        this.cube.castShadow = true;
        this.cube.position.y = 1000 / SCALE_FACTOR;
        this.cube.rotation.x = Math.PI / 4;
        this.graphScene.add( this.cube ); */

        // axes
        CosmosThree.graphScene.add( new THREE.AxesHelper( 300 / SCALE_FACTOR ) );

        // Mesh BBox helper
        CosmosThree.graphScene.add(this.meshBBoxHelper);
        this.meshBBoxHelper.visible = this.showMeshBBoxHelper;

        // Scenario BBox helper
        CosmosThree.graphScene.add(this.scenarioBBoxHelper);
        this.scenarioBBoxHelper.visible = this.showScenarioBBoxHelper;
        
        // Mesh Sphere helper
        CosmosThree.graphScene.add(this.meshSphereHelper);
        this.meshSphereHelper.visible = this.showMeshSphereHelper;

        // Mesh Wrapper BBox helper
        CosmosThree.graphScene.add(this.meshTransformedBBoxHelper);
        this.meshTransformedBBoxHelper.visible = this.showMeshTransformedBBoxHelper;

        // Camera bounds BBox helper
        CosmosThree.graphScene.add(this.cameraBoundsHelper);
        this.cameraBoundsHelper.visible = this.showCameraBoundsBBoxHelper;

        // Dynamic bounding sphere helper
        CosmosThree.graphScene.add(this.dynamicBoundsHelper);
        this.dynamicBoundsHelper.visible = this.showDynamicBoundsHelper;
    
        // 
        if(this.showHTMLColorsDebug){
            // Create a new container for the squares
            const squares = document.createElement('div');
            squares.style.position = 'absolute';
            squares.style.top = '50%';
            squares.style.left = '50%';
            squares.style.transform = 'translate(-50%, -50%)';
            squares.style.display = 'flex';
            squares.style.flexWrap = 'wrap'; // Allow squares to wrap to the next line
            squares.style.width = '220px'; // Enough width for two squares plus the gap
            squares.style.height = '220px'; // Enough height for two rows of squares
            squares.style.gap = '10px'; // Space between squares

            // Array of colors for the squares
            const colors = ['#EFEDF0', '#9933cc', '#8a2eb8', '#6b248f'];

            // Create and style 4 squares
            for (let i = 0; i < 4; i++) {
                const square = document.createElement('div');
                square.style.width = '100px';
                square.style.height = '100px';
                square.style.backgroundColor = colors[i]; // Set the background color
                squares.appendChild(square); // Append the square to the container
            }

            // Append the container to the existing wrapper
            this.container.appendChild(squares);
        }
    }

    private addRenderer(){
        // Renderer
        CosmosThree.renderer = new THREE.WebGLRenderer({ 
            antialias: this.useAntialias,
            stencil: this.useStencil,
            depth: this.useDepth,
            powerPreference:"high-performance",
            precision: "highp",
            logarithmicDepthBuffer: this.useLogarithmicDepthBuffer
        });

        CosmosThree.renderer.debug.checkShaderErrors = false;

        CosmosThree.renderer.setSize(this.container.clientWidth, this.container.clientHeight);

        CosmosThree.renderer.info.autoReset = false;
        CosmosThree.renderer.autoClear = false;

        CosmosThree.renderer.setPixelRatio( CosmosThree.rendererPixelRatio );

        CosmosThree.renderer.outputColorSpace = THREE.SRGBColorSpace;
    
        CosmosThree.renderer.shadowMap.enabled = true;
        // CosmosThree.renderer.toneMapping = THREE.LinearToneMapping;
        CosmosThree.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // CosmosThree.renderer.shadowMap.type = THREE.VSMShadowMap;


        CosmosThree.anisotropy = CosmosThree.renderer.capabilities.getMaxAnisotropy();

        // This prevents the black 1px border outline to be shown when the canvas is focused
        CosmosThree.renderer.domElement.style.outline = "none";
        // The canvas needs to have a tab index set for it to be able to be focused.
        // If it can't be focused it won't be able to fire keyboard events.
        CosmosThree.renderer.domElement.setAttribute("tabindex", "0");

        this.container.appendChild(CosmosThree.renderer.domElement);

        // This loads the render targets into memory, avoiding future freeze when animations should run.
        CosmosThree.renderer.initRenderTarget ( this.shadowRenderTarget );
        CosmosThree.renderer.initRenderTarget ( this.shadowRenderTargetBlur );

        CosmosThree.rendererReady = new signals.Signal();
    }

    private addInputManager(){
        // Input manager (raycasting, mouse, states, etc)
        this.inputManager = new InputManager(this);
    }

    private addCamControls(){
        // Camera Controls
        CosmosThree.clock = new THREE.Clock();
        CosmosThree.camControls = new CameraControls(CosmosThree.graphCamera, CosmosThree.renderer.domElement);
        CosmosThree.camControls.maxZoom = 1;
        CosmosThree.camControls.minZoom = 0.02;
        CosmosThree.camControls.dollySpeed = 3 * CosmosThree.rendererPixelRatio;
        CosmosThree.camControls.draggingSmoothTime = 0.100;
        CosmosThree.camControls.smoothTime = INTERACTION_ANIMATION_SPEED_FAST;
        CosmosThree.camControls.enabled = this.enableCameraControls;
        CosmosThree.camControls.mouseButtons.left = CameraControls.ACTION.TRUCK;

        // CosmosThree.camControls.mouseButtons.right = CameraControls.ACTION.ROTATE; // uncomment to allow rotation with right mouse button
        CosmosThree.camControls.mouseButtons.right = CameraControls.ACTION.NONE;

        CosmosThree.camControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        CosmosThree.camControls.mouseButtons.middle = CameraControls.ACTION.ZOOM;
        CosmosThree.camControls.touches.one = CameraControls.ACTION.TOUCH_TRUCK;
        CosmosThree.camControls.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK;

        // CosmosThree.camControls.touches.two = CameraControls.ACTION.TOUCH_ROTATE;
        CosmosThree.camControls.touches.two = CameraControls.ACTION.NONE;

        CosmosThree.camControls.dollyToCursor = true;

        CosmosThree.camControls.addEventListener( 'control', ()=> {
            CosmosThree.userControlledCamera = true;
        });
    }

    private addEffectComposer() {
        // We check how many samples the native canvas MSAA is using so we can mimick it in the postprocessing pipeline
        // const gl = this.renderer.getContext();
        // const samples = gl.getParameter(gl.SAMPLES);

        const renderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {
            samples: 4
        });

        CosmosThree.renderer.initRenderTarget ( renderTarget );

        // this.composer = new EffectComposer( this.renderer );
        this.composer = new EffectComposer( CosmosThree.renderer, renderTarget );

        this.renderPass = new RenderPass( CosmosThree.graphScene, CosmosThree.graphCamera );
        // this.renderPass.renderToScreen = true;
        this.composer.addPass( this.renderPass );

        // this.composer.addPass( this.renderPass );
        /* const copyPass = new ShaderPass( CopyShaderCustom );
        this.composer.addPass( copyPass ); */

        this.ssaoPass = new SSAOPass( CosmosThree.graphScene, CosmosThree.graphCamera, window.innerWidth, window.innerHeight );
		// this.composer.addPass( this.ssaoPass );

        // this.ssaoPass.output = SSAOPass.OUTPUT.Depth;

        /* this.composer.addPass( new GlitchPass() ); */

        /* this.outputPass = new OutputPass();
        this.composer.addPass( this.outputPass ); */
    }

    private addSymbolRenderTarget(){
        this.symbolRenderTarget = new THREE.WebGLRenderTarget(SYMBOL_CANVAS_WIDTH * CosmosThree.rendererPixelRatio, SYMBOL_CANVAS_HEIGHT * CosmosThree.rendererPixelRatio, {
            samples: 4,
            colorSpace: THREE.SRGBColorSpace
        });
        this.symbolRenderTarget.texture.generateMipmaps = false;

        CosmosThree.renderer.initRenderTarget ( this.symbolRenderTarget );

        this.symbolRenderTargetCam = new THREE.OrthographicCamera( -SYMBOL_CANVAS_WIDTH / (2 * SCALE_FACTOR), SYMBOL_CANVAS_WIDTH / (2 * SCALE_FACTOR), SYMBOL_CANVAS_HEIGHT / (2 * SCALE_FACTOR), -SYMBOL_CANVAS_HEIGHT / (2 * SCALE_FACTOR), this.graphCameraNear, this.graphCameraFar );
        this.symbolRenderTargetCam.position.set(this.graphCameraPosition, this.graphCameraPosition, this.graphCameraPosition);
        this.symbolRenderTargetCam.lookAt(CosmosThree.graphScene.position);
    }

    private addGUIControls() {
        this.gui = new Pane();
        this.gui.expanded = true;

        // Camera --
        const folderCamera = this.gui.addFolder({ title: 'Camera', expanded: true });
        const folderCameraPosition = folderCamera.addFolder( { title: 'Position' } );
        folderCameraPosition.addBinding(CosmosThree.graphCamera.position, 'x', { readonly: false, min: -20000, max: 20000 });
        folderCameraPosition.addBinding(CosmosThree.graphCamera.position, 'y', { readonly: false, min: -20000, max: 20000 });
        folderCameraPosition.addBinding(CosmosThree.graphCamera.position, 'z', { readonly: false, min: -20000, max: 20000 });

        const folderCameraRotation = folderCamera.addFolder( { title: 'Rotation' } );
        folderCameraRotation.addBinding(CosmosThree.graphCamera.rotation, 'x', { readonly: false, min: -Math.PI * 2, max: Math.PI * 2 });
        folderCameraRotation.addBinding(CosmosThree.graphCamera.rotation, 'y', { readonly: false, min: -Math.PI * 2, max: Math.PI * 2 });
        folderCameraRotation.addBinding(CosmosThree.graphCamera.rotation, 'z', { readonly: false, min: -Math.PI * 2, max: Math.PI * 2 });

        const folderCameraZoom = folderCamera.addFolder( { title: 'Zoom' } );
        folderCameraZoom.addBinding(CosmosThree.graphCamera, 'zoom', { readonly: false, min: -100, max: 100 });

        const folderLights = this.gui.addFolder( { title: 'Lights', expanded: false } );
        folderLights.addBinding(this, 'showLightHelpers', {label: "visible"}).on('change', () => {
            this.light1Helper.visible = this.showLightHelpers;
            this.light1CameraHelper.visible = this.showLightHelpers;
            this.light2Helper.visible = this.showLightHelpers;
            this.light2CameraHelper.visible = this.showLightHelpers;
        });

        const folderAmbientLight = folderLights.addFolder( { title: 'Ambient' } );
        folderAmbientLight.addBinding( this.ambientLight, 'intensity', { min: 0, max: 5 } );

        const folderDirectionaLight1 = folderLights.addFolder( { title: 'Directional Light 1' } );

        const folderDirectionaLight1Tabs = folderDirectionaLight1.addTab({
            pages: [
              {title: 'Light'},
              {title: 'Shadow'},
            ],
          });

        folderDirectionaLight1Tabs.pages[0].addBinding( CosmosThree.directionalLight1, 'intensity', { min: 0, max: 5 } );
        folderDirectionaLight1Tabs.pages[0].addBinding( CosmosThree.directionalLight1.position, 'x', { min: -40, max: 40 } );
        folderDirectionaLight1Tabs.pages[0].addBinding( CosmosThree.directionalLight1.position, 'y', { min: -40, max: 40 } );
        folderDirectionaLight1Tabs.pages[0].addBinding( CosmosThree.directionalLight1.position, 'z', { min: -40, max: 40 } );

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'far', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'near', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'left', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'right', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'top', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        folderDirectionaLight1Tabs.pages[1].addBinding(CosmosThree.directionalLight1.shadow.camera, 'bottom', {min: -50000, max: 50000}).on('change', () => {
            CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();
            this.light1CameraHelper.update();
        });

        const folderDirectionaLight2 = folderLights.addFolder( { title: 'Directional Light 2' } );

        const folderDirectionaLight2Tabs = folderDirectionaLight2.addTab({
            pages: [
              {title: 'Light'},
              {title: 'Shadow'},
            ],
          });

        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2, 'intensity', { min: 0, max: 5 } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'x', { min: -50000, max: 50000 } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'y', { min: -50000, max: 50000 } );
        folderDirectionaLight2Tabs.pages[0].addBinding( this.directionalLight2.position, 'z', { min: -50000, max: 50000 } );

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'far', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'near', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'left', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'right', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'top', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        folderDirectionaLight2Tabs.pages[1].addBinding(this.directionalLight2.shadow.camera, 'bottom', {min: -50000, max: 50000}).on('change', () => {
            this.directionalLight2.shadow.camera.updateProjectionMatrix();
            this.light2CameraHelper.update();
        });

        const folderMesh = this.gui.addFolder( { title: 'Helpers', expanded: false } );
        folderMesh.addBinding(this, 'showCameraTargetHelper', {label: "camera target"}).on('change', () => { this.graphCameraTargetHelper.visible = this.showCameraTargetHelper; });
        folderMesh.addBinding(this, 'showMeshBBoxHelper', {label: "bounding box"}).on('change', () => { this.meshBBoxHelper.visible = this.showMeshBBoxHelper; });
        folderMesh.addBinding(this, 'showMeshSphereHelper', {label: "bounding sphere"}).on('change', () => { this.meshSphereHelper.visible = this.showMeshSphereHelper; });
        folderMesh.addBinding(this, 'showMeshTransformedBBoxHelper', {label: "bounding Wrapper box"}).on('change', () => { this.meshTransformedBBoxHelper.visible = this.showMeshTransformedBBoxHelper; });
        folderMesh.addBinding(this, 'showCameraBoundsBBoxHelper', {label: "camera bounds box"}).on('change', () => { this.cameraBoundsHelper.visible = this.showCameraBoundsBBoxHelper; });
        folderMesh.addBinding(this, 'showDynamicBoundsHelper', {label: "dynamic bounding box"}).on('change', () => { this.dynamicBoundsHelper.visible = this.showDynamicBoundsHelper; });

        const folderPostprocessing = this.gui.addFolder( { title: 'Postprocessing', expanded: false } );
        folderPostprocessing.addBinding(this, 'usePostProcessing', {label: "usePostProcessing"});

        folderPostprocessing.addBinding( this.ssaoPass!, 'kernelRadius', {min: 0, max: 32} );
        folderPostprocessing.addBinding( this.ssaoPass!, 'minDistance', {min: 0.001, max: 0.02} );
        folderPostprocessing.addBinding( this.ssaoPass!, 'maxDistance', {min: 0.01, max: 0.3} );
        
        const folderVSM = this.gui.addFolder( { title: 'VSM', expanded: false } );

        folderVSM.addBinding( CosmosThree.directionalLight1!.shadow, 'blurSamples', {min: 1, max: 25, step: 1,} );
        folderVSM.addBinding( CosmosThree.directionalLight1!.shadow, 'radius', {min: 0, max: 25, step: 1,} );

        const folderContactShadows = this.gui.addFolder( { title: 'Contact Shadows' } );

        folderContactShadows.addBinding( this.shadowCamera.position, 'y', {min: -10, max: 10, step: 0.1,} ).on('change', () => {
            CosmosThree.contactShadowsNeedUpdate = true;
        });
        folderContactShadows.addBinding( this.shadowCamera, 'near', {min: 0, max: 10, step: 0.1,} ).on('change', () => {
            this.shadowCamera.updateProjectionMatrix();
            CosmosThree.contactShadowsNeedUpdate = true;
        });
        folderContactShadows.addBinding( this.shadowCamera, 'far', {min: 0, max: 10, step: 0.1,} ).on('change', () => {
            this.shadowCamera.updateProjectionMatrix();
            CosmosThree.contactShadowsNeedUpdate = true;
        });
        folderContactShadows.addBinding( this.paramsContactShadow, 'blur', {min: 0, max: 10, step: 0.1,} ).on('change', () => {
            CosmosThree.contactShadowsNeedUpdate = true;
        });
        folderContactShadows.addBinding( this.paramsContactShadow, 'darkness', {min: 0, max: 2, step: 0.1,} ).on('change', () => {
            this.shadowDepthMaterial.userData.darkness.value = this.paramsContactShadow.darkness;
            CosmosThree.contactShadowsNeedUpdate = true;
        });
        folderContactShadows.addBinding( this.paramsContactShadow, 'opacity', {min: 0, max: 1, step: 0.01,} ).on('change', () => {
            (this.groundShadow.material as any).opacity = this.paramsContactShadow.opacity;
        });

        const folderPlane = this.gui.addFolder( { title: 'Ground Fill' } );

        /* folderPlane.addBinding( this.paramsPlane, 'color' ).on('change', () => {
            (this.groundFill.material as any).color = new THREE.Color( this.paramsPlane.color );
        }); */
        folderPlane.addBinding( this.paramsPlane, 'opacity', {min: 0, max: 1, step: 0.01,} ).on('change', () => {
            (this.groundFill.material as any).opacity = this.paramsPlane.opacity;
        });

        const folderFog = this.gui.addFolder( { title: 'Vertical fog' } );
        folderFog.addBinding( this.cube.position, 'y', {min: -10, max: 10, step: 0.1,} )
        folderFog.addBinding( CosmosThree, 'fogDepth', {min: -1, max: 1, step: 0.01,} )
        folderFog.addBinding( CosmosThree.fogGlobalPlane, 'constant', {min: -1, max: 1, step: 0.01,} )
    }

    updateSize(){
        CosmosThree.canvasWidth = this.container.clientWidth;
        CosmosThree.canvasHeight = this.container.clientHeight;

        // Update renderer
        CosmosThree.renderer.setSize( CosmosThree.canvasWidth, CosmosThree.canvasHeight );

        // Update graph camera
        CosmosThree.graphCamera.left = -CosmosThree.canvasWidth / (2 * SCALE_FACTOR);
        CosmosThree.graphCamera.right = CosmosThree.canvasWidth / (2 * SCALE_FACTOR);
		CosmosThree.graphCamera.top = CosmosThree.canvasHeight / (2 * SCALE_FACTOR);
		CosmosThree.graphCamera.bottom = -CosmosThree.canvasHeight / (2 * SCALE_FACTOR);

        CosmosThree.graphCamera.updateProjectionMatrix();

        // Update gui camera
        CosmosThree.guiCamera.left = -CosmosThree.canvasWidth / (2 * SCALE_FACTOR);
        CosmosThree.guiCamera.right = CosmosThree.canvasWidth / (2 * SCALE_FACTOR);
		CosmosThree.guiCamera.top = CosmosThree.canvasHeight / (2 * SCALE_FACTOR);
		CosmosThree.guiCamera.bottom = -CosmosThree.canvasHeight / (2 * SCALE_FACTOR);

        CosmosThree.guiCamera.updateProjectionMatrix();

        this.composer?.setSize(CosmosThree.canvasWidth, CosmosThree.canvasHeight);

        if(CosmosThree.debug) { this.light1CameraHelper.update(); }
    }

    updateWorldSize(bbox: THREE.Box3){
        this.groundSize = Math.max(this.minGroundSize, (Math.max(Math.abs(bbox.max.x) + Math.abs(bbox.min.x), Math.abs(bbox.max.z) + Math.abs(bbox.min.z))) ) + (GROUND_PADDING * 2);

        CosmosThree.directionalLight1.position.set(0, this.groundSize / 2, this.groundSize / 2);

        CosmosThree.directionalLight1.shadow.camera.far = new THREE.Vector3(0,0,0).distanceTo(CosmosThree.directionalLight1.position) * 2;
        CosmosThree.directionalLight1.shadow.camera.left = -this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.right = this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.top = this.groundSize / 2;
        CosmosThree.directionalLight1.shadow.camera.bottom = -this.groundSize / 2;

        CosmosThree.directionalLight1.shadow.camera.updateProjectionMatrix();

        if(CosmosThree.debug){ this.light1CameraHelper.update(); }

        this.directionalLight2.position.set(0, this.groundSize / 2, 0);

        this.shadowCamera.left = -this.groundSize / 2;
        this.shadowCamera.right = this.groundSize / 2;
        this.shadowCamera.top = this.groundSize / 2;
        this.shadowCamera.bottom = -this.groundSize / 2;

        this.shadowCamera.updateProjectionMatrix();

        this.groundGeo.dispose();
        this.groundGeo = new THREE.PlaneGeometry( isFinite(this.groundSize)? this.groundSize : 1, isFinite(this.groundSize)? this.groundSize : 1 ).rotateX( Math.PI / 2 );
        this.groundShadow.geometry = this.groundGeo;
        this.groundBlur.geometry = this.groundGeo;
        this.groundFill.geometry = this.groundGeo;
    }

    setCameraMode(mode: "3d" | "2d", animated = true){
        // We need to move the camera target so the rotation happens around the 'correct' orbit point.
        // Otherwise at certain positions and/or zoom levels the movement would be super crazy.
        // To do that we cast a ray from the viewport center to the ground floor, and we move the camera center to that point, whic should be not perceptible.
        // Then we perform the rest of the animation (camera rotation, lights, etc).
        // TODO: The calculated point can be outside the camera bounds so the camera manager may clamp it, that means you see a jump to move it inside the bounds. For now it's still WAY better than before.
        const raycaster = new THREE.Raycaster();

        const raycasterVector = new THREE.Vector3();
        const raycasterDirection = new THREE.Vector3();

        const pointer = new THREE.Vector2();

        pointer.x = ( (CosmosThree.canvasWidth / 2) / CosmosThree.canvasWidth ) * 2 - 1;
        pointer.y = - ( (CosmosThree.canvasHeight / 2) / CosmosThree.canvasHeight ) * 2 + 1;

        raycasterVector.set( pointer.x, pointer.y, -1 ); // z = -1 important!
        raycasterVector.unproject( CosmosThree.graphCamera );
        raycasterDirection.set( 0, 0, -1 ).transformDirection( CosmosThree.graphCamera.matrixWorld );
        raycaster.set( raycasterVector, raycasterDirection );

        const intersects = raycaster.intersectObject( this.groundFill );

        if(intersects.length > 0){
            const intersectionPoint = intersects[0].point;
            CosmosThree.camControls.moveTo(intersectionPoint.x, intersectionPoint.y, intersectionPoint.z, false);
        }

        this.camMode = mode;
        if(mode === "3d"){
            CosmosThree.camControls.rotateTo(Math.PI / 4, Math.atan(Math.sqrt(2)), animated);
            gsap.to(CosmosThree.directionalLight1.shadow, {duration: animated ? CosmosThree.camControls.azimuthRotateSpeed : 0, intensity: 1, ease: "none"});
        }else if (mode === "2d"){
            CosmosThree.camControls.rotateTo(0, 0, animated);
            gsap.to(CosmosThree.directionalLight1.shadow, {duration: animated ? CosmosThree.camControls.azimuthRotateSpeed : 0, intensity: 0, ease: "none"});
        }
    }

    setTheme(activeTheme: "cosmosLight" | "cosmosDark", animated = true){
        if(activeTheme === "cosmosLight"){
            gsap.to(CosmosThree.groundColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUND_COLOR_LIGHT.r, g: GROUND_COLOR_LIGHT.g, b: GROUND_COLOR_LIGHT.b, ease: "none", onUpdate: () => {
                CosmosThree.graphScene.background = CosmosThree.groundColor;
                (this.groundFill.material as any).color = CosmosThree.groundColor;
            }});
        }else if(activeTheme === "cosmosDark"){
            gsap.to(CosmosThree.groundColor, {duration: animated ? INTERACTION_ANIMATION_SPEED_FAST : 0, r: GROUND_COLOR_DARK.r, g: GROUND_COLOR_DARK.g, b: GROUND_COLOR_DARK.b, ease: "none", onUpdate: () => {
                CosmosThree.graphScene.background = CosmosThree.groundColor;
                (this.groundFill.material as any).color = CosmosThree.groundColor;
            }});
        }
    }

    getSymbolSnapshot(callback: any){
        const selected = Repository.mesh!._selected as Symbol;

        if (!selected) return;

        if(Repository.mesh?.activeTheme === "cosmosLight"){
            if(selected.snapshotLight){
                callback(selected.snapshotLight);
                return;
            }
        }else if(Repository.mesh?.activeTheme === "cosmosDark"){
            if(selected.snapshotDark){
                callback(selected.snapshotDark);
                return;
            }
        }
        
        const position = Repository.mesh!.fitToElems([selected], false, true).center;
        this.symbolRenderTargetCam.position.set(this.graphCameraPosition + position.x, this.graphCameraPosition + position.y, this.graphCameraPosition + position.z);
        
        // We hide the elements we don't want in the snapshot
        Repository.symbolsDuplicateIndicatorsMesh!.visible = false;
        Repository.groupsShapesSideMesh!.visible = false;
        Repository.groupsShapesTopMesh!.visible = false;
        Repository.mesh!.selectionIndicator.visible = false;
        if (Repository.linksPortsMesh) Repository.linksPortsMesh.visible = false;
        if (Repository.linksShapesMesh) Repository.linksShapesMesh.visible = false;
        if (Repository.linksInteractionsShapesMesh) Repository.linksInteractionsShapesMesh.visible = false;
        if (Repository.linksIndicatorsMesh) Repository.linksIndicatorsMesh.visible = false;
        if (Repository.linksDuplicatesShapesMesh) Repository.linksDuplicatesShapesMesh.visible = false;
        this.groundShadow.visible = false;
        this.groundFill.visible = false;

        // Deactivate fog
        (Repository.symbolsPadsMesh!.material as any).fActive = false;
        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			(symbolShapeMesh.material as any).fActive = false;
		});
        (Repository.symbolsIconsMesh!.material as any).fActive = false;

        // Set selected element to his spotlighted state
        const previousSelectedScaleY = selected.shape.three.scale.y;
        selected.shape.three.scale.y = 2;
        selected.shape.matrixNeedsUpdate = true;

        const previousSelectedTint = selected.tint;
        selected.tint = 0;

        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
            symbolShapeMesh.sync();
        });
        CosmosThree.directionalLight1.shadow.needsUpdate = true;

        const previousSelectedIconY = selected.icon.three.position.y;
        selected.icon.three.position.y = SYMBOL_ICON_Y_POSITION * 2;
        selected.icon.matrixNeedsUpdate = true;

        const previousSelectedIconOpacity = selected.icon.opacity;
        selected.icon.opacity = 1;

        Repository.symbolsIconsMesh?.sync();

        // remove the background
        const initialBackground = CosmosThree.graphScene.background;
        /*const domElem = document.querySelector(".ui-panel");
        if(domElem) {
            const colorString = getComputedStyle(domElem).getPropertyValue('background-color');
            CosmosThree.graphScene.background = new THREE.Color(colorString);
        }*/

        const bgColor = useAppStore.getState().activeTheme.variables.uiColorBase100;
        CosmosThree.graphScene.background = new THREE.Color(`rgb(${bgColor})`);

        // set renderer clear alpha
        const initialClearAlpha = CosmosThree.renderer.getClearAlpha();
        CosmosThree.renderer.setClearAlpha( 0 );

        CosmosThree.renderer.setRenderTarget( this.symbolRenderTarget );
        CosmosThree.renderer.render( CosmosThree.graphScene, this.symbolRenderTargetCam );

        // We restore the renderer
        CosmosThree.renderer.setRenderTarget( null );
        CosmosThree.renderer.setClearAlpha( initialClearAlpha );
        CosmosThree.graphScene.background = initialBackground;

        // We restore the visibility of the elements
        Repository.symbolsDuplicateIndicatorsMesh!.visible = true;
        Repository.groupsShapesSideMesh!.visible = true;
        Repository.groupsShapesTopMesh!.visible = true;
        Repository.mesh!.selectionIndicator.visible = true;
        if (Repository.linksPortsMesh) Repository.linksPortsMesh.visible = true;
        if (Repository.linksShapesMesh) Repository.linksShapesMesh.visible = true;
        if (Repository.linksInteractionsShapesMesh) Repository.linksInteractionsShapesMesh.visible = true;
        if (Repository.linksIndicatorsMesh) Repository.linksIndicatorsMesh.visible = true;
        if (Repository.linksDuplicatesShapesMesh) Repository.linksDuplicatesShapesMesh.visible = true;
        this.groundShadow.visible = true;
        this.groundFill.visible = true;

        // Reactivate fog
        (Repository.symbolsPadsMesh!.material as any).fActive = true;
        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
			(symbolShapeMesh.material as any).fActive = true;
		});
        (Repository.symbolsIconsMesh!.material as any).fActive = true;

        // Reset selected element to his previous state
        selected.shape.three.scale.y = previousSelectedScaleY;
        selected.shape.matrixNeedsUpdate = true;

        selected.tint = previousSelectedTint;

        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
            symbolShapeMesh.sync();
        });
        CosmosThree.directionalLight1.shadow.needsUpdate = true;

        selected.icon.three.position.y = previousSelectedIconY;
        selected.icon.matrixNeedsUpdate = true;

        selected.icon.opacity = previousSelectedIconOpacity;

        Repository.symbolsIconsMesh?.sync();

        const buffer = new Uint8ClampedArray(1 * 1 * 4 * SYMBOL_CANVAS_WIDTH * CosmosThree.rendererPixelRatio * SYMBOL_CANVAS_HEIGHT * CosmosThree.rendererPixelRatio);

        // We read the pixels from the render target - SLOW, can be made async
        CosmosThree.renderer.readRenderTargetPixelsAsync(this.symbolRenderTarget, 0, 0, SYMBOL_CANVAS_WIDTH * CosmosThree.rendererPixelRatio, SYMBOL_CANVAS_HEIGHT * CosmosThree.rendererPixelRatio, buffer).then(()=>{
            const imgData = new ImageData(buffer, SYMBOL_CANVAS_WIDTH * CosmosThree.rendererPixelRatio, SYMBOL_CANVAS_HEIGHT * CosmosThree.rendererPixelRatio, {colorSpace: "srgb"});
            if(Repository.mesh?.activeTheme === "cosmosLight"){
                selected.snapshotLight = imgData;
            }else if(Repository.mesh?.activeTheme === "cosmosDark"){
                selected.snapshotDark = imgData;
            }

            callback(imgData);
        });
    }

    /**
     * Sync three proxy objects with their instances
     */
    syncInstances(){
        Repository.groupsShapesSideMesh?.sync();
        Repository.groupsShapesTopMesh?.sync();

        Repository.linksPortsMesh?.sync();
        Repository.linksShapesMesh?.sync();
        Repository.linksInteractionsShapesMesh?.sync();
        Repository.linksIndicatorsMesh?.sync();

        Repository.symbolsPadsMesh?.sync();
        Repository.symbolsShapesMeshes.forEach((symbolShapeMesh) =>{
            symbolShapeMesh.sync();
        });
        Repository.symbolsIconsMesh?.sync();
        Repository.symbolsDuplicateIndicatorsMesh?.sync();

        Repository.linksDuplicatesShapesMesh?.sync();

        Repository.symbolsLegendsOutlinesMesh?.sync();
        Repository.symbolsLegendsBackgroundsMesh?.sync();
        Repository.symbolsLegendsTextsMesh?.sync();

        Repository.groupsLegendsOutlinesMesh?.sync();
        Repository.groupsLegendsBackgroundsMesh?.sync();
        Repository.groupsLegendsTextsMesh?.sync();
        Repository.groupsLegendsFoldersMesh?.sync();

        Repository.mesh?.fullLegend.sync();
    }

    animate() {
        if(!this.isRendererPaused){

            const clockDelta = CosmosThree.clock.getDelta();

            // Check last user interaction
            CosmosThree.lastUserInteraction = CosmosThree.lastUserInteraction + clockDelta;

            if(CosmosThree.lastUserInteraction > MAX_USER_IDLE_TIME || CosmosThree.forceIdle){
                // We enter Idle mode
                Repository.mesh?.startIdleMode();
            }else if(!CosmosThree.forceIdle){
                Repository.mesh?.stopIdleMode();
            }

            // Apply camera controls to camera
            if(CosmosThree.debug){
                CosmosThree.camControls.getTarget( this.graphCameraTargetHelper.position );
            }

            if(CosmosThree.debug && this.gui){ this.gui.refresh(); }

            CosmosThree.camControls.update(clockDelta);
            CosmosThree.graphCamera.updateMatrixWorld(); // Very important or the legend positions will be 'dampened'

            this.syncInstances();

            // Vertical Fog
            CosmosThree.fogViewNormalMatrix.getNormalMatrix( CosmosThree.graphCamera.matrixWorldInverse );
            CosmosThree.fogUtilPlane.copy(CosmosThree.fogGlobalPlane).applyMatrix4(CosmosThree.graphCamera.matrixWorldInverse, CosmosThree.fogViewNormalMatrix);
            CosmosThree.fogPlane.set(CosmosThree.fogUtilPlane.normal.x, CosmosThree.fogUtilPlane.normal.y, CosmosThree.fogUtilPlane.normal.z, CosmosThree.fogUtilPlane.constant);

            // Contact Shadows
            if(CosmosThree.contactShadowsNeedUpdate){
                CosmosThree.contactShadowsNeedUpdate = false;

                // remove the background
                const initialBackground = CosmosThree.graphScene.background;
                CosmosThree.graphScene.background = null;

                // force the depthMaterial to everything
                CosmosThree.graphScene.overrideMaterial = this.shadowDepthMaterial;

                // set renderer clear alpha
                const initialClearAlpha = CosmosThree.renderer.getClearAlpha();
                CosmosThree.renderer.setClearAlpha( 0 );

                // render to the render target to get the depths
                CosmosThree.renderer.setRenderTarget( this.shadowRenderTarget );
                CosmosThree.renderer.clear();
                CosmosThree.renderer.render( CosmosThree.graphScene, this.shadowCamera );

                // and reset the override material
                CosmosThree.graphScene.overrideMaterial = null;

                this.blurShadow( this.paramsContactShadow.blur );

                // reset and render the normal scene
                CosmosThree.renderer.setRenderTarget( null );
                CosmosThree.renderer.setClearAlpha( initialClearAlpha );
                CosmosThree.graphScene.background = initialBackground;
            }

            // Rendering
            if(this.usePostProcessing){
                this.composer?.render();
            }else{
                CosmosThree.renderer.render( CosmosThree.graphScene, CosmosThree.graphCamera );
            }

            CosmosThree.renderer.clearDepth();
            CosmosThree.renderer.render(CosmosThree.guiScene, CosmosThree.guiCamera);

            // Reset normal lights
            CosmosThree.directionalLight1.shadow.autoUpdate = false;

            // UI zoom percentage
            if(this.zoomPercentageSpan){
                if(this.prevZoom !== CosmosThree.graphCamera.zoom){
                const zoomPercent = Math.max(1, Math.ceil((CosmosThree.graphCamera.zoom - CosmosThree.camControls.minZoom) / (CosmosThree.camControls.maxZoom - CosmosThree.camControls.minZoom) * 100));
                    this.prevZoom = CosmosThree.graphCamera.zoom;
                    this.zoomPercentageSpan.innerText = zoomPercent + "";
                }
            }

            if(CosmosThree.debug && this.showStats){
                this.stats!.update();
                if(CosmosThree.debug){ this.rendererStats.update(CosmosThree.renderer); }
            }

            if(CosmosThree.debug) { CosmosThree.renderer.info.reset(); }

            // In case we want to execute something after the GPU has been busy we fire this signal
            if(CosmosThree.gpuBusy){
                CosmosThree.gpuBusy = false;
                CosmosThree.rendererReady.dispatch();
                // performance.mark("GPU is ready")
            }
        }

        this.requestedAnimationFrameId = requestAnimationFrame(() => { this.animate(); });
    }

    pauseRenderer(){
        this.isRendererPaused = true;
    }

    resumeRenderer(){
        this.isRendererPaused = false;
    }

    toogleRenderer(){
        this.isRendererPaused = !this.isRendererPaused;
    }

    destroy (){
        cancelAnimationFrame(this.requestedAnimationFrameId);

        CosmosThree.camControls.dispose();
        this.inputManager.dispose();
        if(CosmosThree.debug){ this.gui?.dispose(); }

        window.removeEventListener('resize', this.updateSize.bind(this), false);

        CosmosThree.renderer.domElement.remove();

        if(CosmosThree.debug){
            this.stats!.dom.remove();
            this.rendererStats.domElement.remove();
        }

        CosmosThree.graphScene.children.forEach((child)=>{
            child.clear();
        });

        CosmosThree.guiScene.children.forEach((child)=>{
            child.clear();
        });

        this.loadManager = null;

        CosmosThree.iconsAtlasTexture.dispose();
        CosmosThree.iconsAtlasMap64?.clear();
        CosmosThree.iconsAtlasMap32?.clear();

        this.shadowRenderTarget.dispose();
        this.shadowRenderTargetBlur.dispose();

        this.groundFill.geometry.dispose();
        (this.groundFill.material as any).dispose();

        this.groundShadow.geometry.dispose();
        (this.groundShadow.material as any).dispose();

        this.groundBlur.geometry.dispose();
        (this.groundBlur.material as any).dispose();

        this.shadowDepthMaterial.dispose();
        this.shadowHorizontalBlurMaterial.dispose();
        this.shadowVerticalBlurMaterial.dispose();

        this.symbolRenderTarget.dispose();

        if(CosmosThree.debug){
            this.graphCameraTargetHelper.geometry.dispose();
            (this.graphCameraTargetHelper.material as any).dispose();

            this.light1Helper.dispose();
            this.light1CameraHelper.dispose();
            this.light2Helper.dispose();
            this.light2CameraHelper.dispose();

            this.meshBBoxHelper.dispose();

            this.meshSphereHelper.geometry.dispose();
            (this.meshSphereHelper.material as any).dispose();

            this.meshTransformedBBoxHelper.dispose();

            this.dynamicBoundsHelper.dispose();
        }

        CosmosThree.renderer.dispose();
    }
}