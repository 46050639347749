import {Link, LoaderFunctionArgs, redirect, useLoaderData, useNavigate} from 'react-router-dom';
import {LocalStorageManager} from '@/utils/LocalStorageManager.ts';
import supabase from '@/utils/supabase-client';
import type {Session} from '@supabase/supabase-js';
import LoadingOverlay from '@/components/utils/LoadingOverlay.tsx';
import {useMemo, useRef, useState} from 'react';
import {useLazyEffect} from '@/utils/hooks.ts';
import {Logger} from '@/utils/Logger.ts';
import type {LoginRequestData} from '@/utils/url-query-parser.ts';
import {buildSpaceRedirectQuery} from '@/utils/url-query-parser.ts';
import {ErrorType, getErrorMessage, handleEdgeFunctionError} from '@/utils/error.ts';
import {useAppStore} from '@/store/Store.ts';
import {NotificationType} from '@/store/Notification.ts';

interface MakeOrganization {
    id: number,
    name: string,
    zone: string,
}

interface OrgsPerZone {
    zone: string;
    orgs: MakeOrganization[]
}

export default function LoginWithMakeResolve() {

    const {
        addNotification,
    } = useAppStore((state) => {
        return {
            addNotification: state.addNotification,
        }
    });

    const [isLoading, setIsLoading] = useState(true);
    const session = useRef<Session | null>(null);
    const loginRequestsData = useRef<LoginRequestData | null>(null);
    const [organizations, setOrganizations] = useState<MakeOrganization[]>([]);

    const { loginData } = useLoaderData() as {
        loginData: LoginRequestData,
        loginRequestId: string
    };
    const navigate = useNavigate();

    const handleAfterLogin = async (zone: string, organizationId: number, teamId?: number) => {
        if (!session.current) {
            return navigate('/');
        }
        if (!zone || !organizationId) {
            addNotification({
                type: NotificationType.ERROR,
                title: getErrorMessage(ErrorType.MISSING_ZONE_AND_ORGANIZATION_INFO),
                sticky: true,
            });
        }

        const { data, error } = await supabase.functions.invoke('after-user-login', {
            body: JSON.stringify({
                accessToken: session.current.provider_token,
                refreshToken: session.current.provider_refresh_token,
                zone: zone,
                teamId: teamId,
                organizationId: organizationId,
            })
        });

        await supabase.auth.setSession(session.current);
        LocalStorageManager.remove('login');

        if (data?.spaceId) {
            return navigate(buildSpaceRedirectQuery(data.spaceId, loginRequestsData.current));
        }

        if (error) {
            const errorData = await handleEdgeFunctionError(error);
            if (errorData && errorData.message.includes('does not have access to the Grid')) {
                addNotification({
                    type: NotificationType.ERROR,
                    title: getErrorMessage(ErrorType.USER_NOT_ALLOWED_BY_ORGANIZATION),
                    sticky: true,
                });
            } else if (errorData && errorData.message.includes('fetch organizations')) {
                addNotification({
                    type: NotificationType.ERROR,
                    title: getErrorMessage(ErrorType.FAILED_TO_RESOLVE_MAKE_ORGANIZATIONS),
                    sticky: true,
                });
            } else {
                addNotification({
                    type: NotificationType.ERROR,
                    title: getErrorMessage(ErrorType.ERROR_WHILE_RESOLVING_GRID_CONNECTION),
                    sticky: true,
                });
            }
        }

        navigate('/logout');
    }

    const orgsPerZone: OrgsPerZone[] = useMemo(() => {
        const orgsPerZone = new Map<string, OrgsPerZone>();
        for (const org of organizations) {
            const orgPerZone = orgsPerZone.get(org.zone);
            if (orgPerZone) {
                orgPerZone.orgs.push(org);
                orgsPerZone.set(org.zone, orgPerZone);
            } else {
                orgsPerZone.set(org.zone, {
                    zone: org.zone,
                    orgs: [org]
                });
            }
        }

        return Array.from(orgsPerZone.values());
    }, [organizations])

    useLazyEffect(() => {
        const resolveLogin = async () => {
            const { data } = await supabase.auth.getSession();
            if (!data?.session) {
                return navigate('/');
            }

            session.current = data.session;
            loginRequestsData.current = loginData;

            if (!loginData?.organizationId || !loginData?.zone) {
                const res = await supabase.functions.invoke('get-make-organizations', {
                    body: JSON.stringify({
                        accessToken: data.session.provider_token
                    })
                });

                if (res.error) {
                    const errorData = await handleEdgeFunctionError(res.error);
                    if (errorData && errorData.message.includes('Failed to fetch')) {
                        addNotification({
                            type: NotificationType.ERROR,
                            title: getErrorMessage(ErrorType.USER_NO_PERMISSION_FOR_OAUTH),
                            sticky: true,
                        });
                    } else if (errorData && errorData.message.includes('expired')) {
                        addNotification({
                            type: NotificationType.ERROR,
                            title: getErrorMessage(ErrorType.MAKE_OAUTH_TOKEN_EXPIRED),
                            sticky: true,
                        });
                    } else {
                        addNotification({
                            type: NotificationType.ERROR,
                            title: getErrorMessage(ErrorType.FAILED_TO_RESOLVE_MAKE_ORGANIZATIONS),
                            sticky: true,
                        });
                    }
                    Logger.error(res.error);
                    return navigate('/logout');
                }

                setOrganizations(res.data?.organizations || []);

                setTimeout(() => {
                    setIsLoading(false);
                }, 200);

                return;
            }

            await handleAfterLogin(loginData.zone, loginData.organizationId);
        }

        resolveLogin();
    }, [], 100);

    const chooseOrganization = async (zone: string, organizationId: number) => {
        await handleAfterLogin(zone, organizationId);
    }

    return (
        <>
            <LoadingOverlay isLoading={isLoading}/>
            <div className='ui-bg-gradient flex h-screen justify-center items-center flex-col gap-8'>
                <div className='flex items-center'>
                    <img src='/images/make-logo.png' className='h-4' alt="Make Grid"></img>
                    <span className='text-base-content-500 flex text-lg relative top-[0.05rem]'>| Grid</span>
                </div>
                <div className={'ui-panel min-w-80 p-6 flex flex-col gap-6'}>
                    <h2 className="text-xl font-semibold leading-7">Select Make Organization</h2>
                    {orgsPerZone.map((zoneOrgs) => (
                        <div key={zoneOrgs.zone} className={'flex flex-col gap-6'}>
                            <div>
                                {zoneOrgs.zone}
                            </div>
                            {zoneOrgs.orgs.map((org) => (
                                <button
                                    key={org.id}
                                    type="button"
                                    className={'ui-btn-outline'}
                                    onClick={() => chooseOrganization(zoneOrgs.zone, org.id)}
                                >
                                    {org.name}
                                </button>
                            ))}
                        </div>
                    ))}
                </div>
                <Link to={'/logout'} className={'hover:underline'}>Sign out</Link>
            </div>
        </>
    )
}

export async function loader({request}: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const loginRequestId = url.searchParams.get('login');
    const loginData = LocalStorageManager.getValue('login', loginRequestId!) as LoginRequestData;

    // check error
    const error = url.searchParams.get('error');
    const errorDescription = url.searchParams.get('error_description');
    if (error && error.length) {
        if (
            errorDescription &&
            (errorDescription?.includes('error saving new user') || errorDescription?.includes('do not have access'))
        ) {
            useAppStore.getState().addNotification({
                type: NotificationType.ERROR,
                title: getErrorMessage(ErrorType.USER_NOT_ALLOWED_BY_EMAIL),
                sticky: true,
            });
        } else {
            useAppStore.getState().addNotification({
                type: NotificationType.ERROR,
                title: getErrorMessage(ErrorType.SUPABASE_OAUTH_ERROR),
                sticky: true,
            });
        }
        return redirect(`/logout`);
    }

    if (!loginRequestId) {
        useAppStore.getState().addNotification({
            type: NotificationType.ERROR,
            title: getErrorMessage(ErrorType.MISSING_ZONE_AND_ORGANIZATION_INFO),
            sticky: true,
        });
        return redirect(`/logout`);
    }
    return {
        loginRequestId,
        loginData
    };
}