type Props = {
    className?: string,
}

export const Enter = ({className}: Props) => {
    return (
        <svg className={'h-2.5 w-2.5 inline ' + (className || '')} viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 6H7C7.39782 6 7.77936 5.84196 8.06066 5.56066C8.34196 5.27936 8.5 4.89782 8.5 4.5V3"
                  stroke="currentColor" strokeWidth="1.29" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5 4L1.5 6L3.5 8" stroke="currentColor" strokeWidth="1.29" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}

