type Props = {
    className?: string,
}

export const CloseSmall = ({className}: Props) => {
  return (
      <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 16 16" fill="none"
           xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4L12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M4 12L12 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  );
}