import type {Symbol} from '@/three/symbols/Symbol.ts';
import {Logger} from '@/utils/Logger.ts';
import {classNames, capitalizeFirstLetter} from '@/utils/helpers.ts';
import {Home} from '@/assets/icons/Home.tsx';
import {Links} from '@/assets/icons/Links.tsx';
import Panel from '@/components/ui/panels/Panel.tsx';
import Tooltip from '@/components/utils/Tooltip.tsx';
import {OpenUrl} from '@/assets/icons/OpenUrl.tsx';
import {Close} from '@/assets/icons/Close.tsx';
import {Checkbox} from '@/assets/icons/Checkbox.tsx';
import {UnCheckbox} from '@/assets/icons/UnCheckbox.tsx';
import {Scheduled} from '@/assets/icons/Scheduled.tsx';
import {SymbolBrand} from '@/assets/icons/SymbolBrand.tsx';
import {SymbolType} from '@/assets/icons/SymbolType.tsx';
import {Bolt} from '@/assets/icons/Bolt.tsx';
import {Squares} from '@/assets/icons/Squares.tsx';
import {Attributes} from '@/assets/icons/Attributes.tsx';
import Breadcrumb, {type BreadcrumbItem} from '@/components/ui/Breadcrumb.tsx';
import Pill from '@/components/ui/Pill.tsx';
import {Transition} from '@headlessui/react';
import Badge from '@/components/ui/Badge.tsx';
import {AppEnv} from '@/utils/AppEnv.ts';
import {CodeBracketSquareIcon} from '@heroicons/react/24/outline';
import AppsCircles from '@/components/ui/AppsCircles.tsx';
import NodeLinks from './NodeLinks.tsx';
import NodeTitle from './NodeTitle.tsx';
import SymbolAttributes from './symbolTabs/SymbolAttributes.tsx';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useAppStore} from '@/store/Store.ts';
import {symbolHasUrl} from '@/utils/symbol.ts';
import { defaultDisplayProps, symbolDisplayPropsMap } from './SymbolDisplayProps.ts';
import { symbolShowAttributesMap } from '@/components/nodeDetail/SymbolShowAttributes.ts';
import { MakeScenarioSymbol } from '@/three/symbols/types/MakeScenarioSymbol.ts';
import { WebhookSymbol } from '@/three/symbols/types/WebhookSymbol.ts';
import { SYMBOL_CANVAS_HEIGHT, SYMBOL_CANVAS_WIDTH } from '@/three/common/constants.ts';
import { CosmosThree } from '@/three/CosmosThree.ts';

type DetailView = 'node' | 'links' | 'attributes';

type Props = {
    nodeDetail: Symbol;
    onReady: () => void;
}

export default function SymbolDetail({nodeDetail, onReady}: Props) {

    const {
        currentMeshInstance,
        activeTheme,
    } = useAppStore((state) => {
        return {
            currentMeshInstance: state.currentMeshInstance,
            activeTheme: state.activeTheme,
        }
    });

    const [currentView, setCurrentView] = useState<DetailView>('node');
    const [showRaw, setShowRaw] = useState(false);
    const [brandTitle, setBrandTitle] = useState<string>('');
    const [symbolTitle, setSymbolTitle] = useState<string>('');
    const [innerContainerHeight, setInnerContainerHeight] = useState(0);

    const contentContainerRef = useRef<HTMLDivElement>(null);
    const nodeContainerRef = useRef<HTMLDivElement>(null);
    const linksContainerRef = useRef<HTMLDivElement>(null);
    const attributesContainerRef = useRef<HTMLDivElement>(null);

    const lastNodeDetailId = useRef<string | null>(null);
    const nodeContainerCanvasRef = useRef<HTMLCanvasElement>(null);

    const closeDetail = () => {
        if (currentMeshInstance) {
            // close spotlight
            currentMeshInstance.unselectAll();
        }
    }

    const getBreadcrumbItems = () => {
        const items: BreadcrumbItem[] = [];
        if ((nodeDetail?.originalData?.attributes as any)?.organization?.name) {
            items.push({
                id: (nodeDetail.originalData!.attributes as any)!.organization!.id,
                label: (nodeDetail.originalData!.attributes as any).organization!.name
            });
        }
        if ((nodeDetail?.originalData?.attributes as any)?.team?.name) {
            items.push({
                id: (nodeDetail.originalData!.attributes as any)!.team!.id,
                label: (nodeDetail.originalData!.attributes as any).team!.name
            });
        }
        if ((nodeDetail?.originalData?.attributes as any)?.folder?.name) {
            items.push({
                id: (nodeDetail.originalData!.attributes as any)!.folder!.id,
                label: (nodeDetail.originalData!.attributes as any).folder!.name
            });
        }
        return items;
    }

    const isSymbolActive = (nodeDetail: Symbol): boolean => {
        if (nodeDetail instanceof MakeScenarioSymbol) {
            return (nodeDetail.originalData!.attributes as any).active;
        }
        if (nodeDetail instanceof WebhookSymbol) {
            return (nodeDetail.originalData!.attributes as any).enabled || false;
        }
        return false;
    }

    const showUsages = (nodeDetail: Symbol) => {
        if (currentMeshInstance) {
            currentMeshInstance.showUsages = true;
            nodeDetail.select = true;
        }
    }

    const changeHeight = (container: HTMLElement) => {
        if (container && contentContainerRef.current) {
            const height = container.clientHeight + 20;
            setInnerContainerHeight(height!);
            contentContainerRef.current.scrollTop = 0;
        }
    }

    const attachSymbolSnapshot = () => {
        try {
            currentMeshInstance!.cosmos.getSymbolSnapshot((imageData: ImageData)=> {
                if (nodeContainerCanvasRef.current) {
                    const canvas = nodeContainerCanvasRef.current;

                    canvas.width = SYMBOL_CANVAS_WIDTH * CosmosThree.rendererPixelRatio;
                    canvas.height = SYMBOL_CANVAS_HEIGHT * CosmosThree.rendererPixelRatio;

                    canvas.style.width = SYMBOL_CANVAS_WIDTH +"px";
                    canvas.style.height = SYMBOL_CANVAS_HEIGHT +"px";

                    const ctx = canvas.getContext("2d");

                    createImageBitmap(imageData).then((bitmap)=> {
                        ctx?.save();
                        // The imageData extracted from the GPU has the y axis of pixels inverted, so we have to invert it back again
                        ctx?.scale(1, -1);
                        ctx?.drawImage(bitmap, 0 , -bitmap.height);
                        ctx?.restore();
                    });
                }
            });
        } catch (e) {
            Logger.error(e);
        }
    }

    useEffect(() => {
        attachSymbolSnapshot();
    }, [activeTheme.id]);

    useEffect(() => {
        switch (currentView) {
            case 'node': {
                const typeArray = nodeDetail.type.split(':');
                setBrandTitle(capitalizeFirstLetter(typeArray[0]));
                setSymbolTitle(capitalizeFirstLetter(typeArray[1]));

                setTimeout(() => {
                    // wait until DOM updated
                    // @TODO split detail and links tabs to separate components
                    attachSymbolSnapshot();
                    changeHeight(nodeContainerRef.current!);
                }, 1)
                break;
            }
            case 'links': {
                setTimeout(() => {
                    changeHeight(linksContainerRef.current!);
                }, 1)
                break;
            }
            case 'attributes': {
                setTimeout(() => {
                    changeHeight(attributesContainerRef.current!);
                }, 1)
                break;
            }
        }
        onReady();
    }, [currentView, nodeDetail]);

    useEffect(() => {
        if (!nodeDetail?.id) {
            lastNodeDetailId.current = null;
            setCurrentView('node');
        } else {
            if (lastNodeDetailId.current !== nodeDetail.id) {
                setCurrentView('node'); // reset view to node detail after each node detail change
            }
            lastNodeDetailId.current = nodeDetail.id;
        }
    }, [nodeDetail]);

    const setView = (view: DetailView) => {
        setCurrentView(view);
    }

    const onShowAllLinks = () => {
        setView('links');
    }

    return (
        <>
            <div className={'flex flex-col gap-1.5'}>
                <div className={'ui-panel ui-pointer-events flex p-1.5'}>
                    <button type={'button'} onClick={() => setView('node')}
                            className={classNames(currentView === 'node' ? 'active' : '', 'ui-btn-icon')}>
                        <Home className={'p-0.5'}/>
                    </button>
                </div>
                {nodeDetail?.links.length > 0 && (
                    <div className={'ui-panel ui-pointer-events flex p-1.5'}>
                        <button type={'button'} onClick={() => setView('links')}
                                className={classNames(currentView === 'links' ? 'active' : '', 'ui-btn-icon')}>
                            <Links className={'p-0.5'}/>
                        </button>
                    </div>
                )}
                {symbolShowAttributesMap.get(nodeDetail.type) && (
                    <div className={'ui-panel ui-pointer-events flex p-1.5'}>
                        <button type={'button'} onClick={() => setView('attributes')}
                                className={classNames(currentView === 'attributes' ? 'active' : '', 'ui-btn-icon p-0')}>
                            <Attributes className={''}/>
                        </button>
                    </div>
                )}
            </div>
            <Panel className={'max-h-[80vh]'}>
                <div className={'flex flex-col w-full gap-3 px-sm'}>
                    <div className={'flex w-full gap-2 justify-between flex-row items-start p-0'}>
                        <NodeTitle symbol={nodeDetail}/>
                        {symbolHasUrl(nodeDetail) && (
                            <Tooltip message={"Open URL"} position={'top'}>
                                <button
                                    tabIndex={-1}
                                    type="button"
                                    className={'ui-btn-icon-close'}
                                    onClick={() => {
                                        window.open(nodeDetail.originalData!.url, '_blank');
                                    }}
                                >
                                    <OpenUrl></OpenUrl>
                                </button>
                            </Tooltip>
                        )}
                        <button
                            tabIndex={-1}
                            type="button"
                            className="ui-btn-icon-close"
                            onClick={closeDetail}
                        >
                            <Close />
                        </button>
                    </div>
                    <div>
                        <Breadcrumb items={getBreadcrumbItems()}></Breadcrumb>
                    </div>
                    {/* pills */}
                    {/*<div>
                        <div className="flex items-start gap-1">
                            {nodeDetail.originalData?.type && (
                                <Pill color={nodeDetail.color.getStyle()}>
                                    {nodeDetail.originalData?.type}
                                </Pill>
                            )}
                            <PlusPill/>
                        </div>
                    </div>*/}
                    {/*<div className={'flex flex-col w-full gap-2'}>
                                  <div className={'text-lg'}>Description</div>
                                  <div className={'text-base-content-500'}>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                      Suspendisse porttitor neque et risus posuere pharetra.
                                      Mauris congue diam vel lorem ornare tincidunt.
                                      Nulla nec bibendum augue.
                                  </div>
                              </div>*/}
                </div>
                <div ref={contentContainerRef} className="relative px-sm overflow-hidden overflow-y-auto w-full transition-[height] duration-200" style={{
                    height: `${innerContainerHeight}px`,
                }}>
                    <Transition show={currentView === 'node'}>
                        <div
                            ref={nodeContainerRef}
                            className="absolute p-[inherit] left-0 right-0 flex gap-6 flex-col justify-center items-start w-full transition duration-200 ease-in data-[closed]:opacity-0"
                        >
                            <div className={'flex w-full justify-center items-center flex-row gap-6'}>
                                <div className={'flex flex-col justify-center items-center gap-3 min-w-16'}>
                                    <Badge
                                        title={brandTitle}
                                        showBorder={false}
                                    >
                                        <SymbolBrand />
                                    </Badge>
                                    <Badge
                                        title={symbolTitle}
                                        showBorder={false}
                                    >
                                        <SymbolType />
                                    </Badge>
                                </div>
                                <div className={'content-center w-[auto]'} id="symbol-detail-canvas-cont">
                                    <canvas
                                        className="rounded-btn"
                                        ref={nodeContainerCanvasRef}
                                        id="symbol-detail-canvas"
                                        width="1"
                                        height="1"
                                    ></canvas>
                                </div>
                                <div className={'flex flex-col justify-center items-center gap-3 min-w-16'}>
                                    {(
                                        typeof (nodeDetail.originalData!.attributes as any).active !== 'undefined' ||
                                        typeof (nodeDetail.originalData!.attributes as any).enabled !== 'undefined') && (
                                        <Badge
                                            title={isSymbolActive(nodeDetail) ? 'Active' : 'Inactive'}
                                            showBorder={false}
                                            tooltip={'Status'}
                                        >
                                            {isSymbolActive(nodeDetail) ?
                                                <Checkbox /> :
                                                <UnCheckbox />
                                            }
                                        </Badge>
                                    )
                                    }
                                    {
                                        typeof (nodeDetail as any)?.instant !== 'undefined' && (
                                            <Badge
                                                title={(nodeDetail.originalData!.attributes as any).instant ? 'Instant' : 'Scheduled'}
                                                showBorder={false}
                                                tooltip={'Scheduling'}
                                            >
                                                {(nodeDetail.originalData!.attributes as any).instant ? (
                                                    <Bolt />
                                                ) : (
                                                    <Scheduled />
                                                )}
                                            </Badge>
                                        )
                                    }
                                    {AppEnv.getMode() === 'development' && (
                                        <Badge
                                            title={'Data'}
                                            showBorder={false}
                                            className={'cursor-pointer'}
                                            onClick={() => {
                                                setShowRaw(!showRaw)
                                            }}
                                        >
                                            <CodeBracketSquareIcon/>
                                        </Badge>
                                    )}
                                </div>
                            </div>
                            <pre
                                className={classNames(showRaw ? 'p-2 max-h-60' : 'opacity-0 max-h-0 hidden', 'rounded-box bg-base-100 max-w-full overflow-auto text-xs transition-all duration-200 ease-in-out')}
                            >
                                {JSON.stringify(nodeDetail.originalData, null, 4)}
                            </pre>
                            {currentView === 'node' && nodeDetail.duplicates.filter(symbol => !(symbol as Symbol).filtered).length > 0 && (
                                <button type="button"
                                        className={'flex w-full flex-row p-3 px-4 gap-x-4 bg-primary-300 text-primary-content-100 rounded-btn items-center justify-center'}
                                        onClick={() => {
                                            showUsages(nodeDetail)
                                        }}
                                >
                                    <div>
                                        <Squares />
                                    </div>
                                    <div className={'max-w-44 text-left'}>
                                        This object is part of multiple folders.
                                    </div>
                                    <div className={'font-semibold ml-auto'}>
                                        Explore
                                    </div>
                                </button>
                            )}
                            {
                                (nodeDetail.originalData?.attributes as any)?.apps?.length && (
                                    <div className={'flex flex-col w-full gap-2'}>
                                        <div className={'text-lg font-semibold'}>Apps used</div>
                                        <AppsCircles allowTooltip={true}
                                                     apps={(nodeDetail.originalData!.attributes as any)!.apps!}/>
                                    </div>
                                )
                            }
                            {/* Node links */}
                            {nodeDetail?.links.length > 0 && (
                                <NodeLinks nodeDetail={nodeDetail} limit={3} onShowAll={onShowAllLinks}/>
                            )}
                            {/* Node properties */}
                            <div className={'flex flex-col w-full gap-2'}>
                                <div className={'text-lg font-semibold'}>Properties</div>
                                <div className={'flex flex-col w-full gap-1'}>
                                    {
                                        [...(symbolDisplayPropsMap.get(nodeDetail.type) || []), ...defaultDisplayProps].map((displayProp) => {
                                        const value = _.get(nodeDetail.originalData, displayProp.key);
                                        if (typeof value === 'undefined' || value === null) {
                                            return;
                                        }
                                        return (
                                            <div
                                                key={displayProp.key}
                                                className={'flex justify-between'}
                                            >
                                                <div
                                                    className={'text-base-content-500 pr-2 min-w-20'}>{displayProp.label}:
                                                </div>
                                                <div
                                                    className={'[word-break:break-word] truncate text-right overflow-clip'}>
                                                    <Pill className={'!py-0.5'}>
                                                                <span
                                                                    className={'text-xs truncate hover:whitespace-normal hover:text-clip'}>{value}</span>
                                                    </Pill>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <Transition show={currentView === 'links'}>
                        <div
                            ref={linksContainerRef}
                            className="absolute p-[inherit] left-0 right-0 w-full transition duration-200 ease-in data-[closed]:opacity-0"
                        >
                            {/* Node links */}
                            {nodeDetail?.links.length > 0 && (
                                <div className={'flex flex-col w-full gap-6'}>
                                    <NodeLinks
                                        nodeDetail={nodeDetail}
                                        showSearch={true}
                                        limit={0}
                                        showCount={true}
                                        showFiltered={true}
                                        limitFiltered={5}
                                    />
                                </div>
                            )}
                        </div>
                    </Transition>
                    <Transition show={currentView === 'attributes'}>
                        <div
                            ref={attributesContainerRef}
                            className="absolute p-[inherit] left-0 right-0 w-full transition duration-200 ease-in data-[closed]:opacity-0"
                        >
                            {/* Symbol attributes */}
                            <SymbolAttributes nodeDetail={nodeDetail} />
                        </div>
                    </Transition>
                </div>
            </Panel>
        </>
    );

}