import { BaseInstancedMesh } from "../common/BaseInstancedMesh";
import { BaseObject } from "../common/BaseObject";
import { LEGEND_GROUPS_OFFSET_X, LEGEND_GROUPS_OFFSET_Z, LEGEND_POSITION_Y_OFFSET } from "../common/constants";
import { CosmosThree } from "../CosmosThree";
import { GroupLegendFolder } from "./GroupLegendFolder";


export class GroupLegendsFoldersIM extends BaseInstancedMesh{

    constructor(size : number){
        super(GroupLegendFolder.getGeometry(), GroupLegendFolder.getMaterial(), size);

        this.name = "groupLegendsFoldersInstancedMesh";
    }

    override sync (){
        let colorsWhereUpdated = false;
        let opacitiesWhereUpdated = false;
    
        /* Logger.time('[perf] mesh: update matrices'); */
		for(let i = 0 ; i < this.elems.length; i++){
			const elem = this.elems[i];
			if(elem.instanceId !== -1){
				this.syncMatrix(elem);

				if(elem.colorNeedsUpdate){
					this.syncColor(elem);

					colorsWhereUpdated = true;
				}

				if(this.opacityNeedsUpdate && elem.opacityNeedsUpdate){
					this.syncOpacity(elem);

					opacitiesWhereUpdated = true;
				}
			}
		}

        if(colorsWhereUpdated) this.colorNeedsUpdate = false;
        if(opacitiesWhereUpdated) this.opacityNeedsUpdate = false;

        /* Logger.timeEnd('[perf] mesh: update matrices'); */
    }

    override syncMatrix(elem: BaseObject){
        this.vector.setFromMatrixPosition(elem.three.parent!.parent!.matrix);

        this.vector.x += (elem as GroupLegendFolder).offsetX - LEGEND_GROUPS_OFFSET_X;
        this.vector.z += (elem as GroupLegendFolder).offsetZ - LEGEND_GROUPS_OFFSET_Z;

        this.vector.add(CosmosThree.meshOffset);
        this.vector.project(CosmosThree.graphCamera);

        this.vector.unproject(CosmosThree.guiCamera);

        // Update the matrices of the objects in the scene
        this.vector.y -= CosmosThree.graphCamera.zoom * LEGEND_POSITION_Y_OFFSET;

        this.vector.x += (elem as GroupLegendFolder).positionX;
        this.vector.y += (elem as GroupLegendFolder).positionY;

        this.mtx.makeTranslation(this.vector);

        // We apply the scale of the legend to efectively 'hide' the legend if necessary
        // The opacity is controlled by other systems so we can't mess with that to accomplish the 'hide'/'show'
        this.mtx.scale(elem.three.scale);

        this.setMatrixAt(elem.instanceId, this.mtx);

        this.instanceMatrix.needsUpdate = true;
	}
}