type Props = {
    className?: string,
}

export const Settings = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.41895 8H10.9904" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M10.9902 9.71422V6.28564" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M13.5615 8H14.4187" stroke="currentColor" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M14.4189 3.71436H8.41895" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.84766 2V5.42857" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.84752 3.71436H2.41895" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M14.4189 12.2856H8.41895" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.84766 10.5715V14.0001" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.84752 12.2856H2.41895" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}
