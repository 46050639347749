import type {LinkAttributeMapping, SerializedLink} from "@/three/links/BaseLink";
import {Symbol} from '@/three/symbols/Symbol';
import {Link} from "@/three/links/Link";

export type LinkDirection = 'fromNodeDetail' | 'toNodeDetail' | 'both';

export type LinkItem = {
    node: Symbol,
    link: Link,
    otherFolder?: boolean
}

export type LinkType = {
    id: string;
    label: string;
    bgColor: string;
    contentColor: string;
    direction?: LinkDirection;
}

export interface ScenarioModule {
    id: number;
    label: string;
    customLabel?: string;
}

export interface AttributeUsed {
    id: string;
    label: string;
    modules: Map<number, ScenarioModule>,
    targetSymbol: Symbol,
    symbols: Map<string, {
        symbol: Symbol,
        otherFolder: boolean
    }>
}

export const triggerLink: LinkType = {
    id: 'trigger',
    label: 'Trigger',
    bgColor: 'bg-trigger',
    contentColor: 'text-trigger-content'
}

export const readLink: LinkType = {
    id: 'read',
    label: 'Read',
    bgColor: 'bg-read',
    contentColor: 'text-read-content'
}

export const writeLink: LinkType = {
    id: 'write',
    label: 'Write',
    bgColor: 'bg-write',
    contentColor: 'text-write-content'
}

export const linkTypeMap: {[key:string]: LinkType} = {
    'system:listen': triggerLink,
    'system:invoke': triggerLink,
    'system:read': readLink,
    'system:update': writeLink,
    'system:base': readLink,
    'system:arrow': triggerLink,
    'system:create': writeLink,
    'system:delete': writeLink,
    'system:stream': writeLink
}

export interface LinkModule {
    name: string;
    id: number;
    originalType: string;
    customLabel?: string;
    moduleSymbol?: Symbol;
}

export interface LinkDetailItem {
    leftSymbol: Symbol,
    rightSymbol: Symbol,
    linkType: LinkType,
    modules: LinkModule[],
}

export interface LinkDetailData {
    items: LinkDetailItem[]
}

type AttributesMappingType = 'source' | 'target';

export const getAllLinksByLink = (link: Link): SerializedLink[] => {

    const allLinks: SerializedLink[] = [];
    if (link.originalData) {
        allLinks.push(link.originalData);

        for (const otherLink of link.originalData.allLinks) {
            allLinks.push(otherLink);
        }
    }

    return allLinks;
}

const getPriorityLinkItem = (obj: LinkItem) => {
    if (obj.link.isTrigger) return 1;
    if (obj.link.isWrite) return 2;
    if (obj.link.isRead) return 3;
    return 4;
};

const getPriorityLinkDetailItem = (obj: LinkDetailItem) => {
    if (obj.linkType.id === 'trigger') return 1;
    if (obj.linkType.id === 'write') return 2;
    if (obj.linkType.id === 'read') return 3;
    return 4;
};

export const sortLinkItems = (a: LinkItem, b: LinkItem) => {
    if (a.otherFolder && !b.otherFolder) return 1;
    if (!a.otherFolder && b.otherFolder) return -1;
    if (a.otherFolder && b.otherFolder) return 0;

    return getPriorityLinkItem(a) - getPriorityLinkItem(b);
}

export const sortLinkDetailItems = (a: LinkDetailItem, b: LinkDetailItem) => {
    return getPriorityLinkDetailItem(a) - getPriorityLinkDetailItem(b);
}

const resolveLinkDirection = (targetId: string, symbol: Symbol, linkType: LinkType) => {
    if (linkType.id === 'read') {
        return targetId === symbol.id ? 'fromNodeDetail' : 'toNodeDetail';
    } else {
        return targetId === symbol.id ? 'toNodeDetail' : 'fromNodeDetail';
    }
}

export const getLinkDetailData = (mainLink: Link): LinkDetailData => {

    const allLinks = getAllLinksByLink(mainLink);
    const linkItemsMap: Map<string, LinkDetailItem> = new Map();

    for (const link of allLinks) {

        const modules: LinkModule[] = [];
        if (link.sourceAttributes.mappings?.length) {
            for (const moduleMapping of link.sourceAttributes.mappings) {
                modules.push({
                    name: moduleMapping.module,
                    id: moduleMapping.moduleId,
                    originalType: link.type,
                    customLabel: moduleMapping.customLabel ?? undefined,
                    moduleSymbol: link.source === mainLink.source!.id ? mainLink.target as Symbol : mainLink.source as Symbol,
                })
            }
        }
        if (link.targetAttributes.mappings?.length) {
            for (const moduleMapping of link.targetAttributes.mappings) {
                modules.push({
                    name: moduleMapping.module,
                    id: moduleMapping.moduleId,
                    originalType: link.type,
                    customLabel: moduleMapping.customLabel ?? undefined,
                    moduleSymbol: link.target === mainLink.target!.id ? mainLink.source as Symbol : mainLink.target as Symbol,
                })
            }
        }

        const linkType = linkTypeMap[link.type];
        if (!linkType) {
            continue;
        }
        const linkDirection = resolveLinkDirection(link.target!, mainLink.source! as Symbol, linkType);

        const linkTypeAlready = linkItemsMap.get(linkType.id);
        if (linkTypeAlready) {
            linkTypeAlready.modules = [...linkTypeAlready.modules, ...modules];
            if (linkTypeAlready.linkType.direction !== linkDirection) {
                linkTypeAlready.linkType.direction = 'both';
            }
            linkItemsMap.set(linkType.id, linkTypeAlready);
        } else {
            linkType.direction = linkDirection;
            linkItemsMap.set(linkType.id, {
                leftSymbol: mainLink.source! as Symbol,
                rightSymbol: mainLink.target! as Symbol,
                linkType: linkType,
                modules: modules
            });
        }
    }

    const items = Array.from(linkItemsMap.values());

    items.sort(sortLinkDetailItems);

    return {
        items
    }
}

export const resolveLinksInOtherFolders = (
    nodeDetail: Symbol,
    virtualLink: Link,
    linksMap: Map<string, LinkItem>
): void => {
    let virtualSymbol: Symbol | undefined;
    const sharedSymbols: Symbol[] = [];
    if (virtualLink.source!.id === nodeDetail.id) {
        virtualSymbol = virtualLink.target! as Symbol;
    } else if (virtualLink.target!.id === nodeDetail.id) {
        virtualSymbol = virtualLink.source! as Symbol;
    }
    if (!virtualSymbol) {
        return;
    }
    for (const virtualLink of virtualSymbol.links) {
        if (
            virtualLink.target?.id === nodeDetail.id ||
            virtualLink.source?.id === nodeDetail.id
        ) {
            continue;
        }
        sharedSymbols.push(virtualLink.source! as Symbol);
    }
    for (const sharedSymbol of sharedSymbols) {
        for (const link of sharedSymbol.links as Link[]) {
            if (link.isShared) {
                continue;
            }
            linksMap.set(link.id, {
                node: sharedSymbol,
                link: link,
                otherFolder: true
            });
        }

    }
}

export const getAllSymbolLinks = (symbol: Symbol): LinkItem[] => {
    const linkItems: Map<string, LinkItem> = new Map();
    const linkItemsInFolders: Map<string, LinkItem> = new Map();

    let links: LinkItem[] = [];
    const linksArrays = [];
    if (symbol.inputLinks.length) {
        linksArrays.push(symbol.inputLinks);
    }
    if (symbol.outputLinks.length) {
        linksArrays.push(symbol.outputLinks);
    }
    linksArrays.forEach((linkArray) => {
        linkArray.forEach((link) => {
            if (link.isShared) {
                resolveLinksInOtherFolders(symbol, link as Link, linkItemsInFolders);
            } else {
                linkItems.set(link.id, {
                    node: symbol,
                    link: link as Link,
                });
            }
        });
    });

    links = links.concat(Array.from(linkItems.values()));
    links = links.concat(Array.from(linkItemsInFolders.values()));

    return links;
}

const parseAttributesMapping = (
    attributesUsed: Map<string, AttributeUsed> ,
    type: AttributesMappingType,
    link: SerializedLink,
    mappings: LinkAttributeMapping[],
    linkItem: LinkItem
) => {
    let attributeSymbol: Symbol;
    let targetSymbol: Symbol;
    if (type === 'source') {
        if (link.source === linkItem.link.source!.id) {
            attributeSymbol = linkItem.link.source as Symbol;
            targetSymbol = linkItem.link.target as Symbol;
        } else {
            attributeSymbol = linkItem.link.target as Symbol;
            targetSymbol = linkItem.link.source as Symbol;
        }
    } else {
        if (link.target === linkItem.link.target!.id) {
            attributeSymbol = linkItem.link.target as Symbol;
            targetSymbol = linkItem.link.source as Symbol;
        } else {
            attributeSymbol = linkItem.link.source as Symbol;
            targetSymbol = linkItem.link.target as Symbol;
        }
    }
    /*const attributeSymbol = type === 'source' ? linkItem.link.source! as Symbol : linkItem.link.target! as Symbol;*/
    for (const attributeMapping of mappings) {
        const toIterate = [];
        if (attributeMapping.usedInputFields?.length) {
            toIterate.push(attributeMapping.usedInputFields);
        }
        if (attributeMapping.refsOut?.length) {
            toIterate.push(attributeMapping.refsOut);
        }
        for (const attributesArray of toIterate) {
            for (const attribute of attributesArray) {
                const attributeUsed = attributesUsed.get(attribute.id);
                if (attributeUsed) {
                    attributeUsed.modules.set(attributeMapping.moduleId, {
                        id: attributeMapping.moduleId,
                        label: attributeMapping.module,
                        customLabel: attributeMapping.customLabel
                    });
                    attributeUsed.symbols.set(attributeSymbol.id, {
                        symbol: attributeSymbol as Symbol,
                        otherFolder: !!linkItem.otherFolder
                    });
                } else {
                    const modules: Map<number, ScenarioModule> = new Map();
                    modules.set(attributeMapping.moduleId, {
                        id: attributeMapping.moduleId,
                        label: attributeMapping.module,
                        customLabel: attributeMapping.customLabel
                    });
                    const symbols: Map<string, {
                        symbol: Symbol,
                        otherFolder: boolean
                    }> = new Map();
                    symbols.set(attributeSymbol.id, {
                        symbol: attributeSymbol as Symbol,
                        otherFolder: !!linkItem.otherFolder
                    });
                    attributesUsed.set(attribute.id, {
                        id: attribute.id,
                        label: attribute.label,
                        modules: modules,
                        targetSymbol: targetSymbol,
                        symbols: symbols
                    });
                }
            }
        }
    }
}

export const getSymbolAttributesUsed = (symbol: Symbol): AttributeUsed[] => {

    const links = getAllSymbolLinks(symbol);
    const attributesUsed: Map<string, AttributeUsed> = new Map();

    for (const linkItem of links) {
        const allLinks = getAllLinksByLink(linkItem.link);
        for (const link of allLinks) {
            if (link?.sourceAttributes?.mappings?.length) {
                parseAttributesMapping(attributesUsed, 'source', link, link.sourceAttributes.mappings, linkItem);
            }
            if (link?.targetAttributes?.mappings?.length) {
                parseAttributesMapping(attributesUsed, 'target', link, link.targetAttributes.mappings, linkItem);
            }
        }
    }

    return Array.from(attributesUsed.values());
}