import * as THREE from 'three';
import { MakeScenarioSymbol } from '../types/MakeScenarioSymbol';

export class ScenarioAppShape extends THREE.Mesh{

    constructor(color: string){
        super();

        this.geometry = this.createGeometry();
        this.material = this.createMaterial();

        (this.material as THREE.MeshLambertMaterial).color = new THREE.Color(color);
    }

    createGeometry(){
        const geometry = MakeScenarioSymbol.getGeometry();

        return geometry;
    }

    createMaterial(){
        const material = MakeScenarioSymbol.getMaterial();

        return material;
    }

    override clear(): this{
		return this.dispose();
	}

    dispose(){
        this.geometry.dispose();
        (this.material as any).dispose();

        super.clear();

        return this;
    }

}