import * as THREE from 'three';

import { BaseObject } from '../common/BaseObject';
import { ExtendedMaterial } from '../utils/materials/ExtendedMaterial';
import { LINK_INDICATOR_SIZE_FACTOR } from '../common/constants';
import { ExtensionOpacity } from '../utils/materials/extensions/ExtensionOpacity';
import { ExtensionTint } from '../utils/materials/extensions/ExtensionTint';
import { CosmosThree } from '../CosmosThree';

export class LinkIndicator extends BaseObject{

    constructor(){
        super();
    }
    
    static getGeometry(): THREE.BufferGeometry {
        const geometry = new THREE.ShapeGeometry(CosmosThree.linkIndicatorArrowSVGShape, 6)
            .center()
			.scale(LINK_INDICATOR_SIZE_FACTOR, LINK_INDICATOR_SIZE_FACTOR, LINK_INDICATOR_SIZE_FACTOR)
			.rotateX(-Math.PI / 2)
            .rotateY(Math.PI / 2);
        return geometry;
    }

    static getMaterial(){
        const material = new (ExtendedMaterial as any)(
            THREE.MeshLambertMaterial,
            [ExtensionTint, ExtensionOpacity],
            {
                transparent: true,
                alphaToCoverage: true, // This is super important so transparent geometries don't clip the ones behind even when they are fully transparent
                //wireframe: true
            },
            { debug: false }
        );

        return material;
    }
}