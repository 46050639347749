type Props = {
    className?: string,
}

export const CloudSuccess = ({className}: Props) => {
    return (
        <svg className={'w-4 h-4 ' + (className || '')} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.2002 12.9774L7.2252 15.2274L11.5002 9.82739" stroke="#2A9E00" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M14.7157 8.42571C15.0527 7.75271 15.2607 7.00371 15.2607 6.19971C15.2607 3.43871 13.0217 1.19971 10.2607 1.19971C7.77074 1.19971 5.72174 3.02571 5.34074 5.40771C5.00474 5.27771 4.64274 5.19971 4.26074 5.19971C2.60374 5.19971 1.26074 6.54271 1.26074 8.19971C1.26074 8.98671 1.57074 9.69771 2.06674 10.2327"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

