import * as THREE from 'three';

import * as signals from 'signals';

import { gsap } from "gsap";

import { Repository } from '../common/Repository';
import { INTERACTION_ANIMATION_SPEED_FAST, INTERACTION_ANIMATION_SPEED_SLOW, INTERACTION_ANIMATION_SPEED_SUPER_FAST, LINK_INDICATOR_DEFAULT_COLOR_DARK, LINK_INDICATOR_DEFAULT_COLOR_LIGHT, LINK_INDICATOR_TRIGGER_COLOR_DARK, LINK_INDICATOR_TRIGGER_COLOR_LIGHT, LINK_INDICATOR_VELOCITY, LINK_INDICATOR_Y_POSITION, LINK_INTERACTIONS_OFFSET, LINK_PORT_Y_POSITION, LINK_SHAPE_Y_POSITION, SYMBOL_HIDE_Y_POSITION, SYMBOL_PAD_RADIUS, SYMBOL_Y_POSITION } from '../common/constants';
import { ContextMenu } from '../utils/interaction/ContextMenu';
import { LinkIndicator } from './LinkIndicator';
import { LinkShape } from './LinkShape';
import { linkTypeMap, readLink, triggerLink, writeLink } from '@/utils/link';
import { CosmosThree } from '../CosmosThree';
import { LinkPort } from './LinkPort';
import { BaseLink, SerializedLink } from './BaseLink';
import { LinkInteractionsShape } from './LinkInteractionsShape';

export abstract class Link extends BaseLink{
	private _isTrigger = false;
	private _isRead = false;
	private _isWrite = false;

	static globalCount = 0;

	private _interactionsCount = 0;

	static interactionLayer = 1;

	private _type = "";

	private _portSource: LinkPort;
	private _portTarget: LinkPort;
	private _shape: LinkShape;
	private _interactionsShape: LinkInteractionsShape | null = null;

	indicatorTrigger: LinkIndicator | null = null;
	indicatorRead: LinkIndicator | null = null;
	indicatorWrite: LinkIndicator | null = null;

	tlIndicatorTrigger: gsap.core.Timeline | null = null;
	tlIndicatorRead: gsap.core.Timeline | null = null;
	tlIndicatorWrite: gsap.core.Timeline | null = null;

	private _hover = false;
	private _click = false;
	private _doubleclick = false;
	private _select = false;

	private _visible = false;
	private _filtered = false;
	private _muted = false;
	private _spotlight = false;

	hovered: signals.Signal;
	unhovered: signals.Signal;

	clicked: signals.Signal;
	doubleclicked: signals.Signal;

	selected: signals.Signal;
	unselected: signals.Signal;

	contextmenued: signals.Signal;

	private _tintTo: gsap.QuickToFunc;
	private _yTo: gsap.QuickToFunc;

	private _opacityShapeTo: gsap.QuickToFunc;
	private _opacityInteractionsShapeTo: gsap.QuickToFunc | null = null;

    constructor (json: SerializedLink) {
        super(json);

		if(linkTypeMap[this.originalData.type] === triggerLink){
			this.isTrigger = true;
		}else if(linkTypeMap[this.originalData.type] === readLink){
			this.isRead = true;
		}else if(linkTypeMap[this.originalData.type] === writeLink){
			this.isWrite = true;
		}

		for(let i = 0 ; i < this.originalData.allLinks.length; i++){
			const linkData = this.originalData.allLinks[i];

			if(linkTypeMap[linkData.type] === triggerLink){
				this.isTrigger = true;
			}else if(linkTypeMap[linkData.type] === readLink){
				this.isRead = true;
			}else if(linkTypeMap[linkData.type] === writeLink){
				this.isWrite = true;
			}
		}

		this._portSource = new LinkPort();
		this._portSource.instancedOrBatchedMesh = Repository.linksPortsMesh!;
		this._portSource.three.position.y = LINK_PORT_Y_POSITION;
		this.three.add(this._portSource.three);

		this._portTarget = new LinkPort();
		this._portTarget.instancedOrBatchedMesh = Repository.linksPortsMesh!;
		this._portTarget.three.position.y = LINK_PORT_Y_POSITION;
		this.three.add(this._portTarget.three);

		this._shape = new LinkShape();
		this._shape.instancedOrBatchedMesh = Repository.linksShapesMesh!;
		this._shape.three.position.y = LINK_SHAPE_Y_POSITION;
		this.three.add(this._shape.three);

		if(this._interactionsCount > 1){
			this._interactionsShape = new LinkInteractionsShape();
			this._interactionsShape.instancedOrBatchedMesh = Repository.linksInteractionsShapesMesh!;
			this._interactionsShape.three.position.y = LINK_SHAPE_Y_POSITION;
			this._interactionsShape.opacity = 0;
			this.three.add(this._interactionsShape.three);
		}

		if(this.isTrigger){
			this.indicatorTrigger = new LinkIndicator();
			this.indicatorTrigger.instancedOrBatchedMesh = Repository.linksIndicatorsMesh!;
			this.indicatorTrigger.three.position.y = LINK_INDICATOR_Y_POSITION;
			this.three.add(this.indicatorTrigger.three);
		}

		if(this.isRead){
			this.indicatorRead = new LinkIndicator();
			this.indicatorRead.instancedOrBatchedMesh = Repository.linksIndicatorsMesh!;
			this.indicatorRead.three.position.y = LINK_INDICATOR_Y_POSITION;
			this.three.add(this.indicatorRead.three);
		}

		if(this.isWrite){
			this.indicatorWrite = new LinkIndicator();
			this.indicatorWrite.instancedOrBatchedMesh = Repository.linksIndicatorsMesh!;
			this.indicatorWrite.three.position.y = LINK_INDICATOR_Y_POSITION;
			this.three.add(this.indicatorWrite.three);
		}

		this.globalInstanceId = Link.globalCount++;

		this.hovered = new signals.Signal();
		this.unhovered = new signals.Signal();

		this.clicked = new signals.Signal();
		this.doubleclicked = new signals.Signal();

		this.selected = new signals.Signal();
		this.unselected = new signals.Signal();

		this.contextmenued = new signals.Signal();
		this.contextmenued.add(this.onContextMenu, this);

		// Quick tween functions to boost performance even more
		this._tintTo = gsap.quickTo(this, "tint", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		this._yTo = gsap.quickTo(this.three.position, "y", { duration: INTERACTION_ANIMATION_SPEED_SLOW, ease: "power2.inOut", onUpdate: () => {
			this.matrixNeedsUpdate = true;
		} });
		this._opacityShapeTo = gsap.quickTo(this._shape, "opacity", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });
		if(this._interactionsShape) this._opacityInteractionsShapeTo = gsap.quickTo(this._interactionsShape, "opacity", { duration: INTERACTION_ANIMATION_SPEED_FAST, ease: "none" });

		if(this.indicatorTrigger) this.tlIndicatorTrigger = new gsap.core.Timeline({repeat: -1, repeatDelay: INTERACTION_ANIMATION_SPEED_FAST, paused: true});
		if(this.indicatorRead) this.tlIndicatorRead = new gsap.core.Timeline({repeat: -1, repeatDelay: INTERACTION_ANIMATION_SPEED_FAST, paused: true});
		if(this.indicatorWrite) this.tlIndicatorWrite = new gsap.core.Timeline({repeat: -1, repeatDelay: INTERACTION_ANIMATION_SPEED_FAST, paused: true});

		this.type = json.type;
	}

	get type() {
		return this._type;
	}

	set type(value) {
		if(this._type === value) return;
		this._type = value;
	}

	override get globalInstanceId(): number {
		return super.globalInstanceId;
	}

	override set globalInstanceId(value: number) {
		if(this.globalInstanceId === value) return;

		super.globalInstanceId = value;

		this._shape.globalInstanceId = value;
		if(this._interactionsShape) this._interactionsShape.globalInstanceId = value;
	}

	get active() {
		return super.active;
	}
	set active(value) {
		super.active = value;
		/* const prevColor = this.color.getHSL({} as THREE.HSL);
		prevColor.s = this._active ? prevColor.s : 0;
		this.color = new THREE.Color().setHSL(prevColor.h, prevColor.s, prevColor.l); */

		if(!value) this.color = CosmosThree.linksDisabledColor;
	}

	public get isTrigger() {
		return this._isTrigger;
	}
	public set isTrigger(value) {
		if (value === this._isTrigger) return;

		this._isTrigger = value;

		if(value) this._interactionsCount++;
	}

	public get isRead() {
		return this._isRead;
	}
	public set isRead(value) {
		if (value === this._isRead) return;

		this._isRead = value;

		if(value) this._interactionsCount++;
	}

	public get isWrite() {
		return this._isWrite;
	}
	public set isWrite(value) {
		if (value === this._isWrite) return;

		this._isWrite = value;

		if(value) this._interactionsCount++;
	}

	override get matrixNeedsUpdate() {
		return super.matrixNeedsUpdate;
	}
	override set matrixNeedsUpdate(value) {
		super.matrixNeedsUpdate = value;

		this._portSource.matrixNeedsUpdate = value;
		this._portTarget.matrixNeedsUpdate = value;
		this._shape.matrixNeedsUpdate = value;
		if(this._interactionsShape) this._interactionsShape.matrixNeedsUpdate = value;

		if(this.indicatorTrigger) this.indicatorTrigger.matrixNeedsUpdate = value;
		if(this.indicatorRead) this.indicatorRead.matrixNeedsUpdate = value;
		if(this.indicatorWrite) this.indicatorWrite.matrixNeedsUpdate = value;
	}

	override get color() {
		return super.color;
	}
	override set color(value: THREE.Color) {
		if (this.color.equals(value)) return;

		super.color = value;

		this._shape.color = this.color;
		if(this._interactionsShape) this._interactionsShape.color = this.color;

		if(Repository.mesh?.activeTheme === 'cosmosLight'){
			if(this.indicatorTrigger) this.indicatorTrigger.color = LINK_INDICATOR_TRIGGER_COLOR_LIGHT;
			if(this.indicatorRead) this.indicatorRead.color = LINK_INDICATOR_DEFAULT_COLOR_LIGHT;
			if(this.indicatorWrite) this.indicatorWrite.color = LINK_INDICATOR_DEFAULT_COLOR_LIGHT;
		}else{
			if(this.indicatorTrigger) this.indicatorTrigger.color = LINK_INDICATOR_TRIGGER_COLOR_DARK;
			if(this.indicatorRead) this.indicatorRead.color = LINK_INDICATOR_DEFAULT_COLOR_DARK;
			if(this.indicatorWrite) this.indicatorWrite.color = LINK_INDICATOR_DEFAULT_COLOR_DARK;
		}
	}

	override get tint() {
		return super.tint;
	}

	override set tint(value) {
		if(this.tint === value) return;

		super.tint = value;

		this._portSource.tint = value;
		this._portTarget.tint = value;
		this._shape.tint = value;
		if(this._interactionsShape) this._interactionsShape.tint = value;
		
		if(this.indicatorTrigger) this.indicatorTrigger.tint = value;
		if(this.indicatorRead) this.indicatorRead.tint = value;
		if(this.indicatorWrite) this.indicatorWrite.tint = value;
	}

	get hover() {
		return this._hover;
	}
	set hover(value) {
		if(this._hover === value){ return }

		this._hover = value;

		if(this._hover){
			this.hovered.dispatch(this);
		}else{
			this.unhovered.dispatch(this);
		}
	}

	get click() {
		return this._click;
	}

	set click(value) {
		if(this._filtered){ return }

		this._click = value;

		if(this._click){
			this.clicked.dispatch(this);
		}
	}

	get doubleclick() {
		return this._doubleclick;
	}

	set doubleclick(value) {
		if(this._filtered){ return }

		this._doubleclick = value;

		if(this._doubleclick){
			this.doubleclicked.dispatch(this);
		}
	}

	get select() {
		return this._select;
	}
	set select(value) {
		if(this._select === value){ return }

		this._select = value;

		if(this._select){
			this.selected.dispatch(this);
		}else{
			this.unselected.dispatch(this);
		}
	}

	get visible() {
		return this._visible;
	}
	set visible(value) {
		if(this._visible === value) return;

		this._visible = value;

		if(!this._filtered){
			if(!this._visible){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get filtered() {
		return this._filtered;
	}

	set filtered(value) {
		if(this._filtered === value) return;

		this._filtered = value;

		if(this._visible){
			if(this._filtered){
				this.muted = true;
				this.hideIn();
			}else{
				this.muted = false;
				this.hideOut();
			}
		}
	}

	get muted() {
		return this._muted;
	}

	set muted(value) {
		if(this._filtered || !this.visible){ return }
		if(this._muted === value) return;

		this._muted = value;

		if(this._muted){
			this.muteIn();
		}else{
			this.muteOut();
		}
	}

	get spotlight() {
		return this._spotlight;
	}
	set spotlight(value) {
		if(this._filtered || !this.visible){ return }

		this._spotlight = value;

		if(this._spotlight){
			this.muted = false;
			if(this._interactionsCount > 1) {
				this._opacityShapeTo(0);
				if(this._opacityInteractionsShapeTo) this._opacityInteractionsShapeTo(1);
			}

			if(this.tlIndicatorTrigger) this.tlIndicatorTrigger.play(0);
			if(this.tlIndicatorRead) this.tlIndicatorRead.play(0);
			if(this.tlIndicatorWrite) this.tlIndicatorWrite.play(0);
		}else{
			this.muted = false;
			if(this._interactionsCount > 1) {
				this._opacityShapeTo(1);
				if(this._opacityInteractionsShapeTo) this._opacityInteractionsShapeTo(0);
			}

			if(this.tlIndicatorTrigger) this.tlIndicatorTrigger.pause(0);
			if(this.tlIndicatorRead) this.tlIndicatorRead.pause(0);
			if(this.tlIndicatorWrite) this.tlIndicatorWrite.pause(0);
		}
	}

	update() {
		if (!this.source || !this.target) return;
	
		// Define the start and end points
		const pointSource = new THREE.Vector3(0, 0, 0);
	
		// Calculate the direction vector from source to target
		const directionVectorSource = this.target.three.position.clone().sub(this.source.three.position);
		directionVectorSource.normalize();
	
		// Offset the start point slightly in the direction of the target by the radius of the symbol pad
		pointSource.add(directionVectorSource.clone().multiplyScalar(SYMBOL_PAD_RADIUS));
	
		const pointTarget = this.target.three.position.clone().sub(this.source.three.position);
	
		// Calculate the direction vector from target to source
		const directionVectorTarget = this.target.three.position.clone().sub(this.source.three.position);
		directionVectorTarget.normalize();
	
		// Offset the end point slightly in the direction of the target by the radius of the symbol pad
		pointTarget.sub(directionVectorTarget.clone().multiplyScalar(SYMBOL_PAD_RADIUS));
	
		// Position and matrix updates
		this.three.position.x = this.source.three.position.x;
		this.three.position.z = this.source.three.position.z;
		this.matrixNeedsUpdate = true;
	
		// Update the link shape
		this._shape.updateGeometry(pointSource, pointTarget, this._isTrigger);
		if (this._interactionsShape) this._interactionsShape.updateGeometry(pointSource, pointTarget);
	
		// Set link color based on trigger status
		this.color = this._isTrigger ? CosmosThree.linksTriggerColor : CosmosThree.linksDefaultColor;
	
		// Position the ports
		this._portSource.three.position.x = pointSource.x;
		this._portSource.three.position.z = pointSource.z;
		this._portSource.matrixNeedsUpdate = true;
	
		this._portTarget.three.position.x = pointTarget.x;
		this._portTarget.three.position.z = pointTarget.z;
		this._portTarget.matrixNeedsUpdate = true;
	
		// Check if the link is active
		if (!this.source.active || !this.target.active) {
			this.active = false;
			return;
		}
	
		// Calculate a perpendicular offset vector to apply to each indicator if both indicators are present
		const applyLaneOffset = this.indicatorRead && this.indicatorWrite;
		const perpendicularOffset = applyLaneOffset ? new THREE.Vector3().crossVectors(directionVectorSource, new THREE.Vector3(0, 1, 0)).normalize() : new THREE.Vector3();
		const laneOffset = LINK_INTERACTIONS_OFFSET; // Offset distance for each lane
	
		// Update tlIndicatorTrigger if it exists
		if (this.tlIndicatorTrigger && this.indicatorTrigger) {
			const from = pointSource.clone();
			from.y = LINK_INDICATOR_Y_POSITION;

			const to = pointTarget.clone();
			to.y = LINK_INDICATOR_Y_POSITION;

			this.initIndicatorTimeline(from, to, this.tlIndicatorTrigger, this.indicatorTrigger);
		}
	
		// Update tlIndicatorRead with a lane offset if both indicators are present
		if (this.tlIndicatorRead && this.indicatorRead) {
			const from = pointTarget.clone();
			if (applyLaneOffset) from.add(perpendicularOffset.clone().multiplyScalar(laneOffset)); // Offset for Read lane
			from.y = LINK_INDICATOR_Y_POSITION;
	
			const to = pointSource.clone();
			if (applyLaneOffset) to.add(perpendicularOffset.clone().multiplyScalar(laneOffset));
			to.y = LINK_INDICATOR_Y_POSITION;

			this.initIndicatorTimeline(from, to, this.tlIndicatorRead, this.indicatorRead);
		}
	
		// Update tlIndicatorWrite with an opposite lane offset if both indicators are present
		if (this.tlIndicatorWrite && this.indicatorWrite) {
			const from = pointSource.clone();
			if (applyLaneOffset) from.sub(perpendicularOffset.clone().multiplyScalar(laneOffset)); // Offset for Write lane
			from.y = LINK_INDICATOR_Y_POSITION;

			const to = pointTarget.clone();
			if (applyLaneOffset) to.sub(perpendicularOffset.clone().multiplyScalar(laneOffset)); // Opposite offset for Write lane
			to.y = LINK_INDICATOR_Y_POSITION;
	
			this.initIndicatorTimeline(from, to, this.tlIndicatorWrite, this.indicatorWrite);
		}
	}
	
	initIndicatorTimeline(from: THREE.Vector3, to: THREE.Vector3, timeline: gsap.core.Timeline, indicator: LinkIndicator ){
        // Calculate direction vector
        const direction = new THREE.Vector3().subVectors(to, from).normalize();

        // Calculate the angle of rotation around the Y-axis
        const angle = Math.atan2(direction.x, direction.z);

        indicator.three.rotation.y = angle;

        // Calculate the length of the link
        const linkLength = from.distanceTo(to);

        // Set the duration based on the length of the link
        const duration = linkLength / LINK_INDICATOR_VELOCITY; // Define SOME_VELOCITY_CONSTANT based on your needs

		timeline.clear();
		timeline.add(gsap.fromTo(indicator, {opacity: 0}, {duration: INTERACTION_ANIMATION_SPEED_SUPER_FAST, opacity: 1, ease: "none"}), 0);
		timeline.add(gsap.fromTo(indicator.three.position, {x: from.x, z: from.z}, {duration: duration, x: to.x, z: to.z, ease: "none", onUpdate: () => { this.matrixNeedsUpdate = true; } }), 0);
		timeline.add(gsap.fromTo(indicator, {opacity: 1}, {duration: INTERACTION_ANIMATION_SPEED_SUPER_FAST, opacity: 0, ease: "none", immediateRender: false}), "-=" + INTERACTION_ANIMATION_SPEED_SUPER_FAST);
		timeline.pause(0);
	}

	onContextMenu(point: THREE.Vector2){
		const cm = new ContextMenu();
		this.createContextMenu(cm, point);
	}

	muteIn(){
		this._tintTo(1);
	}

	muteOut(){
		this._tintTo(0);
	}

	hide(){
		this.three.position.y = SYMBOL_HIDE_Y_POSITION;
		this.matrixNeedsUpdate = true;
	}

	hideIn(){
		this._yTo(SYMBOL_HIDE_Y_POSITION);
	}

	hideOut(){
		this._yTo(SYMBOL_Y_POSITION);
	}

	protected createContextMenu(cm: ContextMenu, point: THREE.Vector2): void {
		if (this.type) {
			cm.addItem(`Link type: ${this.type}`, 'arrow-up-right-from-square', () => {
				//
			})
		}

		cm.updatePosition(point);
	}

	override dispose(){
		this.hovered.removeAll();
		this.unhovered.removeAll();
		this.selected.removeAll();
		this.unselected.removeAll();
		this.contextmenued.removeAll();

		this._portSource.dispose();
		this._portTarget.dispose();
		this._shape.dispose();
		if(this._interactionsShape) this._interactionsShape.dispose();

		if(this.indicatorTrigger) this.indicatorTrigger.dispose();
		if(this.indicatorRead) this.indicatorRead.dispose();
		if(this.indicatorWrite) this.indicatorWrite.dispose();

		super.dispose();
	}
}