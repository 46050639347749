type Props = {
    className?: string,
}

export const Check = ({className}: Props) => {
    return (
        <svg className={'w-2.5 h-2 ' + (className || '')} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.88965 4.44447L3.88893 6.6659L8.10965 1.33447" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}
