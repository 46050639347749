type Props = {
    className?: string,
}

export const Funnel = ({className}: Props) => {
    return (
        <svg className={'h-[1rem] w-[1rem]' + className || ''} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.6744 12.0148C9.92949 11.8262 10.08 11.5278 10.08 11.2106V7.98373L13.293 3.80215C13.525 3.50013 13.5654 3.09252 13.397 2.75087C13.2287 2.40923 12.8809 2.19287 12.5 2.19287H3.5C3.11913 2.19287 2.77133 2.40923 2.60298 2.75087C2.43464 3.09252 2.47499 3.50013 2.70704 3.80215L5.92 7.98373V12.8071C5.92 13.1846 6.1326 13.53 6.46967 13.7C6.80673 13.87 7.21081 13.8357 7.5144 13.6113L9.6744 12.0148Z"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
